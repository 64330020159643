import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Spin, message, Icon } from 'antd';
import classNames from 'classnames';
import Card from '../../../../../../../components/Card/Card';
import { fetchDisruptionRiskScales } from '../../../BiaAction';
import { mtpdSelector } from '../../../../Plan/PlanSelectors';
import { disruptionRiskScalesSelector, loadingStrategicBiasSelector } from '../../../BiaSelectors';

const DISRUPTION_RISK_SCALE_SEVERITY = ['Negligible', 'Minor', 'Moderate', 'Major', 'Severe'];
const MSG_ERROR = 'Please select one scale from each category.';

const DisruptionRiskScale = ({
  mtpd,
  loading,
  disruptionRiskScales,
  fetchDisruptionRiskScales,
  strategicBia,
  save,
  explanations,
  onExplanationChange
}) => {
  const [rate, setRate] = useState({});

  useEffect(() => {
    fetchDisruptionRiskScales();
    const { strategicBiaDrsList } = strategicBia;
    const rate = {};
    if (strategicBiaDrsList) {
      strategicBiaDrsList.forEach(item => {
        const category = item.disruptionRiskScaleCategoryName;
        rate[category] = item.disruptionRiskScaleId;
      });
    }
    setRate(rate);
  }, [strategicBia, fetchDisruptionRiskScales]);

  const onClick = (category, item) => {
    setRate({ ...rate, [category]: item.disruptionRiskScaleId });
  };

  const onSave = () => {
    if (Object.keys(rate).length < disruptionRiskScales.length) {
      message.error(MSG_ERROR);
      return;
    }
    const data = { disruptionRiskScales: Object.values(rate) };
    save(true, data);
  };

  return strategicBia && disruptionRiskScales ? (
    <Card
      color="#343579"
      title="Thanks for taking time to rate"
      subtitle={`"${strategicBia.strategicCriticalArea.name}"`}
    >
      <div className="bia-sca-rate">
        <div className="bia-sca-rate-prompt">
          {`If "${strategicBia.strategicCriticalArea.name}" was unavailable for a period of "${mtpd.name}" what would it mean for the company?`}
        </div>
        {disruptionRiskScales.map(([category, disruptionRiskScaleList]) => (
          <div className="bia-sca-rate-category" key={category}>
            <div className="category-title">
              {category}
              <Icon
                type="info-circle-o"
                className="bia-info-icon"
                onClick={() => onExplanationChange(explanations[category] || explanations.default)}
              />
            </div>
            <Row type="flex" justify="space-around" className="category-list">
              {disruptionRiskScaleList
                .sort((a, b) => a.value - b.value)
                .map(item => (
                  <Col
                    span={4}
                    key={item.disruptionRiskScaleId}
                    onClick={() => onClick(category, item)}
                    className={classNames('category-item', { active: rate[category] === item.disruptionRiskScaleId })}
                  >
                    <div className="title">{DISRUPTION_RISK_SCALE_SEVERITY[item.value - 1]}</div>
                    <div className="rating">{item.value}</div>
                    <div className="description">{item.description}</div>
                  </Col>
                ))}
            </Row>
          </div>
        ))}
        <div className="btn-container center">
          <Button type="primary" className="bia-btn" onClick={onSave} loading={loading}>
            Next
          </Button>
        </div>
      </div>
    </Card>
  ) : (
    <Spin tip="Loading..." className="loading" />
  );
};

const mapStateToProps = state => ({
  mtpd: mtpdSelector(state),
  disruptionRiskScales: disruptionRiskScalesSelector(state),
  loading: loadingStrategicBiasSelector(state)
});

const mapDispatchToProps = { fetchDisruptionRiskScales };

export default connect(mapStateToProps, mapDispatchToProps)(DisruptionRiskScale);
