import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Empty } from 'antd';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import TeamType from './components/TeamType';
import { fetchTeam } from './TeamAction';
import { fetchContact } from '../../../Organisation/scenes/Contact/ContactAction';
import { orgIdSelector } from '../../../Organisation/OrganisationSelectors';
import { planIdSelector } from '../Plan/PlanSelectors';
import { teamListSelector, loadingTeamsSelector } from './TeamSelectors';
import { loadingContactsSelector } from '../../../Organisation/scenes/Contact/ContactSelectors';
import './Team.scss';

const INFO_MSG = (
  <>
    <p>
      There are typically 3 teams that support the Business Continuity Governance Committee and report to that
      committee. These are
    </p>
    <ol>
      <li>Crisis Management Team</li>
      <li>Information Technology Team</li>
      <li>Emergency Response Team</li>
    </ol>
    <p>The purpose and role of each team is explained as you complete the details below.</p>
  </>
);

const TEAMS = {
  'Emergency Response Team': {
    id: 'emergency-response-team',
    description:
      "The composition of your Emergency Response Teams is important so as to identify the possible functional areas and the team member's role. The Response Controller will determine which functions are required during a specific response."
  },
  'Information Technology Team': {
    id: 'information-technology-team',
    description:
      'The Information Technology Team is going to have a great responsibility for certain sections of your Business Continuity Plan (particularly related to Disaster Recovery). The Team may consist of internal staff or contractors where you outsource your Information and Communication Technology.'
  },
  'Crisis Management Team': {
    id: 'crisis-management-team',
    description: (
      <>
        <p>
          The Crisis Management Team consists of a small group of executives who have the resources, ability and
          authority to do whatever is necessary to resolve a crisis. The team will take operational ownership of the
          crisis response when a crisis has occurred or when a possible or probable crisis situation will exist.
        </p>
        <p>
          The Crisis Management Team is the highest level decision making authority at times of crisis. It has
          unrestricted authority to respond in the best interest of all stakeholders and its crisis response decisions
          will supersede the existence or normal interpretation of all or any policy or standard operating procedures.
        </p>
        <p>
          If the Organization plan covers multiple subsidiary organizations that have different directors or senior
          executives, these should be added here as this list even though they may not be responsible in each and every
          business continuity event.
        </p>
      </>
    )
  }
};

const Team = ({ teamList, teamsLoading, contactsLoading, orgId, planId, fetchTeam, fetchContact }) => {
  useEffect(() => {
    fetchTeam(planId);
  }, [fetchTeam, planId]);

  useEffect(() => {
    fetchContact(orgId);
  }, [fetchContact, orgId]);

  const scrollToAnchor = () => {
    let anchorName = window.location.hash;
    if (anchorName) {
      anchorName = anchorName.replace('#', '');
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        window.scrollTo(0, anchorElement.offsetTop);
      }
    }
  };

  useEffect(() => {
    if (!teamsLoading && !contactsLoading) {
      scrollToAnchor();
    }
  }, [teamsLoading, contactsLoading]);

  return (
    <>
      <ProcessBar color="#5BC2E7" title="Teams" prev="/dashboard/business-units" next="/dashboard/policy" />
      <InfoBox color="#5BC2E7" message={INFO_MSG} />
      {!teamsLoading && !contactsLoading ? (
        <Spin spinning={teamsLoading || contactsLoading}>
          <div className="component-wrapper">
            {teamList && teamList.length > 0 ? (
              teamList.map(team => (
                <div key={team.teamId} id={TEAMS[team.teamType.name].id}>
                  <TeamType team={team} description={(TEAMS[team.teamType.name] || {}).description} />
                </div>
              ))
            ) : (
              <div>
                <Empty
                  description={<p>Your plan has expired. Please renew plan subscription to view this section.</p>}
                />
              </div>
            )}
          </div>
        </Spin>
      ) : (
        <Spin tip="Loading Teams..." className="loading" />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  planId: planIdSelector(state),
  teamList: teamListSelector(state),
  teamsLoading: loadingTeamsSelector(state),
  contactsLoading: loadingContactsSelector(state)
});

const mapDispatchToProps = {
  fetchContact,
  fetchTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
