import { types } from './OrganisationAction';
import { USER_LOGOUT } from '../../components/Login/LoginAction';

const initialState = {
  loadingOrganisation: false,
  loadingOrganisations: false,
  loadingHazardIndex: false,
  loadingRoles: false,
  loadingOrganisationUsers: false,
  loadingOrganisationInvitees: false,
  loadingOrganisationBranches: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initialState };
    case types.REQUEST_ORGANISATION:
      return { ...state, loadingOrganisation: true };
    case types.RECEIVE_ORGANISATION:
      return { ...state, loadingOrganisation: false, businessInfo: action.businessInfo };
    case types.REQUEST_ORGANISATIONS:
      return { ...state, loadingOrganisation: true };
    case types.RECEIVE_ORGANISATIONS:
      return { ...state, loadingOrganisation: false, organisationList: action.organisationList };
    case types.REQUEST_HAZARD_INDEX:
      return { ...state, loadingHazardIndex: true };
    case types.RECEIVE_HAZARD_INDEX:
      return { ...state, loadingHazardIndex: false, hazardIndex: action.hazardIndex || state.hazardIndex };
    case types.REQUEST_ROLES:
      return { ...state, loadingRoles: true };
    case types.RECEIVE_ROLES:
      return { ...state, loadingRoles: false, roles: action.roles || state.roles };
    case types.REQUEST_ORGANISATION_USERS:
      return { ...state, loadingOrganisationUsers: true };
    case types.RECEIVE_ORGANISATION_USERS:
      return {
        ...state,
        loadingOrganisationUsers: false,
        organisationUsers: action.organisationUsers || state.organisationUsers
      };
    case types.REQUEST_ORGANISATION_INVITEES:
      return { ...state, loadingOrganisationInvitees: true };
    case types.RECEIVE_ORGANISATION_INVITEES:
      return {
        ...state,
        loadingOrganisationInvitees: false,
        organisationInvitees: action.organisationInvitees || state.organisationInvitees
      };
    case types.REQUEST_ORGANISATION_BRANCHES:
      return { ...state, loadingOrganisationBranches: true };
    case types.RECEIVE_ORGANISATION_BRANCHES: {
      const org = state.organisationList.find(org => org.organisationId === action.orgId);
      const newOrg = { ...org, branches: action.orgBranches };
      const newOrgList = state.organisationList.map(org =>
        org.organisationId === newOrg.organisationId ? newOrg : org
      );
      return {
        ...state,
        loadingOrganisationBranches: false,
        organisationList: newOrgList
      };
    }
    case types.STORE_GEOCODE:
      return { ...state, geoCode: action.geoCode };
    default:
      return state;
  }
};
