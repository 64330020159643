import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, Button } from 'antd';
import { loadingTeamsSelector } from '../TeamSelectors';

const FormItem = Form.Item;
const { Option } = Select;

const TeamContactEdit = ({ form, teamContactInfo = {}, loading, onUpdate, close }) => {
  const { getFieldDecorator } = form;

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const { contactId } = teamContactInfo;
        onUpdate(contactId, values);
        close();
      }
    });
  };

  return (
    <Form>
      <FormItem label="Designation">
        {getFieldDecorator('designation', {
          initialValue: teamContactInfo.designation
        })(
          <Select disabled={loading}>
            <Option key="primary" value="Primary">
              Primary
            </Option>
            <Option key="alternatives" value="Alternatives">
              Alternatives
            </Option>
          </Select>
        )}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loading} onClick={onSubmit}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  loading: loadingTeamsSelector(state)
});

export default connect(mapStateToProps)(Form.create()(TeamContactEdit));
