import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import StrategicBia from './scenes/StrategicBia/StrategicBia';
import TacticalBia from './scenes/TacticalBia/TacticalBia';
import OperationalBia from './scenes/OperationalBia/OperationalBia';
import './Bia.scss';

const routes = Object.freeze([
  { route: 'strategic', component: StrategicBia },
  { route: 'tactical', component: TacticalBia },
  { route: 'operational', component: OperationalBia }
]);

export default () => (
  <Switch>
    {routes.map(item => (
      <Route key={item.route} path={`/dashboard/business-impact-analysis/${item.route}`} component={item.component} />
    ))}
    <Redirect to="/not-found" />
  </Switch>
);
