import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const trackingId = process.env.REACT_APP_GA_IDENTIFIER;

if (trackingId) {
  ReactGA.initialize(trackingId);
}

const Tracker = ({ location }) => {
  useEffect(() => {
    const page = location.pathname;
    if (trackingId) {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    }
  }, [location.pathname]);

  return null;
};

export default withRouter(Tracker);
