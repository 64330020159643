import { types } from './RecoveryStrategyAction';

const initialState = { loadingRecoveryStrategyTypes: false, loadingRecoveryStrategies: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_RECOVERY_STRATEGY_TYPES:
      return { ...state, loadingRecoveryStrategyTypes: true };
    case types.RECEIVE_RECOVERY_STRATEGY_TYPES:
      return { ...state, loadingRecoveryStrategyTypes: false, recoveryStrategyTypes: action.recoveryStrategyTypes };
    case types.REQUEST_RECOVERY_STRATEGIES:
      return { ...state, loadingRecoveryStrategies: true };
    case types.RECEIVE_RECOVERY_STRATEGIES:
      return { ...state, loadingRecoveryStrategies: false, recoveryStrategy: action.recoveryStrategy };
    default:
      return state;
  }
};
