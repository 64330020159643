import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Divider, Spin, Tag, message } from 'antd';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import Table from '../../../../components/Table/Table';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import Import from '../../../../components/Import/Import';
import Export from '../../../../components/Export/Export';
import CustomerEdit from './components/CustomerEdit';
import * as actions from './CustomerAction';
import { orgIdSelector, businessNameSelector } from '../../OrganisationSelectors';
import { customerListSelector, loadingCustomersSelector } from './CustomerSelectors';
import { MSG_ERROR } from '../../../../constants';
import './Customer.scss';

const INFO_MSG = (
  <>
    <p>
      Being able to contact any or all of your organization&apos;s Strategic Customers can be extremely important in the
      event of a crisis.
    </p>
    <p>You have two options to complete this important section.</p>
    <ol>
      <li>
        For an organization with a relatively small number of Strategic Customers it may be easier to simply complete
        the fields below by using the &quot;+ Add new&quot; button.
      </li>
      <li>For larger organizations we suggest using the &quot;Import&quot; function.</li>
    </ol>
    <p>
      To Import your Strategic Customers first click the &quot;Import&quot; button so you can download the required
      Template. Ensure you complete the template without adding or deleting any columns, then save the spreadsheet and
      import it straight into ContinuityCoach by dragging and dropping the spreadsheet into the clearly marked
      &quot;drag and drop&quot; field, or click the field to allow a search of your computer&apos;s files.
    </p>
    <p>
      There are typically some important or &quot;Strategic Customers&quot; that you may feel obliged to contact in the
      event of a crisis which means you cannot fulfil their normal demand. By keeping your Strategic Customers up to
      date with developments typically means that relationships are maintained in the long term and they are more
      forgiving and understanding should a disruption to your supply to them occur.
    </p>
    <p>
      As with all parts of ContinuityCoach, the more time you invest in this planning process the more valuable the plan
      is likely to be in the event of a crisis.
    </p>
    <p>
      As you review your Strategic Customer list, consider just how important they are to your organization. If they are
      habitually late payers and always seeking discounts, are they really long term strategically important customers
      to your organization?
    </p>
  </>
);

const Customer = ({
  orgId,
  businessName,
  loading,
  customerList,
  fetchCustomer,
  deleteCustomer,
  exportCustomers,
  downloadCustomerImportTemplate,
  importCustomer
}) => {
  useEffect(() => {
    fetchCustomer(orgId);
  }, [orgId, fetchCustomer]);

  const onDelete = async customer => {
    try {
      await deleteCustomer(orgId, customer.customerId);
      message.success('The customer has been successfully deleted');
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const columns = [
    { key: 'name', title: 'Name', dataIndex: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      render: category => {
        try {
          const parsedCategory = JSON.parse(category);
          return (
            <div>
              {parsedCategory.map(tag => (
                <Tag className='category-name' key={tag}>
                  {tag}
                </Tag>
              )
              )}
            </div>
          )
        } catch {
          return (
            <Tag className='category-name' key={category}>
              {category}
            </Tag>
          )
        }
      }
    },
    {
      key: 'contactPerson',
      title: 'Contact Person',
      dataIndex: 'contactPerson'
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null)
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      render: text => (text ? <a href={`tel:${text}`}>{text}</a> : null)
    },
    {
      key: 'action',
      width: 115,
      render: (text, record) => (
        <span>
          <Modal explanation toggle={show => <Button type="link" icon="edit" onClick={show} />}>
            {(hide, setExplanation) => (
              <CustomerEdit customerInfo={record} close={hide} onExplanationChange={setExplanation} />
            )}
          </Modal>
          <Divider type="vertical" />
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
            <Button type="link" icon="delete" />
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <>
      <ProcessBar title="Strategic Customers" prev="/organization/contacts" next="/organization/suppliers" />
      <InfoBox message={INFO_MSG} />
      {customerList ? (
        <div className="component-wrapper">
          <Spin spinning={loading}>
            <Card
              title="Strategic Customers"
              description="Please add the details of your Strategic Customers. A Strategic Customer is one that plays a particular role in the continuation and/or survival of your business."
            >
              <Table
                title="Customers"
                columns={columns}
                dataSource={customerList}
                rowKey="customerId"
                searchFields={['name', 'category', 'contactPerson', 'email', 'phone']}
              />
              <div className="btn-container">
                <Export
                  fileName={`${businessName.replace(/\s+/g, '_')}_Customers.xlsx`}
                  exportFile={() => exportCustomers(orgId)}
                >
                  {onExport => (
                    <Button type="primary" icon="export" onClick={onExport}>
                      Export
                    </Button>
                  )}
                </Export>
                <Modal
                  title="Import Customers"
                  toggle={show => (
                    <Button type="primary" icon="contacts" onClick={show}>
                      Import
                    </Button>
                  )}
                >
                  {() => (
                    <Import
                      templateName="customers_template.xlsx"
                      downloadTemplate={downloadCustomerImportTemplate}
                      importFromTemplate={file => importCustomer(orgId, file)}
                    />
                  )}
                </Modal>
                <Modal
                  explanation
                  toggle={show => (
                    <Button type="primary" icon="plus" onClick={show}>
                      Add new
                    </Button>
                  )}
                >
                  {(hide, setExplanation) => <CustomerEdit close={hide} onExplanationChange={setExplanation} />}
                </Modal>
              </div>
            </Card>
          </Spin>
        </div>
      ) : (
          <Spin tip="Loading Customers..." className="loading" />
        )}
    </>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  businessName: businessNameSelector(state),
  customerList: customerListSelector(state),
  loading: loadingCustomersSelector(state)
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
