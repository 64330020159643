import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import { initialize } from './AppAction';

const App = ({ children, initialized, initialize }) => {
  useEffect(() => {
    initialize();
  }, [initialize]);

  return initialized ? children : <Spinner />;
};

const mapStateToProps = state => ({
  initialized: state.appReducer.initialized
});

const mapDispatchToProps = { initialize };

export default connect(mapStateToProps, mapDispatchToProps)(App);
