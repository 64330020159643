import axios from 'axios';
import { orgIdSelector } from '../../../Organisation/OrganisationSelectors';
import { planSelector } from './PlanSelectors';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_ALL_PLANS: 'plan/REQUEST_ALL_PLANS',
  RECEIVE_ALL_PLANS: 'plan/RECEIVE_ALL_PLANS',
  REQUEST_PLANS: 'plan/REQUEST_PLANS',
  RECEIVE_PLANS: 'plan/RECEIVE_PLANS',
  CURRENT_PLAN: 'plan/CURRENT_PLAN',
  REQUEST_CURRENCIES: 'plan/REQUEST_CURRENCIES',
  RECEIVE_CURRENCIES: 'plan/RECEIVE_CURRENCIES',
  REQUEST_PLAN_SUBSCRIPTIONS: 'plan/REQUEST_PLAN_SUBSCRIPTIONS',
  RECEIVE_PLAN_SUBSCRIPTIONS: 'plan/RECEIVE_PLAN_SUBSCRIPTIONS'
};

const requestAllPlans = () => ({ type: types.REQUEST_ALL_PLANS });
const receiveAllPlans = data => ({ type: types.RECEIVE_ALL_PLANS, allPlans: data });

const requestPlans = () => ({ type: types.REQUEST_PLANS });
const receivePlans = data => ({ type: types.RECEIVE_PLANS, plans: data });

const requestPlanSubscriptions = () => ({ type: types.REQUEST_PLAN_SUBSCRIPTIONS });
const receivePlanSubscriptions = (data, planId) => ({
  type: types.RECEIVE_PLAN_SUBSCRIPTIONS,
  planSubsciptions: data,
  planId
});

const requestCurrencies = () => ({ type: types.REQUEST_CURRENCIES });
const receiveCurrencies = data => ({ type: types.RECEIVE_CURRENCIES, currencies: data });

export const currentPlan = currentPlan => dispatch => {
  dispatch({ type: types.CURRENT_PLAN, currentPlan });
};

export const fetchAllPlans = () => async dispatch => {
  dispatch(requestAllPlans());
  const url = `${API_BASE}plans`;
  try {
    const res = await axios.get(url);
    dispatch(receiveAllPlans(res.data));
  } catch (err) {
    dispatch(receiveAllPlans());
  }
};

export const fetchPlans = orgId => async (dispatch, getState) => {
  dispatch(requestPlans());
  const org = orgId || orgIdSelector(getState());
  const url = `${API_BASE}plans`;
  try {
    const res = await axios.get(url, { params: { org } });
    const plans = res.data;
    dispatch(receivePlans(plans));
    if (plans.length > 0) {
      const planDates = plans.map(obj => new Date(obj.lastModifiedDate).getTime());
      const latestDate = Math.max.apply(null, planDates);
      const index = Math.max(
        0,
        planDates.findIndex(item => item === latestDate)
      );
      dispatch(currentPlan(plans[index]));
    } else {
      dispatch(currentPlan({}));
    }
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receivePlans());
    return Promise.reject(err);
  }
};

export const createPlan = data => async dispatch => {
  dispatch(requestPlans());
  const url = `${API_BASE}plans`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchPlans());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receivePlans());
    return Promise.reject(err);
  }
};

export const updatePlan = (planId, data) => async dispatch => {
  dispatch(requestPlans());
  const url = `${API_BASE}plans/${planId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchPlans());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receivePlans());
    return Promise.reject(err);
  }
};

export const deletePlan = planId => async dispatch => {
  dispatch(requestPlans());
  const url = `${API_BASE}plans/${planId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchPlans());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receivePlans());
    return Promise.reject(err);
  }
};

export const clonePlan = data => async dispatch => {
  dispatch(requestPlans());
  const url = `${API_BASE}plans/clone`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchPlans());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receivePlans());
    return Promise.reject(err);
  }
};

export const renewPlan = (planId, data) => async dispatch => {
  dispatch(requestPlans());
  const url = `${API_BASE}plans/${planId}/subscription`;
  try {
    const res = await axios.put(url, data);
    dispatch(fetchPlans());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receivePlans());
    return Promise.reject(err);
  }
};

export const fetchPlanSubscriptions = planId => async dispatch => {
  dispatch(requestPlanSubscriptions());
  const url = `${API_BASE}plans/${planId}/subscriptions`;
  try {
    const res = await axios.get(url);
    dispatch(receivePlanSubscriptions(res.data, planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receivePlanSubscriptions());
    return Promise.reject(err);
  }
};

export const fetchPlanProgress = () => async (dispatch, getState) => {
  const url = `${API_BASE}plans`;
  try {
    const res = await axios.get(url);
    const plans = res.data;
    const oldPlan = planSelector(getState());
    const newPlanData = plans.find(obj => obj.planId === oldPlan.planId) || {};
    dispatch(currentPlan({ ...oldPlan, progress: newPlanData.progress }));
  } catch (err) {
    console.error(err);
  }
};

export const fetchCurrencies = () => async dispatch => {
  dispatch(requestCurrencies());
  const url = `${API_BASE}currencies`;
  try {
    const res = await axios.get(url);
    dispatch(receiveCurrencies(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveCurrencies());
    return Promise.reject(err);
  }
};
