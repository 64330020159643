import React from 'react';
import Cover from './components/Cover/Cover';
import Main from './components/Main/Main';
import './RiskReportPreview.scss';

export default props => (
  <article className="risk-report-preview">
    <Cover />
    <Main {...props} />
  </article>
);

export { Cover, Main };
