import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import * as HttpStatus from 'http-status-codes';
import RegistrationForm from './components/RegistrationForm';
import MFAForm from './components/MFAForm';
import {
  sendOtp,
  verifyOtp,
  login,
  register,
  sendConfirmationEmail,
  sendConfirmationRequest,
  setErrorMessage,
  loginStatus
} from './LoginAction';
import { fetchBroker } from '../../scenes/Admin/scenes/Broker/BrokerActions';
import { brokerInfoSelector } from '../../scenes/Admin/scenes/Broker/BrokerSelectors';
import './Login.scss';

const { TabPane } = Tabs;

const Login = ({ loginStatus, registerStatus, setErrorMessage, close, ...otherProps }) => {
  useEffect(() => {
    setErrorMessage('');
  }, [close, setErrorMessage]);

  const onCancel = () => {
    setErrorMessage('');
    close();
  };

  const onChange = () => {
    setErrorMessage('');
  };

  return (
    <Tabs onChange={onChange}>
      <TabPane tab="Login" key="login-2fa">
        <MFAForm
          loginHasError={loginStatus !== HttpStatus.OK && loginStatus !== HttpStatus.FORBIDDEN}
          needResend={loginStatus === HttpStatus.FORBIDDEN}
          setErrorMessage={setErrorMessage}
          {...otherProps}
        />
      </TabPane>
      <TabPane tab="Register" key="signup">
        <RegistrationForm
          registerHasError={registerStatus !== HttpStatus.CREATED}
          cancelModal={onCancel}
          {...otherProps}
        />
      </TabPane>
    </Tabs>
  );
};

const mapStateToProps = state => ({
  brokerInfo: brokerInfoSelector(state),
  ...state.loginReducer
});

const mapDispatchToProps = {
  login,
  register,
  sendConfirmationEmail,
  setSendConfirmationRequest: sendConfirmationRequest,
  setErrorMessage,
  fetchBroker,
  sendOtp,
  verifyOtp,
  setLoginStatus: loginStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
