import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import PolicyMember from './components/PolicyMember';
import { fetchPlanPolicy, createPlanPolicy } from './PolicyAction';
import { planIdSelector } from '../../../Dashboard/scenes/Plan/PlanSelectors';
import { planPolicyMembersSelector } from './PolicySelectors';

const PlanPolicy = ({ planId, planPolicyMembers, loading, fetchPlanPolicy, createPlanPolicy }) => {
  useEffect(() => {
    fetchPlanPolicy(planId);
  }, [planId, fetchPlanPolicy]);

  const addMember = memberContacts => {
    const data = { contacts: memberContacts.map(obj => obj.contactId) };
    createPlanPolicy(planId, data);
  };

  return (
    <>
      <ProcessBar
        color="#5BC2E7"
        title="Business Continuity Policy Accountable Members"
        prev="/dashboard/teams"
        next="/dashboard/business-impact-analysis/strategic"
      />
      <InfoBox
        color="#5BC2E7"
        message="This area is used to compile data required to build your Business Continuity Policy"
      />
      <div className="component-wrapper">
        {!loading || planPolicyMembers ? (
          <PolicyMember
            color="#5BC2E7"
            title="Business Continuity Policy Accountable Members"
            members={planPolicyMembers}
            addMember={addMember}
          />
        ) : (
          <Spin className="loading" />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  planId: planIdSelector(state),
  planPolicyMembers: planPolicyMembersSelector(state),
  loading: state.policyReducer.loadingPlanPolicy
});

const mapDispatchToProps = { fetchPlanPolicy, createPlanPolicy };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanPolicy);
