import { createSelector } from 'reselect';
import _ from 'lodash';

export const biaListSelector = state => state.biaReducer.biaList;

export const criticalBiaListSelector = createSelector(biaListSelector, biaList =>
  biaList ? biaList.filter(item => !!item.strategicBiaId) : undefined
);

// const processStrategicBias = biaList => {
//   const test = biaList
//     ? _.chain(biaList)
//         .groupBy(item => item.strategicCriticalArea.strategicCriticalAreaCategory.name)
//         .map((list, category) => [category, list])
//         .value()
//     : undefined;
//   // group by category name
//   const groupedStrategicBias = _.groupBy(biaList, obj => obj.strategicCriticalArea.strategicCriticalAreaCategory.name);
//   // sort by category name
//   let scaListByCategory = Object.entries(groupedStrategicBias).sort((a, b) => a[0] > b[0]);
//   // sort sca array in each category by scaId
//   scaListByCategory = scaListByCategory.map(([category, arr]) => {
//     const sortedScaList = arr.sort(
//       (a, b) => a.strategicCriticalArea.strategicCriticalAreaId - b.strategicCriticalArea.strategicCriticalAreaId
//     );
//     return [category, sortedScaList];
//   });
//   return scaListByCategory;
// };

const getStrategicBias = biaList =>
  biaList
    ? _.chain(biaList)
        .groupBy(item => item.strategicCriticalArea.strategicCriticalAreaCategory.name)
        .map((list, category) => [category, list])
        .value()
    : undefined;

export const loadingStrategicBiasSelector = state => state.biaReducer.loadingStrategicBias;

export const strategicBiasSelector = createSelector(biaListSelector, biaList => getStrategicBias(biaList));

export const criticalStrategicBiasSelector = createSelector(criticalBiaListSelector, criticalBiaList =>
  getStrategicBias(criticalBiaList)
);

export const drsCategoriesSelector = state => state.biaReducer.drsCategories;

export const drsListSelector = createSelector(biaListSelector, biaList =>
  biaList ? biaList.map(item => item.strategicBiaDrsList || []).reduce((a, b) => [...a, ...b], []) : undefined
);

export const drsHasDataSelector = createSelector(drsListSelector, drsList => drsList && drsList.length > 0);

export const criticalPeriodPresetsSelector = state => state.biaReducer.criticalPeriodPresets;

export const mtpdListSelector = state => state.biaReducer.mtpdList;

export const disruptionRiskScalesSelector = createSelector(
  state => state.biaReducer.disruptionRiskScales,
  disruptionRiskScales =>
    disruptionRiskScales
      ? _.chain(disruptionRiskScales)
          .groupBy(item => item.disruptionRiskScaleCategory.name)
          .map((disruptionRiskScaleList, category) => [category, disruptionRiskScaleList])
          .value()
      : undefined
);

export const loadingTacticalBiasSelector = state => state.biaReducer.loadingTacticalBias;

export const tacticalBiasSelector = state => state.biaReducer.tacticalBias;

export const assetTypesSelector = state => state.biaReducer.assetTypes;

export const loadingAssetsSelector = state => state.biaReducer.loadingAssets;

export const assetsSelector = createSelector(
  state => state.biaReducer.assets,
  assets =>
    assets
      ? _.chain(assets)
          .groupBy('departmentId')
          .mapValues(item => _.groupBy(item, 'assetTypeId'))
          .value()
      : undefined
);
