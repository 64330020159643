import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_TEAMS: 'team/REQUEST_TEAMS',
  RECEIVE_TEAMS: 'team/RECEIVE_TEAMS'
};

const requestTeams = () => ({ type: types.REQUEST_TEAMS });
const receiveTeams = data => ({ type: types.RECEIVE_TEAMS, teamList: data });

export const updateTeam = (planId, teamId, data) => () => {
  const url = `${API_BASE}plans/${planId}/teams/${teamId}`;
  return axios.patch(url, data);
};

export const deleteTeam = (planId, teamId) => () => {
  const url = `${API_BASE}plans/${planId}/teams/${teamId}`;
  return axios.delete(url);
};

export const fetchTeam = planId => async dispatch => {
  dispatch(requestTeams());
  const url = `${API_BASE}plans/${planId}/teams`;
  try {
    const res = await axios.get(url);
    dispatch(receiveTeams(res.data));
  } catch (err) {
    dispatch(receiveTeams());
  }
};

export const createTeamContact = (planId, teamId, data) => async dispatch => {
  dispatch(requestTeams());
  const url = `${API_BASE}plans/${planId}/teams/${teamId}/contacts`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchTeam(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveTeams());
    return Promise;
  }
};

export const updateTeamContact = (planId, teamId, contactId, data) => async dispatch => {
  dispatch(requestTeams());
  const url = `${API_BASE}plans/${planId}/teams/${teamId}/contacts/${contactId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchTeam(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveTeams());
    return Promise;
  }
};

export const deleteTeamContact = (planId, teamId, contactId) => async dispatch => {
  dispatch(requestTeams());
  const url = `${API_BASE}plans/${planId}/teams/${teamId}/contacts/${contactId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchTeam(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveTeams());
    return Promise;
  }
};
