import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import classNames from 'classnames';
import './Collapse.scss';

export default class Collapse extends Component {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    descriptionColor: PropTypes.string,
    icon: PropTypes.node,
    showArrow: PropTypes.bool
  };

  static defaultProps = {
    color: '#F1C400',
    title: null,
    description: null,
    descriptionColor: '#999',
    icon: null,
    showArrow: true
  };

  state = {
    open: false
  };

  toggle = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const { children, color, title, description, descriptionColor, icon, showArrow } = this.props;
    const { open } = this.state;
    const arrow = open ? 'caret-up' : 'caret-down';
    return (
      <div className="collapse">
        {/* eslint-disable */}
        <div className={classNames('collapse-title', { border: open })} onClick={this.toggle}>
          {/* eslint-enable */}
          {title && (
            <div className="title single-line">
              {title}
              {icon}
            </div>
          )}
          {description && (
            <div className="description" style={{ color: descriptionColor }}>
              {description}
            </div>
          )}
          {showArrow && <Icon type={arrow} style={{ color }} className="collapse-toggle" />}
        </div>
        <div className={classNames('collapse-container', { close: !open })}>{children}</div>
      </div>
    );
  }
}
