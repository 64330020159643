import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Select, Button } from 'antd';
import { contactListSelector } from '../../../../Organisation/scenes/Contact/ContactSelectors';
import { loadingTeamsSelector } from '../TeamSelectors';

const FormItem = Form.Item;
const { Option } = Select;
const DESIGNATION = {
  PRIMARY: 'Primary',
  ALTERNATIVES: 'Alternatives'
};

class TeamContactAdd extends Component {
  state = { selectedContacts: [] };

  onSubmit = e => {
    e.preventDefault();
    const { form, onCreate, close } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        const primaryContacts = (values.primary || []).map(item => ({
          contactId: item,
          designation: DESIGNATION.PRIMARY
        }));
        const alternativeContacts = (values.alternatives || []).map(item => ({
          contactId: item,
          designation: DESIGNATION.ALTERNATIVES
        }));
        onCreate([...primaryContacts, ...alternativeContacts]);
        close();
      }
    });
  };

  onContactSelect = value => {
    this.setState(({ selectedContacts }) => ({ selectedContacts: [...selectedContacts, value] }));
  };

  render() {
    const { form, teamInfo, contactList = [], loading } = this.props;
    const { getFieldDecorator } = form;
    const { selectedContacts } = this.state;
    const { teamContacts } = teamInfo;
    const contactOptions = contactList.map(contact => (
      <Option
        key={contact.email}
        value={contact.contactId}
        label={`${contact.firstName} ${contact.lastName}`}
        disabled={
          (teamContacts && !!teamContacts.find(obj => obj.contactId === contact.contactId)) ||
          selectedContacts.includes(contact.contactId)
        }
      >
        {contact.jobTitle
          ? `${contact.firstName} ${contact.lastName} - ${contact.jobTitle}`
          : `${contact.firstName} ${contact.lastName}`}
      </Option>
    ));

    return (
      <Form>
        <FormItem label="Primary Contacts">
          {getFieldDecorator('primary')(
            <Select
              mode="multiple"
              placeholder="Select from contacts"
              disabled={loading}
              showSearch
              notFoundContent="Not Found"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              optionLabelProp="label"
              onSelect={this.onContactSelect}
            >
              {contactOptions}
            </Select>
          )}
        </FormItem>
        <FormItem label="Alternative Contacts">
          {getFieldDecorator('alternatives')(
            <Select
              mode="multiple"
              placeholder="Select from contacts"
              disabled={loading}
              showSearch
              notFoundContent="Not Found"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              optionLabelProp="label"
              onSelect={this.onContactSelect}
            >
              {contactOptions}
            </Select>
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" block loading={loading} onClick={this.onSubmit}>
            {loading ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  contactList: contactListSelector(state),
  loading: loadingTeamsSelector(state)
});

export default connect(mapStateToProps)(Form.create()(TeamContactAdd));
