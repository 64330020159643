import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Icon } from 'antd';

const TEAMS = [
  { key: 'ERT', name: 'Emergency Response Team', id: 'emergency-response-team', icon: 'medicine-box' },
  { key: 'CMT', name: 'Crisis Management Team', id: 'crisis-management-team', icon: 'bar-chart' },
  { key: 'IT', name: 'IT Recovery Team', id: 'information-technology-team', icon: 'desktop' }
];

export default () => (
  <Row gutter={20} type="flex" className="dashboard-team-action">
    {TEAMS.map(item => (
      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} key={item.key} className="flex">
        <div className="team-action-item">
          <div className="team-action-item-info">
            <div className="name">{item.name}</div>
            <Icon type={item.icon} className="icon" />
          </div>
          <div className="team-action-item-actions">
            <Link to={`/dashboard/teams#${item.id}`}>
              <Button type="link">GO TO TEAM</Button>
            </Link>
            <Link to={`/dashboard/plan-document#${item.id}`}>
              <Button type="link">GO TO PLAN</Button>
            </Link>
          </div>
        </div>
      </Col>
    ))}
  </Row>
);
