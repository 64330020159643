import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { Row, Col, Tooltip } from 'antd';
import ErrorBoundary from '../../../components/Error/ErrorBoundary';
import Card from '../../../components/Card/Card';
import { ReactComponent as ExpiryIcon } from '../assets/expiry-icon.svg';
import DashboardTeamAction from './DashboardTeamAction';
import {
  Progress,
  BusinessLocation,
  HazardIndex,
  RaChart,
  BiaChart,
  BiaDetail,
  RtoRpoChart
} from '../../../components/widget';
import { fetchBranch } from '../../Organisation/scenes/Branch/BranchAction';
import { fetchAlternativeSite } from '../../Organisation/scenes/AlternativeSite/AlternativeSiteAction';
import { fetchCustomer } from '../../Organisation/scenes/Customer/CustomerAction';
import { fetchSupplier } from '../../Organisation/scenes/Supplier/SupplierAction';
import { fetchStrategicThirdParty } from '../../Organisation/scenes/StrategicThirdParty/StrategicThirdPartyAction';
import { fetchPlanProgress, fetchPlanSubscriptions } from '../scenes/Plan/PlanAction';
import { fetchStrategicBia, fetchTacticalBia, fetchDrsCategories } from '../scenes/Bia/BiaAction';
import { fetchRisks, fetchRiskCategories, fetchRiskLikelihoodPresets } from '../scenes/Risk/RiskAction';
import { fetchHazardIndex, storeGeoCode } from '../../Organisation/OrganisationAction';
import { planSelector, planIdSelector, planExpiryDateSelector } from '../scenes/Plan/PlanSelectors';
import {
  geoCodeSelector,
  orgIdSelector,
  industryCodeSelector,
  hazardIndexSelector,
  industryNameSelector
} from '../../Organisation/OrganisationSelectors';
import { alternativeSiteListSelector } from '../../Organisation/scenes/AlternativeSite/AlternativeSiteSelectors';
import { branchesSelector } from '../../Organisation/scenes/Branch/BranchSelectors';
import { customerListSelector } from '../../Organisation/scenes/Customer/CustomerSelectors';
import { supplierListSelector } from '../../Organisation/scenes/Supplier/SupplierSelectors';
import { strategicThirdPartyListSelector } from '../../Organisation/scenes/StrategicThirdParty/StrategicThirdPartySelectors';
import { risksSelector, riskCategoriesSelector, riskLikelihoodPresetsSelector } from '../scenes/Risk/RiskSelectors';
import {
  strategicBiasSelector,
  drsCategoriesSelector,
  drsHasDataSelector,
  tacticalBiasSelector
} from '../scenes/Bia/BiaSelectors';

class DashboardMainPanel extends Component {
  state = { showBiaDetail: false };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { orgId, industryCode, planId } = this.props;
    /* eslint react/destructuring-assignment: off */
    this.props.fetchBranch(orgId);
    this.props.fetchPlanProgress();
    this.props.fetchRiskCategories();
    this.props.fetchRiskLikelihoodPresets();
    this.props.fetchRisks(planId);
    this.props.fetchCustomer(orgId);
    this.props.fetchSupplier(orgId);
    this.props.fetchAlternativeSite(orgId);
    this.props.fetchStrategicBia(planId);
    this.props.fetchTacticalBia(planId);
    this.props.fetchDrsCategories();
    this.props.fetchHazardIndex(industryCode);
    this.props.fetchStrategicThirdParty(orgId);
    this.props.fetchPlanSubscriptions(planId);
  };

  toggleBia = () => {
    this.setState(({ showBiaDetail }) => ({ showBiaDetail: !showBiaDetail }));
  };

  getExpiryStatus = expiry => {
    if (moment().isAfter(moment(expiry, 'DD/MM/YYYY'))) {
      return 'expired';
    }
    if (moment(expiry, 'DD/MM/YYYY').diff(moment(), 'M', true) > 1) {
      return 'not-expired';
    }
    return 'expiring-soon';
  };

  render() {
    const {
      geoCode = {},
      industryName,
      branches,
      hazardIndex,
      currentPlan,
      risks,
      riskCategories,
      riskLikelihoodPresets = [],
      customerList,
      supplierList,
      alternativeSiteList,
      strategicBias,
      tacticalBias,
      drsCategories,
      drsHasData,
      expiry
    } = this.props;
    const { showBiaDetail } = this.state;

    return (
      <div className="dashboard-container">
        <div className="dashboard-info">
          {expiry && (
            <Tooltip title="Expiry Date" placement="right">
              <div className="expiry-date-container">
                <ExpiryIcon className={classNames('expiry-date-icon', this.getExpiryStatus(expiry))} />
                <div className="expiry-date">{expiry}</div>
              </div>
            </Tooltip>
          )}
        </div>
        <DashboardTeamAction />
        {showBiaDetail ? (
          <BiaDetail toggleBia={this.toggleBia} />
        ) : (
          <Row type="flex" gutter={20}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Card
                color="#F1C400"
                title="Progress"
                description="The following bar graph will display your progress through the different sections of your plan. You will not be able to Implement your plan until Policy & Program, Analysis & Planning and Planning & Design each reach 100%."
              >
                <ErrorBoundary>
                  <Progress progress={currentPlan.progress} />
                </ErrorBoundary>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Card
                color="#343579"
                title="Business Locations"
                description="The Map below plots your Branches, Strategic Customers and Suppliers and your Short Term Recovery Sites. Additional pins can be added from the Organization area."
              >
                <ErrorBoundary>
                  <BusinessLocation
                    branches={branches}
                    customerList={customerList}
                    supplierList={supplierList}
                    alternativeSiteList={alternativeSiteList}
                    geoCode={geoCode}
                    storeGeoCode={storeGeoCode}
                  />
                </ErrorBoundary>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Card
                color="#CB2C30"
                title="Hazard Index"
                description="The following chart is a graphical representation or the likelihood and severity of a loss occurring within any of the 13 classes of insurance listed in the chart."
              >
                <ErrorBoundary>
                  <HazardIndex industryName={industryName} data={hazardIndex} />
                </ErrorBoundary>
              </Card>
            </Col>
            {risks && Object.entries(risks).length > 0 && riskCategories && riskLikelihoodPresets.length > 0 && (
              <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                <Card
                  color="#00685E"
                  title="Risk Analysis"
                  description="The following bubble chart provides a graphical representation of your organization's monetary exposure to an adverse impact against time. The graph shows both your total and annualized monetary exposure. Changes to this chart can be made from the Risk and Threat Assessment sections."
                >
                  <ErrorBoundary>
                    <RaChart
                      risks={risks}
                      riskCategories={riskCategories}
                      riskLikelihoodPresets={riskLikelihoodPresets}
                    />
                  </ErrorBoundary>
                </Card>
              </Col>
            )}
            {strategicBias && drsCategories && drsHasData && (
              <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                <Card
                  color="#343579"
                  title="Business Impact Analysis"
                  description="The following chart identifies the scale of severity of a potential impact to the Strategic Areas of your business that you have identified. Changes to the scales are made in the Strategic BIA area of the Business Impact Analysis."
                  onMore={this.toggleBia}
                >
                  <ErrorBoundary>
                    <BiaChart strategicBias={strategicBias} drsCategories={drsCategories} />
                  </ErrorBoundary>
                </Card>
              </Col>
            )}
            {tacticalBias && tacticalBias.length > 0 && currentPlan.mtpd && (
              <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                <Card
                  color="#343579"
                  title="Recovery Time Objective and Recovery Point Objective (RTO-RPO)"
                  description="The following chart shows your Recovery Time Objectives and Recovery Point Objectives set against your chosen Maximum Tolerable Period of Disruption. This information can be added/altered from the Tactical BIA (Business Impact Analysis) in the Analysis and Planning section."
                >
                  <ErrorBoundary>
                    <RtoRpoChart tacticalBias={tacticalBias} mtpd={currentPlan.mtpd} />
                  </ErrorBoundary>
                </Card>
              </Col>
            )}
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  geoCode: geoCodeSelector(state),
  branches: branchesSelector(state),
  industryName: industryNameSelector(state),
  industryCode: industryCodeSelector(state),
  hazardIndex: hazardIndexSelector(state),
  alternativeSiteList: alternativeSiteListSelector(state),
  customerList: customerListSelector(state),
  supplierList: supplierListSelector(state),
  strategicThirdPartyList: strategicThirdPartyListSelector(state),
  currentPlan: planSelector(state),
  planId: planIdSelector(state),
  risks: risksSelector(state),
  riskCategories: riskCategoriesSelector(state),
  riskLikelihoodPresets: riskLikelihoodPresetsSelector(state),
  strategicBias: strategicBiasSelector(state),
  tacticalBias: tacticalBiasSelector(state),
  drsCategories: drsCategoriesSelector(state),
  drsHasData: drsHasDataSelector(state),
  expiry: planExpiryDateSelector(state)
});

const mapDispatchToProps = {
  storeGeoCode,
  fetchBranch,
  fetchPlanProgress,
  fetchRisks,
  fetchRiskCategories,
  fetchRiskLikelihoodPresets,
  fetchCustomer,
  fetchSupplier,
  fetchAlternativeSite,
  fetchStrategicBia,
  fetchTacticalBia,
  fetchDrsCategories,
  fetchHazardIndex,
  fetchStrategicThirdParty,
  fetchPlanSubscriptions
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMainPanel);
