import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Logo from '../../../../../../../../components/Logo/Logo';
import { businessNameSelector, businessLogoSelector } from '../../../../../../../Organisation/OrganisationSelectors';
import { planNameSelector, planDateSelector } from '../../../../../Plan/PlanSelectors';
import './Cover.scss';

const Cover = ({ businessName, businessLogo, planName, planDate }) => (
  <section className="plan-preview-cover">
    <Logo className="lmi-logo" />
    <div className="organisation">
      <div className="organisation-logo" style={{ backgroundImage: `url(${businessLogo}` }} />
      <div className="organisation-name">{businessName || 'PREPARED FOR\nORGANISATION\nNAME'}</div>
      <div className="organisation-date">{`DATE ISSUED ${moment(planDate).format('DD/MM/YYYY')}`}</div>
    </div>
    <div className="title">
      <h3>{planName}</h3>
      <h2>
        <strong>BUSINESS</strong>
        <br />
        CONTINUITY
      </h2>
      <h1>PLAN</h1>
    </div>
  </section>
);

const mapStateToProps = state => ({
  businessName: businessNameSelector(state),
  businessLogo: businessLogoSelector(state),
  planName: planNameSelector(state),
  planDate: planDateSelector(state)
});

export default connect(mapStateToProps)(Cover);
