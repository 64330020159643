import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Popconfirm, Divider, message } from 'antd';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import Table from '../../../../components/Table/Table';
import BranchEdit from './components/BranchEdit';
import { fetchBranch, deleteBranch } from './BranchAction';
import { orgIdSelector } from '../../OrganisationSelectors';
import { branchesSelector, loadingBranchesSelector } from './BranchSelectors';
import { MSG_ERROR } from '../../../../constants';

const Branch = ({ orgId, branches, loading, fetchBranch, deleteBranch }) => {
  useEffect(() => {
    fetchBranch(orgId);
  }, [orgId, fetchBranch]);

  const onDelete = async branch => {
    try {
      await deleteBranch(orgId, branch.branchId);
      message.success('The branch has been successfully deleted');
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const columns = [
    { key: 'name', title: 'Name', dataIndex: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
    { key: 'address', title: 'Address', dataIndex: 'address' },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null)
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      render: text => (text ? <a href={`tel:${text}`}>{text}</a> : null)
    },
    {
      key: 'action',
      width: 115,
      render: (text, record) => (
        <span>
          <Modal explanation toggle={show => <Button type="link" icon="edit" onClick={show} />}>
            {(hide, setExplanation) => (
              <BranchEdit branchInfo={record} close={hide} onExplanationChange={setExplanation} />
            )}
          </Modal>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this branch? All plans under this branch will be deleted too."
            onConfirm={() => onDelete(record)}
          >
            <Button type="link" icon="delete" />
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <Spin spinning={loading}>
      <Card title="Business Locations">
        <Table
          title="Business Locations"
          columns={columns}
          dataSource={branches}
          rowKey="branchId"
          searchFields={['name', 'address', 'email', 'phone']}
        />
        <div className="btn-container">
          <Modal
            explanation
            toggle={show => (
              <Button type="primary" icon="plus" onClick={show}>
                Add new
              </Button>
            )}
          >
            {(hide, setExplanation) => <BranchEdit close={hide} onExplanationChange={setExplanation} />}
          </Modal>
        </div>
      </Card>
    </Spin>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  branches: branchesSelector(state),
  loading: loadingBranchesSelector(state)
});

const mapDispatchToProps = { fetchBranch, deleteBranch };

export default connect(mapStateToProps, mapDispatchToProps)(Branch);
