import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Affix, Spin, Tabs, Divider, Popconfirm, Button, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import ProcessBar from '../../../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../../../components/Card/Card';
import Table from '../../../../../../components/Table/Table';
import Alert from '../../../../../../components/Alert/Alert';
import InfoBox from '../../../../../../components/InfoBox/InfoBox';
import InformationPanel from '../../../../../../components/InformationPanel/InformationPanel';
import Import from '../../../../../../components/Import/Import';
import Export from '../../../../../../components/Export/Export';
import Modal from '../../../../../../components/Modal/Modal';
import NavList from '../../components/NavList';
import AssetEdit from './components/AssetEdit';
import {
  fetchAssetTypes,
  fetchAssets,
  deleteAssets,
  downloadAssetImportTemplate,
  importAssets,
  exportAssets
} from '../../BiaAction';
import { fetchBranch } from '../../../../../Organisation/scenes/Branch/BranchAction';
import { orgIdSelector } from '../../../../../Organisation/OrganisationSelectors';
import { planIdSelector, planNameSelector } from '../../../Plan/PlanSelectors';
import { departmentListSelector } from '../../../Department/DepartmentSelectors';
import { assetTypesSelector, assetsSelector, loadingAssetsSelector } from '../../BiaSelectors';
import explanations from '../../data/explanations.json';
import { MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../../constants';

const { TabPane } = Tabs;
const BIA_STEP_EXPLANATION = {
  title: 'Disaster Recovery Requirements',
  description: (
    <>
      <p>
        A person responsible for each Business Unit is required to complete the Recovery Requirements for their Business
        Unit.
      </p>
      <p>
        The more detail that you can capture here the quicker replacement equipment or alternative suppliers can be
        sourced thereby reducing the impact on your organization in the event of a crisis.
      </p>
    </>
  )
};

class OperationalBia extends Component {
  state = {
    departmentList: null,
    departmentNavList: null,
    selectedDepartment: null,
    activeTypeKey: null,
    activeTypeId: null
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { orgId, planId, fetchBranch, fetchAssetTypes, fetchAssets } = this.props;
    const { activeTypeKey, selectedDepartment } = this.state;
    await fetchBranch(orgId);
    await fetchAssetTypes();
    await fetchAssets(planId);
    const { departments, assetTypes } = this.props;
    if (assetTypes && assetTypes.length > 0 && !activeTypeKey) {
      const { name, assetTypeId } = assetTypes[0];
      this.setState({ activeTypeKey: name, activeTypeId: assetTypeId });
    }
    const departmentList = departments.map(obj => ({ id: obj.departmentId, name: obj.name }));
    const departmentNavList = [[null, departmentList]];
    let department = null;
    if (selectedDepartment) {
      department = departmentList.find(obj => obj.id === selectedDepartment.id);
    } else {
      department = departmentList.length > 0 ? departmentList[0] : null;
    }
    this.setState({ departmentList, departmentNavList, selectedDepartment: department });
  };

  onChange = key => {
    const { assetTypes } = this.props;
    const activeType = assetTypes.find(item => item.name === key);
    const activeTypeId = activeType.assetTypeId;
    this.setState({ activeTypeKey: key, activeTypeId });
  };

  onDepartmentSelect = departmentObj => {
    this.setState({ selectedDepartment: departmentObj });
    window.scrollTo(0, 0);
  };

  onDelete = async asset => {
    const { planId, deleteAssets } = this.props;
    try {
      await deleteAssets(planId, [asset.assetId]);
      message.success('The asset has been successfully deleted');
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  downloadTemplate = () => {
    const { planId, downloadAssetImportTemplate } = this.props;
    return downloadAssetImportTemplate(planId);
  };

  importFromTemplate = file => {
    const { planId, importAssets } = this.props;
    return importAssets(planId, file);
  };

  export = () => {
    const { planId, exportAssets } = this.props;
    return exportAssets(planId);
  };

  render() {
    const { history, planName, assetTypes, assets = {}, loading } = this.props;
    const { departmentList, departmentNavList, selectedDepartment, activeTypeKey, activeTypeId } = this.state;
    const departmentId = selectedDepartment ? selectedDepartment.id : null;
    const departmentName = selectedDepartment ? selectedDepartment.name : null;
    const data = assets[departmentId] || {};
    const columns = [
      { key: 'description', title: 'Description', dataIndex: 'name' },
      { key: 'quantity', title: 'Quantity', dataIndex: 'quantity' },
      { key: 'comments', title: 'Comments', dataIndex: 'comments' },
      {
        key: 'action',
        width: 115,
        render: (text, record) => (
          <span>
            <Modal title={activeTypeKey} toggle={show => <Button type="link" icon="edit" onClick={show} />}>
              {hide => (
                <AssetEdit assetInfo={record} departmentId={departmentId} activeTypeId={activeTypeId} close={hide} />
              )}
            </Modal>
            <Divider type="vertical" />
            <Popconfirm title="Sure to delete?" onConfirm={() => this.onDelete(record)}>
              <Button type="link" icon="delete" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <>
        <ProcessBar
          title="Operational Business Impact Analysis"
          prev="/dashboard/business-impact-analysis/tactical"
          next="/dashboard/recovery-strategy"
        />
        {departmentNavList && assetTypes && assets ? (
          <div className="bia-container">
            {departmentList.length > 0 ? (
              <Row gutter={20}>
                <Col span={5}>
                  <Affix>
                    <NavList
                      color="#f1c400"
                      title="Business Units"
                      navList={departmentNavList}
                      selectedItem={selectedDepartment}
                      onItemSelect={this.onDepartmentSelect}
                    />
                  </Affix>
                </Col>
                <Col span={15}>
                  <Spin spinning={loading}>
                    <Card
                      title="Disaster Recovery Requirements"
                      subtitle={`${departmentName} Business Unit`}
                      description="Should undertake the responsibility of compiling a schedule of the Processes &amp; Services etc that they anticipate their Business Unit will require to continue operating following a disaster."
                    >
                      <Tabs className="tabs" onChange={this.onChange}>
                        {assetTypes.map(item => (
                          <TabPane key={item.name} tab={item.name}>
                            {explanations.operationalBia[item.name] && (
                              <InfoBox type="fill" message={explanations.operationalBia[item.name].text} />
                            )}
                            <Table
                              title="Assets"
                              columns={columns}
                              dataSource={data[item.assetTypeId]}
                              rowKey="assetId"
                            />
                          </TabPane>
                        ))}
                      </Tabs>
                      <div className="btn-container">
                        <Export fileName={`${planName.replace(/\s+/g, '_')}_Assets.xlsx`} exportFile={this.export}>
                          {onExport => (
                            <Button type="primary" icon="export" onClick={onExport}>
                              Export
                            </Button>
                          )}
                        </Export>
                        <Modal
                          title="Import Assets"
                          toggle={show => (
                            <Button type="primary" icon="contacts" onClick={show}>
                              Import
                            </Button>
                          )}
                        >
                          {() => (
                            <Import
                              warning="Please note that importing from template will delete the old Assets and replace them with the ones listed in the template."
                              templateName="assets_template.xlsx"
                              downloadTemplate={this.downloadTemplate}
                              importFromTemplate={this.importFromTemplate}
                            />
                          )}
                        </Modal>
                        <Modal
                          title={activeTypeKey}
                          toggle={show => (
                            <Button type="primary" icon="plus" onClick={show}>
                              Add new
                            </Button>
                          )}
                        >
                          {hide => <AssetEdit departmentId={departmentId} activeTypeId={activeTypeId} close={hide} />}
                        </Modal>
                      </div>
                    </Card>
                  </Spin>
                </Col>
                <Col span={4}>
                  <InformationPanel {...BIA_STEP_EXPLANATION} />
                </Col>
              </Row>
            ) : (
              <Card title="Operational Business Impact Analysis">
                <Alert
                  message="You have not added any Business Units. To continue please navigate to Business Units."
                  button={
                    <Button type="primary" onClick={() => history.push('/dashboard/business-units')}>
                      Go To Business Units
                    </Button>
                  }
                />
              </Card>
            )}
          </div>
        ) : (
          <Spin tip="Loading..." className="loading" />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  planId: planIdSelector(state),
  planName: planNameSelector(state),
  departments: departmentListSelector(state),
  assetTypes: assetTypesSelector(state),
  assets: assetsSelector(state),
  loading: loadingAssetsSelector(state)
});

const mapDispatchToProps = {
  fetchBranch,
  fetchAssetTypes,
  fetchAssets,
  deleteAssets,
  downloadAssetImportTemplate,
  importAssets,
  exportAssets
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationalBia);
