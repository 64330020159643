import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Divider, Spin, message } from 'antd';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import Table from '../../../../components/Table/Table';
import AlternativeSiteEdit from './components/AlternativeSiteEdit';
import { fetchAlternativeSite, deleteAlternativeSite } from './AlternativeSiteAction';
import { orgIdSelector } from '../../OrganisationSelectors';
import { alternativeSiteListSelector, loadingAlternativeSitesSelector } from './AlternativeSiteSelectors';
import { MSG_ERROR } from '../../../../constants';

const AlternativeSite = ({ orgId, alternativeSiteList, loading, fetchAlternativeSite, deleteAlternativeSite }) => {
  useEffect(() => {
    fetchAlternativeSite(orgId);
  }, [orgId, fetchAlternativeSite]);

  const onDelete = async alternativeSite => {
    try {
      await deleteAlternativeSite(orgId, alternativeSite.alternativeSiteId);
      message.success('The alternative site has been successfully deleted');
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const columns = [
    { key: 'name', title: 'Name', dataIndex: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
    { key: 'address', title: 'Address', dataIndex: 'address' },
    {
      key: 'additionalAddressInformation',
      title: 'Unit/Suite/Floor',
      dataIndex: 'additionalAddressInformation'
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null)
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      render: text => (text ? <a href={`tel:${text}`}>{text}</a> : null)
    },
    {
      key: 'action',
      width: 115,
      render: (text, record) => (
        <span>
          <Modal explanation toggle={show => <Button type="link" icon="edit" onClick={show} />}>
            {(hide, setExplanation) => (
              <AlternativeSiteEdit alternativeSiteInfo={record} close={hide} onExplanationChange={setExplanation} />
            )}
          </Modal>
          <Divider type="vertical" />
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
            <Button type="link" icon="delete" />
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <Spin spinning={loading}>
      <Card title="Short Term Recovery Sites">
        <Table
          title="Short Term Recovery Sites"
          columns={columns}
          dataSource={alternativeSiteList}
          rowKey="alternativeSiteId"
          searchFields={['name', 'address', 'email', 'phone']}
        />
        <div className="btn-container">
          <Modal
            explanation
            toggle={show => (
              <Button type="primary" icon="plus" onClick={show}>
                Add new
              </Button>
            )}
          >
            {(hide, setExplanation) => <AlternativeSiteEdit close={hide} onExplanationChange={setExplanation} />}
          </Modal>
        </div>
      </Card>
    </Spin>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  alternativeSiteList: alternativeSiteListSelector(state),
  loading: loadingAlternativeSitesSelector(state)
});

const mapDispatchToProps = { fetchAlternativeSite, deleteAlternativeSite };

export default connect(mapStateToProps, mapDispatchToProps)(AlternativeSite);
