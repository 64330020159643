import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider, connect } from 'react-redux';
import { Row, Col, Button, Spin } from 'antd';
import store from '../../../../store';
import Download from '../../../../components/Download/Download';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../components/Card/Card';
import PlanPreview from './scenes/PlanPreview/PlanPreview';
import { download } from '../../../../components/App/AppAction';
import { fetchBranch } from '../../../Organisation/scenes/Branch/BranchAction';
import { fetchTeam } from '../Team/TeamAction';
import { fetchStrategicBia, fetchTacticalBia, fetchAssets, fetchAssetTypes } from '../Bia/BiaAction';
import { fetchAlternativeSite } from '../../../Organisation/scenes/AlternativeSite/AlternativeSiteAction';
import { fetchCustomer } from '../../../Organisation/scenes/Customer/CustomerAction';
import { fetchSupplier } from '../../../Organisation/scenes/Supplier/SupplierAction';
import { fetchStrategicThirdParty } from '../../../Organisation/scenes/StrategicThirdParty/StrategicThirdPartyAction';
import { orgIdSelector, businessNameSelector } from '../../../Organisation/OrganisationSelectors';
import { planIdSelector } from '../Plan/PlanSelectors';
import './PlanDocument.scss';

class PlanDocument extends Component {
  state = { loaded: false };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {
      orgId,
      planId,
      fetchBranch,
      fetchTeam,
      fetchStrategicBia,
      fetchTacticalBia,
      fetchAssets,
      fetchAssetTypes,
      fetchAlternativeSite,
      fetchCustomer,
      fetchSupplier,
      fetchStrategicThirdParty
    } = this.props;
    await fetchBranch(orgId);
    await fetchTeam(planId);
    await fetchStrategicBia(planId);
    await fetchTacticalBia(planId);
    await fetchAssets(planId);
    await fetchAssetTypes();
    await fetchAlternativeSite(orgId);
    await fetchCustomer(orgId);
    await fetchSupplier(orgId);
    await fetchStrategicThirdParty(orgId);
    this.setState({ loaded: true }, () => {
      const el = document.getElementById(window.location.hash.slice(1));
      if (el) {
        el.scrollIntoView();
      }
    });
  };

  onDownload = () => {
    const { download } = this.props;
    const content = renderToStaticMarkup(
      <Provider store={store}>
        <PlanPreview />
      </Provider>
    );
    return download(content);
  };

  render() {
    const { businessName } = this.props;
    const { loaded } = this.state;
    return (
      <>
        <ProcessBar color="#CB2C30" title="Business Continuity Plan" prev="/dashboard/recovery-strategy" />
        {loaded ? (
          <div className="plan-document-container">
            <Card color="#CB2C30" title="Business Continuity Plan">
              <Row>
                <Col xs={24} sm={24} md={24} lg={19} xl={20} xxl={20} className="plan-document-preview">
                  <PlanPreview />
                </Col>
                <Col xs={24} sm={24} md={24} lg={5} xl={4} xxl={4} className="plan-document-functions">
                  <Download
                    fileName={`Business_Continuity_Plan_${businessName.replace(/\s+/g, '_')}.pdf`}
                    download={this.onDownload}
                  >
                    {(downloadFile, downloading) => (
                      <Button
                        type="primary"
                        icon="download"
                        className="plan-document-btn"
                        loading={downloading}
                        onClick={downloadFile}
                      >
                        Download
                      </Button>
                    )}
                  </Download>
                </Col>
              </Row>
            </Card>
          </div>
        ) : (
          <Spin tip="Loading..." className="loading" />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  businessName: businessNameSelector(state),
  planId: planIdSelector(state)
});

const mapDispatchToProps = {
  download,
  fetchBranch,
  fetchTeam,
  fetchStrategicBia,
  fetchTacticalBia,
  fetchAssets,
  fetchAssetTypes,
  fetchAlternativeSite,
  fetchCustomer,
  fetchSupplier,
  fetchStrategicThirdParty
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanDocument);
