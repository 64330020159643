/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
import React, { PureComponent } from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import { Spin, Icon } from 'antd';
import mapStyle from './MapStyle';
import { ReactComponent as MarkerIcon } from './assets/marker.svg';
import pinIcons from './assets';

const API_GOOGLE_MAP = process.env.REACT_APP_API_GOOGLE_MAP;
const COLOR_BRANCH = '#5BC2E7';
const COLOR_CUSTOMER = '#F1C400';
const COLOR_SUPPLIER = '#CB2C30';
const COLOR_ALTERNATIVE_SITE = '#00685E';

const mapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false
};

const style = {
  legend: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '220px',
    height: '160px',
    padding: '20px 10px',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, .8)'
  },
  legendItem: {
    marginLeft: '20px'
  },
  infoBox: {
    backgroundColor: '#343579',
    opacity: 0.9,
    borderRadius: '10px',
    boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.4), 5px 5px 20px 5px rgba(0, 0, 0, 0.2)'
  },
  infoBoxInner: {
    width: '200px',
    padding: '20px',
    color: '#fff'
  },
  infoBoxName: {
    lineHeight: '36px',
    fontSize: '16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  infoBoxAddress: {
    fontSize: '12px'
  }
};

const MapComponent = compose(
  withProps({
    googleMapURL: API_GOOGLE_MAP,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%', padding: '20px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
    center: { lat: -37.8443907, lng: 145.0546349 }
  }),
  withScriptjs,
  lifecycle({
    async componentDidMount() {
      this.geocoder = new window.google.maps.Geocoder();
      const { locations, geoCode } = this.props;

      const processCircuit = async interval => {
        const allLocations = {};
        const errors = [];
        let count = 0;
        const geoCodeInStore = [];
        this.setState({ positions: geoCodeInStore });

        Object.values(locations).forEach(addressList => {
          addressList.forEach(addressInfo => {
            if (addressInfo.address && !geoCode[addressInfo.address]) {
              const key = Math.floor(count / 5);
              if (allLocations[key] === undefined) {
                allLocations[key] = [];
              }
              allLocations[key].push(addressInfo);
              count += 1;
            } else if (geoCode[addressInfo.address]) {
              geoCodeInStore.push(geoCode[addressInfo.address]);
            }
          });
        });

        const timer = i => {
          return new Promise(resolve =>
            setTimeout(() => {
              (async () => {
                // eslint-disable-next-line no-restricted-syntax
                for (const addressInfo of allLocations[i]) {
                  try {
                    // eslint-disable-next-line no-await-in-loop
                    const result = await this.getGeoCode(addressInfo);
                    geoCodeInStore.push(result);
                    this.setState({
                      positions: [...geoCodeInStore]
                    });
                  } catch (err) {
                    errors.push(err);
                  }
                }
              })();
              resolve();
            }, interval)
          );
        };

        const countRequest = Object.keys(allLocations).length;

        const allocateGeoCodeLoop = async () => {
          for (let i = 0; i < countRequest; i++) {
            // eslint-disable-next-line no-await-in-loop
            await timer(i);
          }
        };

        await allocateGeoCodeLoop();
      };

      processCircuit(1000);
    },

    async getGeoCode(addressInfo) {
      const { storeGeoCode, geoCode } = this.props;

      const res = await this.getGeoCodePromise(addressInfo.address);
      const lat = res[0].geometry.location.lat();

      const lng = res[0].geometry.location.lng();
      geoCode[addressInfo.address] = { ...addressInfo, lat, lng };
      storeGeoCode(geoCode);
      return { ...addressInfo, lat, lng };
    },
    getGeoCodePromise(address) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK') {
            resolve(results);
          } else {
            reject(status);
          }
        });
      });
    }
  }),
  withGoogleMap
)(props => {
  return (
    <GoogleMap
      defaultZoom={10}
      center={props.positions.length > 0 ? props.positions[0] : props.center}
      defaultOptions={{ styles: mapStyle, ...mapOptions }}
    >
      {props.positions.map((position, i) => (
        <MarkerComponent key={`${position.name}-${i}`} position={position} />
      ))}
    </GoogleMap>
  );
});

class MarkerComponent extends PureComponent {
  state = { isOpen: false };

  onToggleOpen = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  render() {
    const { position } = this.props;
    const { isOpen } = this.state;

    return (
      <Marker key={position.address} icon={pinIcons[position.type]} position={position} onClick={this.onToggleOpen}>
        {isOpen && (
          <InfoBox onCloseClick={this.onToggleOpen} options={{ boxStyle: style.infoBox, closeBoxMargin: '10px' }}>
            <div style={style.infoBoxInner}>
              <div style={style.infoBoxName}>{position.name}</div>
              <div style={style.infoBoxAddress}>{position.address}</div>
            </div>
          </InfoBox>
        )}
      </Marker>
    );
  }
}

/* eslint react/no-multi-comp: off */
export default class BusinessLocation extends PureComponent {
  state = {
    locations: null,
    loaded: false
  };

  static getDerivedStateFromProps(nextProps) {
    const { branches, customerList, supplierList, alternativeSiteList } = nextProps;
    if (!branches || !customerList || !supplierList || !alternativeSiteList) {
      return null;
    }
    const branchAddressList = branches.map(branch => ({
      name: branch.name,
      address: branch.address,
      type: 'branch',
      color: COLOR_BRANCH
    }));
    const customerAddressList = customerList.map(customer => ({
      name: customer.name,
      address: customer.address,
      type: 'customer',
      color: COLOR_CUSTOMER
    }));
    const supplierAddressList = supplierList.map(supplier => ({
      name: supplier.name,
      address: supplier.address,
      type: 'supplier',
      color: COLOR_SUPPLIER
    }));
    const alternativeSiteAddressList = alternativeSiteList.map(alternativeSite => ({
      name: alternativeSite.name,
      address: alternativeSite.address,
      type: 'alternativeSite',
      color: COLOR_ALTERNATIVE_SITE
    }));
    const locations = { branchAddressList, customerAddressList, supplierAddressList, alternativeSiteAddressList };
    return { locations, loaded: true };
  }

  render() {
    const { locations, loaded } = this.state;
    const { geoCode, storeGeoCode } = this.props;

    return (
      <div className="widget">
        {loaded ? (
          <>
            <MapComponent locations={locations} geoCode={geoCode} storeGeoCode={storeGeoCode} />
            <ul style={style.legend}>
              <li style={{ color: COLOR_BRANCH }}>
                <Icon component={MarkerIcon} alt="branch" />
                <span style={style.legendItem}>Branches</span>
              </li>
              <li style={{ color: COLOR_CUSTOMER }}>
                <Icon component={MarkerIcon} alt="customer" />
                <span style={style.legendItem}>Customers</span>
              </li>
              <li style={{ color: COLOR_SUPPLIER }}>
                <Icon component={MarkerIcon} alt="supplier" />
                <span style={style.legendItem}>Suppliers</span>
              </li>
              <li style={{ color: COLOR_ALTERNATIVE_SITE }}>
                <Icon component={MarkerIcon} alt="recovery sites" />
                <span style={style.legendItem}>Short Term Recovery Sites</span>
              </li>
            </ul>
          </>
        ) : (
          <Spin className="loading" />
        )}
      </div>
    );
  }
}
