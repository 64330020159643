import { createSelector } from 'reselect';
import { branchIdSelector } from '../../../Dashboard/scenes/Plan/PlanSelectors';

export const loadingBranchesSelector = state => state.branchReducer.loadingBranches;

export const branchesSelector = state => state.branchReducer.branches;

export const branchSelector = createSelector(branchesSelector, branchIdSelector, (branches, branchId) =>
  branches ? branches.find(item => item.branchId === branchId) : undefined
);
