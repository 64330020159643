import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Select, Button, message } from 'antd';
import { withOnFocus } from '../../../../../components/HOC';
import AddressSearchBox from '../../../../../components/AddressSearchBox/AddressSearchBox';
import { orgIdSelector } from '../../../OrganisationSelectors';
import * as actions from '../StrategicThirdPartyAction';
import { loadingStrategicThirdPartySelector } from '../StrategicThirdPartySelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../constants';
import explanations from '../data/explanations.json';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

class StrategicThirdPartyEdit extends PureComponent {
  constructor(props) {
    super(props);
    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.AddressSearchBoxWithOnFocus = withOnFocus(AddressSearchBox, props.onExplanationChange);
    const category = props.strategicThirdPartyInfo
      ? props.strategicThirdPartyInfo.strategicThirdPartyCategory.name
      : null;
    this.state = { category };
  }

  onSubmit = e => {
    e.preventDefault();
    const {
      form,
      orgId,
      strategicThirdPartyInfo,
      updateStrategicThirdParty,
      createStrategicThirdParty,
      close
    } = this.props;
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          acc[key] =
            fieldsValue[key] && typeof fieldsValue[key] === 'string' ? fieldsValue[key].trim() : fieldsValue[key];
          return acc;
        }, {});
        try {
          if (strategicThirdPartyInfo) {
            const { strategicThirdPartyId } = strategicThirdPartyInfo;
            await updateStrategicThirdParty(orgId, strategicThirdPartyId, values);
          } else {
            await createStrategicThirdParty(orgId, values);
          }
          message.success(MSG_SAVE_SUCCESS);
          close();
        } catch (err) {
          message.error(MSG_ERROR);
        }
      }
    });
  };

  onCategoryChange = value => {
    const { strategicThirdPartyCategories } = this.props;
    const category = strategicThirdPartyCategories.find(item => item.strategicThirdPartyCategoryId === value);
    this.setState({ category: category ? category.name : null });
  };

  render() {
    const { InputWithOnFocus, AddressSearchBoxWithOnFocus } = this;
    const { form, loading, strategicThirdPartyInfo = {}, strategicThirdPartyCategories } = this.props;
    const { getFieldDecorator } = form;
    const { category } = this.state;
    const strategicThirdPartyCategoryOptions = (strategicThirdPartyCategories || []).map(item => (
      <Option key={item.strategicThirdPartyCategoryId} value={item.strategicThirdPartyCategoryId}>
        {item.name}
      </Option>
    ));

    return (
      <Form>
        <FormItem label="Business Name">
          {getFieldDecorator('name', {
            initialValue: strategicThirdPartyInfo.name,
            rules: [{ required: true, message: 'Please input the name.' }]
          })(
            <InputWithOnFocus
              placeholder="Business Name"
              disabled={loading}
              spellCheck={false}
              explanation={explanations.name}
            />
          )}
        </FormItem>
        <FormItem label="Category">
          {getFieldDecorator('strategicThirdPartyCategoryId', {
            initialValue: (strategicThirdPartyInfo.strategicThirdPartyCategory || {}).strategicThirdPartyCategoryId,
            rules: [{ required: true, message: 'Please select category.' }]
          })(
            <Select placeholder="Select Category" disabled={loading} onChange={this.onCategoryChange}>
              {strategicThirdPartyCategoryOptions}
            </Select>
          )}
        </FormItem>
        {category === 'Other' && (
          <FormItem label="Other">
            {getFieldDecorator('strategicThirdPartyCategoryText', {
              initialValue: strategicThirdPartyInfo.strategicThirdPartyCategoryText
            })(<InputWithOnFocus placeholder="Input Category" disabled={loading} />)}
          </FormItem>
        )}
        <FormItem label="Contact Person">
          {getFieldDecorator('contactPerson', {
            initialValue: strategicThirdPartyInfo.contactPerson,
            rules: [{ required: true, message: 'Please input the contact person.' }]
          })(<InputWithOnFocus placeholder="Contact Person" disabled={loading} spellCheck={false} />)}
        </FormItem>
        <Row gutter={20}>
          <Col span={12}>
            <FormItem label="Email">
              {getFieldDecorator('email', {
                initialValue: strategicThirdPartyInfo.email,
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'Please input an valid email.'
                  },
                  {
                    required: true,
                    message: 'Please input the email.'
                  }
                ]
              })(
                <InputWithOnFocus
                  placeholder="Email"
                  disabled={loading}
                  spellCheck={false}
                  explanation={explanations.email}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Phone">
              {getFieldDecorator('phone', {
                initialValue: strategicThirdPartyInfo.phone
              })(<InputWithOnFocus placeholder="Phone" disabled={loading} explanation={explanations.phone} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem label="Address">
              {getFieldDecorator('address', {
                initialValue: strategicThirdPartyInfo.address
              })(
                <AddressSearchBoxWithOnFocus
                  placeholder="Address"
                  disabled={loading}
                  spellCheck={false}
                  explanation={explanations.address}
                />
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Unit/Suite/Floor">
              {getFieldDecorator('additionalAddressInformation', {
                initialValue: strategicThirdPartyInfo.additionalAddressInformation
              })(
                <InputWithOnFocus
                  placeholder="Unit/Suite/Floor"
                  disabled={loading}
                  explanation={explanations.additionalAddressInformation}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem label="Website">
          {getFieldDecorator('website', {
            initialValue: strategicThirdPartyInfo.website
          })(<InputWithOnFocus placeholder="Website" disabled={loading} />)}
        </FormItem>
        <FormItem label="Comments">
          {getFieldDecorator('comments', {
            initialValue: strategicThirdPartyInfo.comments
          })(<TextArea placeholder="Comments" disabled={loading} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" block loading={loading} onClick={this.onSubmit}>
            {loading ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  loading: loadingStrategicThirdPartySelector(state)
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(StrategicThirdPartyEdit));
