import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_ALTERNATIVE_SITES: 'alternativeSite/REQUEST_ALTERNATIVE_SITES',
  RECEIVE_ALTERNATIVE_SITES: 'alternativeSite/RECEIVE_ALTERNATIVE_SITES'
};

const requestAlternativeSites = () => ({ type: types.REQUEST_ALTERNATIVE_SITES });
const receiveAlternativeSites = data => ({ type: types.RECEIVE_ALTERNATIVE_SITES, alternativeSiteList: data });

export const fetchAlternativeSite = orgId => async dispatch => {
  dispatch(requestAlternativeSites());
  const url = `${API_BASE}orgs/${orgId}/alternative_sites`;
  try {
    const res = await axios.get(url);
    dispatch(receiveAlternativeSites(res.data));
  } catch (err) {
    dispatch(receiveAlternativeSites());
  }
};

export const createAlternativeSite = (orgId, data) => async dispatch => {
  dispatch(requestAlternativeSites());
  const url = `${API_BASE}orgs/${orgId}/alternative_sites`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchAlternativeSite(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveAlternativeSites());
    return Promise.reject(err);
  }
};

export const updateAlternativeSite = (orgId, alternativeSiteId, data) => async dispatch => {
  dispatch(requestAlternativeSites());
  const url = `${API_BASE}orgs/${orgId}/alternative_sites/${alternativeSiteId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchAlternativeSite(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveAlternativeSites());
    return Promise.reject(err);
  }
};

export const deleteAlternativeSite = (orgId, alternativeSiteId) => async dispatch => {
  dispatch(requestAlternativeSites());
  const url = `${API_BASE}orgs/${orgId}/alternative_sites/${alternativeSiteId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchAlternativeSite(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveAlternativeSites());
    return Promise.reject(err);
  }
};
