import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Button, Icon, Badge } from 'antd';
import Logo from '../Logo/Logo';
import Modal from '../Modal/Modal';
import Login from '../Login/Login';
import './Header.scss';

const AntHeader = Layout.Header;

const Header = ({ history, location, siderWidth, hasSider, purchaseCount, toggleSiderVisible }) => {
  const loggedIn = localStorage.getItem('token');

  const navigateHome = () => {
    history.push('/');
  };

  return (
    <AntHeader className="header">
      <Link to="/" className="header-brand" style={{ width: siderWidth }}>
        <Logo />
      </Link>
      {hasSider && (
        <Button type="link" className="header-hamburger" onClick={toggleSiderVisible}>
          <i className="fa fa-bars" />
        </Button>
      )}
      {loggedIn ? (
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[`/${location.pathname.split('/')[1]}`]}
          className="header-menu"
        >
          <Menu.Item key="/dashboard" className="header-menu-item">
            <Link to="/dashboard">
              <Icon type="dashboard" />
              <div>Dashboard</div>
            </Link>
          </Menu.Item>
          <Menu.Item key="/admin" className="header-menu-item">
            <Link to="/admin">
              <Badge dot={!!purchaseCount} offset={[24, 0]}>
                <Icon type="setting" />
              </Badge>
              <div>Admin</div>
            </Link>
          </Menu.Item>
        </Menu>
      ) : (
        // <Modal
        //   toggle={show => (
        //     <Button icon="login" onClick={show} className="header-login-btn">
        //       Register / Log in
        //     </Button>
        //   )}
        // >
        <Modal
          toggle={show => (
            <Button icon="login" onClick={() => { show(); navigateHome(); }} className="header-login-btn">
              Register / Log in
            </Button>
          )}
        >
          {hide => <Login close={hide} />}
        </Modal>
      )}
    </AntHeader>
  );
};

const mapStateToProps = state => ({ purchaseCount: state.purchaseReducer.purchaseCount });

export default compose(withRouter, connect(mapStateToProps))(Header);
