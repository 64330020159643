import React, { PureComponent } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import _ from 'lodash';

export default class CriticalPeriodsChart extends PureComponent {
  constructor(props) {
    super(props);
    const { biaList } = props;
    const criticalPeriods = biaList.map(biaObj => biaObj.criticalPeriods || []).reduce((a, b) => [...a, ...b]);
    const groupedCriticalPeriods = _.groupBy(criticalPeriods, item => item.name);
    const data = Object.entries(groupedCriticalPeriods)
      .map(([name, value]) => ({ name, value: value.length }))
      .sort((a, b) => b.value - a.value);
    this.state = { data, total: criticalPeriods.length };
  }

  render() {
    const { data, total } = this.state;
    const options = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {d}%'
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          data,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true
            }
          },
          labelLine: {
            show: false
          }
        }
      ]
    };

    return (
      <div className="bia-detail-container">
        {data.length > 0 ? (
          <>
            <ReactEchartsCore echarts={echarts} option={options} notMerge lazyUpdate style={{ height: '50%' }} />
            <ul className="critical-periods-list">
              {data.map(item => (
                <li key={item.name} className="bia-detail-item">
                  <span>{item.name}</span>
                  <span>{`${((item.value / total) * 100).toFixed(2)}%`}</span>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>No Critical Periods</div>
        )}
      </div>
    );
  }
}
