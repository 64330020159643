import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Affix, message, Button } from 'antd';
import _ from 'lodash';
import * as HttpStatus from 'http-status-codes';
import ProcessBar from '../../../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../../../components/Card/Card';
import InfoBox from '../../../../../../components/InfoBox/InfoBox';
import Alert from '../../../../../../components/Alert/Alert';
import InformationPanel from '../../../../../../components/InformationPanel/InformationPanel';
import NavList from '../../components/NavList';
import Question from './components/Question';
import Mtpd from './components/Mtpd';
import DisruptionRiskScale from './components/DisruptionRiskScale';
import {
  fetchStrategicBia,
  createStrategicBia,
  updateStrategicBia,
  deleteStrategicBia,
  fetchCriticalPeriods
} from '../../BiaAction';
import { planIdSelector, mtpdSelector } from '../../../Plan/PlanSelectors';
import { strategicBiasSelector, criticalStrategicBiasSelector } from '../../BiaSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../../constants';
import explanations from '../../data/explanations.json';
import rtoRpoImg from '../../images/rto_rpo.png';

const BIA_STEP_CRITICAL = 'Critical Strategic Areas';
const BIA_STEP_MTPD = 'Maximum Tolerable Period of Disruption';
const BIA_STEP_DRS = 'Disruption Risk Scales';
const BIA_STEPS = [BIA_STEP_CRITICAL, BIA_STEP_MTPD, BIA_STEP_DRS];
const BIA_STEP_EXPLANATIONS = {
  'Critical Strategic Areas': {
    title: explanations.strategicBia.default.title,
    text: explanations.strategicBia.default.text
  },
  'Maximum Tolerable Period of Disruption': {
    title: explanations.mtpd.maximumTolerablePeriodOfDisruption.title,
    text: explanations.mtpd.maximumTolerablePeriodOfDisruption.text
  },
  'Disruption Risk Scales': {
    title: explanations.drs.default.title,
    text: explanations.drs.default.text
  }
};
const INFO_MSG = (
  <>
    <p>
      The Strategic Business Impact Analysis has been built from the Strategic Areas of your organization that you
      identified when you completed that section. If you need to add more Strategic Areas you will need to do this from
      the &quot;Organization&quot; screen.
    </p>
    <p>
      Make sure you complete your analysis of each of your Strategic Areas before moving to the new step. To do this
      simply click on the name of each Strategic Area listed below.
    </p>
  </>
);

class StrategicBia extends Component {
  state = {
    scaList: null,
    scaNavList: null,
    criticalScaList: null,
    criticalScaNavList: null,
    selectedStrategicBia: null,
    biaStep: BIA_STEP_CRITICAL,
    explanationTitle: BIA_STEP_EXPLANATIONS[BIA_STEP_CRITICAL].title,
    explanationText: BIA_STEP_EXPLANATIONS[BIA_STEP_CRITICAL].text
  };

  componentDidMount() {
    const { planId, fetchStrategicBia, fetchCriticalPeriods } = this.props;
    fetchStrategicBia(planId);
    fetchCriticalPeriods(planId);
  }

  static getDerivedStateFromProps(props, state) {
    const { strategicBias, criticalStrategicBias } = props;
    if (strategicBias) {
      const scaList = [];
      const scaNavList = [];
      strategicBias.forEach(([category, biaList]) => {
        scaList.push(...biaList.map(obj => ({ ...obj, id: obj.strategicCriticalArea.strategicCriticalAreaId })));
        const scaNavListOfCategory = biaList.map(obj => ({
          ...obj,
          id: obj.strategicCriticalArea.strategicCriticalAreaId,
          name: obj.strategicCriticalArea.name
        }));
        scaNavList.push([category, scaNavListOfCategory]);
      });
      const criticalScaList = [];
      const criticalScaNavList = [];
      criticalStrategicBias.forEach(([category, biaList]) => {
        criticalScaList.push(
          ...biaList.map(obj => ({ ...obj, id: obj.strategicCriticalArea.strategicCriticalAreaId }))
        );
        const scaNavListOfCategory = biaList.map(obj => ({
          ...obj,
          id: obj.strategicCriticalArea.strategicCriticalAreaId,
          name: obj.strategicCriticalArea.name
        }));
        criticalScaNavList.push([category, scaNavListOfCategory]);
      });
      let selectedStrategicBia = null;
      // if selected strategicBia is exist, reset it with the new value;
      // otherwise set it as the first item
      if (state.selectedStrategicBia) {
        selectedStrategicBia = scaList.find(obj => obj.id === state.selectedStrategicBia.id);
      } else {
        selectedStrategicBia = scaList.length > 0 ? scaList[0] : null;
      }

      return { scaList, scaNavList, criticalScaList, criticalScaNavList, selectedStrategicBia };
    }
    return null;
  }

  onStepPrev = () => {
    const { history } = this.props;
    const { biaStep } = this.state;
    if (biaStep === BIA_STEP_CRITICAL) {
      history.push('/dashboard/policy');
      return;
    }
    let index = BIA_STEPS.findIndex(item => item === biaStep);
    index = Math.max(index - 1, 0);
    this.setState({ biaStep: BIA_STEPS[index] });
    this.onExplanationChange(BIA_STEP_EXPLANATIONS[BIA_STEPS[index]]);
  };

  onStepNext = () => {
    const { history } = this.props;
    const { biaStep, criticalScaList } = this.state;
    if (biaStep === BIA_STEP_DRS) {
      history.push('/dashboard/business-impact-analysis/tactical');
      return;
    }
    let index = BIA_STEPS.findIndex(item => item === biaStep);
    index = Math.min(index + 1, BIA_STEPS.length - 1);
    this.setState({ biaStep: BIA_STEPS[index], selectedStrategicBia: criticalScaList[0] });
    this.onExplanationChange(BIA_STEP_EXPLANATIONS[BIA_STEPS[index]]);
  };

  disableNext = () => {
    const { mtpd } = this.props;
    const { biaStep, criticalScaList } = this.state;
    if (biaStep === BIA_STEP_CRITICAL && criticalScaList && criticalScaList.length > 0) {
      return false;
    }
    if (biaStep === BIA_STEP_MTPD && mtpd) {
      return false;
    }
    if (biaStep === BIA_STEP_DRS) {
      return false;
    }
    return true;
  };

  onScaSelect = scaObj => {
    this.setState({ selectedStrategicBia: scaObj });
    window.scrollTo(0, 0);
  };

  onScaNext = () => {
    const { biaStep, scaList, criticalScaList, selectedStrategicBia } = this.state;
    const navList = biaStep === BIA_STEP_CRITICAL ? scaList : criticalScaList;
    const currentIndex = _.findIndex(navList, { id: selectedStrategicBia.id });
    if (currentIndex < navList.length - 1) {
      this.setState({ selectedStrategicBia: navList[currentIndex + 1] });
      window.scrollTo(0, 0);
    } else {
      this.onStepNext();
    }
  };

  onSave = async (isCritical, data) => {
    const { planId, createStrategicBia, updateStrategicBia, deleteStrategicBia, fetchCriticalPeriods } = this.props;
    const { selectedStrategicBia } = this.state;
    const { strategicBiaId } = selectedStrategicBia;
    let promise = null;
    if (!strategicBiaId && isCritical) {
      promise = createStrategicBia(planId, data);
    } else if (isCritical) {
      promise = updateStrategicBia(planId, strategicBiaId, data);
    } else if (strategicBiaId) {
      promise = deleteStrategicBia(planId, strategicBiaId);
    }
    try {
      await promise;
      fetchCriticalPeriods(planId);
      message.success(MSG_SAVE_SUCCESS);
      this.onScaNext();
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  onExplanationChange = value => {
    const { title, text } = value;
    this.setState({ explanationTitle: title, explanationText: text });
  };

  render() {
    const {
      scaNavList,
      criticalScaNavList,
      selectedStrategicBia,
      biaStep,
      explanationTitle,
      explanationText
    } = this.state;
    const navList = biaStep === BIA_STEP_CRITICAL ? scaNavList : criticalScaNavList;
    const stepSeq = BIA_STEPS.findIndex(item => item === biaStep) + 1;
    const disableNext = this.disableNext();
    return (
      <>
        <ProcessBar
          color="#343579"
          title="Strategic Business Impact Analysis"
          subtitle={biaStep}
          stepSeq={stepSeq}
          totalSteps={BIA_STEPS.length}
          prev={this.onStepPrev}
          next={this.onStepNext}
          disableNext={disableNext}
        />
        <InfoBox color="#343579" message={INFO_MSG} more={biaStep === BIA_STEP_MTPD} image={rtoRpoImg} />
        {!!navList && (
          <div className="bia-container">
            {navList.length > 0 ? (
              <Row gutter={20}>
                <Col span={5}>
                  <Affix>
                    <NavList
                      color="#343579"
                      title={biaStep === BIA_STEP_CRITICAL ? 'Strategic Areas' : 'Critical Strategic Areas'}
                      navList={navList}
                      selectedItem={selectedStrategicBia}
                      onItemSelect={this.onScaSelect}
                      selectable={biaStep !== BIA_STEP_MTPD}
                    />
                  </Affix>
                </Col>
                <Col span={15}>
                  {biaStep === BIA_STEP_CRITICAL && (
                    <Question
                      key={selectedStrategicBia.strategicCriticalArea.strategicCriticalAreaId}
                      strategicBia={selectedStrategicBia}
                      onSave={this.onSave}
                      explanations={explanations.strategicBia}
                      onExplanationChange={this.onExplanationChange}
                    />
                  )}
                  {biaStep === BIA_STEP_MTPD && (
                    <Mtpd explanations={explanations.mtpd} onExplanationChange={this.onExplanationChange} />
                  )}
                  {biaStep === BIA_STEP_DRS && (
                    <DisruptionRiskScale
                      key={selectedStrategicBia.strategicCriticalArea.strategicCriticalAreaId}
                      strategicBia={selectedStrategicBia}
                      save={this.onSave}
                      explanations={explanations.drs}
                      onExplanationChange={this.onExplanationChange}
                    />
                  )}
                </Col>
                <Col span={4}>
                  <InformationPanel title={explanationTitle} description={explanationText} />
                </Col>
              </Row>
            ) : (
              <Card color="#343579" title="Strategic Business Impact Analysis">
                <Alert
                  message="You have not added any Strategic Areas. To continue please navigate to Organization | Strategic Areas."
                  button={
                    <Button type="primary" className="bia-btn">
                      <Link to="/organization/strategic-areas">Go To Strategic Areas</Link>
                    </Button>
                  }
                />
              </Card>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  planId: planIdSelector(state),
  mtpd: mtpdSelector(state),
  strategicBias: strategicBiasSelector(state),
  criticalStrategicBias: criticalStrategicBiasSelector(state)
});

const mapDispatchToProps = {
  fetchStrategicBia,
  createStrategicBia,
  updateStrategicBia,
  deleteStrategicBia,
  fetchCriticalPeriods
};

export default connect(mapStateToProps, mapDispatchToProps)(StrategicBia);
