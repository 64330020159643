/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import { Form, Icon, Input, Checkbox, Button, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TwoFactorIcon } from '../assets/twoFactorIcon.svg';

const FormItem = Form.Item;

const Intro = () => (
  <div className="intro">
    <h3>
      <Icon component={TwoFactorIcon} className="two-factor-icon" /> Introducing Two - Factor Authentication (2FA)
    </h3>
    <p className="description">
      <Icon type="exclamation-circle" className="exclamation-icon" />
      <ul>
        <li>
          For added security, Two-Factor Authentication (2FA) introduces an additional layer of protection to your
          account. In addition to your primary email and password, you will be required to input a code sent via email
          or SMS.
        </li>
        <li>
          You can configure SMS-based authentication at any time in the future by navigating to Admin &gt; Settings.
        </li>
      </ul>
    </p>
  </div>
);

const MFAForm = ({
  form,
  brokerInfo,
  loginRequest,
  loginHasError,
  sendOtp,
  verifyOtp,
  needResend,
  sendConfirmationRequest,
  sendConfirmationEmail,
  setSendConfirmationRequest,
  setErrorMessage,
  errorMessage,
  setLoginStatus
}) => {
  const [codeSent, setCodeSent] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState();
  const [userInfo, setUserInfo] = useState();
  const history = useHistory();
  const { getFieldDecorator } = form;

  const codeInput = useCallback(inputElement => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const onVerify = e => {
    e.preventDefault();
    form.validateFields(async (err, { otpCode }) => {
      if (!err) {
        await verifyOtp({ otpCode: `${otpCode}`, userId: userInfo?.userId, rememberMe });
        setCodeSent(false);
        const redirectPath = brokerInfo ? '/admin' : '/dashboard';
        history.push(redirectPath);
      }
    });
  };

  const onLogin = e => {
    e.preventDefault();
    form.validateFields(async (err, { email, password, rememberMe }) => {
      if (!err) {
        setEmail(email);
        setPassword(password);
        setRememberMe(rememberMe);
        const userSetting = await sendOtp({ email, password });
        if (userSetting) {
          setUserInfo(userSetting);
        }
        setCodeSent(true);
      }
    });
  };

  const onResendOtp = async () => {
    await sendOtp({ email, password });
  };

  const resetLoginStatus = () => {
    setLoginStatus(null);
    setErrorMessage('');
  };

  const onResendConfirmationEmail = async () => {
    await sendConfirmationEmail({ email, password });
    setSendConfirmationRequest(false);
    resetLoginStatus();
  };

  return (
    <>
      {loginHasError && errorMessage !== '' && <Alert message={errorMessage} type="error" />}

      {codeSent && !errorMessage && (
        <Alert
          type="success"
          message={`One-time passcode has been sent via ${userInfo?.otpType === 2 ? 'email' : 'SMS'}`}
        />
      )}

      {needResend && (
        <>
          <Alert
            message="Your account has not been activated."
            description={errorMessage}
            type="warning"
            showIcon
            className="login-alert"
          />
          <Button type="default" onClick={resetLoginStatus} style={{ marginLeft: 10 }} className="login-alert-btn">
            Login
          </Button>
          <Button
            type="primary"
            loading={sendConfirmationRequest}
            onClick={onResendConfirmationEmail}
            className="login-alert-btn"
          >
            {sendConfirmationRequest ? 'Sending' : 'Resend'}
          </Button>
        </>
      )}

      {!needResend &&
        (codeSent ? (
          <Form onSubmit={onVerify} className="login-form">
            <h3>Verification Code</h3>
            <FormItem>
              {getFieldDecorator('otpCode', {
                rules: [{ required: true, message: 'One-time passcode is required.' }]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  placeholder="Code"
                  disabled={loginRequest}
                  autoComplete="one-time-code"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  ref={codeInput}
                  maxLength={6}
                />
              )}
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loginRequest}
                loading={loginRequest}
                className="login-form-btn"
              >
                {loginRequest ? 'Verifying..' : 'Verify'}
              </Button>
              <Button type="secondary" disabled={loginRequest} className="login-form-btn" onClick={onResendOtp}>
                Resend
              </Button>
            </FormItem>
          </Form>
        ) : (
          <Form onSubmit={onLogin} className="login-form">
            <Intro />
            <FormItem>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [{ required: true, message: 'Username is required' }]
              })(
                <Input
                  prefix={<Icon type="user" style={{ fontSize: 13 }} />}
                  placeholder="Username"
                  disabled={loginRequest}
                  spellCheck={false}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Password is required' }]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  type="password"
                  placeholder="Password"
                  disabled={loginRequest}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('rememberMe', {
                valuePropName: 'checked',
                initialValue: false
              })(<Checkbox>Remember me</Checkbox>)}
              <a href="/reset-password" className="login-link">
                Forgot password
              </a>
              <Button type="primary" htmlType="submit" loading={loginRequest} className="login-form-btn">
                {loginRequest ? 'Logging in' : 'Log in'}
              </Button>
            </FormItem>
          </Form>
        ))}
    </>
  );
};

export default Form.create()(MFAForm);
