import { types } from './AlternativeSiteAction';
import { types as organisationTypes } from '../../OrganisationAction';

const initialState = { loadingAlternativeSites: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case organisationTypes.SWITCH_ORGANISATION:
      return { ...initialState };
    case types.REQUEST_ALTERNATIVE_SITES:
      return { ...state, loadingAlternativeSites: true };
    case types.RECEIVE_ALTERNATIVE_SITES:
      return { ...state, loadingAlternativeSites: false, alternativeSiteList: action.alternativeSiteList };
    default:
      return state;
  }
};
