import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import Layout from '../../components/Layout/Layout';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import Sider from './components/Sider/Sider';
import OrganisationPlan from './scenes/OrganisationPlan/OrganisationPlan';
import Broker from './scenes/Broker/Broker';
import BrokerageSubscriptions from './scenes/Subscription/scenes/BrokerageSubscriptions/BrokerageSubscriptions';
import MySubscriptions from './scenes/Subscription/scenes/MySubscriptions/MySubscriptions';
import Transaction from './scenes/Transaction/Transaction';
import User from './scenes/User/User';
import { logout } from '../../components/Login/LoginAction';
import { fetchBroker, fetchBrokers, fetchBrokerage } from './scenes/Broker/BrokerActions';
import { fetchTransactions } from './scenes/Transaction/TransactionActions';
import { brokerInfoSelector, loadingBrokerSelector } from './scenes/Broker/BrokerSelectors';

const Admin = ({
  match,
  history,
  location,
  brokerInfo,
  loadingBroker,
  fetchBroker,
  fetchBrokers,
  fetchBrokerage,
  fetchTransactions,
  logout
}) => {
  useEffect(() => {
    fetchBroker();
  }, [fetchBroker]);

  useEffect(() => {
    if (brokerInfo) {
      const { brokerageId } = brokerInfo;
      fetchBrokers(brokerageId);
      fetchBrokerage(brokerageId);
      fetchTransactions(brokerageId);
    } else {
      fetchTransactions();
    }
  }, [brokerInfo, fetchBrokers, fetchBrokerage, fetchTransactions]);

  const menuItems = [
    {
      route: 'organisations',
      name: 'Organisations & Plans',
      type: 'menu-item',
      component: <OrganisationPlan />,
      icon: 'unordered-list',
      show: true
    },
    { route: 'admin-users', name: 'My Admin Users', component: <Broker />, icon: 'team', show: !!brokerInfo },
    {
      name: 'Subscriptions',
      type: 'sub-menu',
      icon: 'key',
      submenus: [
        { route: 'my-subscriptions', name: 'My Subscriptions', type: 'menu-item', component: <MySubscriptions /> },
        {
          route: 'brokerage-subscriptions',
          name: 'Brokerage Subscriptions',
          type: 'menu-item',
          component: <BrokerageSubscriptions />
        }
      ],
      show: !!brokerInfo
    },
    {
      name: 'My Subscriptions',
      type: 'menu-item',
      icon: 'key',
      route: 'my-subscriptions',
      component: <MySubscriptions />,
      show: !brokerInfo
    },
    {
      route: 'transactions',
      name: 'Transaction History',
      type: 'menu-item',
      component: <Transaction />,
      icon: 'dollar',
      show: true
    },
    {
      route: 'settings',
      name: 'Settings',
      type: 'menu-item',
      component: <User logoWidth={120} />,
      icon: 'setting',
      show: true
    }
  ].filter(item => item.show);

  const selectedKey = location.pathname.split('/').pop();

  const onLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <Layout
      sider={
        <Sider
          menuItems={menuItems}
          selectedKey={selectedKey}
          defaultOpenKeys={['Subscriptions']}
          onLogout={onLogout}
        />
      }
    >
      {!brokerInfo && loadingBroker ? (
        <Spin className="loading" />
      ) : (
        <Switch>
          <Route exact path={match.url} render={() => <Redirect to={`${match.url}/${menuItems[0].route}`} />} />
          {menuItems.map(item => {
            if (item.type === 'sub-menu') {
              return item.submenus.map(subitem => (
                <Route
                  exact
                  key={subitem.route}
                  path={`${match.url}/${subitem.route}`}
                  render={() => <ErrorBoundary>{subitem.component}</ErrorBoundary>}
                />
              ));
            }
            return (
              <Route
                exact
                key={item.route}
                path={`${match.url}/${item.route}`}
                render={() => <ErrorBoundary>{item.component}</ErrorBoundary>}
              />
            );
          })}
          <Redirect to={`${match.url}/${menuItems[0].route}`} />
        </Switch>
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  brokerInfo: brokerInfoSelector(state),
  loadingBroker: loadingBrokerSelector(state)
});

const mapDispatchToProps = {
  fetchBroker,
  fetchBrokers,
  fetchBrokerage,
  fetchTransactions,
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
