import {
  LOGIN_REQUEST,
  LOGIN_STATUS,
  REGISTER_REQUEST,
  REGISTER_STATUS,
  SEND_CONFIRMATION_REQUEST,
  ERROR_MESSAGE,
  RESET_PASSWORD_REQUEST
} from './LoginAction';

const initialState = {
  loginRequest: false,
  registerRequest: false,
  sendConfirmationRequest: false,
  errorMessage: '',
  resetPasswordRequest: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loginRequest: action.loginRequest };
    case LOGIN_STATUS:
      return { ...state, loginStatus: action.loginStatus };
    case REGISTER_REQUEST:
      return { ...state, registerRequest: action.registerRequest };
    case REGISTER_STATUS:
      return { ...state, registerStatus: action.registerStatus };
    case SEND_CONFIRMATION_REQUEST:
      return { ...state, sendConfirmationRequest: action.sendConfirmationRequest };
    case ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };
    case RESET_PASSWORD_REQUEST:
      return { ...state, resetPasswordRequest: action.resetPasswordRequest };
    default:
      return state;
  }
};

export default loginReducer;
