import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Spin, Divider, Popconfirm, message, Button } from 'antd';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import * as HttpStatus from 'http-status-codes';
import ProcessBar from '../../../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../../../components/Card/Card';
import Modal from '../../../../../../components/Modal/Modal';
import Table from '../../../../../../components/Table/Table';
import Export from '../../../../../../components/Export/Export';
import RiskEdit from '../../components/RiskEdit';
import {
  fetchRisks,
  fetchRiskLikelihoodPresets,
  fetchMitigationStrategies,
  fetchMitigationStatus,
  deleteRisks,
  exportRisks
} from '../../RiskAction';
import { fetchContact } from '../../../../../Organisation/scenes/Contact/ContactAction';
import { orgIdSelector } from '../../../../../Organisation/OrganisationSelectors';
import { planIdSelector, planNameSelector, currencySelector } from '../../../Plan/PlanSelectors';
import { riskListSelector } from '../../RiskSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../../constants';

const THEME_COLOR = '#a8c08f';

class RiskRegister extends PureComponent {
  state = { loaded: false };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {
      orgId,
      planId,
      fetchRiskLikelihoodPresets,
      fetchMitigationStrategies,
      fetchMitigationStatus,
      fetchRisks,
      fetchContact
    } = this.props;
    await fetchRiskLikelihoodPresets();
    await fetchMitigationStrategies();
    await fetchMitigationStatus();
    await fetchRisks(planId);
    await fetchContact(orgId);
    this.setState({ loaded: true });
  };

  deleteRisk = async data => {
    const { planId, deleteRisks } = this.props;
    try {
      await deleteRisks(planId, [data]);
      message.success(MSG_SAVE_SUCCESS);
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  export = () => {
    const { planId, exportRisks } = this.props;
    return exportRisks(planId);
  };

  render() {
    const { risks, planName, riskRequest, currency } = this.props;
    const { loaded } = this.state;
    const followUpDateFilters = [
      { text: 'This Week', value: 'week' },
      { text: 'This Month', value: 'month' },
      { text: 'This Year', value: 'year' },
      { text: 'Overdue', value: 'overdue' }
    ];

    const columns = [
      {
        key: 'risk',
        title: 'Risk',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) => <span className="risk-name">{record.name}</span>
      },
      {
        key: 'category',
        title: 'Risk Category',
        dataIndex: 'riskCategoryName',
        sorter: (a, b) => a.riskCategoryName.localeCompare(b.riskCategoryName),
        filters: [...new Set(risks?.map(({ riskCategoryName }) => riskCategoryName))].map(value => ({
          text: value,
          value
        })),
        onFilter: (value, record) => record.riskCategoryName === value,
        filterSearch: true
      },
      {
        key: 'likelihood',
        title: 'Likelihood',
        dataIndex: 'likelihoodText',
        sorter: (a, b) => a.likelihoodValue - b.likelihoodValue
      },
      {
        key: 'cost',
        title: 'Loss Value',
        render: (text, record) => (
          <span style={{ textAlign: 'end' }}>
            {record.cost ? (
              <CurrencyFormat value={record.cost} displayType="text" thousandSeparator prefix={`${currency} `} />
            ) : null}
          </span>
        ),
        sorter: (a, b) => a.cost - b.cost
      },
      {
        key: 'annualised',
        title: 'Annualised Value',
        render: (text, record) => (
          <span style={{ textAlign: 'end' }}>
            <CurrencyFormat
              value={((record.cost * record.likelihoodValue).toFixed(2) * 100) / 100}
              displayType="text"
              thousandSeparator
              prefix={`${currency} `}
            />
          </span>
        ),
        sorter: (a, b) => a.cost * a.likelihoodValue - b.cost * b.likelihoodValue
      },
      {
        key: 'mitigationStrategy',
        title: 'Mitigation Strategy',
        render: (text, record) => <span>{(record.mitigationStrategies || []).map(item => item.name).join(', ')}</span>,
        sorter: (a, b) =>
          (a.mitigationStrategies || [])
            .map(item => item.name)
            .join(', ')
            .localeCompare((b.mitigationStrategies || []).map(item => item.name).join(', '))
      },
      {
        key: 'mitigationStatus',
        title: 'Mitigation Status',
        dataIndex: 'mitigationStatusName',
        sorter: (a, b) => (a.mitigationStatusName || '').localeCompare(b.mitigationStatusName || '')
      },
      {
        key: 'contact',
        title: 'Responsible Person',
        render: (text, record) => (
          <span>
            {record.accountableContact
              ? `${record.accountableContact.firstName} ${record.accountableContact.lastName}`
              : null}
          </span>
        ),
        filters: [
          ...new Set(
            risks?.map(({ accountableContact }) =>
              accountableContact ? `${accountableContact.firstName} ${accountableContact.lastName}` : null
            )
          )
        ]
          .filter(value => value)
          .map(value => ({
            text: value,
            value
          })),
        onFilter: (value, record) =>
          `${record?.accountableContact?.firstName} ${record?.accountableContact?.lastName}` === value || false,
        sorter: (a, b) =>
          (a.accountableContact
            ? `${a.accountableContact.firstName} ${a.accountableContact.lastName}`
            : ''
          ).localeCompare(
            b.accountableContact ? `${b.accountableContact.firstName} ${b.accountableContact.lastName}` : ''
          )
      },
      {
        key: 'date',
        title: 'Follow Up Date',
        sorter: (a, b) => new Date(a?.followUpDate).getTime() - new Date(b?.followUpDate).getTime(),
        render: (text, record) => (
          <span>{record?.followUpDate ? moment(record?.followUpDate).format('DD/MM/YYYY') : null}</span>
        )
      },
      {
        key: 'dateFilter',
        filters: followUpDateFilters,
        width: 30,
        filterMultiple: false,
        onFilter: (value, record) => {
          if (value === 'overdue') {
            return moment().isAfter(record?.followUpDate);
          }
          return moment().isSame(record?.followUpDate, value);
        }
      },
      {
        key: 'action',
        width: 115,
        render: (text, record) => (
          <span>
            <Modal
              title={record.name}
              explanation={{ title: record.name, text: record.description }}
              toggle={show => <Button type="link" icon="edit" onClick={show} />}
            >
              {(hide, setExplanation) => (
                <RiskEdit
                  categoryId={record.riskCategoryId}
                  riskInfo={record}
                  close={hide}
                  onExplanationChange={setExplanation}
                />
              )}
            </Modal>
            <Divider type="vertical" />
            <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteRisk(record.riskId)}>
              <Button type="link" icon="delete" />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <>
        <ProcessBar
          color={THEME_COLOR}
          title="Risk Register"
          prev="/dashboard/risk/assessment"
          next="/dashboard/risk/report"
        />
        {loaded ? (
          <div className="risk-container">
            <Spin spinning={riskRequest}>
              <Card color={THEME_COLOR} title="Risk Register">
                <Table
                  columns={columns}
                  dataSource={risks}
                  rowKey="riskId"
                  breakpoint={1200}
                  searchFields={['name', 'riskCategoryName', 'likelihoodText']}
                />
                <div className="btn-container">
                  <Export fileName={`${planName.replace(/\s+/g, '_')}_Risk_Register.xlsx`} exportFile={this.export}>
                    {onExport => (
                      <Button type="primary" icon="export" onClick={onExport}>
                        Export
                      </Button>
                    )}
                  </Export>
                </div>
              </Card>
            </Spin>
          </div>
        ) : (
          <Spin tip="Loading Risks..." className="loading" />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  planId: planIdSelector(state),
  planName: planNameSelector(state),
  risks: riskListSelector(state),
  currency: currencySelector(state),
  riskRequest: state.riskReducer.riskRequest
});

const mapDispatchToProps = {
  fetchRisks,
  fetchRiskLikelihoodPresets,
  fetchMitigationStrategies,
  fetchMitigationStatus,
  deleteRisks,
  fetchContact,
  exportRisks
};

export default connect(mapStateToProps, mapDispatchToProps)(RiskRegister);
