import axios from 'axios';
import { fetchTransactions } from '../Transaction/TransactionActions';
import { fetchOrganisations } from '../../../Organisation/OrganisationAction';
import { fetchAllPlans } from '../../../Dashboard/scenes/Plan/PlanAction';
import { fetchUser } from '../User/UserActions';
import { brokerageIdSelector } from './BrokerSelectors';
import { userIdSelector } from '../User/UserSelectors';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_BROKER_ORGS: 'broker/REQUEST_BROKER_ORGS',
  REQUEST_BROKER: 'broker/REQUEST_BROKER',
  RECEIVE_BROKER: 'broker/RECEIVE_BROKER',
  REQUEST_BROKERS: 'broker/REQUEST_BROKERS',
  RECEIVE_BROKERS: 'broker/RECEIVE_BROKERS',
  REQUEST_BROKERAGE: 'broker/REQUEST_BROKERAGE',
  RECEIVE_BROKERAGE: 'broker/RECEIVE_BROKERAGE',
  REQUEST_BROKERAGE_SUBSCRIPTIONS: 'broker/REQUEST_BROKERAGE_SUBSCRIPTIONS',
  RECEIVE_BROKERAGE_SUBSCRIPTIONS: 'broker/RECEIVE_BROKERAGE_SUBSCRIPTIONS'
};

const requestBrokerOrgs = bool => ({ type: types.REQUEST_BROKER_ORGS, requestBrokerOrgs: bool });

const requestBroker = () => ({ type: types.REQUEST_BROKER });
const receiveBroker = data => ({ type: types.RECEIVE_BROKER, brokerInfo: data });

const requestBrokers = () => ({ type: types.REQUEST_BROKERS });
const receiveBrokers = data => ({ type: types.RECEIVE_BROKERS, brokers: data });

const requestBrokerageSubscriptions = () => ({ type: types.REQUEST_BROKERAGE_SUBSCRIPTIONS });
const receiveBrokerageSubscriptions = data => ({
  type: types.RECEIVE_BROKERAGE_SUBSCRIPTIONS,
  brokerageSubscriptions: data
});

const requestBrokerage = () => ({ type: types.REQUEST_BROKERAGE });
const receiveBrokerage = data => ({ type: types.RECEIVE_BROKERAGE, brokerageInfo: data });

export const createOrgFromBroker = data => async (dispatch, getState) => {
  dispatch(requestBrokerOrgs(true));
  const brokerageId = brokerageIdSelector(getState());
  const url = `${API_BASE}/brokerages/${brokerageId}/organisations`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchOrganisations());
    dispatch(fetchAllPlans());
    dispatch(fetchUser());
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(requestBrokerOrgs(false));
  }
};

export const fetchBroker = () => async dispatch => {
  dispatch(requestBroker());
  const url = `${API_BASE}user/broker`;
  try {
    const res = await axios.get(url);
    dispatch(receiveBroker(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBroker());
    return Promise.reject(err);
  }
};

export const fetchBrokers = brokerageId => async dispatch => {
  dispatch(requestBrokers());
  const url = `${API_BASE}brokerages/${brokerageId}/brokers`;
  try {
    const res = await axios.get(url);
    dispatch(receiveBrokers(res.data));
  } catch (err) {
    dispatch(receiveBrokers());
  }
};

export const fetchBrokerageSubscriptions = brokerageId => async dispatch => {
  dispatch(requestBrokerageSubscriptions());
  const url = `${API_BASE}brokerages/${brokerageId}/subscriptions`;
  try {
    const res = await axios.get(url);
    dispatch(receiveBrokerageSubscriptions(res.data));
  } catch (err) {
    dispatch(receiveBrokerageSubscriptions());
  }
};

export const createBroker = (brokerageId, data) => async dispatch => {
  dispatch(requestBrokers());
  const url = `${API_BASE}brokerages/${brokerageId}/brokers`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchBrokers(brokerageId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBrokers());
    return Promise.reject(err);
  }
};

export const updateBroker = (brokerId, data) => async (dispatch, getState) => {
  dispatch(requestBrokers());
  const url = `${API_BASE}brokers/${brokerId}`;
  try {
    const res = await axios.patch(url, data);
    const brokerageId = brokerageIdSelector(getState());
    dispatch(fetchBrokers(brokerageId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBrokers());
    return Promise.reject(err);
  }
};

export const deleteBroker = brokerId => async (dispatch, getState) => {
  dispatch(requestBrokers());
  const url = `${API_BASE}brokers/${brokerId}`;
  try {
    const res = await axios.delete(url);
    const brokerageId = brokerageIdSelector(getState());
    dispatch(fetchBrokers(brokerageId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBrokers());
    return Promise.reject(err);
  }
};

export const fetchBrokerage = brokerageId => async dispatch => {
  dispatch(requestBrokerage());
  const url = `${API_BASE}brokerages/${brokerageId}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveBrokerage(res.data));
  } catch (err) {
    dispatch(receiveBrokerage());
  }
};

export const transferQuota = (brokerageId, data) => async (dispatch, getState) => {
  dispatch(requestBrokerage());
  const url = `${API_BASE}brokerages/${brokerageId}/transfer`;
  try {
    const res = await axios.post(url, { ...data, initiator: userIdSelector(getState()) });
    dispatch(fetchBrokerage(brokerageId));
    dispatch(fetchTransactions(brokerageId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBrokerage());
    return Promise.reject(err);
  }
};

export const updateBrokerage = (brokerageId, data) => async dispatch => {
  dispatch(requestBrokerage());
  const url = `${API_BASE}brokerages/${brokerageId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchBrokerage(brokerageId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBrokerage());
    return Promise.reject(err);
  }
};

export const acceptBrokerageInvitation = (brokerageId, data) => () => {
  const url = `${API_BASE}brokerages/${brokerageId}/invitees/accept`;
  return axios.post(url, data);
};

export const createPromoCode = data => async (dispatch, getState) => {
  dispatch(requestBrokerageSubscriptions());
  const url = `${API_BASE}promo-codes/create`;
  try {
    const res = await axios.post(url, data);
    const brokerageId = brokerageIdSelector(getState());
    dispatch(fetchBrokerageSubscriptions(brokerageId));
    dispatch(fetchBrokerage(brokerageId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(requestBrokerageSubscriptions());
    return Promise.reject(err);
  }
};

export const revokePromoCode = data => async (dispatch, getState) => {
  dispatch(requestBrokerageSubscriptions());
  const url = `${API_BASE}promo-codes/revoke`;
  try {
    const res = await axios.post(url, data);
    const brokerageId = brokerageIdSelector(getState());
    dispatch(fetchBrokerageSubscriptions(brokerageId));
    dispatch(fetchBrokerage(brokerageId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(requestBrokerageSubscriptions());
    return Promise.reject(err);
  }
};

export const assignUser = data => async (dispatch, getState) => {
  dispatch(requestBrokerageSubscriptions());
  const url = `${API_BASE}subscriptions/assign`;
  try {
    const res = await axios.post(url, data);
    const brokerageId = brokerageIdSelector(getState());
    dispatch(fetchBrokerageSubscriptions(brokerageId));
    dispatch(fetchBroker());
    dispatch(fetchBrokerage(brokerageId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(requestBrokerageSubscriptions());
    return Promise.reject(err);
  }
};

export const revokeSubscriptionKey = data => async (dispatch, getState) => {
  dispatch(requestBrokerageSubscriptions());
  const url = `${API_BASE}subscriptions/revoke`;
  try {
    const res = await axios.post(url, data);
    const brokerageId = brokerageIdSelector(getState());
    dispatch(fetchBrokerageSubscriptions(brokerageId));
    dispatch(fetchBroker());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(requestBrokerageSubscriptions());
    return Promise.reject(err);
  }
};
