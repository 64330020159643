import React from 'react';
import { connect } from 'react-redux';
import { Icon, Button, Switch, Modal as AntModal, message } from 'antd';
import CurrencyFormat from 'react-currency-format';
import * as HttpStatus from 'http-status-codes';
import Collapse from '../../../../../components/Collapse/Collapse';
import Modal from '../../../../../components/Modal/Modal';
import Table from '../../../../../components/Table/Table';
import RiskEdit from './RiskEdit';
import { deleteRisks } from '../RiskAction';
import { currencySelector, planIdSelector } from '../../Plan/PlanSelectors';
import { riskListSelector } from '../RiskSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../constants';

const THEME_COLOR = '#a8c08f';

const RiskEvaluation = ({
  planId,
  risks,
  currency,
  riskList = [],
  riskCategory,
  selectedRisks,
  onInfoChange,
  deleteRisks
}) => {
  const { riskCategoryId, name, description } = riskCategory;
  const savedRisks = riskList.map(obj => obj.riskId);
  const numOfSelectedRisks = selectedRisks ? selectedRisks.length : 0;
  const collapseDescription =
    numOfSelectedRisks === 0
      ? `No ${name} selected`
      : `${numOfSelectedRisks} ${name}${numOfSelectedRisks > 1 ? 's' : ''} selected`;

  const isRiskSelected = riskName => selectedRisks && selectedRisks.includes(riskName);

  const deleteRisk = async data => {
    try {
      await deleteRisks(planId, [data]);
      message.success(MSG_SAVE_SUCCESS);
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  const columns = [
    {
      key: 'risk',
      title: 'Risk',
      render: (text, record) => (
        <span className="risk-name">
          {record.name}
          <Icon
            type="info-circle-o"
            className="risk-info-icon"
            onClick={() => onInfoChange(record.name, record.description)}
          />
        </span>
      )
    },
    {
      key: 'select',
      title: 'No/Yes',
      render: (text, record) => (
        <Modal
          explanation={{ title: record.name, text: record.description }}
          toggle={show => (
            <Switch
              checked={isRiskSelected(record.name)}
              onChange={checked => {
                if (checked) {
                  show();
                } else if (savedRisks.includes(record.riskId)) {
                  AntModal.confirm({
                    title: 'Are you sure to delete this risk?',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk: () => {
                      deleteRisk(record.riskId);
                    }
                  });
                }
              }}
            />
          )}
        >
          {(hide, setExplanation) => (
            <RiskEdit categoryId={riskCategoryId} riskInfo={record} close={hide} onExplanationChange={setExplanation} />
          )}
        </Modal>
      )
    },
    {
      key: 'likelihood',
      title: 'Likelihood',
      dataIndex: 'likelihoodText'
    },
    {
      key: 'cost',
      title: 'Loss Value',
      render: (text, record) => (
        <span style={{ textAlign: 'end' }}>
          {record.cost ? (
            <CurrencyFormat value={record.cost} displayType="text" thousandSeparator prefix={`${currency} `} />
          ) : null}
        </span>
      )
    },
    {
      key: 'annualised',
      title: 'Annualised Value',
      render: (text, record) => (
        <span style={{ textAlign: 'end' }}>
          {record.annualisedValue ? (
            <CurrencyFormat
              value={(record.annualisedValue.toFixed(2) * 100) / 100}
              displayType="text"
              thousandSeparator
              prefix={`${currency} `}
            />
          ) : null}
        </span>
      )
    },
    {
      key: 'action',
      render: (text, record) => (
        <Modal
          title={record.name}
          explanation={{ title: record.name, text: record.description }}
          toggle={show => (
            <Button
              type="primary"
              icon="edit"
              disabled={!isRiskSelected(record.name)}
              onClick={show}
              className="risk-btn"
            >
              Edit
            </Button>
          )}
        >
          {(hide, setExplanation) => (
            <RiskEdit categoryId={riskCategoryId} riskInfo={record} close={hide} onExplanationChange={setExplanation} />
          )}
        </Modal>
      )
    }
  ];

  return (
    <Collapse
      color={THEME_COLOR}
      title={name}
      description={collapseDescription}
      descriptionColor={numOfSelectedRisks > 0 ? THEME_COLOR : '#aaa'}
      icon={<Icon type="info-circle-o" className="risk-info-icon" onClick={() => onInfoChange(name, description)} />}
    >
      <Table columns={columns} dataSource={risks} rowKey="name" pagination={false} />
      <div className="btn-container">
        <Modal
          title="Add New Risk"
          explanation
          toggle={show => (
            <Button type="primary" icon="plus" className="risk-btn" onClick={show}>
              Add new
            </Button>
          )}
        >
          {(hide, setExplanation) => (
            <RiskEdit categoryId={riskCategoryId} close={hide} onExplanationChange={setExplanation} />
          )}
        </Modal>
      </div>
    </Collapse>
  );
};

const mapStateToProps = state => ({
  planId: planIdSelector(state),
  riskList: riskListSelector(state),
  currency: currencySelector(state)
});

const mapDispatchToProps = { deleteRisks };

export default connect(mapStateToProps, mapDispatchToProps)(RiskEvaluation);
