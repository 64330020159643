import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import RecoveryStrategyLocation from './components/RecoveryStrategyLocation';
import { fetchBranch } from '../../../Organisation/scenes/Branch/BranchAction';
import { fetchAlternativeSite } from '../../../Organisation/scenes/AlternativeSite/AlternativeSiteAction';
import {
  fetchRecoveryStrategyTypes,
  fetchRecoveryStrategy,
  createRecoveryStrategy,
  updateRecoveryStrategy
} from './RecoveryStrategyAction';
import { orgIdSelector } from '../../../Organisation/OrganisationSelectors';
import { branchesSelector, loadingBranchesSelector } from '../../../Organisation/scenes/Branch/BranchSelectors';
import {
  alternativeSiteListSelector,
  loadingAlternativeSitesSelector
} from '../../../Organisation/scenes/AlternativeSite/AlternativeSiteSelectors';
import { planIdSelector, branchIdSelector } from '../Plan/PlanSelectors';
import {
  recoveryStrategyTypesSelector,
  recoveryStrategySelector,
  loadingRecoveryStrategiesSelector,
  loadingRecoveryStrategyTypesSelector
} from './RecoveryStrategySelectors';

class RecoveryStrategy extends Component {
  state = { allData: undefined, locations: [] };

  componentDidMount() {
    this.loadData();
  }

  setLocations() {
    const { branches, alternativeSiteList } = this.props;
    const locations = [...branches, ...alternativeSiteList].map(obj => obj.address);
    locations.push('Other');
    this.setState({ locations });
  }

  loadData = async () => {
    const { orgId, fetchBranch, fetchAlternativeSite, fetchRecoveryStrategyTypes } = this.props;
    await fetchBranch(orgId);
    await fetchAlternativeSite(orgId);
    await fetchRecoveryStrategyTypes();
    this.setLocations();
    this.setData();
  };

  setData = async () => {
    const { planId, fetchRecoveryStrategy } = this.props;
    await fetchRecoveryStrategy(planId);
    const { recoveryStrategy, recoveryStrategyTypes } = this.props;
    const departmentRecoveryStrategyTypeId = recoveryStrategyTypes[0].recoveryStrategyTypeId;
    // const scaCategoryRecoveryStrategyTypeId = recoveryStrategyTypes[1].recoveryStrategyTypeId;
    const departmentRecoveryStrategyData = recoveryStrategy.departments.map(item => ({
      ...item,
      recoveryStrategyKey: item.departmentId,
      recoveryStrategyTypeId: departmentRecoveryStrategyTypeId
    }));
    // const scaCategoryRecoveryStrategyData = recoveryStrategy.scaCategories.map(item => ({
    //   ...item,
    //   recoveryStrategyKey: item.strategicCriticalAreaCategoryId,
    //   recoveryStrategyTypeId: scaCategoryRecoveryStrategyTypeId
    // }));
    const allData = [
      {
        title: 'Business Units',
        recoveryStrategyTypeId: departmentRecoveryStrategyTypeId,
        data: departmentRecoveryStrategyData
      }
      // {
      //   title: 'Critical Strategic Areas',
      //   recoveryStrategyTypeId: scaCategoryRecoveryStrategyTypeId,
      //   data: scaCategoryRecoveryStrategyData
      // }
    ];
    this.setState({ allData });
  };

  onLocationChange = (address, record, isOther) => {
    const { allData } = this.state;
    const typeIndex = allData.findIndex(item => item.recoveryStrategyTypeId === record.recoveryStrategyTypeId);
    const recordIndex = allData[typeIndex].data.findIndex(
      item => item.recoveryStrategyKey === record.recoveryStrategyKey
    );
    if (isOther) {
      allData[typeIndex].data[recordIndex].otherAddress = address;
    } else {
      allData[typeIndex].data[recordIndex].recoveryStrategyAddress = address;
    }
    this.setState({ allData });
  };

  onCommentChange = (comments, record) => {
    const { allData } = this.state;
    const typeIndex = allData.findIndex(item => item.recoveryStrategyTypeId === record.recoveryStrategyTypeId);
    const recordIndex = allData[typeIndex].data.findIndex(
      item => item.recoveryStrategyKey === record.recoveryStrategyKey
    );
    allData[typeIndex].data[recordIndex].recoveryStrategyComments = comments;
    this.setState({ allData });
  };

  render() {
    const { loading } = this.props;
    const { locations, allData = [] } = this.state;
    return (
      <>
        <ProcessBar
          title="Recovery Strategy"
          prev="/dashboard/business-impact-analysis/operational"
          next="/dashboard/plan-document"
        />
        <InfoBox message="Prior to an event occurring that could result in the loss of use of any of your Business Premises you should determine where each Business Unit could operate from. How tight you have set your Recovery Time Objectives will govern what your requirements will be in terms of needing a Hot Site (a site that has all the equipment needed for the Organization to continue operation, including office space and furniture, telephone connections and computer equipment) or simply the home of one of the Business Owners." />
        <div className="component-wrapper">
          {allData.map(obj => (
            <RecoveryStrategyLocation
              key={obj.recoveryStrategyTypeId}
              title={obj.title}
              data={obj.data}
              locations={locations}
              recoveryStrategyTypeId={obj.recoveryStrategyTypeId}
              loading={loading}
              onLocationChange={this.onLocationChange}
              onCommentChange={this.onCommentChange}
              {...this.props}
            />
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  branches: branchesSelector(state),
  branchId: branchIdSelector(state),
  planId: planIdSelector(state),
  alternativeSiteList: alternativeSiteListSelector(state),
  recoveryStrategyTypes: recoveryStrategyTypesSelector(state),
  recoveryStrategy: recoveryStrategySelector(state),
  loading:
    loadingRecoveryStrategiesSelector(state) ||
    loadingRecoveryStrategyTypesSelector(state) ||
    loadingBranchesSelector(state) ||
    loadingAlternativeSitesSelector(state)
});

const mapDispatchToProps = {
  fetchBranch,
  fetchAlternativeSite,
  fetchRecoveryStrategyTypes,
  fetchRecoveryStrategy,
  createRecoveryStrategy,
  updateRecoveryStrategy
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryStrategy);
