import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import InvocationLevel from '../../../InvocationLevel/InvocationLevel';
import Cover from './components/Cover/Cover';
import Disclaimer from './components/Disclaimer/Disclaimer';
import Navigation from './components/Navigation/Navigation';
import BackCover from './components/BackCover/BackCover';
import Table from './components/Table/Table';
import Page from './components/Page/Page';
import Supplier from './components/Supplier/Supplier';
import Customer from './components/Customer/Customer';
import StrategicThirdParty from './components/StrategicThirdParty/StrategicThirdParty';
import Asset from './components/Asset/Asset';
import {
  orgIdSelector,
  businessNameSelector,
  governanceSelector
} from '../../../../../Organisation/OrganisationSelectors';
import { alternativeSiteListSelector } from '../../../../../Organisation/scenes/AlternativeSite/AlternativeSiteSelectors';
import { planIdSelector, planNameSelector, planDateSelector, mtpdDaysSelector } from '../../../Plan/PlanSelectors';
import { biaListSelector, tacticalBiasSelector } from '../../../Bia/BiaSelectors';
import './PlanPreview.scss';

const RECOVERY_TEAMS = [
  { type: 'Emergency Response Team', name: 'Emergency Response Team', sections: ['B', 'C', 'D', 'E'] },
  { type: 'Crisis Management Team', name: 'Crisis Management Team', sections: ['F'] },
  { type: 'Information Technology Team', name: 'IT Recovery Team', sections: ['G'] }
];

const PlanPreview = ({
  businessName,
  governance,
  planName,
  planDate,
  mtpdDays,
  teamList = [],
  biaList = [],
  tacticalBias = [],
  alternativeSiteList = []
}) => {
  const getTeamTableData = teams => {
    const header = [
      { width: 34, name: 'NAME' },
      { width: 33, name: 'MOBILE' },
      { width: 33, name: 'SECTION' }
    ];
    const data = [];
    RECOVERY_TEAMS.forEach(item => {
      const team = teams.find(teamObj => teamObj.teamType.name === item.type);
      const teamContacts = [];
      if (team) {
        team.teamContacts.slice(0, 6).forEach(obj => {
          teamContacts.push({
            id: obj.contact.contactId,
            name: `${obj.contact.firstName} ${obj.contact.lastName}`,
            mobile: obj.contact.mobile
          });
        });
      }
      while (teamContacts.length < 5) {
        teamContacts.push({ id: -teamContacts.length });
      }
      data.push({
        title: item.name,
        sections: item.sections,
        contacts: teamContacts
      });
    });
    return { header, data };
  };

  const getCriticalAreaTableData = bias => {
    const header = [
      { width: 33, name: 'CRITICAL AREAS' },
      { width: 33, name: 'CATEGORY' },
      { width: 34, name: 'CRITICAL PERIOD' }
    ];
    const data = bias.slice(0, 15).map(item => ({
      id: item.strategicBiaId,
      name: item.strategicCriticalArea.name,
      category: item.strategicCriticalArea.strategicCriticalAreaCategory.name,
      criticalPeriods: item.criticalPeriods
    }));
    while (data.length < 10) {
      data.push({ id: -data.length, criticalPeriods: [] });
    }
    return { header, data };
  };

  const getTacticalBiaTableData = tacticalBias => {
    const header = [
      { width: 34, name: 'BUSINESS UNITS' },
      { width: 33, name: 'RECOVERY TIME OBJECTIVE' },
      { width: 33, name: 'RECOVERY POINT OBJECTIVE' }
    ];
    const data = tacticalBias.slice(0, 10).map(item => ({
      id: item.tacticalBiaId,
      department: item.departmentName,
      rto: item.rto,
      rpo: item.rpo
    }));
    while (data.length < 10) {
      data.push({ id: -data.length });
    }
    return { header, data };
  };

  const getAlternativeSiteTableData = alternativeSites => {
    const header = [
      { width: 30, name: 'NAME' },
      { width: 45, name: 'ADDRESS' },
      { width: 25, name: 'CONTACT DETAILS' }
    ];
    const data = alternativeSites.slice(0, 5).map(item => ({
      id: item.alternativeSiteId,
      name: item.name,
      address: item.address,
      contact: item.phone
    }));
    while (data.length < 5) {
      data.push({ id: -data.length });
    }
    return { header, data };
  };

  const criticalBiaList = (biaList || []).filter(obj => !!obj.strategicBiaId);
  const teamTableData = getTeamTableData(teamList);
  const criticalAreaTableData = getCriticalAreaTableData(criticalBiaList);
  const tacticalBiaTableData = getTacticalBiaTableData(tacticalBias);
  const alternativeSiteTableData = getAlternativeSiteTableData(alternativeSiteList);

  let pageNumber = 3;
  let figureSeq = 0;
  let ertSeq = 0;
  let secSeq = 0;
  let hrSeq = 0;
  let dmSeq = 0;
  let cmtSeq = 0;
  let itSeq = 0;

  /* eslint no-return-assign: off */
  return (
    <article className="plan-preview">
      <Cover />
      <Disclaimer />
      <Navigation />
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="plan-preview-table-of-content">
          <div className="title">
            <span className="shape" />
            <h1>TABLE OF CONTENT</h1>
          </div>
          <ul>
            <li>
              <div className="section">SECTION A</div>
              <div className="item">INTRODUCTION &amp; CONTEXT</div>
              <div className="page">06</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">1 RECOVERY TEAMS AND ROLES</div>
              <div className="page">08</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">2 RECOVERY TEAMS</div>
              <div className="page">09</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">3 INCIDENT LEVELS FOR ACTIVATION</div>
              <div className="page">10</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">4 IDENTIFIED CRITICAL AREAS</div>
              <div className="page">11</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">5 CRITICAL TIME MEASURES</div>
              <div className="page">12</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">6 SUPPORTING LINES OF BUSINESS</div>
              <div className="page">13</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">7 MEDIA/OTHER COMMUNICATIONS</div>
              <div className="page">14</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">8 ACTIVATION AUTHORITY AND METHOD</div>
              <div className="page">15</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">9 METHODS OF COMMUNICATION</div>
              <div className="page">15</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">10 ALTERNATIVE/RECOVERY SITES</div>
              <div className="page">16</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">11 STRATEGIC SUPPLIERS</div>
              <div className="page">16</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">12 STRATEGIC CUSTOMERS</div>
              <div className="page">16</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">13 STRATEGIC THIRD PARTIES</div>
              <div className="page">16</div>
            </li>
          </ul>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="plan-preview-table-of-content">
          <div className="title">
            <span className="shape" />
            <h1>TABLE OF CONTENT</h1>
          </div>
          <ul>
            <li>
              <div className="section">SECTION B</div>
              <div className="item">EMERGENCY RESPONSE</div>
              <div className="page">17</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">EMERGENCY RESPONSE TEAM</div>
              <div className="page">18</div>
            </li>
            <li>
              <div className="section">SECTION C</div>
              <div className="item">EMERGENCY RESPONSE TEAM - SECURITY</div>
              <div className="page">22</div>
            </li>
            <li>
              <div className="section">SECTION D</div>
              <div className="item">EMERGENCY RESPONSE TEAM - HUMAN MANAGEMENT</div>
              <div className="page">25</div>
            </li>
            <li>
              <div className="section">SECTION E</div>
              <div className="item">EMERGENCY RESPONSE TEAM - DAMAGE ASSESSMENT</div>
              <div className="page">28</div>
            </li>
            <li>
              <div className="section">SECTION F</div>
              <div className="item">CRISIS MANAGEMENT</div>
              <div className="page">33</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">CRISIS MANAGEMENT TEAM STRUCTURE</div>
              <div className="page">33</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">CRISIS MANAGEMENT TEAM ACTIONS</div>
              <div className="page">34</div>
            </li>
            <li>
              <div className="section">SECTION G</div>
              <div className="item">IT RECOVERY TEAM</div>
              <div className="page">43</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">IT SERVICE CONTINUITY ACTIONS</div>
              <div className="page">43</div>
            </li>
            <li>
              <div className="section">APPENDIX</div>
              <div className="item">A. STRATEGIC SUPPLIERS</div>
              <div className="page">51</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">B. STRATEGIC CUSTOMERS</div>
              <div className="page">52</div>
            </li>
            <li>
              <div className="section" />
              <div className="item">C. STRATEGIC THIRD PARTIES</div>
              <div className="page">53</div>
            </li>
          </ul>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="title">
          <h1 className="shape light">A.</h1>
          <h1>SECTION</h1>
        </div>
        <div className="content">
          <h2>INTRODUCTION &amp; CONTEXT</h2>
          <p>
            {businessName} takes very seriously its responsibility to do all in its power to ensure that it provides a
            continuous, safe, and dependable business environment for those:
          </p>
          <ul>
            <li>it employs;</li>
            <li>it supplies services and products to;</li>
            <li>it purchases supplies and services from; and</li>
            <li>to whom {businessName} represents a viable investment opportunity including partners.</li>
          </ul>
          <p>
            This Business Continuity Plan is the 3<sup>rd</sup> output of ContinuityCoach and has been created following
            a detailed Risk and Threat Assessment and the completion of a Business Continuity Policy.
          </p>
          {!!governance && (
            <>
              <p>
                <strong>Governance and Regulators:</strong>
              </p>
              <p>{governance}</p>
            </>
          )}
          <p>
            {businessName}&apos;s activities are subject to disruption, or incidents, by events that are mostly beyond
            the control of the Business. Risks deemed most likely in the macro environment, as well as services and
            processes deemed most critical to the internal environment of this company, have been critically analysed.
          </p>
          <p>
            For the purpose that this Business Continuity Plan (&apos;BCP&apos;) must fulfill, no non-critical areas
            will be included in the recovery strategies detailed herein.
          </p>
          <p>
            An incident, or disruption, can be defined as any situation that threatens critical operations, the
            integrity of {businessName} people, premises and / or reputation.
          </p>
          <p>
            Business Continuity Management is structured in 4 facets along with the supporting Information and
            Communications Technology (&apos;ICT&apos;) Service Continuity Management facet which supports and enables
            business recovery. The following diagram illustrates these facets and the sequence in which they are
            normally executed during a crisis:
          </p>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <div className="chart diagram" />
          <p className="chart-description">
            <strong>Figure {(figureSeq += 1)}: </strong>4 facets of Business Continuity Management
          </p>
        </div>
        <div className="content">
          <p>
            This plan is to be used during an incident that affects normal business processes that have been interrupted
            or affected by:
          </p>
          <ul>
            <li>Loss / unavailability of key personnel (staff); and / or</li>
            <li>Loss / unavailability buildings/workspace (premises), inaccessible (denial of access); and/ or</li>
            <li>Loss / unavailability critical technology (IT systems, applications, networks, etc.); and / or</li>
            <li>Loss / unavailability key documentation (records); and / or</li>
            <li>Loss / unavailability of specialised equipment; and / or</li>
            <li>Loss / unavailability or interruption of supply chain, key third-party product / service suppliers.</li>
          </ul>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <h2>A.1 RECOVERY TEAMS AND ROLES:</h2>
          <p>
            This Business Continuity Plan is a proactive plan which has been designed to guide you through a disruption
            / incident or crisis and is comprised of information describing the actions, procedures and process flows to
            be followed by:
          </p>
          <ul>
            <li>Emergency Response Team,</li>
            <ul>
              <li>Human Resources</li>
              <li>Security</li>
              <li>Damage Assessment</li>
            </ul>
            <li>Crisis Management Team</li>
            <li>IT Recovery Team</li>
          </ul>
          <p>
            It will be common for these Teams to be supported by specialist loss mitigators that may be listed in your
            Strategic Third Parties (refer APPENDIX C. STRATEGIC THIRD PARTIES).
          </p>
          <p>The ownership and accountability of the Business Continuity Plan resides with the:</p>
          <p>
            {businessName} Board
            <br />
            {businessName} CEO
            <br />
            {businessName} CFO
          </p>
          <p>The maintenance and update of this Business Continuity Plan resides with the following teams:</p>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <h2>A.2 RECOVERY TEAMS</h2>
          <Table header={teamTableData.header}>
            {teamTableData.data.map(item => (
              <Fragment key={item.title}>
                <tr>
                  <td className="table-top-action-row" colSpan="2">
                    {item.title}
                  </td>
                  <td className="table-top-action-row">{`SECTION ${item.sections.join(', ')}`}</td>
                </tr>
                {item.contacts.map(contactObj => (
                  <tr key={contactObj.id}>
                    <td className="table-content">{contactObj.name}</td>
                    <td className="table-content">{contactObj.mobile}</td>
                    <td className="table-content" />
                  </tr>
                ))}
              </Fragment>
            ))}
          </Table>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <h2>A.3 INCIDENT LEVELS FOR ACTIVATION</h2>
          <p>
            Incidents are defined to be one of three levels. Typically a full activation of the Business Continuity Plan
            only occurs at level 2 or 3 incidents.
          </p>
          <p>
            Following an incident, an assessment should be made on the level of severity using the diagram below as a
            guide.
          </p>
          <InvocationLevel embedded style={{ padding: 0, fontSize: '12px' }} />
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <h2>A.4 IDENTIFIED CRITICAL AREAS</h2>
          <p>
            The critical areas, or core functions, within the business have been detailed below with their corresponding
            criticality and possible penalties for breach of delivery. If an incident were to occur during any of the
            critical periods, special recovery consideration may need to be given during these times.
          </p>
          <Table header={criticalAreaTableData.header}>
            {criticalAreaTableData.data.map(item => (
              <tr key={item.id}>
                <td className="table-content">{item.name}</td>
                <td className="table-content">{item.category}</td>
                <td className="table-content">{item.criticalPeriods.map(obj => obj.name).join(', ')}</td>
              </tr>
            ))}
          </Table>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <h2>A.5 CRITICAL TIME MEASURES</h2>
          <p>
            The Recovery Time Objective (RTO) is a measure of how soon business requires the service to be restored
            (either in Production or in Disaster Recovery) from the time of the incident and not from when the decision
            was taken to switch to recovery.
          </p>
          <p>
            Actual Recovery Time (ART) is how long it actually takes to recover the system. Without proper planning and
            testing, Actual Recovery Time (ART) will always exceed Recovery Time Objective (RTO).
          </p>
          <p>
            The Maximum Tolerable Period of Disruption (MTPD), is the point at which the business can no longer survive.
          </p>
          <p>
            The following diagram depicts the high level process to follow when deciding to switch to Disaster Recovery:
          </p>
          <div className="chart rto-rpo" />
          <p className="highlight">
            {businessName}&apos;s strategic Maximum Tolerable Period of Disruption has been set at:
            <br />
            <strong>{mtpdDays} DAYS</strong>
          </p>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <h2>A.6 SUPPORTING LINES OF BUSINESS</h2>
          <p>
            In order for {businessName}&apos;s core services to be delivered at the {planName}, the following business
            units have been identified as critical, with their respective Recovery Time Objectives/Recovery Point
            Objectives detailed below.
          </p>
          <Table header={tacticalBiaTableData.header}>
            {tacticalBiaTableData.data.map(item => (
              <tr key={item.id}>
                <td className="table-content">{item.department}</td>
                <td className="table-content">{item.rto}</td>
                <td className="table-content">{item.rpo}</td>
              </tr>
            ))}
          </Table>
          <div className="break" />
          <p>
            If <span>Employees</span> for these departments are unavailable during an incident:
          </p>
          <p>
            Notify the Crisis Management Team (&apos;CMT&apos;) of the incident resulting in shortage or unavailability
            of full employees complement;
          </p>
          <ul>
            <li>Assess:</li>
            <ul>
              <li>
                The available employee level versus the current workload and determine the level of employee required
                for recovery; and
              </li>
              <li>Current team strength and capabilities versus the required skills set needed for recovery.</li>
            </ul>
          </ul>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <ul>
            <li>Allocate:</li>
            <ul>
              <li>Available employee members to critical functions required for recovery;</li>
            </ul>
          </ul>
          <p>If the in-house employees complement is inadequate consider the following:</p>
          <ul>
            <li>Ex-employees / Outsource Function / Contractors / Recruitment</li>
            <li>
              Note: Provide supporting documentation such as process flows, standard operating procedures and policies
              etc. as required to support staff.
            </li>
            <li>Distribute the workload among the available employees and assign tasks to each team member;</li>
            <li>
              Proceed to monitor team performance. (Be alert for signs of stress in employees and take appropriate
              actions); and
            </li>
            <li>Update the Crisis Management Team (&apos;CMT&apos;) on the status of recovery actions.</li>
          </ul>
          <p>
            If <span>Premises</span> are inaccessible for these employees:
          </p>
          <ul>
            <li>Liaise with the Crisis Management Team to assess the extent of the area affected, (if applicable);</li>
            <li>If premises / workspace is partially inaccessible, ensure that the affected area is cordoned off;</li>
            <li>Depending on the extent of the area affected, liaise with the Crisis Management Team regarding:</li>
            <li>Relocation to alternate on-site work area;</li>
            <li>Relocation to an alternative recovery site.</li>
          </ul>
          <div className="break" />
          <h2>A.7 MEDIA/OTHER COMMUNICATIONS</h2>
          <p>
            The Crisis Management Team will manage all direct communications with media and/or external stakeholders.
            Consider using your Brand &amp; Marketing Consultant/Media Consultant as listed in your Strategic Third
            Parties.
          </p>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <h2>A.8 ACTIVATION AUTHORITY AND METHOD</h2>
          <p>
            The Crisis Management Team (&apos;CMT&apos;) has the ultimate authority to declare a disaster and activate
            this Business Continuity Plan (&apos;BCP&apos;); however the other teams may authorise the recovery from a
            Level 1 incident without prior notification to the Crisis Management Team.
          </p>
          <p>
            The formal Activation declaration, following an operational disruption, from the Crisis Management Team
            activates this Business Continuity Plan in its entirety, and / or authorises the team and /or his or her
            deputy, to activate the relevant impacted operational resource dependency and the applicable recovery
            solution.
          </p>
          <div className="break" />
          <h2>A.9 METHODS OF COMMUNICATION</h2>
          <p>In the event of an incident, the Crisis Management Team becomes the central point of communication.</p>
          <p>The following methods may be utilised to activate and communicate to team members:</p>
          <p>
            <span>VOICE, EMAIL, WHATSAPP OR SMS</span>
          </p>
          <ul>
            <li>If possible, verbal communications (at assembly point in the event of an evacuation);</li>
            <li>If possible, telephonic communication (voice);</li>
            <li>If possible, email communication ;</li>
            <li>If possible via SMS or a Whatsapp chat group;</li>
            <li>
              Communication to and from the Emergency Response Team, Crisis Management Team and IT Recovery Team will
              occur verbally where possible. Alternatively, telephonic communication will be used between these teams.
            </li>
          </ul>
          <p>
            As soon as the team member(s) have received the message, the member(s) must confirm receipt of the message
            if sent via a written format.
          </p>
          <p>
            <span>Note:</span> If a member is unavailable, the person responsible to convey the message should continue
            with the chain of communication - if possible, in written format.
          </p>
        </div>
      </Page>
      <Page businessName={businessName} planName={planName} pageNumber={(pageNumber += 1)} date={planDate}>
        <div className="content">
          <h2>A.10 ALTERNATIVE/RECOVERY SITES</h2>
          <p>A suitable command centre / meeting point for the teams will be required to:</p>
          <ul>
            <li>Assess the impact of the incident;</li>
            <li>Make strategic decisions on actions and recovery resources to be deployed; as well as;</li>
            <li>General coordination of the teams and the entire recovery process.</li>
          </ul>
          <p>Unless otherwise specified:</p>
          <Table header={alternativeSiteTableData.header}>
            {alternativeSiteTableData.data.map(item => (
              <tr key={item.id}>
                <td className="table-content">{item.name}</td>
                <td className="table-content">{item.address}</td>
                <td className="table-content">{item.contact}</td>
              </tr>
            ))}
          </Table>
          <div className="break" />
          <h2>A.11 STRATEGIC SUPPLIERS</h2>
          <p>See Appendix A</p>
          <h2>A.12 STRATEGIC CUSTOMERS</h2>
          <p>See Appendix B</p>
          <h2>A.13 STRATEGIC THIRD PARTIES</h2>
          <p>See Appendix C</p>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
        anchor="emergency-response-team"
      >
        <div className="title">
          <h1 className="shape light" style={{ background: '#c86d6a' }}>
            B.
          </h1>
          <h1 style={{ color: '#c86d6a' }}>SECTION</h1>
        </div>
        <div className="content">
          <h2>EMERGENCY RESPONSE</h2>
          <p>
            Although minor events will be handled through existing business unit&apos;s operational processes, in
            certain cases, an incident may require activation of the Emergency Response Team (&apos;ERT&apos;). This
            team may be the largest of the Recovery teams, as its role is critical to the human element of recovery.
          </p>
          <p>
            <strong>
              An Emergency can be defined as any situation / incident that poses an immediate threat to the physical
              safety of people or premises. As a member of the Emergency Response Team your role is to coordinate the
              response to a physical emergency in order to help protect the people, assets and facilities.
            </strong>
          </p>
          <p>
            <strong>
              Emergency Response Team may activate emergency procedures, and brief all team members on their roles and
              responsibilities and provide regular and effective communication to the Crisis Management Team;
            </strong>
          </p>
          <p>
            <strong>
              Protect people and assets by deploying a team member/s as Human Resources&apos; representative and a team
              member/s as a Security representative to the site to manage the situation;
            </strong>
          </p>
          <p>
            <strong>
              Emergency Response Team is responsible for the occupational health and safety of staff and visitors. More
              specifically, the Emergency Response Team shall be responsible for the following during an incident:
            </strong>
          </p>
          <ul>
            <li>
              Ensure that all staff members and / or visitors are evacuated in a safe and efficient manner from the
              building in the event of a crisis;
            </li>
            <li>
              Perform a &quot;sweep&quot; of the building after the initial evacuation to ensure that any remaining
              staff members and / or visitors which may be trapped inside the building are evacuated to safety;
            </li>
            <li>
              Work with the authorities to perform assessments of the nature of the incident and the impact on the
              building and environment.
            </li>
            <li>
              Provide feedback to the Crisis Management Team throughout the incident on the status of the building or
              situation affected by the crisis or incident;
            </li>
          </ul>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <ul>
            <li>
              Assist with the relocation of staff back to their respective building once the incident has been averted
              and business as usual resumes, or direct staff otherwise;
            </li>
            <li>
              Manage trauma to staff / clients / visitors / suppliers, consolidate roll call of staff, and, arrange
              transport (to employee&apos;s homes / offsite location/recovery site) and security of people and
              infrastructure.
            </li>
          </ul>
          <p>
            Although minor events will be handled through existing business unit&apos;s operational processes, in
            certain cases, specialist teams or representatives may be required to assist.
          </p>
          <ul>
            <li>
              Damage Assessment (&apos;DAMS&apos;) - Evaluate initial status of damage after an incident and conduct
              damage assessments, establishing the extent of the damage, if any, and take decisions regarding the
              building infrastructure, electrical reticulation, equipment repairs and maintenance, etc., and to
              understand the severity of impacts, timelines of restoration, and what can be salvaged, estimating the
              time to reoccupy the facility, communicating and reporting all findings to the Crisis Management Team.
            </li>
            <li>
              Insurance representative: the role of this individual is to advise the Emergency Response Team on all
              insurance related matters and to liaise with insurance companies and loss adjusters, and to perform
              additional duties as delegated / requested by the Emergency Response Team.
            </li>
            <li>
              IT Coordinator and/or IT Recovery Team representative: the role of this individual is to advise the
              Emergency Response Team on all IT related matters i.e. Information and Communications Technology equipment
              layout, restoration etc., and to carry out additional duties as requested by the Emergency Response Team.
            </li>
          </ul>
          <div className="break" />
          <h2>
            EMERGENCY RESPONSE TEAM
            <br />
            Responsibilities
          </h2>
          <ul>
            <li>Manage and coordinate the incident.</li>
            <ul>
              <li>Activate the Emergency Response Team (&apos;ERT&apos;) and issue instructions;</li>
              <li>
                Make decisions without authorisation of the Crisis Management Team (&apos;CMT&apos;) (if required);
              </li>
              <li>Inform the Crisis Management Team of the incident.</li>
            </ul>
          </ul>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">ER {(ertSeq += 1)}</td>
              <td className="table-top-action-row">CONFIRM THERE IS AN INCIDENT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">How has the building been affected?</td>
              <td className="table-content" rowSpan="3">
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="table-content">What immediate actions are required?</td>
            </tr>
            <tr>
              <td className="table-content">
                Consider assembling any other members of the Emergency Response Team (nature and extent of incident)
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT ONE - Communicate to Staff/Visitors
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER {(ertSeq += 1)}</td>
              <td className="table-top-action-row">EVACUATE THE BUILDING</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="5" />
              <td className="table-content">Proceed with evacuation of the building</td>
              <td className="table-content" rowSpan="5">
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="table-content">Follow your company&apos;s &quot;Evacuation plan(s)&quot;</td>
            </tr>
            <tr>
              <td className="table-content">
                Ensure that everyone is evacuated and has been accounted for at the designated assembly point by means
                of a roll call and cross checked against the current staff list and applicable visitor registry.
              </td>
            </tr>
            <tr>
              <td className="table-content">The visitor registry must be checked and all visitors accounted for</td>
            </tr>
            <tr>
              <td className="table-content">
                Contact emergency services if required
                <ul>
                  <li>Police</li>
                  <li>Fire Department</li>
                  <li>Ambulance Services </li>
                  <li>Counselling Services (Refer to your Strategic Third Parties)</li>
                  <li>Hazardous materials experts (Hazmat)</li>
                </ul>
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">ER {(ertSeq += 1)}</td>
              <td className="table-top-action-row">FEEDBACK STATUS TO CRISIS MANAGEMENT TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Once you have safely evacuated the building, contact the Crisis Management Team to provide feedback on
                the success of the evacuation and initial damage assessment, and any other relevant information gathered
                during the evacuation e.g.:
                <ul>
                  <li>Injuries</li>
                  <li>Damaged floors / equipment</li>
                  <li>Arrival time of Emergency Services</li>
                </ul>
              </td>
              <td className="table-content">Emergency Response Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER {(ertSeq += 1)}</td>
              <td className="table-top-action-row">MOBILISE YOUR SUPPORTING TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="4" />
              <td className="table-content">
                Select from one of the designated meeting points near the assembly point of the affected site. Ensure
                the meeting point is safe and secure:
                <span>(See your company&apos;s &quot;Evacuation Plan&quot;)</span>
              </td>
              <td className="table-content" rowSpan="4">
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Assemble Emergency Response Team and / or any additional suitable resources to assist you
              </td>
            </tr>
            <tr>
              <td className="table-content">Refer to Strategic Contact Lists</td>
            </tr>
            <tr>
              <td className="table-content">
                Give instructions to each of the Emergency Response Team members. Ensure that actions are prioritised
                based on the nature of the incident and in what sequence the activities need to be executed
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER {(ertSeq += 1)}</td>
              <td className="table-top-action-row">COORDINATE INCIDENT SITE ACTIVITIES</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">Monitor and assist staff / clients / visitors / suppliers for stress</td>
              <td className="table-content" rowSpan="2">
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Obtain prioritisation list of divisions who require transport to recovery site from the Crisis
                Management Team and then provide this to Human Resources (if applicable)
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">ER {(ertSeq += 1)}</td>
              <td className="table-top-action-row">RELAY IMPORTANT DEVELOPMENTS TO CRISIS MANAGEMENT TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="9" />
              <td className="table-content">State of the building</td>
              <td className="table-content" rowSpan="9">
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="table-content">Health and safety hazards</td>
            </tr>
            <tr>
              <td className="table-content">Access to the surrounding area</td>
            </tr>
            <tr>
              <td className="table-content">Mastering our Minds Psychology at the incident site</td>
            </tr>
            <tr>
              <td className="table-content">Standard staff and media messages issued</td>
            </tr>
            <tr>
              <td className="table-content">
                Staff / Clients / Visitors / Suppliers/ Media status at the incident site
              </td>
            </tr>
            <tr>
              <td className="table-content">Emergency Services requirements</td>
            </tr>
            <tr>
              <td className="table-content">Important information gathered from Emergency Services</td>
            </tr>
            <tr>
              <td className="table-content">
                Any other information / documents required by the Emergency Response Team from the Crisis Management
                Team
              </td>
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Status of damage to Crisis Management Team from Damage Assessment Coordinator
              </td>
              <td className="table-content" />
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT TWO - Incident Over
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER {(ertSeq += 1)}</td>
              <td className="table-top-action-row">PERFORM CLOSURE ACTIONS AND UPDATE ERP</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">
                Conduct a de-brief with Emergency Response Team members and produce post incident reports
              </td>
              <td className="table-content" rowSpan="3">
                Emergency Response Team;
                <br />
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">Final Damage Assessment to be provided to the Crisis Management Team</td>
            </tr>
            <tr>
              <td className="table-content">Update ERP Must be done in conjunction with the Crisis Management Team</td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT THREE - End
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
        anchor="security"
      >
        <div className="title">
          <h1 className="shape light" style={{ background: '#c86d6a' }}>
            C.
          </h1>
          <h1 style={{ color: '#c86d6a' }}>SECTION</h1>
        </div>
        <div className="content">
          <h2>EMERGENCY RESPONSE TEAM - SECURITY</h2>
          <ul>
            <li>
              Protect staff / clients / visitors / suppliers entering the scene or at the scene from health and safety
              hazards
            </li>
            <li>Secure the building and assets</li>
            <li>
              Redirect unauthorised staff / clients / visitors / suppliers trying to access the site e.g. Couriers
            </li>
          </ul>
          <Table>
            <tr>
              <td className="table-top-action-row">ER Sec {(secSeq += 1)}</td>
              <td className="table-top-action-row">MOBILISE SUPPORT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="6" />
              <td className="table-content">Will you be able to handle your responsibilities alone?</td>
              <td className="table-content" rowSpan="6">
                Emergency Response Security
              </td>
            </tr>
            <tr>
              <td className="table-content">If not, identify suitable resources to assist you</td>
            </tr>
            <tr>
              <td className="table-content">Utilise existing Emergency Response Team personnel</td>
            </tr>
            <tr>
              <td className="table-content">When these resources are available, give them instructions</td>
            </tr>
            <tr>
              <td className="table-content">
                Instruct ER Safety &amp; Security staff members to contact their families to inform them that they are
                safe
              </td>
            </tr>
            <tr>
              <td className="table-content">
                <span className="italic">
                  Note: All media and communications will take place through the Crisis Management Team
                </span>
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT ONE - Communications
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER Sec {(secSeq += 1)}</td>
              <td className="table-top-action-row">PERFORM PRELIMINARY HIGH LEVEL SAFETY ASSESSMENT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">Perform a quick visual analysis of the site</td>
              <td className="table-content" rowSpan="3">
                Emergency Response Security;
                <br />
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="table-content">Identify areas where damage has occurred</td>
            </tr>
            <tr>
              <td className="table-content">Identify any risks that may affect people onsite</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">
                Consider the following:
                <ul>
                  <li>Are hard hats, torches, protective clothing etc. required?</li>
                  <li>If the cause of the incident is suspicious, call the police</li>
                  <li>Is external advice required?</li>
                </ul>
              </td>
              <td className="table-content" rowSpan="3" />
            </tr>
            <tr>
              <td className="table-content">Inform Emergency Responses Coordinator</td>
            </tr>
            <tr>
              <td className="table-content">Update the Crisis Management Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER Sec {(secSeq += 1)}</td>
              <td className="table-top-action-row">SECURE THE AFFECTED AREA</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="8" />
              <td className="table-content">
                Assess whether your security team will be sufficient to provide security
              </td>
              <td className="table-content" rowSpan="8">
                Emergency Response Security;
                <br />
                Police;
                <br />
                Security Company;
                <br />
                External Contractors;
                <br />
                Emergency Response Team
                <br />
              </td>
            </tr>
            <tr>
              <td className="table-content">
                If not, arrange additional backup security through your Security Company or the police
              </td>
            </tr>
            <tr>
              <td className="table-content">Identify and secure access points to the damaged area</td>
            </tr>
            <tr>
              <td className="table-content">Instruct the security staff to secure the identified areas</td>
            </tr>
            <tr>
              <td className="table-content">If required, engage with external contractors for safety advice</td>
            </tr>
            <tr>
              <td className="table-content">Ensure that external contractors do not enter unsafe areas</td>
            </tr>
            <tr>
              <td className="table-content">Ensure the safety of staff / clients / visitors / suppliers</td>
            </tr>
            <tr>
              <td className="table-content">
                Consider the following:
                <ul>
                  <li>Cordon tapes</li>
                  <li>Specialised ID cards for access to the area, if applicable</li>
                  <li>Elevator security to prevent people from using the elevator(s)</li>
                  <li>Prevent entry to access points into the building</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER Sec {(secSeq += 1)}</td>
              <td className="table-top-action-row">ADDRESS OCCUPATIONAL HEALTH AND SAFETY HAZARDS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                Get information from Emergency Services on the status of the affected area
              </td>
              <td className="table-content" rowSpan="2">
                Emergency Services &amp; Emergency Response Security
              </td>
            </tr>
            <tr>
              <td className="table-content">Determine what needs to be done to make the damaged area safe</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Consider the following:
                <ul>
                  <li>
                    Activate the mechanical systems in the building to remove smoke while preventing fresh air entering
                    the building
                  </li>
                  <li>Arrange for the reinstallation of power supply or temporary lighting and torches</li>
                  <li>Provide warning and arrange for clean-up of slippery surfaces</li>
                </ul>
              </td>
              <td className="table-content" />
            </tr>
            <tr>
              <td className="table-top-action-row">ER Sec {(secSeq += 1)}</td>
              <td className="table-top-action-row">REDIRECT UNAUTHORISED STAFF / VENDORS / CLIENTS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">
                Deny access to any staff / clients / visitors / suppliers not authorised to be onsite or returning to
                the building
              </td>
              <td className="table-content" rowSpan="3">
                Emergency Response Security;
                <br />
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="table-content">Determine where suppliers, mail and couriers should be redirected</td>
            </tr>
            <tr>
              <td className="table-content">Log all incidents where suppliers, mail and couriers are redirected</td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER Sec {(secSeq += 1)}</td>
              <td className="table-top-action-row">FEEDBACK TO EMERGENCY RESPONSE TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Important decisions that the Emergency Response Team needs to make to assist the ER S&amp;S
                representative:
                <ul>
                  <li>State of the building</li>
                  <li>Health and safety hazards</li>
                  <li>Access to the surrounding area</li>
                  <li>What mail and deliveries have been redirected and where they have been redirected to</li>
                </ul>
              </td>
              <td className="table-content">
                Emergency Response Security;
                <br />
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT TWO - End
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
        anchor="human-management"
      >
        <div className="title">
          <h1 className="shape light" style={{ background: '#c86d6a' }}>
            D.
          </h1>
          <h1 style={{ color: '#c86d6a' }}>SECTION</h1>
        </div>
        <div className="content">
          <h2>EMERGENCY RESPONSE TEAM - HUMAN MANAGEMENT</h2>
          <ul>
            <li>
              Identify injured or deceased staff / clients / visitors / suppliers, obtain their family contact
              information and provide that information to the Emergency Services and the Emergency Response Team
            </li>
            <li>Get the onsite physical roll call results</li>
            <li>
              Ensure on at least bi-annual basis that up-to-date staff contact lists have been sourced from business and
              securely stored
            </li>
          </ul>
          <Table>
            <tr>
              <td className="table-top-action-row">ER HR {(hrSeq += 1)}</td>
              <td className="table-top-action-row">DETERMINE THE NEED FOR ADDITIONAL RESOURCES</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="5" />
              <td className="table-content">Will you be able to handle your responsibilities alone?</td>
              <td className="table-content" rowSpan="5">
                Emergency Response Human Resources
              </td>
            </tr>
            <tr>
              <td className="table-content">
                If not, identify suitable resources to assist you
                <ul>
                  <li>Utilise existing Emergency Response Team personnel</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="table-content">When these resources are available, give them instructions</td>
            </tr>
            <tr>
              <td className="table-content">
                Instruct the Emergency Response Human Resources members to contact their families to inform them that
                they are safe
              </td>
            </tr>
            <tr>
              <td className="table-content">
                <span className="italic">
                  Note: All media and communications will take place through the Crisis Management Team
                </span>
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT ONE - Contact with Emergency Services
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER HR {(hrSeq += 1)}</td>
              <td className="table-top-action-row">ASSESS THE SEVERITY OF THE INJURIES</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Obtain the names of injured persons from the First Aiders
                <ul>
                  <li>Confirm the name of the injured person</li>
                  <li>Confirm the extent of their injuries</li>
                  <li>Confirm the hospital destination</li>
                  <li>Confirm the ambulance service</li>
                </ul>
              </td>
              <td className="table-content">
                First Aiders;
                <br />
                Emergency Services;
                <br />
                Emergency Response Team
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-content" rowSpan="4" />
              <td className="table-content">
                For deceased persons, provide the necessary next of kin details to the ES/Crisis Management Team and the
                Emergency Response Team
              </td>
              <td className="table-content" rowSpan="4" />
            </tr>
            <tr>
              <td className="table-content">Determine when the police intend to notify the families of the deceased</td>
            </tr>
            <tr>
              <td className="table-content">
                Communicate the severity of the injuries / casualties / fatalities to the Emergency Response Team and
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Ensure that sufficient information is gathered to ensure that workman&apos;s compensation forms can be
                comprehensively completed at a later date
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER HR {(hrSeq += 1)}</td>
              <td className="table-top-action-row">PROVIDE ONSITE SUPPORT AND COUNSELLING</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="5" />
              <td className="table-content">
                Determine how many staff / clients / visitors / suppliers require counselling
              </td>
              <td className="table-content" rowSpan="5">
                Emergency Services;
                <br />
                Counselling Services (Refer to your Strategic Third Parties)
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Determine what Emergency Services have in place to assist with counselling
              </td>
            </tr>
            <tr>
              <td className="table-content">Liaise with Counselling Services</td>
            </tr>
            <tr>
              <td className="table-content">
                Provide interim support and care
                <ul>
                  <li>Identify a safe and secure area for staff to gather</li>
                  <li>Set-up the support and counselling area</li>
                  <li>Consider the need for blankets / mattresses</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Brief the counsellors when they arrive
                <ul>
                  <li>They will need to know the details of the event</li>
                  <li>
                    They will need to have some background information on the affected staff / clients / visitors /
                    suppliers
                  </li>
                </ul>
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">ER HR {(hrSeq += 1)}</td>
              <td className="table-top-action-row">ARRANGE TRANSPORT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="5" />
              <td className="table-content">
                <span className="italic">
                  Note: Building considered to be in lock-down mode once evacuation notice is made, basements will only
                  be accessible once declared safe to re-enter by emergency services
                </span>
              </td>
              <td className="table-content" rowSpan="5">
                Emergency Response Human Resources &amp; transport companies
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Is the basement parking accessible? If yes, allow staff / clients / visitors / suppliers to retrieve
                their vehicles in an orderly manner
              </td>
            </tr>
            <tr>
              <td className="table-content">
                If not, liaise with the Crisis Management Team and determine which staff / clients / visitors /
                suppliers need transport home and who needs to go to the offsite recovery area
              </td>
            </tr>
            <tr>
              <td className="table-content">Separate these groups</td>
            </tr>
            <tr>
              <td className="table-content">
                Contact Transport Company:
                <br />
                Prioritise transport to the nominated Recovery site. The IT Coordinator will inform you of the order in
                which division needs to be moved to the recovery site (it should be based on the divisions process
                RTOs). Identify and move each division to the designated pick-up point whilst waiting for buses to
                arrive
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">ER HR {(hrSeq += 1)}</td>
              <td className="table-top-action-row">FEEDBACK TO THE EMERGENCY RESPONSE TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="4" />
              <td className="table-content">Status of available counselling facilities at the affected site</td>
              <td className="table-content" rowSpan="4">
                Emergency Response Human Resources
              </td>
            </tr>
            <tr>
              <td className="table-content">Standard staff messages being communicated to staff onsite</td>
            </tr>
            <tr>
              <td className="table-content">
                Ongoing staff status at the affected site - injuries / casualties / fatalities
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Staff questions being directed to the Emergency Response Human Resources Lead that need resolution
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT TWO - End
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
        anchor="damage-assessment"
      >
        <div className="title">
          <h1 className="shape light" style={{ background: '#c86d6a' }}>
            E.
          </h1>
          <h1 style={{ color: '#c86d6a' }}>SECTION</h1>
        </div>
        <div className="content">
          <h2>EMERGENCY RESPONSE TEAM - DAMAGE ASSESSMENT</h2>
          <ul>
            <li>Evaluate initial status of damage after an incident;</li>
            <li>
              Assist the Emergency Response Team to estimate both the time to reoccupy the building and salvage ability
              of any remaining equipment; and
            </li>
            <li>Insurance representative may take the lead in this process</li>
          </ul>
          <Table>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">RECEIVE NOTIFICATION OF INCIDENT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="4" />
              <td className="table-content">
                Establish whether you are required to proceed to the nominated meeting point or directly to the affected
                site
              </td>
              <td className="table-content" rowSpan="4">
                Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Establish whether the Emergency Response Team requires a damage assessment report
              </td>
            </tr>
            <tr>
              <td className="table-content">Contact the site facility manager to perform the damage assessment</td>
            </tr>
            <tr>
              <td className="table-content">
                Complete the initial assessment within 1 hour of being notified of the event
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT ONE - Notification received
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">PROCEED TO INCIDENT SITE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Proceed to incident site and agree the assessment approach and information gathering techniques
                (photo&apos;s, drawings etc.). Ensure that safety is the first priority
              </td>
              <td className="table-content">Damage Assessment</td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">MAINTAIN COMMUNICATIONS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">Maintain communications with the Emergency Response Team</td>
              <td className="table-content" rowSpan="2">
                Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">Refer any queries regarding the incident to the Crisis Management Team</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">ASSESSMENT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                Perform a walkthrough of the premises and make notes and record the initial findings
              </td>
              <td className="table-content" rowSpan="2">
                Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Initial assessment to consider the following:
                <ul>
                  <li>Injuries or death of staff / clients / visitors / suppliers;</li>
                  <li>Safety of the staff / clients / visitors / suppliers and office environment;</li>
                  <li>Damage to the production area and equipment;</li>
                  <li>
                    Damage to the offices / buildings (including meeting rooms, air- conditioners, filing rooms,
                    electrical and water supplies);
                  </li>
                  <li>Damage to the local IT data centre / UPS / power;</li>
                  <li>
                    Damage to computer equipment outside of the IT data centre (including desktops, laptops, printers);
                  </li>
                  <li>Which lines of business, if any, are unaffected and can continue with work;</li>
                  <li>Safety and security of site environment</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">FEEDBACK INITIAL ASSESSMENT FINDINGS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Present the findings of the initial assessment to the Emergency Response Team and Crisis Management Team
              </td>
              <td className="table-content">Damage Assessment</td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">RECEIVE INCIDENT MANAGEMENT DECISION</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Await feedback from the Crisis Management Team to inform you if an activation of the Business Continuity
                Plan has been declared
              </td>
              <td className="table-content">Damage Assessment</td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row" />
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                If the Crisis Management Team has indicated that a Final Assessment is required, proceed with{' '}
                <span>DM {dmSeq + 1}</span>, otherwise proceed to <span>DM {dmSeq + 3}</span>
              </td>
              <td className="table-content" />
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT TWO
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">PERFORM FINAL ASSESSMENT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="4" />
              <td className="table-content">
                Coordinate and document final assessment within 1 - 2 days (if possible)
              </td>
              <td className="table-content" rowSpan="4">
                Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">Final assessment to be done on the following:</td>
            </tr>
            <tr>
              <td className="table-content">
                <ul>
                  <li>Damage to the building structure</li>
                  <li>Damage to equipment and production floor</li>
                  <li>Damage to office furniture / related assets</li>
                  <li>Damage to the affected IT data centre</li>
                  <li>Computing equipment within the IT data centre</li>
                  <li>Computing equipment outside of the IT data centre</li>
                  <li>Data processing forms and supplies</li>
                  <li>Video conferencing and telephone equipment</li>
                  <li>
                    Non-data processing equipment within the data centre, including wide area network (WAN)
                    considerations
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="table-content">Assess impact to critical business processes</td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">FINAL ASSESSMENT FINDINGS FEEDBACK</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Present the detailed findings of the Final assessment to the Crisis Management Team
              </td>
              <td className="table-content">Damage Assessment</td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">INITIATE INSURANCE CLAIMS PROCESS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                If company assets are damaged and it seems likely that an insurance claim will be made, contact the
                Crisis Management Team and request that they inform the insurance company of the incident if they have
                not done so
              </td>
              <td className="table-content" rowSpan="2">
                Crisis Management Team / Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Also, request that a loss assessor / adjuster be made available to attend on site to validate the
                damaged assets. You should also consider the appointment of specialist claims mitigation experts, which
                would include an Insurance Claims Preparer (refer to your Strategic Third Parties)
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">IDENTIFY ANY NEED FOR MAKESAFE AND/OR REMOVAL OF DEBRIS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                If company property is damaged and it seems likely that an insurance claim will be made, contact the
                Crisis Management Team and request that they inform the insurance company of the incident if they have
                not done so
              </td>
              <td className="table-content" rowSpan="2">
                Crisis Management Team / Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">
                It may also be necessary to contact a specialised insurance claim loss mitigation firm such as a
                Restoration/Make Safe firm or an Insurance Claims Preparer as detailed in your Strategic Third Parties
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">IDENTIFY ASSETS &amp; HARDWARE FOR SALVAGE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="4" />
              <td className="table-content">
                If applicable, identify:
                <ul>
                  <li>Office infrastructure,</li>
                  <li>Equipment;</li>
                  <li>Paper files; and</li>
                  <li>IT hardware</li>
                </ul>
              </td>
              <td className="table-content" rowSpan="4">
                Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">To be salvaged in conjunction with the suppliers and loss adjusters</td>
            </tr>
            <tr>
              <td className="table-content">
                Note that salvage work on wet paper files must commence within 24 hours to minimise fungus, bacterial
                growth and rotting
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Compile a list of available (not damaged) equipment and office furniture
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">ARRANGE FOR REMOVAL OF SALVAGE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">Establish time schedules for salvage activities</td>
              <td className="table-content" rowSpan="2">
                Crisis Management Team / Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">Coordinate the removal / relocation of items</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#c86d6a"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">PROVIDE LIST FOR INSURANCE CLAIMS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Keep a copy for audit / reference purposes</td>
              <td className="table-content">Damage Assessment</td>
            </tr>
            <tr>
              <td className="table-top-action-row">DM {(dmSeq += 1)}</td>
              <td className="table-top-action-row">PARTICIPATE IN INCIDENT REVIEW MEETING</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                Participate in an incident review meeting in order to address any shortcomings in the plans and identify
                lessons learnt
              </td>
              <td className="table-content" rowSpan="2">
                Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">Update plans and recovery process(es) where necessary</td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT THREE - End
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
        anchor="crisis-management-team"
      >
        <div className="title">
          <h1 className="shape light" style={{ background: '#1f5993' }}>
            F.
          </h1>
          <h1 style={{ color: '#1f5993' }}>SECTION</h1>
        </div>
        <div className="content">
          <h2>CRISIS MANAGEMENT</h2>
          <p>
            <strong>
              The Crisis Management Team (&apos;CMT&apos;) constitutes the central decision-making unit during this
              Business Continuity Plan (&apos;BCP&apos;) activation and should be responsible for:
            </strong>
          </p>
          <ul>
            <li>
              Protecting the people, assets and reputation of operations, by responding to any incident effectively and
              to provide strategic direction, ensuring the reputation and the image of the company is protected at all
              times;
            </li>
            <li>
              Updating key stakeholders, regulatory bodies, and / or media, on status of the incident, and the progress
              of all recovery actions and processes;
            </li>
            <li>
              Assessing an incident, in order to decide whether to activate the Business Continuity Plan for possible
              relocation and resumption of head office operations at the nominated recovery site;
            </li>
            <li>
              Formally activating the Business Continuity Plan, for a <strong>Level 3</strong> and possibly a{' '}
              <strong>Level 2</strong>, and / or authorising the Team Leaders, to activate the relevant impacted
              operational resource dependency and the applicable recovery solution, on a <strong>Level 1</strong>.
            </li>
            <li>
              Strategically managing and directing communications and overseeing the recovery efforts, in conjunction
              with the teams, within the set Recovery Time Objectives (&quot;RTOs&quot;) and the stipulated Recovery
              Point Objectives (&quot;RPOs&quot;), for all the business processes, until the resumption of normal
              business operations has returned.
            </li>
          </ul>
          <h2>CRISIS MANAGEMENT TEAM STRUCTURE</h2>
          <p>
            Depending on the nature of the incident and if required, other representatives, the company&apos;s executive
            members, Operational team members, Senior management or subject matter experts may be called upon, to attend
            Crisis Management Team meetings, from time to time. These could also include representatives from
            Information Technology, Human Resources, Facilities and the Emergency Response Team.
          </p>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <h2>CRISIS MANAGEMENT TEAM ACTIONS</h2>
          <Table>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">RECEIVE NOTIFICATION OF AN INCIDENT ALERT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                Crisis Management Team receive notification of the incident or operational disruption
              </td>
              <td className="table-content" rowSpan="2">
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                <span className="italic">
                  Note: Depending on the nature of the incident, an initial CM emergency meeting must be held at least
                  within one hour of the alert
                </span>
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">EVACUATION OF OFFICES (if required)</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                If the nature of the incident requires the building to be evacuated ensure that all staff / clients /
                visitors / suppliers have been evacuated by the Emergency Response Team and have been accounted for at
                the designated assembly point by means of a roll call and cross checked against the current staff list
                and applicable visitor registry
              </td>
              <td className="table-content">
                Crisis Management Team;
                <br />
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT ONE - Incident Alert
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">IMPACT AND INCIDENT LEVEL</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                The Crisis Management Team will assess the severity of the incident level
              </td>
              <td className="table-content" rowSpan="2" />
            </tr>
            <tr>
              <td className="table-content">
                <span>Note:</span>
                <ul>
                  <li>
                    Level 1: The IT Recovery Team may have the authority to declare the activation of the relevant
                    impacted operational resource dependency and the applicable recovery solution
                  </li>
                  <li>Level 2 and Level 3: Activation is governed by the Crisis Management Team</li>
                </ul>
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">ASSESS IF:</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Information and Communications Technology element is affected or unavailable
              </td>
              <td className="table-content">
                Crisis Management Team;
                <br />
                Damage Assessment;
                <br />
                Information Technology Team
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">ASSESS IF:</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Critical equipment is affected or unavailable</td>
              <td className="table-content">
                Crisis Management Team;
                <br />
                Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">ASSESS IF:</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Key staff member(s) is affected or unavailable</td>
              <td className="table-content">
                Crisis Management Team;
                <br />
                Human Resources
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">ASSESS IF:</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Critical service / product provider(s) is affected or unavailable</td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">ASSESS IF:</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Critical documentation / records are affected or unavailable</td>
              <td className="table-content">
                Crisis Management Team;
                <br />
                Information Technology Team
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">ATTEND CRISIS MANAGEMENT TEAM MEETING</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="4" />
              <td className="table-content">
                Within the first day of notification, convene an initial Crisis Management Team meeting at the Temporary
                Recovery Site. Assess what has happened and high-level impact
              </td>
              <td className="table-content" rowSpan="4">
                Crisis Management Team;
                <br />
                Damage Assessment
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Recover any Strategic documentation which may be required by the Crisis Management Team. This may be
                offsite, or stored in a virtual &quot;Battle Box&quot; (your Documents Page within ContinuityCoach) in
                the cloud
              </td>
            </tr>
            <tr>
              <td className="table-content">Receive initial Damage Assessment report, if applicable</td>
            </tr>
            <tr>
              <td className="table-content">
                <span className="italic">Note: Each meeting must have minutes recorded</span>
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">INTERACT WITH THE EMERGENCY RESPONSE TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="4" />
              <td className="table-content">
                Obtain further clear information from the Emergency Response Team regarding the impact of the incident
                on people and the building
              </td>
              <td className="table-content" rowSpan="4">
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Assess whether the office is accessible, has it been evacuated, any restricted access?
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Assess if staff / clients / visitors / suppliers are accounted for and if there are any injuries,
                fatalities, where are the staff / clients / visitors / suppliers now?
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Who is at the scene, emergency services, media, key recovery site staff / clients / visitors /
                suppliers?
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">REASSESS INCIDENT LEVELS &amp; TARGET TIME FRAMES FOR RECOVERY</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Reassess impact and incident levels</td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">INCIDENT RESOLVED?</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                If incident is resolved at this point instruct the Emergency Response Team and the nominated recovery
                site to stand down from the alert, and then proceed to conduct a review of the incident(CMT24)
              </td>
              <td className="table-content">ALL</td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">PREPARE INITIAL COMMUNICATION</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Whether the activation of this Business Continuity Plan or any business recovery team(s) is to occur or
                not, a brief should be prepared to inform stakeholders of the incident (and released if required),
                either stating that the teams have been activated or that the incident has been resolved without the
                need to activate business recovery procedures
              </td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT TWO - BCP Activated?
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">INCIDENT DECLARATION</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">
                The decision to formally activate should be made as promptly as possible, taking into account:
              </td>
              <td className="table-content" rowSpan="3">
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                After initial assessment, if the Crisis Management Team feels that the severity of the incident requires
                the relocation of staff or stakeholder communication about the incident, then they must formally
                activate
              </td>
            </tr>
            <tr>
              <td className="table-content">
                If an incident has been declared that requires business recovery procedures to be activated, immediately
                instruct the teams to activate their Business Continuity Plan responses to resume critical operations
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">INTERNAL COMMUNICATIONS: INFORM INTERNAL STAFF</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                The Crisis Management Team should communicate a simple message to internal staff
                <ul>
                  <li>What has happened - be very brief</li>
                  <li>That the recovery site/offsite location has been activated</li>
                  <li>How to physically get to the recovery site (assuming you cannot access your car)</li>
                  <li>
                    Inform staff of information control and media policy - all media requests must be forwarded to the
                    Crisis Management Team Leader
                  </li>
                  <li>Any access difficulties - blocked roads, public transport, parking</li>
                  <li>To be as quick as possible (but to be safe)</li>
                  <li>Anything specific to take with them</li>
                  <li>A contact number to call if they have any questions or difficulties</li>
                  <li>Inform remaining personnel to proceed home, as necessary and await further instructions</li>
                </ul>
              </td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">EXTERNAL COMMUNICATIONS: INFORM STAKEHOLDERS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Inform relevant stakeholders and / or media of the incident. A brief must be prepared, if not required
                previously, to inform media and stakeholders of the incident and released, or an update must be
                provided, either stating that the teams have been formally activated or that the incident is being
                attended to
              </td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">INFORM THE RECOVERY SITE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Ensure that the Crisis Management Team has informed the nominated recovery site that the BCP has been
                activated
              </td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">PROCEED TO RECOVERY SITE TO MONITOR RECOVERY</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">Proceed to recovery site</td>
              <td className="table-content" rowSpan="3">
                Crisis Management Team;
                <br />
                Information Technology Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Monitor, oversee and manage the overall recovery actions in line with pre-established recovery time
                objectives, ensuring that all recovery efforts are adequately resourced to deliver an efficient recovery
                process. Request management meetings as and when required
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Monitoring decision points:
                <ul>
                  <li>
                    Decide whether to &quot;Go Live&quot; at recovery site once recovery has been effected or to go live
                    on the standby equipment (if applicable)
                  </li>
                  <li>
                    At this point, if the primary equipment has been recovered / repaired then the strategy may change
                    and the recovery designated an &quot;unplanned test&quot; and the business will revert back to using
                    the primary equipment
                  </li>
                  <li>If not, business units should start using recovery equipment for live business processing</li>
                  <li>Provide assistance and strategically direct teams as required</li>
                  <li>
                    Head of Finance / Insurance Representative (or Crisis Management Team member) to liaise with
                    insurers of the incident. This may be with the assistance of your appointed specialist Insurance
                    Claim Preparer (refer Strategic Third Parties)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">NOTIFICATION OF SUCCESSFUL RECOVERY</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">
                Receive notification that critical processes have been resumed effectively
              </td>
              <td className="table-content" rowSpan="3">
                Information Technology Team;
                <br />
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                This should be within the pre-agreed recovery time objectives, and if not assess any resulting impacts
                that may need to be dealt with and action accordingly
              </td>
            </tr>
            <tr>
              <td className="table-content">
                <span className="italic">
                  Note: This could be a reiterative process as various applications are recovered
                </span>
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT THREE - Recovery Solutions Implemented
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">
                CRISIS MANAGEMENT TEAM TO UPDATE THE RELEVANT INTERNAL STAFF AND STAKEHOLDERS
              </td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Prepare a briefing to inform media and/or stakeholders on progress and that critical operations have
                been recovered in line with the Business Continuity Plan
              </td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">AUTHORISE RELEVANT MAJOR EXPENDITURE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Head of Finance / Insurance Representative (or Crisis Management Team member) to assess and authorise
                expenditure beyond pre-defined authority levels of team leaders, as required, for business continuity
                and restoration projects
              </td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">
                MONITOR PROJECT DETAILS TO RETURN TO THE PRIMARY SITE AND / OR NORMAL OPERATIONAL LEVEL
              </td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                This may include possible building renovations, documents and data restoration, staffing
              </td>
              <td className="table-content">Crisis Management Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">SALVAGE OPERATIONS, if applicable</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                As required, liaise with the Emergency Response Team for an updated impact of the incident on resources,
                likely recovery project requirements and to provide a list of salvageable / recoverable resources
              </td>
              <td className="table-content" rowSpan="2">
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Insurance Representative to submit the detailed insurance claim based on complete information when
                available. This may be with the assistance of your appointed specialist Insurance Claim Preparer (refer
                Strategic Third Parties)
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">
                CONDUCT SITE VISIT AT OFFICE AND IMPLEMENT PLAN OF ACTION, if applicable
              </td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                After evaluation of the primary site, and if it is found to be unsuitable, then another building or
                location should be prepared accordingly for long term use
              </td>
              <td className="table-content" rowSpan="2">
                Crisis Management Team;
                <br />
                Information Technology Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                If relocation to the primary / alternate site is authorised then:
                <ul>
                  <li>
                    Test the infrastructure and physical access for full functionality before staff return to the
                    primary / alternative site
                  </li>
                  <li>The IT Recovery Team should manage the move</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT FOUR - Manage Incident / Stand Down
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">MANAGE INCIDENT / STAND DOWN</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                Stand down and instruct the staff and / or recovery site to stand down from the alert (if required)
              </td>
              <td className="table-content" rowSpan="2">
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Manage the incident and resume business-as-usual until the situation returns to normal
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">INCIDENT REVIEW / POST MORTEM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">
                Convene an incident review meeting in conjunction with the teams, and consider including affected
                stakeholders if appropriate
              </td>
              <td className="table-content" rowSpan="3">
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Analyse the incident, Business Continuity Plan, activation, recovery performance and lessons learnt
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Develop a post-incident report. Consider additional briefings to media, stakeholders, and internal staff
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#1f5993"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">CMT {(cmtSeq += 1)}</td>
              <td className="table-top-action-row">BUSINESS CONTINUITY PLANS UPDATE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="5" />
              <td className="table-content">Receive feedback on areas of improvement</td>
              <td className="table-content" rowSpan="5">
                ALL
              </td>
            </tr>
            <tr>
              <td className="table-content">Consider adjusting team roles as required</td>
            </tr>
            <tr>
              <td className="table-content">
                Determine what updates need to be made and action accordingly within specified time frames
              </td>
            </tr>
            <tr>
              <td className="table-content">
                If applicable, report to the Board on the progress of updates to the plan
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Consider scheduling an exercise after the updates have been made to run through the changes and
                additional scenarios
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT Five - End
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#729618"
        anchor="information-technology-team"
      >
        <div className="title">
          <h1 className="shape light" style={{ background: '#729618' }}>
            G.
          </h1>
          <h1 style={{ color: '#729618' }}>SECTION</h1>
        </div>
        <div className="content">
          <h2>IT RECOVERY TEAM</h2>
          <p>
            Recovering critical and medium to high ranked IT systems and applications, at an alternate site/restore to
            infrastructure, within the stipulated Recovery Time Objectives (&apos;RTOs&apos;) and the stipulated
            Recovery Point Objectives (&apos;RPOs&apos;) and supporting the business unit teams with recovering their
            critical business processes.
          </p>
          <p>
            The Recovery Time Objective (&apos;RTO&apos;) is a measure of how soon business requires the service to be
            restored (in Disaster Recovery) from the time it went down and not from when the decision was taken to
            switch to Disaster Recovery (&apos;DR&apos;).
          </p>
          <h2>IT SERVICE CONTINUITY ACTIONS</h2>
          <p>
            NB: The following actions are to be executed by the IT Team for his / her region or his / her deputy, in the
            absence of whom, the actions shall be performed by any member designated to act in the Business Continuity
            Plan Team Leader role.
          </p>
          <Table>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">RECEIVE INCIDENT ALERT</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Receive notification of the incident or operational disruption alert from a staff member, Security
                Personnel (refer Strategic Third Parties), a member of the Crisis Management Team or from the Emergency
                Response Team, or notify the Crisis Management Team of the incident which could have a negative impact
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">NOTIFY THE CRISIS MANAGEMENT TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Depending on the nature of the incident, if notification came from another source, ensure that Crisis
                Management Team is immediately notified utilising any of the chosen methods of communication
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#729618"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">EVACUATION OF OFFICES (if required)</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                If the nature of the incident requires the building to be evacuated, ensure that all staff have been
                evacuated by the Emergency Response Team and have been accounted for at the designated assembly point by
                means of a roll call and cross checked against the current staff list and applicable visitor registry
              </td>
              <td className="table-content">
                Information Technology Team;
                <br />
                Emergency Response Team
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">NOTIFY THE TEAM MEMBERS, (if required)</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="3" />
              <td className="table-content">
                Either verbally at the assembly point following an evacuation (i.e. if incident occurred at a time when
                building was occupied), or telephonically (utilising any of the chosen methods of communication) place
                team members on alert
              </td>
              <td className="table-content" rowSpan="3">
                Information Technology Team
              </td>
            </tr>
            <tr>
              <td className="table-content">Explain what has happened and course of action required</td>
            </tr>
            <tr>
              <td className="table-content">
                <span className="italic">
                  Note: All media and communications will take place through the Crisis Management Team
                </span>
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT ONE - Incident Alert
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">
                JOIN EMERGENCY MEETING WITH THE CRISIS MANAGEMENT TEAM (If required) / OR WAIT FOR FURTHER NOTIFICATION
              </td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                <span>Note:</span>
                <ul>
                  <li>
                    The Crisis Management Team Leader will assess the severity of the incident level in order to
                    determine if the Crisis Management Team requires activation
                  </li>
                </ul>
              </td>
              <td className="table-content" rowSpan="2">
                ALL
              </td>
            </tr>
            <tr>
              <td className="table-content">
                <span>Note:</span>
                <ul>
                  <li>
                    Level 1: The IT Recovery Team Leader(s) or his or her Deputy may have the authority to declare the
                    activation of the relevant impacted operational resource dependency and the applicable recovery
                    solution, however, the Crisis Management Team must be informed
                  </li>
                  <li>Level 2 and Level 3: Activation is governed by the Crisis Management Team</li>
                </ul>
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#729618"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">ALERT BACKUP STORAGE TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                If required alert backup storage team of possible backup delivery requirements
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">
                AWAIT FOR FURTHER NOTIFICATION AS THE CRISIS MANAGEMENT TEAM WILL REASSESS THE SEVERITY LEVELS BASED ON
                NATURE OF INCIDENT AND WHICH RESOURCES HAVE BEEN IMPACTED BELOW IN ORDER TO DETERMINE THE NEXT COURSE OF
                ACTION
              </td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                The Crisis Management Team will reassess the severity of the incident level based on what resources have
                been impacted
              </td>
              <td className="table-content" rowSpan="2">
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Level 2 and Level 3: Activation is governed by the Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">NOTIFICATION OF BCP ACTIVATION</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Receive notification from the Crisis Management Team that the Business Continuity Plan has been invoked
                Ensure course of action is understood and agree on reporting intervals
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row" />
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                <span className="italic">NOTE: If Business Continuity Plans have not been activated go to IT12</span>
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">Reinforce the media communications policy</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                <span className="italic">
                  Note: All media and communications will take place through the Crisis Management Team
                </span>
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT TWO - BCP activated
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#729618"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">EMPLOYEES UNAVAILABLE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="7" />
              <td className="table-content">
                Assess:
                <ul>
                  <li>
                    The available employee level versus the current workload and determine the level of employee
                    required for recovery; and
                  </li>
                  <li>Current team strength and capabilities versus the required skills set needed for recovery</li>
                </ul>
              </td>
              <td className="table-content" rowSpan="7">
                Information Technology Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Allocate:
                <ul>
                  <li>Available employee members to critical functions required for recovery:</li>
                  <li>Employees&apos; back-up logs</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="table-content">
                If the in-house employees&apos; complement is inadequate consider the following:
                <ul>
                  <li>Ex-employees / Outsource Function / Contractors / Recruitment</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Note: Provide supporting documentation such as process flows, standard operating procedures and policies
                etc as required to support staff
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Distribute the workload among the available employees and assign tasks to each team member
              </td>
            </tr>
            <tr>
              <td className="table-content">
                Proceed to monitor team performance. (Be alert for signs of stress in employees and take appropriate
                actions); and update the Crisis Management Team on status of recovery actions
              </td>
            </tr>
            <tr>
              <td className="table-content">
                <span className="italic">
                  Note: All media and communications will take place through the Crisis Management Team
                </span>
              </td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT THREE - Relocation
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#729618"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">RELOCATIONS STRATEGY DECISIONS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                If alternate site recovery is required, decide which Information Technology Team members must relocate
                to the recovery site?
              </td>
              <td className="table-content" rowSpan="2">
                Information Technology Team;
                <br />
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">What to do with the balance of staff?</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">NOTIFY BACKUP STORAGE TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Notify the backup storage team of the decision taken by management to activate the Business Continuity
                Plan and to deliver backups
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">CONTACT TEAM MEMBERS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                Contact team members and inform them of the situation and what is expected of them
              </td>
              <td className="table-content" rowSpan="2">
                Information Technology Team;
                <br />
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                <span className="italic">
                  Note: All media and communications will take place through the Crisis Management Team
                </span>
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row" />
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                NOTE: If recovery site not required for recovery go to Action Item IT 29
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">ITSC {(itSeq += 1)}</td>
              <td className="table-top-action-row">RELOCATE TEAM TO RECOVERY SITE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Send designated team members to the recovery site</td>
              <td className="table-content">Information Technology Team</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#729618"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">DISTRIBUTE DISASTER RECOVERY PLAN</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Distribute the DR sequence of recovery documentation</td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">ACCEPT RECOVERY HARDWARE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Accept recovery hardware from DR site manager, if applicable</td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">KEEP CRISIS MANAGEMENT TEAM INFORMED</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Keep the Crisis Management Team apprised of the recovery status at intervals
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">ITSC {(itSeq += 1)}</td>
              <td className="table-top-action-row">INFORM CRISIS MANAGEMENT TEAM OF ANY DATA LOSS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Keep the Crisis Management Team apprised of what backups are being used and the extent of the data loss,
                if any, per system
              </td>
              <td className="table-content">
                Information Technology Team;
                <br />
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">PERFORM REQUIRED RECOVERY</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Ensure that the Information Technology Team performs recovery of required systems / applications as per
                IT Recovery Strategy
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">NOTIFY CRISIS MANAGEMENT TEAM</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Notify Crisis Management Team as IT systems are recovered</td>
              <td className="table-content">
                Information Technology Team;
                <br />
                Crisis Management Team
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#729618"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">KEEP CRISIS MANAGEMENT TEAM INFORMED</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Keep Crisis Management Team informed of recovery status</td>
              <td className="table-content">
                Information Technology Team;
                <br />
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">AUDIT RECOVERED SYSTEMS</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Audit recovered systems to ensure the systems have been recovered correctly and are ready for use
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">RELEASE SYSTEMS FOR USE</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                Release the recovered systems for use only with permission from the Crisis Management Team
              </td>
              <td className="table-content" rowSpan="2">
                Information Technology Team;
                <br />
                Crisis Management Team
              </td>
            </tr>
            <tr>
              <td className="table-content">
                NOTE: This could be a reiterative process for each application as they are recovered
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">IMPLEMENT OPERATING PROCEDURES</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">Implement standard IT operating procedures at the recovered site</td>
              <td className="table-content">Information Technology Team</td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT FOUR - Functions and processes recovered
              </td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">SUBMIT EXPENSES, if applicable</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Submit expenses to the Finance Representative and/or Insurance Representative, if applicable
              </td>
              <td className="table-content">Information Technology Team</td>
            </tr>
          </Table>
        </div>
      </Page>
      <Page
        businessName={businessName}
        planName={planName}
        pageNumber={(pageNumber += 1)}
        date={planDate}
        color="#729618"
      >
        <div className="content">
          <Table>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">
                RELOCATE BACK TO PRODUCTION SITE OR NEWLY DEFINED SITE (if required)
              </td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" rowSpan="2" />
              <td className="table-content">
                Confirm with Crisis Management Team on schedule to return to Production; and
              </td>
              <td className="table-content" rowSpan="2">
                Information Technology Team
              </td>
            </tr>
            <tr>
              <td className="table-content">Scheduled date and time for resumption of business</td>
            </tr>
            <tr>
              <td className="table-top-action-row">IT {(itSeq += 1)}</td>
              <td className="table-top-action-row">PARTICIPATE IN INCIDENT REVIEW</td>
              <td className="table-top-action-row" />
            </tr>
            <tr>
              <td className="table-content" />
              <td className="table-content">
                Participate in an incident review meeting that will be called by the Crisis Management Team for Team
                leaders in order to address any shortcomings in the plans and update Business Continuity Plan where
                necessary
              </td>
              <td className="table-content">ALL</td>
            </tr>
            <tr>
              <td className="checkpoint" align="center" colSpan="3">
                CHECKPOINT FIVE - End (Stand down)
              </td>
            </tr>
          </Table>
        </div>
      </Page>
      <Supplier />
      <Customer />
      <StrategicThirdParty />
      <Asset />
      <BackCover />
    </article>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  businessName: businessNameSelector(state),
  governance: governanceSelector(state),
  planId: planIdSelector(state),
  planName: planNameSelector(state),
  planDate: planDateSelector(state),
  mtpdDays: mtpdDaysSelector(state),
  biaList: biaListSelector(state),
  tacticalBias: tacticalBiasSelector(state),
  teamList: state.teamReducer.teamList,
  alternativeSiteList: alternativeSiteListSelector(state)
});

export default connect(mapStateToProps)(PlanPreview);
