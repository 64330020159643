import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Card from '../../../../components/Card/Card';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import { fetchCurrencies } from './PlanAction';
import { branchesSelector } from '../../../Organisation/scenes/Branch/BranchSelectors';
import { plansSelector } from './PlanSelectors';
import { orgIdSelector } from '../../../Organisation/OrganisationSelectors';
import PlanList from './components/PlanList';

const INFO_MSG = (
  <>
    <p>
      The Business Continuity Governance Committee is required to determine which business locations require a plan.
      This is discretionary but clearly if a business location is strategically important to the business it ought to
      have a plan of its own.
    </p>
    <p>
      Click the &apos;+ Add new&apos; button and give your Plan a name and assign it to a Business Location. Allocate an
      Internal Contact that will become the &apos;Accountable Person&apos; for that plan.
    </p>
    <p>
      You will require a unique Subscription Key to create a new plan. This Subscription Key will have been provided to
      you or it can either be purchased through ContinuityCoach or obtained from a subscribing partner (insurance
      broker/agent, insurance company).
    </p>
  </>
);

const Plan = ({ history, plans = [], branches = [], fetchCurrencies, orgId }) => {
  const planList = plans.map(plan => {
    const branch = branches.find(branch => branch.branchId === plan.branchId) || {};
    return { ...plan, branch: branch.name, address: branch.address };
  });

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  return (
    <>
      <InfoBox message={INFO_MSG} />
      <div className="component-wrapper">
        <Card title="Plans">
          <PlanList planList={planList} history={history} orgId={orgId} />
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  plans: plansSelector(state),
  branches: branchesSelector(state)
});

const mapDispatchToProps = { fetchCurrencies };

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
