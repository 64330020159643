import React from 'react';
import { connect } from 'react-redux';
import Logo from '../../../../../../components/Logo/Logo';
import { businessNameSelector, businessLogoSelector } from '../../../../OrganisationSelectors';
import { policyDateSelector, policyMembersSelector } from '../../PolicySelectors';
import './Certificate.scss';

const Certificate = ({ businessName, businessLogo, policyDate, policyMembers }) => (
  <article className="certificate">
    <section className="bg-cover">
      <div className="certificate-inner">
        <Logo height={45} />
        <h1>CERTIFICATE</h1>
        <h3>&mdash;&mdash;&mdash;&mdash; OF COMMITMENT</h3>
        <h2>
          TO RISK MANAGEMENT &amp;
          <br />
          BUSINESS CONTINUITY MANAGEMENT
        </h2>
        <p>
          THIS CERTIFICATE IS PROUDLY
          <br />
          PRESENTED TO
        </p>
        <figure className="client-logo">
          <figure className="bg-contain" style={{ backgroundImage: `url(${businessLogo})` }} />
        </figure>
        <h2 className="dotted">{businessName}</h2>
        <p>
          The {businessName} acknowledges its responsibility to plan for resilience of the business, to identify and
          measure its risks, and as far as possible, to mitigate the period and result of disruption and/or loss at the
          time of an incident.
        </p>
        <p>
          The {businessName} is committed to ensuring it provides a sustainable and safe service to its stakeholders and
          the environment, and therefore commits to implementing, maintaining and improving risk management and business
          continuity management enterprise wide.
        </p>
        <p>
          The {businessName} Business Continuity Governance Committee and Crisis Management Team are committed to
          ongoing support and monitoring of this program, and are accountable for their strategic leadership and
          governance.
        </p>
        <div className="date-accept">
          <div>
            <div className="title dotted">DATE</div>
            <div className="sign">{policyDate}</div>
          </div>
          <div>
            <div className="title dotted">ACCEPTED &amp; AGREED BY</div>
            <div className="sign">{policyMembers.join(', ')}</div>
          </div>
        </div>
      </div>
    </section>
  </article>
);

const mapStateToProps = state => ({
  businessName: businessNameSelector(state),
  businessLogo: businessLogoSelector(state),
  policyDate: policyDateSelector(state),
  policyMembers: policyMembersSelector(state)
});

export default connect(mapStateToProps)(Certificate);
