import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Spin, Icon } from 'antd';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../components/Card/Card';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import Modal from '../../../../components/Modal/Modal';
import DepartmentAdd from './components/DepartmentAdd';
import DepartmentEdit from './components/DepartmentEdit';
import { fetchBranch } from '../../../Organisation/scenes/Branch/BranchAction';
import { orgIdSelector } from '../../../Organisation/OrganisationSelectors';
import { departmentListSelector, loadingDepartmentsSelector } from './DepartmentSelectors';
import './Department.scss';

const INFO_MSG = (
  <>
    <p>
      This is a very simple step in which we are only collecting the name of the various business units at this
      location.
    </p>
    <p>A business unit is a department or division which assists in the overall operation of your organization.</p>
    <p>
      As you add the business units relative to the organization, please use the name by which the unit is known within
      the organization.
    </p>
    <p>
      In addition to production, warehousing etc, support units such as Finance, Human Resources, Information
      Communications and Technology need to be included. We have provided a few common units in the drop-down list and
      you can choose to use any or all of these or tailor the plan by typing the name of your units in the cell and hit
      &quot;Enter&quot; to include the new business unit.
    </p>
  </>
);

const EXPLANATION_TEXT_ADD = (
  <>
    <p>
      Add Business Units by clicking in the box to activate the dropdown menu, then select from any of the suggested
      examples.
    </p>
    <p>
      Alternatively, type the name of your Business Unit and hit your Enter key, which will populate the new Business
      Unit in the cell.
    </p>
    <p>Once complete, click the Save button and your Business Units will be created.</p>
  </>
);

const EXPLANATION_TEXT_EDIT = 'You can change the name of your chosen Business Unit.';

const icon = department => {
  const name = typeof department === 'string' ? department.toLowerCase() : '';
  switch (name) {
    case 'it':
    case 'ict':
    case 'information technology':
      return 'desktop';
    case 'finance':
      return 'bank';
    case 'engineer':
    case 'engineering':
      return 'tool';
    case 'hr':
    case 'human resource':
      return 'team';
    case 'sales':
      return 'shopping';
    case 'call':
    case 'call center':
    case 'call centre':
      return 'phone';
    default:
      return 'home';
  }
};

const Department = ({ orgId, departmentList, loading, fetchBranch }) => {
  useEffect(() => {
    fetchBranch(orgId);
  }, [orgId, fetchBranch]);
  return (
    <>
      <ProcessBar color="#5BC2E7" title="Business Units" next="/dashboard/teams" />
      <InfoBox color="#5BC2E7" message={INFO_MSG} />
      {departmentList ? (
        <div className="department-container">
          <Spin spinning={loading}>
            <Card color="#5BC2E7" title="Business Units">
              <Row gutter={20} className="department-list">
                {departmentList.map(item => (
                  <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3} key={item.departmentId}>
                    <div className="department-list-item">
                      <Icon type={icon(item.name)} className="department-icon" />
                      <div className="department-title single-line">{item.name}</div>
                      <Modal
                        title="Edit Business Unit"
                        explanation={{ text: EXPLANATION_TEXT_EDIT }}
                        toggle={show => (
                          <Button type="link" onClick={show} className="department-text">
                            Edit
                          </Button>
                        )}
                      >
                        {hide => <DepartmentEdit department={item} close={hide} />}
                      </Modal>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className="btn-container">
                <Modal
                  title="Add Business Units"
                  explanation={{ title: 'Add Business Units', text: EXPLANATION_TEXT_ADD }}
                  toggle={show => (
                    <Button type="primary" icon="plus" onClick={show} className="department-btn">
                      Add new
                    </Button>
                  )}
                >
                  {hide => <DepartmentAdd close={hide} />}
                </Modal>
              </div>
            </Card>
          </Spin>
        </div>
      ) : (
        <Spin tip="Loading Business Units..." className="loading" />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  departmentList: departmentListSelector(state),
  loading: loadingDepartmentsSelector(state)
});

const mapDispatchToProps = { fetchBranch };

export default connect(mapStateToProps, mapDispatchToProps)(Department);
