import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_TRANSACTIONS: 'transaction/REQUEST_TRANSACTIONS',
  RECEIVE_TRANSACTIONS: 'transaction/RECEIVE_TRANSACTIONS'
};

const requestTransactions = () => ({ type: types.REQUEST_TRANSACTIONS });
const receiveTransactions = data => ({ type: types.RECEIVE_TRANSACTIONS, transactions: data });

export const fetchTransactions = brokerageId => async dispatch => {
  dispatch(requestTransactions());
  const url = brokerageId ? `${API_BASE}brokerages/${brokerageId}/transactions` : `${API_BASE}user/transactions`;
  try {
    const res = await axios.get(url);
    dispatch(receiveTransactions(res.data));
  } catch (err) {
    dispatch(receiveTransactions());
  }
};
