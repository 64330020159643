import React, { Component } from 'react';
import { Alert, Button } from 'antd';
import axios from 'axios';
import * as HttpStatus from 'http-status-codes';
import Layout from '../components/Layout/Layout';
import Modal from '../components/Modal/Modal';
import Login from '../components/Login/Login';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_EMAIL_CONFIRMATION = 'confirmation/email/confirm';

export default class EmailConfirmation extends Component {
  state = {
    confirmStatus: undefined,
    confirmMsg: '',
    confirmDes: ''
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const parsedUrl = new URL(window.location);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const userId = searchParams.get('id');
    const token = searchParams.get('token');
    const url = `${API_BASE}${API_EMAIL_CONFIRMATION}`;
    const data = { id: userId, token };
    try {
      const res = await axios.post(url, data);
      this.setState({
        confirmStatus: res.status,
        confirmMsg: 'Account Successfully Activated',
        confirmDes:
          'Thank you for registering and activating your account with LMI Continuity Coach. Please click the button below to log in.'
      });
    } catch (err) {
      const confirmMsg = 'Sorry, an error occurred';
      let confirmDes = '';
      switch (err.status) {
        case HttpStatus.BAD_REQUEST:
        case HttpStatus.UNAUTHORIZED:
          confirmDes = 'This user does not exist. Please register or log in another account.';
          break;
        case HttpStatus.IM_A_TEAPOT:
          confirmDes = 'The user token is invalid or expired. please log in and resend the confirmation email.';
          break;
        default:
          break;
      }
      this.setState({ confirmStatus: err.status, confirmMsg, confirmDes });
    }
  };

  render() {
    const { confirmStatus, confirmMsg, confirmDes } = this.state;
    return (
      <Layout>
        {confirmStatus && (
          <div style={{ width: '50%', maxWidth: '800px', margin: '100px auto' }}>
            <Alert
              message={confirmMsg}
              description={confirmDes}
              type={confirmStatus === HttpStatus.OK ? 'success' : 'error'}
              showIcon
            />
            <Modal
              footer={null}
              toggle={show => (
                <Button type="primary" style={{ marginTop: '20px', float: 'right' }} onClick={show}>
                  {confirmStatus === HttpStatus.OK || confirmStatus === HttpStatus.IM_A_TEAPOT ? 'Log in' : 'Register'}
                </Button>
              )}
            >
              {hide => <Login close={hide} />}
            </Modal>
          </div>
        )}
      </Layout>
    );
  }
}
