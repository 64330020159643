import React, { Component } from 'react';
import PropTypes from 'prop-types';

const COLOR_BASE = '#ddd';
const COLOR_RATING_HIGH = '#CB2C30';
const COLOR_RATING_MEDIUM = '#F1C400';
const COLOR_RATING_LOW = '#00685E';

export default class Rating extends Component {
  static propTypes = {
    percent: PropTypes.number,
    width: PropTypes.number,
    widthInner: PropTypes.number
  };

  static defaultProps = {
    percent: 0,
    width: 50,
    widthInner: 30
  };

  render() {
    const { percent, width, widthInner } = this.props;
    /* eslint no-nested-ternary: off */
    const color = percent > 60 ? COLOR_RATING_HIGH : percent > 25 ? COLOR_RATING_MEDIUM : COLOR_RATING_LOW;
    let leftTransformDegree = 0;
    let rightTransformDegree = 0;
    let rightColor = COLOR_BASE;

    if (percent >= 50) {
      leftTransformDegree = (percent - 50) * 3.6;
      rightTransformDegree = 0;
      rightColor = color;
    } else {
      leftTransformDegree = 0;
      rightTransformDegree = percent * 3.6;
    }

    return (
      <div
        style={{
          position: 'relative',
          width: `${width}px`,
          height: `${width}px`,
          borderRadius: '50%',
          margin: 'auto',
          backgroundColor: color
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: `${(width - widthInner) / 2}px`,
            left: `${(width - widthInner) / 2}px`,
            width: `${widthInner}px`,
            height: `${widthInner}px`,
            lineHeight: `${widthInner}px`,
            borderRadius: '50%',
            backgroundColor: '#2d2929',
            color: '#fff',
            textAlign: 'center',
            zIndex: '999'
          }}
        >
          {percent}
        </div>
        <div
          style={{
            position: 'absolute',
            width: `${width}px`,
            height: `${width}px`,
            borderRadius: '50%',
            backgroundColor: COLOR_BASE,
            clip: `rect(0, ${width / 2}px, ${width}px, 0)`,
            transform: `rotate(${leftTransformDegree}deg)`
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: `${width}px`,
            height: `${width}px`,
            borderRadius: '50%',
            backgroundColor: rightColor,
            clip: `rect(0, ${width}px, ${width}px, ${width / 2}px)`,
            transform: `rotate(${rightTransformDegree}deg)`
          }}
        />
      </div>
    );
  }
}
