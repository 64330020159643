import { types } from './TeamAction';

const initialState = { loadingTeamTypes: false, loadingTeams: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_TEAMS:
      return { ...state, loadingTeams: true };
    case types.RECEIVE_TEAMS:
      return { ...state, loadingTeams: false, teamList: action.teamList };
    default:
      return state;
  }
};
