import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Input } from 'antd';
import Card from '../../../components/Card/Card';
import Modal from '../../../components/Modal/Modal';
import BusinessEdit from './BusinessEdit';
import { businessInfoSelector } from '../OrganisationSelectors';

const BusinessInfo = ({ businessInfo = {} }) => {
  const { name, legalName, registrationNumber, industryName, governance } = businessInfo;
  return (
    <Card title="Organization Information">
      <Row gutter={40} className="business-info">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="business-info-item">
          <div className="business-info-item-title">Full Business Name</div>
          <Input value={name} readOnly />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="business-info-item">
          <div className="business-info-item-title">Trading Name</div>
          <Input value={legalName} readOnly />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="business-info-item">
          <div className="business-info-item-title">Business Registration Number</div>
          <Input value={registrationNumber} readOnly />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="business-info-item">
          <div className="business-info-item-title">Industry</div>
          <Input value={industryName} readOnly />
        </Col>
        <Col span={24} className="business-info-item">
          <div className="business-info-item-title">Governance &amp; Regulations</div>
          <Input value={governance} readOnly />
        </Col>
      </Row>
      <div className="btn-container">
        <Modal
          explanation
          toggle={show => (
            <Button type="primary" icon="edit" onClick={show}>
              Edit
            </Button>
          )}
        >
          {(hide, setExplanation) => (
            <BusinessEdit businessInfo={businessInfo} close={hide} onExplanationChange={setExplanation} />
          )}
        </Modal>
      </div>
    </Card>
  );
};

const mapStateToProps = state => ({
  businessInfo: businessInfoSelector(state)
});

export default connect(mapStateToProps)(BusinessInfo);
