import { types } from './BrokerActions';
import { USER_LOGOUT } from '../../../../components/Login/LoginAction';

const initialState = {
  requestBrokerOrgs: false,
  loadingBroker: false,
  loadingBrokers: false,
  loadingBrokerage: false,
  loadingBrokerageSubscriptions: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initialState };
    case types.REQUEST_BROKER_ORGS:
      return { ...state, requestBrokerOrgs: action.requestBrokerOrgs };
    case types.REQUEST_BROKER:
      return { ...state, loadingBroker: true };
    case types.RECEIVE_BROKER:
      return { ...state, loadingBroker: false, brokerInfo: action.brokerInfo };
    case types.REQUEST_BROKERS:
      return { ...state, loadingBrokers: true };
    case types.RECEIVE_BROKERS:
      return { ...state, loadingBrokers: false, brokers: action.brokers };
    case types.REQUEST_BROKERAGE:
      return { ...state, loadingBrokerage: true };
    case types.RECEIVE_BROKERAGE:
      return { ...state, loadingBrokerage: false, brokerageInfo: action.brokerageInfo };
    case types.REQUEST_BROKERAGE_SUBSCRIPTIONS:
      return { ...state, loadingBrokerageSubscriptions: true };
    case types.RECEIVE_BROKERAGE_SUBSCRIPTIONS:
      return {
        ...state,
        loadingBrokerageSubscriptions: false,
        brokerageSubscriptions: action.brokerageSubscriptions
      };
    default:
      return state;
  }
};
