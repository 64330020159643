import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Collapse, Avatar, Button, Switch, Icon, message } from 'antd';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import PlanList from '../../../Dashboard/scenes/Plan/components/PlanList';
import AddOrganisationEdit from './components/AddOrganisationEdit';
import { selectOrganisation, setArchive } from '../../../Organisation/OrganisationAction';
import { fetchAllPlans, fetchCurrencies } from '../../../Dashboard/scenes/Plan/PlanAction';
import { orgIdSelector, organisationPlansSelector } from '../../../Organisation/OrganisationSelectors';
import { MSG_ERROR } from '../../../../constants';
import './OrganisationPlan.scss';

const { Panel } = Collapse;

const OrganisationList = ({
  history,
  organisationList = [],
  selectedOrgId,
  fetchAllPlans,
  selectOrganisation,
  setArchive,
  fetchCurrencies
}) => {
  const [showArchived, setShowArchived] = useState(false);
  const [list, setList] = useState(organisationList);

  useEffect(() => {
    if (showArchived) {
      setList(organisationList);
    } else {
      setList([...organisationList].filter(item => !item.isArchived));
    }
  }, [organisationList, showArchived]);

  useEffect(() => {
    fetchAllPlans();
    fetchCurrencies();
  }, [fetchAllPlans, fetchCurrencies]);

  const onSelect = id => {
    selectOrganisation(id);
    history.push('/organization');
  };

  const onArchive = async (id, data) => {
    try {
      await setArchive(id, data);
      message.success(`Organisation has been successfully ${data.IsArchived ? 'archived' : 'unarchived'}`);
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const onSwitch = checked => {
    setShowArchived(checked);
  };

  return (
    <div className="component-wrapper">
      <Card title="Organisations &amp; Plans">
        <div className="switch-wrapper">
          Show Archived
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            onChange={onSwitch}
          />
        </div>
        <Collapse bordered={false} expandIconPosition="right">
          {list.map(item => (
            <Panel
              key={item.organisationId}
              header={
                <div className="organisation-plan-header">
                  <Avatar size={50} src={item.avatarUrl} className="organisation-plan-header-avatar" />
                  <div className="organisation-plan-header-content">
                    <h4 className="organisation-plan-header-title">{item.name}</h4>
                    <div className="organisation-plan-header-description">{item.industry}</div>
                  </div>
                  {selectedOrgId === item.organisationId ? (
                    <Button
                      type="primary"
                      onClick={e => {
                        e.stopPropagation();
                        onSelect(item.organisationId);
                      }}
                    >
                      Selected
                    </Button>
                  ) : (
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        onSelect(item.organisationId);
                      }}
                    >
                      Select
                    </Button>
                  )}
                  {item.isArchived ? (
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        onArchive(item.organisationId, { IsArchived: false });
                      }}
                    >
                      Unarchive
                    </Button>
                  ) : (
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        onArchive(item.organisationId, { IsArchived: true });
                      }}
                    >
                      Archive
                    </Button>
                  )}
                </div>
              }
            >
              <PlanList planList={item.plans} orgId={item.organisationId} />
            </Panel>
          ))}
        </Collapse>
        <div className="btn-container">
          <Modal
            explanation
            toggle={show => (
              <Button type="primary" icon="plus" onClick={show}>
                Add New
              </Button>
            )}
          >
            {(hide, setExplanation) => <AddOrganisationEdit close={hide} onExplanationChange={setExplanation} />}
          </Modal>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  organisationList: organisationPlansSelector(state),
  selectedOrgId: orgIdSelector(state)
});

const mapDispatchToProps = { selectOrganisation, setArchive, fetchAllPlans, fetchCurrencies };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrganisationList);
