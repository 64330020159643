/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { createBrowserHistory } from 'history';
import * as HttpStatus from 'http-status-codes';
// eslint-disable-next-line import/no-cycle
import { logout } from '../Login/LoginAction';
import { fetchOrganisations } from '../../scenes/Organisation/OrganisationAction';
import { fetchPlans } from '../../scenes/Dashboard/scenes/Plan/PlanAction';
import { fetchUser } from '../../scenes/Admin/scenes/User/UserActions';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_AUTH_TOKEN = 'auth/token';
const URL_TOKEN_EXCLUDED_PATHS = ['/confirmation/email', '/reset-password'];

export const types = {
  APP_INITIALIZING: 'app/APP_INITIALIZING',
  APP_INITIALIZED: 'app/APP_INITIALIZED'
};

const isBase64 = str => {
  if (!str || !str.trim()) {
    return false;
  }
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

/**
 * Different types of URLs with token:
 *
 * 1. `token`: JWT token. If API key is not presented, use the default one.
 * 2. `token`: Base64 encoded LinkGen parameters acquired from LMI authentication service. API key MUST be presented in query string.
 * 3. `key` and `EncryptedData`: Raw LinkGen parameters. Usually sent from LMI services. If API key is not presented, use the default one.
 *
 */
class AccessRequestUrl {
  constructor() {
    this.url = new URL(window.location);
  }

  get linkgenToken() {
    console.log('--- Getting LinkGen parameters');

    // Handle raw LinkGen parameters
    const key = this.url.searchParams.get('key');
    const encryptedData = this.url.searchParams.get('EncryptedData');
    if (key && encryptedData) {
      return btoa(`key=${key}&EncryptedData=${encodeURIComponent(encryptedData)}`);
    }

    console.log('--- Getting base64 LinkGen parameters');
    // Handle base64 encoded LinkGen parameters
    const token = this.url.searchParams.get('linkgen');
    if (token && isBase64(token)) {
      return token;
    }

    return undefined;
  }

  get token() {
    if (URL_TOKEN_EXCLUDED_PATHS.includes(this.url.pathname)) {
      return localStorage.getItem('token');
    }
    return this.url.searchParams.get('token') || localStorage.getItem('token');
  }
}

export const initialize = () => async dispatch => {
  dispatch({ type: types.APP_INITIALIZING });

  axios.interceptors.response.use(
    res => res,
    res => {
      const { response } = res;
      const status = response ? response.status : null;
      switch (status) {
        case HttpStatus.UNAUTHORIZED:
          dispatch(logout());
          // if (token) {
          //   window.location.reload();
          // }
          return Promise.reject(response);
        case HttpStatus.PAYMENT_REQUIRED:
          return Promise.reject(response);
        case HttpStatus.EXPECTATION_FAILED:
          return Promise.reject(res);
        default:
          return Promise.reject(response);
      }
    }
  );

  const url = new AccessRequestUrl();

  // first check if the current token is expired
  const expiry = localStorage.getItem('expiry');
  if (expiry && expiry < Date.now()) {
    dispatch(logout());
  } else {
    // Check for LinkGen access
    let token = url.linkgenToken;
    if (token) {
      console.log('--- Found LinkGen token in URL');
      // axios.defaults.headers.common.Authorization = `LinkGen ${token}`;
      try {
        console.log('--- Exchange for JWT token');
        const res = await axios.get(`${API_BASE}${API_AUTH_TOKEN}`, {
          headers: {
            Authorization: `LinkGen ${token}`
          }
        });
        token = res.data.access_token;
        localStorage.setItem('token', token);
        localStorage.setItem('expiry', res.data.expiry);
      } catch (err) {
        console.error('--- Failed to exchange for JWT token', err);
        return;
      }
    } else {
      console.log('--- Getting JWT token from query string or local storage');
      token = url.token;
      // No need to get JWT token again
    }

    // After dealing with all different types of authentication methods, the token, if applicable, is finally settled at this stage.
    // So persist to local storage and bind to axios's Authorization header
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    if (token) {
      const promises = [
        dispatch(fetchOrganisations()),
        dispatch(fetchUser()),
        dispatch(fetchPlans())
        // await dispatch(fetchBroker());
      ];
      try {
        await Promise.all(promises);
        const history = createBrowserHistory();
        history.push('/dashboard');
      } catch (err) {
        console.log(err);
      }
    }
  }
  dispatch({ type: types.APP_INITIALIZED });
};

const htmlTemplate = htmlBody =>
  htmlBody
    ? `
    <html>
      <head>
        <meta charset="utf-8">
        ${
          process.env.NODE_ENV === 'development'
            ? [...document.head.querySelectorAll('style')].map(item => item.outerHTML).join('')
            : [...document.head.querySelectorAll('link[rel="stylesheet"]')]
                .map(item => `<link href="${item.href}" rel="stylesheet" />`)
                .join('')
        }
        <title></title>
      </head>
      <body>
        ${htmlBody}
      </body>
    </html>`
    : null;

export const download = (content, cover, params) => () => {
  const url = process.env.REACT_APP_API_EXPORT_PDF;
  return axios.post(
    url,
    { content: htmlTemplate(content), frontMatter: htmlTemplate(cover), format: 'A4', ...params },
    { responseType: 'arraybuffer' }
  );
};
