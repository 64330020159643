import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Checkbox, Button, message, Alert } from 'antd';
import Spinner from '../components/Spinner/Spinner';
import Layout from '../components/Layout/Layout';
import Card from '../components/Card/Card';
import Modal from '../components/Modal/Modal';
import Login from '../components/Login/Login';
import TermsConditions from '../components/Login/components/TermsConditions';
import { updatePassword, resetPasswordRequest } from '../components/Login/LoginAction';
import { acceptOrganisationInvitation } from './Organisation/OrganisationAction';
import { acceptBrokerageInvitation } from './Admin/scenes/Broker/BrokerActions';
import { MSG_ERROR } from '../constants';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const CHECKBOX_OPTIONS = [
  'I have read and agree to the terms and conditions set out in THE PROCESSING OF PERSONAL DATA: AGREEMENT (GDPR)',
  'I have read and agree to the terms and conditions set out in SCHEDULE 1 - DETAILS OF THE PROCESSING OF YOUR PERSONAL DATA',
  'I have read and agree to the terms and conditions set out in SCHEDULE 2 - STANDARD CONTRACTUAL CLAUSES including APPENDIX 1 and APPENDIX 2',
  'I have read and agree to the terms and conditions set out in Terms of Use (Terms and Conditions)'
];
const MSG_ALERT_SUCCESS = 'Your account has been successfully activated.';
const SPECIAL_CHARACTER = '~`!@#$%^&*()\\-_=+\\[\\]{};:\'",.<>/?|';

const initialState = {
  userId: null,
  token: null,
  valid: null,
  activated: false,
  confirmDirty: false,
  checked: 0
};

class ResetPasswordForm extends Component {
  state = { ...initialState };

  componentDidMount() {
    this.verify();
  }

  verify = async () => {
    const { acceptOrganisationInvitation, acceptBrokerageInvitation } = this.props;
    const parsedUrl = new URL(window.location);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const userId = searchParams.get('id');
    const token = searchParams.get('token');
    const orgId = searchParams.get('orgId');
    const brokerageId = searchParams.get('brokerageId');
    const data = { id: userId, token: encodeURIComponent(token) };
    try {
      const res = orgId
        ? await acceptOrganisationInvitation(orgId, data)
        : await acceptBrokerageInvitation(brokerageId, data);
      const { id, token } = res.data;
      this.setState({ userId: id, token, valid: true });
    } catch (err) {
      this.setState({ valid: false });
    }
  };

  onUpdatePassword = e => {
    e.preventDefault();
    const { form, updatePassword, setResetPasswordRequest } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const { userId, token } = this.state;
        if (userId && token) {
          try {
            const { newPassword } = values;
            const data = { userId, token, newPassword };
            await updatePassword(data);
            this.setState({ activated: true });
            message.success(MSG_ALERT_SUCCESS);
          } catch (err) {
            message.error(err.data || MSG_ERROR);
          }
          setResetPasswordRequest(false);
        }
      }
    });
  };

  onConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Two passwords that you enter is inconsistent.');
    } else {
      callback();
    }
  };

  checkConfirm = (rule, value, callback) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value && confirmDirty) {
      form.validateFields(['confirmNewPassword'], { force: true });
    }
    callback();
  };

  onCheckboxChange = value => {
    this.setState({ checked: value.length });
  };

  render() {
    const { form, resetPasswordRequest } = this.props;
    const { getFieldDecorator } = form;
    const { valid, activated, checked } = this.state;
    return valid === null ? (
      <Spinner />
    ) : (
      <Layout>
        <div className="reset-password">
          <Card title="Activate your account">
            {valid ? (
              <Row gutter={20} className="reset-password-inner">
                <Col span={24}>
                  {activated ? (
                    <Modal
                      footer={null}
                      toggle={show => (
                        <Button type="primary" onClick={show} className="reset-password-btn">
                          Log in
                        </Button>
                      )}
                    >
                      {hide => <Login close={hide} />}
                    </Modal>
                  ) : (
                    <Form className="login-form">
                      <FormItem Label="Set Password" hasFeedback>
                        {getFieldDecorator('newPassword', {
                          rules: [
                            { required: true, message: 'Please input your password.' },
                            {
                              pattern: RegExp(
                                `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${SPECIAL_CHARACTER}])(?=.{6,})`
                              ),
                              message:
                                'Password must be at least 6 characters and contain at least one lowercase letter, one uppercase letter, one digit and one special character.'
                            },
                            { validator: this.checkConfirm }
                          ]
                        })(<Input type="password" placeholder="Set Password" disabled={resetPasswordRequest} />)}
                      </FormItem>
                      <FormItem Label="Confirm Password" hasFeedback>
                        {getFieldDecorator('confirmNewPassword', {
                          rules: [
                            { required: true, message: 'Please confirm your password.' },
                            { validator: this.checkPassword }
                          ]
                        })(
                          <Input
                            type="password"
                            placeholder="Confirm Password"
                            onBlur={this.onConfirmBlur}
                            disabled={resetPasswordRequest}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        <Modal
                          title="THE PROCESSING OF PERSONAL DATA: AGREEMENT"
                          width="auto"
                          footer={null}
                          wrapClassName="terms-conditions-modal"
                          toggle={show => (
                            <Button type="link" onClick={show}>
                              Click here to read the AGREEMENT (GDPR)
                            </Button>
                          )}
                        >
                          {() => <TermsConditions />}
                        </Modal>
                        <CheckboxGroup options={CHECKBOX_OPTIONS} onChange={this.onCheckboxChange} />
                      </FormItem>
                      <FormItem>
                        <Button
                          type="primary"
                          loading={resetPasswordRequest}
                          onClick={this.onUpdatePassword}
                          disabled={checked < CHECKBOX_OPTIONS.length}
                          className="reset-password-btn"
                        >
                          Activate my account
                        </Button>
                      </FormItem>
                    </Form>
                  )}
                </Col>
              </Row>
            ) : (
              <div className="component-wrapper">
                <Alert
                  showIcon
                  type="error"
                  message="Sorry, an error occurred."
                  description="The user token is invalid or expired. Your account cannot be activated. Please resend the invitation email."
                />
              </div>
            )}
          </Card>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  resetPasswordRequest: state.loginReducer.resetPasswordRequest
});

const mapDispatchToProps = {
  updatePassword,
  acceptOrganisationInvitation,
  acceptBrokerageInvitation,
  setResetPasswordRequest: resetPasswordRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ResetPasswordForm));
