import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_SUPPLIERS: 'supplier/REQUEST_SUPPLIERS',
  RECEIVE_SUPPLIERS: 'supplier/RECEIVE_SUPPLIERS'
};

const requestSuppliers = () => ({ type: types.REQUEST_SUPPLIERS });
const receiveSuppliers = data => ({ type: types.RECEIVE_SUPPLIERS, supplierList: data });

export const fetchSupplier = orgId => async dispatch => {
  dispatch(requestSuppliers());
  const url = `${API_BASE}orgs/${orgId}/suppliers`;
  try {
    const res = await axios.get(url);
    dispatch(receiveSuppliers(res.data));
  } catch (err) {
    dispatch(receiveSuppliers());
  }
};

export const createSupplier = (orgId, data) => async dispatch => {
  dispatch(requestSuppliers());
  const url = `${API_BASE}orgs/${orgId}/suppliers`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchSupplier(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveSuppliers());
    return Promise.reject(err);
  }
};

export const updateSupplier = (orgId, supplierId, data) => async dispatch => {
  dispatch(requestSuppliers());
  const url = `${API_BASE}orgs/${orgId}/suppliers/${supplierId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchSupplier(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveSuppliers());
    return Promise.reject(err);
  }
};

export const deleteSupplier = (orgId, supplierId) => async dispatch => {
  dispatch(requestSuppliers());
  const url = `${API_BASE}orgs/${orgId}/suppliers/${supplierId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchSupplier(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveSuppliers());
    return Promise.reject(err);
  }
};

export const importSupplier = (orgId, file) => async dispatch => {
  dispatch(requestSuppliers());
  const url = `${API_BASE}orgs/${orgId}/suppliers/import`;
  try {
    const res = await axios.post(url, file);
    dispatch(fetchSupplier(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveSuppliers());
    return Promise.reject(err);
  }
};

export const downloadSupplierImportTemplate = () => () => {
  const url = `${API_BASE}supplier_import_template`;
  return axios.get(url, { responseType: 'arraybuffer' });
};

export const exportSuppliers = orgId => () => {
  const url = `${API_BASE}orgs/${orgId}/suppliers/export`;
  return axios.get(url, { responseType: 'blob' });
};
