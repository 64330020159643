import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { withOnFocus } from '../../../components/HOC';
import AddressSearchBox from '../../../components/AddressSearchBox/AddressSearchBox';
import IndustrySelectBox from './IndustrySelectBox';
import { createBusiness, updateBusiness } from '../OrganisationAction';
import { loadingOrganisationSelector } from '../OrganisationSelectors';
import { MSG_ERROR } from '../../../constants';
import explanations from '../data/explanations.json';

const FormItem = Form.Item;
const { TextArea } = Input;
const DEFAULT_BRANCH_NAME = 'Head Office';

class BusinessEdit extends Component {
  constructor(props) {
    super(props);
    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.SelectWithOnFocus = withOnFocus(IndustrySelectBox, props.onExplanationChange);
    this.SearchBoxWithOnFocus = withOnFocus(AddressSearchBox, props.onExplanationChange);
    this.TextAreaWithOnFocus = withOnFocus(TextArea, props.onExplanationChange);
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, history, businessInfo, createBusiness, updateBusiness, close } = this.props;
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          acc[key] =
            fieldsValue[key] && typeof fieldsValue[key] === 'string' ? fieldsValue[key].trim() : fieldsValue[key];
          return acc;
        }, {});
        const {
          industry: { industryCode, industryName },
          ...otherValues
        } = values;
        const data = { ...otherValues, industryCode, industryName };
        try {
          if (businessInfo) {
            const { organisationId } = businessInfo;
            await updateBusiness(organisationId, data);
            message.success('Business information has been successfully updated');
          } else {
            await createBusiness(data);
            history.replace('/organization');
          }
          close();
        } catch (err) {
          message.error(MSG_ERROR);
        }
      }
    });
  };

  validateIndustry = (rule, value, callback) => {
    if (value.industryCode && value.industryName) {
      callback();
    }
    callback('Please select industry.');
  };

  render() {
    const { InputWithOnFocus, SelectWithOnFocus, TextAreaWithOnFocus, SearchBoxWithOnFocus } = this;
    const { form, businessInfo, loading } = this.props;
    const { getFieldDecorator } = form;
    const { name, legalName, registrationNumber, industryCode, industryName, governance } = businessInfo || {};
    return (
      <Form>
        <FormItem label="Full Business Name">
          {getFieldDecorator('name', {
            initialValue: name,
            rules: [{ required: true, message: 'Please input the name.' }]
          })(
            <InputWithOnFocus
              placeholder="Full Business Name"
              disabled={loading}
              explanation={explanations.fullBusinessName}
            />
          )}
        </FormItem>
        <FormItem label="Trading Name">
          {getFieldDecorator('legalName', {
            initialValue: legalName,
            rules: [{ required: true, message: 'Please input the trading name.' }]
          })(<InputWithOnFocus placeholder="Trading Name" disabled={loading} explanation={explanations.tradingName} />)}
        </FormItem>
        <FormItem label="Business Registration Number (if applicable)">
          {getFieldDecorator('registrationNumber', { initialValue: registrationNumber })(
            <InputWithOnFocus
              placeholder="Business Registration Number"
              disabled={loading}
              explanation={explanations.registrationNumber}
            />
          )}
        </FormItem>
        <FormItem label="Industry">
          {getFieldDecorator('industry', {
            initialValue: { industryCode, industryName },
            rules: [{ required: true, message: 'Please select industry.' }, { validator: this.validateIndustry }]
          })(<SelectWithOnFocus disabled={loading} explanation={explanations.industry} />)}
        </FormItem>
        <FormItem label="Governance &amp; Regulations">
          {getFieldDecorator('governance', {
            initialValue: governance
          })(
            <TextAreaWithOnFocus
              placeholder="Insert any legislation or regulations that your organization is required to comply with"
              disabled={loading}
              explanation={explanations.governance}
            />
          )}
        </FormItem>
        {!businessInfo && (
          <FormItem label={`${DEFAULT_BRANCH_NAME} Address`}>
            {getFieldDecorator('address', {
              rules: [{ required: true, message: 'Please input the address.' }]
            })(<SearchBoxWithOnFocus placeholder="Address" disabled={loading} explanation={explanations.address} />)}
          </FormItem>
        )}
        <FormItem>
          <Button type="primary" block loading={loading} onClick={this.onSubmit}>
            {loading ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  loading: loadingOrganisationSelector(state)
});

const mapDispatchToProps = { createBusiness, updateBusiness };

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Form.create()(BusinessEdit));
