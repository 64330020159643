import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Input, Table, Anchor, Icon, Spin } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../components/Card/Card';
import './Glossary.scss';

const { Link } = Anchor;
const API_BASE = process.env.REACT_APP_API_BASE;
const API_TERMS = 'terms';

export default () => {
  const [glossary, setGlossary] = useState(null);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    const loadData = async () => {
      const url = `${API_BASE}${API_TERMS}`;
      const res = await axios.get(url);
      const glossary = _.groupBy(res.data, term => term.name.substr(0, 1));
      setGlossary(glossary);
    };
    loadData();
  }, []);

  const onGlossaryFilterChange = e => {
    const query = e.target.value.replace(/\W|_/g, '[$&]');
    setFilterText(query);
  };

  const filter = (glossary, filterText) => {
    if (filterText === '') {
      return glossary;
    }
    const regex = new RegExp(filterText, 'i');
    const results = {};
    Object.entries(glossary).forEach(([k, v]) => {
      const currentResults = [];
      v.forEach(obj => {
        if (obj.name.match(regex) || obj.description.match(regex)) {
          currentResults.push(obj);
        }
      });
      if (currentResults.length > 0) {
        results[k] = currentResults;
      }
    });
    return results;
  };

  const filteredGlossary = filter(glossary, filterText);

  const columns = [
    { title: 'Term', dataIndex: 'name', key: 'term', width: '20%' },
    { title: 'Definition', dataIndex: 'description', key: 'Definition' }
  ];

  return (
    <>
      <ProcessBar title="Glossary" />
      {glossary ? (
        <div className="glossary-container">
          <Card color="#F1C400" title="Glossary">
            <Row className="glossary-inner">
              <Col span={20}>
                <Input
                  size="large"
                  placeholder="Filter Glossary Terms..."
                  prefix={<Icon type="search" />}
                  onChange={onGlossaryFilterChange}
                  className="glossary-search-input"
                />
              </Col>
              <Col span={20}>
                {Object.keys(filteredGlossary).length > 0 ? (
                  Object.entries(filteredGlossary).map(([key, value]) => (
                    <Fragment key={key}>
                      <h2 id={key}>{key}</h2>
                      <Table
                        columns={columns}
                        dataSource={value}
                        rowKey={record => record.name}
                        pagination={false}
                        className="glossary-table"
                      />
                    </Fragment>
                  ))
                ) : (
                  <div className="no-result-text">No results</div>
                )}
              </Col>
              <Col span={2} offset={2}>
                <Anchor>
                  {Object.keys(filteredGlossary).map(key => (
                    <Link key={key} href={`#${key}`} title={key} />
                  ))}
                </Anchor>
              </Col>
            </Row>
          </Card>
        </div>
      ) : (
        <Spin tip="Loading Glossary..." className="loading" />
      )}
    </>
  );
};
