import React, { useState } from 'react';
import { Card, Spin, Divider } from 'antd';
import moment from 'moment';
import newsImagePlaceholder from './assets/news-placeholder.png';
import './newsContainer.scss';

const { Meta } = Card;

export default ({ title, content, contentSnippet, creator, publishedDate, url }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = e => {
    setLoading(false);
    e.target.onerror = null;
    e.target.src = newsImagePlaceholder;
  };

  const handleClickCard = url => {
    window.open(url);
  };

  const date = publishedDate && moment(publishedDate).format('YYYY-MM-DD');
  const descriptionComponent = <div dangerouslySetInnerHTML={{ __html: contentSnippet }} />;
  const imgSrcRe = /src\s*=\s*"(.+?)"/;
  const thumbnail = imgSrcRe.exec(content)[1];

  return (
    <Card
      className="news-card"
      hoverable
      onClick={() => handleClickCard(url)}
      cover={
        <Spin spinning={loading}>
          <div className="news-img-container">
            <img alt={title} src={thumbnail} onLoad={handleImageLoad} onError={e => handleImageError(e)} />
          </div>
        </Spin>
      }
    >
      <Meta title={title} description={descriptionComponent} />
      <Divider />
      <div className="news-detailed">
        <div className="news-detailed-date">
          <i className="fa fa-calendar" />
          {date}
        </div>
        <div className="news-detailed-author">BY {creator || 'LMI Group'}</div>
      </div>
    </Card>
  );
};
