import {
  REQUEST_POLICY_RESPONSIBLE_PRESETS,
  RECEIVE_POLICY_RESPONSIBLE_PRESETS,
  REQUEST_LATEST_POLICY,
  RECEIVE_LATEST_POLICY,
  REQUEST_PLAN_POLICY,
  RECEIVE_PLAN_POLICY,
  POLICY_REQUEST
} from './PolicyAction';

const initialState = {
  policyRequest: false,
  loadingPolicyResponsiblePresets: false,
  loadingLatestPolicy: false,
  loadingPlanPolicy: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_POLICY_RESPONSIBLE_PRESETS:
      return { ...state, loadingPolicyResponsiblePresets: true, policyResponsiblePresets: undefined };
    case RECEIVE_POLICY_RESPONSIBLE_PRESETS:
      return {
        ...state,
        loadingPolicyResponsiblePresets: false,
        policyResponsiblePresets: action.policyResponsiblePresets
      };
    case REQUEST_LATEST_POLICY:
      return { ...state, loadingLatestPolicy: true, latestPolicy: undefined };
    case RECEIVE_LATEST_POLICY:
      return { ...state, loadingLatestPolicy: false, latestPolicy: action.latestPolicy };
    case REQUEST_PLAN_POLICY:
      return { ...state, loadingPlanPolicy: true };
    case RECEIVE_PLAN_POLICY:
      return { ...state, loadingPlanPolicy: false, planPolicy: action.planPolicy };
    case POLICY_REQUEST:
      return { ...state, policyRequest: action.policyRequest };
    default:
      return state;
  }
};
