import { createSelector } from 'reselect';
import moment from 'moment';

export const latestPolicySelector = state => state.policyReducer.latestPolicy;

export const planPolicySelector = state => state.policyReducer.planPolicy;

export const policyResponsiblePresetsSelector = state => state.policyReducer.policyResponsiblePresets;

export const policyResponsibleSelector = createSelector(
  latestPolicySelector,
  latestPolicy => (latestPolicy ? latestPolicy.businessContinuityResponsible : '')
);

export const policyDateSelector = createSelector(
  latestPolicySelector,
  latestPolicy => (latestPolicy ? moment(latestPolicy.lastModifiedDate).format('DD/MM/YYYY') : undefined)
);

export const policyMembersSelector = createSelector(
  latestPolicySelector,
  latestPolicy =>
    latestPolicy && latestPolicy.members
      ? latestPolicy.members.map(member => `${member.firstName} ${member.lastName}`)
      : undefined
);

export const planPolicyMembersSelector = createSelector(
  planPolicySelector,
  planPolicy => (planPolicy ? planPolicy.planPolicyMembers : undefined)
);
