import React, { useState } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import { Modal, Spin } from 'antd';
import Rating from '../Rating/Rating';

const style = {
  heading: {
    paddingBottom: '10px',
    color: '#f1c400',
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  margin: {
    marginTop: '25px'
  },
  summary: {
    maxHeight: '400px',
    overflow: 'auto'
  }
};

const InfoModal = ({ visible, info, onCancel }) => (
  <Modal visible={visible} title={info.category.name} footer={null} onCancel={onCancel}>
    <div style={style.heading}>RATING</div>
    <Rating percent={info.rating} />
    <div style={{ ...style.heading, ...style.margin }}>RISK SUMMARY</div>
    <div style={style.summary} dangerouslySetInnerHTML={{ __html: info.comment }} />
  </Modal>
);

export default ({ data = [], industryName, height = 500, direction = 'vertical', embedded = false, onChartReady }) => {
  const [clickedClass, setClickedClass] = useState(null);
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const onClick = param => {
    setClickedClass(data[param.dataIndex]);
    setInfoModalVisible(true);
  };

  const hazardClasses = data.map(item => item.category.name);
  const hazardRatings = data.map(item => item.rating);
  // Notes: temporarily remove this class
  const index = hazardClasses.indexOf('Product Recall / Product Contamination');
  if (index !== -1) {
    hazardClasses.splice(index, 1);
    hazardRatings.splice(index, 1);
    data.splice(index, 1);
  }
  const vertical = direction === 'vertical';
  const xAxis = {
    type: 'category',
    axisLabel: { interval: 0, rotate: vertical ? 45 : 0 },
    data: hazardClasses
  };
  const yAxis = { type: 'value', max: 100, min: 0 };
  const options = {
    title: {
      text: `Industry: ${industryName}`,
      subtext: embedded ? null : 'click the bar to view details',
      show: true,
      left: 'center',
      textStyle: {
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: 16
      }
    },
    tooltip: {},
    legend: {},
    grid: { containLabel: true },
    xAxis: vertical ? xAxis : yAxis,
    yAxis: vertical ? yAxis : xAxis,
    series: [
      {
        type: 'bar',
        // animation: !embedded,
        animation: false,
        data: hazardRatings,
        itemStyle: { color: '#CB2C30' }
      }
    ]
  };

  return (
    <div className="widget" style={{ height: `${height}px` }}>
      {data && data.length > 0 ? (
        <ReactEchartsCore
          echarts={echarts}
          option={options}
          notMerge
          onChartReady={onChartReady}
          style={{ height: '100%' }}
          onEvents={{ click: onClick }}
        />
      ) : (
        <Spin className="loading" />
      )}
      {clickedClass && (
        <InfoModal visible={infoModalVisible} info={clickedClass} onCancel={() => setInfoModalVisible(false)} />
      )}
    </div>
  );
};
