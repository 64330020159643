import { createSelector } from 'reselect';
import _ from 'lodash';

export const strategicThirdPartyListSelector = createSelector(
  state => state.strategicThirdPartyReducer.strategicThirdPartyList,
  strategicThirdPartyList =>
    strategicThirdPartyList
      ? _.groupBy(strategicThirdPartyList, item => item.strategicThirdPartyCategory.strategicThirdPartyClass)
      : undefined
);

export const strategicThirdPartyCategoriesSelector = createSelector(
  state => state.strategicThirdPartyReducer.strategicThirdPartyCategories,
  strategicThirdPartyCategories =>
    strategicThirdPartyCategories
      ? _.groupBy(strategicThirdPartyCategories, item => item.strategicThirdPartyClass)
      : undefined
);

export const loadingStrategicThirdPartySelector = state => state.strategicThirdPartyReducer.loadingStrategicThirdParty;
