import { types } from './AppAction';

const initialState = { initialized: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APP_INITIALIZING:
      return { ...state, initialized: false };
    case types.APP_INITIALIZED:
      return { ...state, initialized: true };
    default:
      return state;
  }
};
