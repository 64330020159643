import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Input, message } from 'antd';
import { updateDepartment, deleteDepartment } from '../DepartmentAction';
import { orgIdSelector } from '../../../../Organisation/OrganisationSelectors';
import { branchIdSelector } from '../../Plan/PlanSelectors';
import { departmentListSelector, loadingDepartmentsSelector } from '../DepartmentSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../constants';

const FormItem = Form.Item;

const DepartmentEdit = ({ form, orgId, branchId, department, loading, updateDepartment, deleteDepartment, close }) => {
  const { getFieldDecorator } = form;

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await updateDepartment(orgId, branchId, department.departmentId, values);
          message.success(MSG_SAVE_SUCCESS);
          close();
        } catch (err) {
          message.error(MSG_ERROR);
        }
      }
    });
  };

  const onDelete = async () => {
    const { departmentId } = department;
    try {
      await deleteDepartment(orgId, branchId, [departmentId]);
      message.success(MSG_SAVE_SUCCESS);
      close();
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  return (
    <Form>
      <FormItem>
        {getFieldDecorator('name', { initialValue: department ? department.name : undefined })(
          <Input disabled={loading} />
        )}
      </FormItem>
      <FormItem>
        <div className="btn-container inline">
          <Button key="delete" type="danger" loading={loading} onClick={onDelete}>
            Delete
          </Button>
          <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
            {loading ? 'Saving' : 'Save'}
          </Button>
        </div>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  branchId: branchIdSelector(state),
  departmentList: departmentListSelector(state),
  loading: loadingDepartmentsSelector(state)
});

const mapDispatchToProps = { updateDepartment, deleteDepartment };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(DepartmentEdit));
