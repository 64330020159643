import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Select, Input, Button } from 'antd';
import { foldersSelector, loadingDocumentsSelector } from '../DocumentSelectors';

const FormItem = Form.Item;
const { Option } = Select;

const DocumentMove = ({ form, folders, documentInfo, loading, onUpdateDocument, close }) => {
  const [folder, setFolder] = useState();
  const { getFieldDecorator } = form;

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const { folderName } = values;
        const { documentId } = documentInfo;
        let folder = {};
        const folderIndex = folders.findIndex(
          item => item.name && item.name.toLowerCase() === folderName.toLowerCase()
        );
        if (folderIndex === -1) {
          folder = { documentFolderId: 8, documentFolderText: folderName };
        } else {
          const { id, name } = folders[folderIndex];
          folder = { documentFolderId: id, documentFolderText: name };
        }
        onUpdateDocument(documentId, folder);
        close();
      }
    });
  };

  return (
    <Form>
      <FormItem label="Folder Name">
        {getFieldDecorator('folderName', {
          rules: [{ required: true, message: 'Please input the folder name.' }]
        })(
          folder === '' ? (
            <Input placeholder="Folder Name" disabled={loading} />
          ) : (
            <Select onChange={setFolder}>
              {folders.map(item => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
              <Option value="">
                <Button type="link" icon="plus" />
              </Option>
            </Select>
          )
        )}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loading} onClick={onSubmit}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  folders: foldersSelector(state),
  loading: loadingDocumentsSelector(state)
});

export default connect(mapStateToProps)(Form.create()(DocumentMove));
