import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, Button } from 'antd';
import { contactListSelector } from '../../Contact/ContactSelectors';

const FormItem = Form.Item;
const { Option } = Select;

const PolicyMemberEdit = ({ form, contactList = [], memberContacts, policyRequest, addMember, close }) => {
  const { getFieldDecorator } = form;
  const contactOptions = contactList.map(contact => (
    <Option key={contact.email} value={contact.contactId} label={`${contact.firstName} ${contact.lastName}`}>
      {contact.jobTitle
        ? `${contact.firstName} ${contact.lastName} - ${contact.jobTitle}`
        : `${contact.firstName} ${contact.lastName}`}
    </Option>
  ));

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const addedMemberContacts = contactList.filter(contact => values.contacts.includes(contact.contactId));
        addMember(addedMemberContacts);
        close();
      }
    });
  };

  return (
    <Form>
      <FormItem>
        {getFieldDecorator('contacts', {
          initialValue: memberContacts.map(item => item.contactId)
        })(
          <Select
            mode="multiple"
            placeholder="Add member"
            disabled={policyRequest}
            showSearch
            notFoundContent="Not Found"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            optionFilterProp="children"
            optionLabelProp="label"
          >
            {contactOptions}
          </Select>
        )}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={policyRequest} onClick={onSubmit}>
          {policyRequest ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  contactList: contactListSelector(state),
  policyRequest: state.policyReducer.policyRequest
});

export default connect(mapStateToProps)(Form.create()(PolicyMemberEdit));
