import React, { useEffect } from 'react';
import { Popconfirm, Button, Divider, Spin, message } from 'antd';
import { connect } from 'react-redux';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import Table from '../../../../components/Table/Table';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import Export from '../../../../components/Export/Export';
import Import from '../../../../components/Import/Import';
import ContactEdit from './components/ContactEdit';
import * as actions from './ContactAction';
import { orgIdSelector, businessNameSelector } from '../../OrganisationSelectors';
import {
  loadingContactsSelector,
  contactListSelector,
  contactTypesSelector,
  workTypesSelector
} from './ContactSelectors';
import { MSG_ERROR } from '../../../../constants';

const INFO_MSG = (
  <>
    <p>
      Being able to contact any or all of your organization&apos;s internal stakeholders, that is employees,
      contractors, probationers, outworkers is extremely important in the event of a crisis. That is why it is one of
      the first pieces of information that is collected on ContinuityCoach.
    </p>
    <p>You have two options to complete this important section.</p>
    <ol>
      <li>
        For an organization with a relatively small number of internal contacts it may be easier to simply complete the
        fields below by using the &quot;+ Add new&quot; button. This option should also be used to add internal contacts
        as they join your Organization.
      </li>
      <li>For larger organizations we suggest using the &quot;Import&quot; function.</li>
    </ol>
    <p>
      To Import your Internal Contacts first click the &quot;Import&quot; button so you can download the required
      Template. Ensure you complete the template without adding or deleting any columns, then save the spreadsheet and
      import it straight into ContinuityCoach by dragging and dropping the spreadsheet into the clearly marked
      &quot;drag and drop&quot; field, or click the field to allow a search of your computer&apos;s files.
    </p>
    <p>
      It is important that you complete this task now as you will be able to assign your Internal Contacts to Teams
      later in the Plan process. Completing this will also save you time and stress should &quot;it&quot; ever happen to
      you. Please remember to update the list regularly for new team members and departures.
    </p>
  </>
);

const Contact = ({
  orgId,
  businessName,
  loading,
  contactList,
  fetchContact,
  deleteContact,
  exportContacts,
  importContact,
  downloadContactImportTemplate
}) => {
  useEffect(() => {
    fetchContact(orgId);
  }, [orgId, fetchContact]);

  const onDelete = async contact => {
    try {
      await deleteContact(orgId, contact.contactId);
    } catch (err) {
      message.error(MSG_ERROR);
    }
    fetchContact(orgId);
  };

  // const contactTypeFilters = contactTypes.map(obj => ({ text: obj.name, value: obj.name }));
  // const workTypeFilters = workTypes.map(obj => ({ text: obj.name, value: obj.name }));
  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName)
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName)
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null)
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      render: text => (text ? <a href={`tel:${text}`}>{text}</a> : null)
    },
    {
      key: 'mobile',
      title: 'Mobile/Cell Phone',
      dataIndex: 'mobile',
      render: text => (text ? <a href={`tel:${text}`}>{text}</a> : null)
    },
    // {
    //   title: 'Contact Type',
    //   key: 'contactType',
    //   dataIndex: 'contactTypeName',
    //   filters: contactTypeFilters,
    //   onFilter: (value, record) => record.contactTypeName.indexOf(value) === 0
    // },
    // {
    //   title: 'Work Type',
    //   key: 'workType',
    //   dataIndex: 'workTypeName',
    //   filters: workTypeFilters,
    //   onFilter: (value, record) => record.workTypeName.indexOf(value) === 0
    // },
    {
      key: 'jobTitle',
      title: 'Job Title',
      dataIndex: 'jobTitle'
    },
    {
      key: 'action',
      width: 115,
      render: (text, record) => (
        <span>
          <Modal explanation toggle={show => <Button type="link" icon="edit" onClick={show} />}>
            {(hide, setExplanation) => (
              <ContactEdit contactInfo={record} close={hide} onExplanationChange={setExplanation} />
            )}
          </Modal>
          <Divider type="vertical" />
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
            <Button type="link" icon="delete" />
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <>
      <ProcessBar title="Internal Contacts" next="/organization/customers" />
      <InfoBox message={INFO_MSG} />
      {contactList ? (
        <div className="component-wrapper">
          <Spin spinning={loading}>
            <Card
              title="Internal Contacts"
              description="Please add the details of your Internal Contacts by either using the Import or Add New functions. If your Internal Contacts are added here their details will be accessible in other areas of the site."
            >
              <Table
                title="Contacts"
                columns={columns}
                dataSource={contactList}
                rowKey="contactId"
                searchFields={['firstName', 'lastName', 'email', 'phone', 'mobile', 'jobTitle']}
              />
              <div className="btn-container">
                <Export
                  fileName={`${businessName.replace(/\s+/g, '_')}_Contacts.xlsx`}
                  exportFile={() => exportContacts(orgId)}
                >
                  {onExport => (
                    <Button type="primary" icon="export" onClick={onExport}>
                      Export
                    </Button>
                  )}
                </Export>
                <Modal
                  title="Import Contacts"
                  toggle={show => (
                    <Button type="primary" icon="contacts" onClick={show}>
                      Import
                    </Button>
                  )}
                >
                  {() => (
                    <Import
                      templateName="contacts_template.xlsx"
                      downloadTemplate={downloadContactImportTemplate}
                      importFromTemplate={file => importContact(orgId, file)}
                    />
                  )}
                </Modal>
                <Modal
                  explanation
                  toggle={show => (
                    <Button type="primary" icon="plus" onClick={show}>
                      Add new
                    </Button>
                  )}
                >
                  {(hide, setExplanation) => <ContactEdit close={hide} onExplanationChange={setExplanation} />}
                </Modal>
              </div>
            </Card>
          </Spin>
        </div>
      ) : (
        <Spin tip="Loading Contacts..." className="loading" />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  businessName: businessNameSelector(state),
  loading: loadingContactsSelector(state),
  contactList: contactListSelector(state),
  contactTypes: contactTypesSelector(state),
  workTypes: workTypesSelector(state)
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
