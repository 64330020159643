import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Select, Button, Spin, message, Icon } from 'antd';
import Card from '../../../../../../../components/Card/Card';
import { fetchPlans, updatePlan } from '../../../../Plan/PlanAction';
import { fetchMtpd } from '../../../BiaAction';
import { planIdSelector, mtpdSelector } from '../../../../Plan/PlanSelectors';
import { mtpdListSelector } from '../../../BiaSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../../../constants';

const { Option } = Select;

const Mtpd = ({ planId, mtpd, mtpdList, fetchMtpd, setMtpd, refreshMtpd, explanations, onExplanationChange }) => {
  const [selectedMtpd, setSelectedMtpd] = useState(mtpd || {});

  useEffect(() => {
    fetchMtpd();
  }, [fetchMtpd]);

  const onMtpdSelect = (value, option) => {
    setSelectedMtpd({ mtpdId: value, name: option.props.children });
  };

  const onSave = async () => {
    const { mtpdId } = selectedMtpd;
    if (mtpdId) {
      try {
        await setMtpd(planId, { mtpdId });
        await refreshMtpd();
        message.success(MSG_SAVE_SUCCESS);
      } catch (err) {
        message.error(MSG_ERROR);
      }
    }
  };

  return mtpdList ? (
    <Card
      color="#343579"
      title="Maximum Tolerable Period of Disruption"
      icon={
        <Icon
          type="info-circle-o"
          className="bia-info-icon"
          onClick={() => onExplanationChange(explanations.maximumTolerablePeriodOfDisruption)}
        />
      }
    >
      <div className="bia-mtpd">
        <Row type="flex" justify="center" className="bia-sca-question-item">
          <Col span={10}>
            What is the maximum time that these critical areas can be unavailable before it starts to affect the
            organizations operations and/or financial viability?
          </Col>
          <Col span={13} offset={1}>
            <Select
              value={selectedMtpd.mtpdId}
              placeholder="Select maximum tolerable period of disruption"
              onSelect={onMtpdSelect}
              className="bia-mtpd-select"
            >
              {mtpdList.map(item => (
                <Option key={item.mtpdId} value={item.mtpdId}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <div className="btn-container center">
          <Button type="primary" className="bia-btn" onClick={onSave}>
            Save
          </Button>
        </div>
      </div>
    </Card>
  ) : (
    <Spin tip="Loading..." className="loading" />
  );
};

const mapStateToProps = state => ({
  planId: planIdSelector(state),
  mtpd: mtpdSelector(state),
  mtpdList: mtpdListSelector(state)
});

const mapDispatchToProps = { fetchMtpd, setMtpd: updatePlan, refreshMtpd: fetchPlans };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mtpd);
