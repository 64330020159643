import React, { useState } from 'react';
import { notification, Spin } from 'antd';
import FileSaver from 'file-saver';
import { MSG_ERROR } from '../../constants';

export default ({ children, fileName, exportFile }) => {
  const [exporting, setExporting] = useState(false);

  const onExport = async () => {
    if (exporting) {
      return;
    }
    setExporting(true);
    notification.open({
      key: 'exporting',
      message: 'Exporting',
      description: 'Thanks for your patience.',
      duration: 0,
      icon: <Spin />
    });
    try {
      const res = await exportFile();
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      FileSaver.saveAs(blob, fileName);
      notification.close('exporting');
      notification.success({
        key: 'export_success',
        message: 'Export successfully'
      });
    } catch (err) {
      notification.close('exporting');
      notification.error({
        key: 'export_fail',
        message: 'Export failed',
        description: MSG_ERROR
      });
    }
    setExporting(false);
  };

  return children(onExport);
};
