import { combineReducers } from 'redux';
import appReducer from './components/App/AppReducer';
import loginReducer from './components/Login/LoginReducer';
import userReducer from './scenes/Admin/scenes/User/UserReducer';
import organisationReducer from './scenes/Organisation/OrganisationReducer';
import alternativeSiteReducer from './scenes/Organisation/scenes/AlternativeSite/AlternativeSiteReducer';
import branchReducer from './scenes/Organisation/scenes/Branch/BranchReducer';
import contactReducer from './scenes/Organisation/scenes/Contact/ContactReducer';
import customerReducer from './scenes/Organisation/scenes/Customer/CustomerReducer';
import supplierReducer from './scenes/Organisation/scenes/Supplier/SupplierReducer';
import strategicThirdPartyReducer from './scenes/Organisation/scenes/StrategicThirdParty/StrategicThirdPartyReducer';
import productReducer from './scenes/Organisation/scenes/Product/ProductReducer';
import policyReducer from './scenes/Organisation/scenes/Policy/PolicyReducer';
import planReducer from './scenes/Dashboard/scenes/Plan/PlanReducer';
import riskReducer from './scenes/Dashboard/scenes/Risk/RiskReducer';
import departmentReducer from './scenes/Dashboard/scenes/Department/DepartmentReducer';
import biaReducer from './scenes/Dashboard/scenes/Bia/BiaReducer';
import teamReducer from './scenes/Dashboard/scenes/Team/TeamReducer';
import documentReducer from './scenes/Dashboard/scenes/Document/DocumentReducer';
import recoveryStrategyReducer from './scenes/Dashboard/scenes/RecoveryStrategy/RecoveryStrategyReducer';
import brokerReducer from './scenes/Admin/scenes/Broker/BrokerReducer';
import transactionReducer from './scenes/Admin/scenes/Transaction/TransactionReducer';
import purchaseReducer from './scenes/Purchase/PurchaseReducer';

export default combineReducers({
  appReducer,
  loginReducer,
  userReducer,
  organisationReducer,
  alternativeSiteReducer,
  branchReducer,
  planReducer,
  riskReducer,
  contactReducer,
  customerReducer,
  supplierReducer,
  teamReducer,
  productReducer,
  policyReducer,
  departmentReducer,
  biaReducer,
  recoveryStrategyReducer,
  strategicThirdPartyReducer,
  documentReducer,
  brokerReducer,
  transactionReducer,
  purchaseReducer
});
