import React from 'react';
import { Button, Upload, Table, Popconfirm, Tooltip } from 'antd';
import Modal from '../../../../../components/Modal/Modal';
import DocumentMove from './DocumentMove';
import DocumentEdit from './DocumentEdit';
import DocumentAddLink from './DocumentAddLink';

export default ({
  documents = [],
  selectedFolder,
  onAddUrl,
  onUploadDocument,
  onUpdateDocument,
  onDeleteDocument,
  loadingDocuments
}) => {
  const filteredDocuments = documents.filter(
    obj => (obj.documentFolderText || obj.documentFolder.name) === selectedFolder.name
  );

  const columns = [
    {
      key: 'displayName',
      title: 'FRIENDLY NAME',
      render: (text, record) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          {record.displayName || record.displayName}
        </a>
      )
    },
    { key: 'name', title: 'FILE NAME', dataIndex: 'name' },
    {
      key: 'action',
      width: 120,
      render: (text, record) => (
        <span className="document-icons">
          <Modal
            title="Move Document to Another Folder"
            toggle={show => (
              <Tooltip title="move document" placement="bottom">
                <Button type="link" icon="arrow-right" onClick={show} />
              </Tooltip>
            )}
          >
            {hide => <DocumentMove documentInfo={record} onUpdateDocument={onUpdateDocument} close={hide} />}
          </Modal>
          <Modal
            explanation
            toggle={show => (
              <Tooltip title="edit document's information" placement="bottom">
                <Button type="link" icon="edit" onClick={show} />
              </Tooltip>
            )}
          >
            {(hide, setExplanation) => (
              <DocumentEdit
                documentInfo={record}
                onUpdateDocument={onUpdateDocument}
                close={hide}
                onExplanationChange={setExplanation}
              />
            )}
          </Modal>
          <Tooltip title="delete document" placement="bottom">
            <Popconfirm title="Sure to delete?" onConfirm={() => onDeleteDocument(record)}>
              <Button type="link" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      )
    }
  ];

  return (
    <div className="document-list">
      <h3 className="doc-header">{selectedFolder.name}</h3>
      <Upload
        customRequest={({ file }) => onUploadDocument(selectedFolder, file)}
        name="document"
        showUploadList={false}
      >
        <Button type="primary" icon="cloud-upload">
          Upload a new file
        </Button>
      </Upload>
      <Modal
        title="Add URL"
        toggle={show => (
          <Button type="primary" icon="link" style={{ marginLeft: '20px' }} onClick={show}>
            Add URL
          </Button>
        )}
      >
        {hide => (
          <DocumentAddLink
            close={hide}
            onAddUrl={onAddUrl}
            folder={selectedFolder}
            loadingDocuments={loadingDocuments}
          />
        )}
      </Modal>
      <Table
        columns={columns}
        dataSource={filteredDocuments}
        rowKey={record => record.documentId}
        pagination={{ hideOnSinglePage: true }}
        locale={{ emptyText: 'No Documents' }}
      />
    </div>
  );
};
