import React, { PureComponent } from 'react';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import CurrencyFormat from 'react-currency-format';
import { LinearGradient } from 'echarts/lib/util/graphic';
import { currencySelector } from '../../../scenes/Dashboard/scenes/Plan/PlanSelectors';

const initialState = { title: 'Risk Categories' };
const COLORS = [
  '#6699CC',
  '#663366',
  '#CCCC99',
  '#990033',
  '#99CC99',
  '#FF9933',
  '#CC3366',
  '#993366',
  '#336666',
  '#CCCC33',
  '#333366',
  '#CCCCFF'
];

/* eslint no-restricted-syntax: off */
class RaChart extends PureComponent {
  static propTypes = {
    height: PropTypes.number,
    embedded: PropTypes.bool,
    risks: PropTypes.object.isRequired,
    riskCategories: PropTypes.array.isRequired,
    riskLikelihoodPresets: PropTypes.array.isRequired,
    category: PropTypes.object
  };

  static defaultProps = {
    height: 500,
    embedded: false,
    category: null
  };

  constructor(props) {
    super(props);
    const { risks, category } = props;
    if (category) {
      const data = this.setRiskData(category);
      this.state = { categoryData: [], data, title: category.categoryName };
    } else {
      const data = this.setCategoryData(risks);
      this.state = { categoryData: data, data, title: initialState.title };
    }
  }

  // setData = risks => {
  //   const { riskCategories } = this.props;
  //   const data = [];
  //   for (const [categoryId, risksUnderCategory] of Object.entries(risks)) {
  //     const category = riskCategories.find(item => item.riskCategoryId === Number.parseInt(categoryId, 10));
  //     const categoryName = category.name;
  //     const categoryData = [];
  //     risksUnderCategory.forEach(risk => {
  //       const { likelihoodText, likelihoodValue, cost, name } = risk;
  //       const annualisedValue = likelihoodValue * cost;
  //       categoryData.push([likelihoodText, annualisedValue, cost, name]);
  //     });
  //     data.push({ category: categoryName, risks: categoryData });
  //   }
  //   this.setState({ data });
  // };

  /**
   * Set the chart data for all categories by calculating the average values, which has such form [[x, y, size, name], ...]
   * @param {object} risks All risks grouped according to their category
   */
  setCategoryData = risks => {
    const { riskCategories, riskLikelihoodPresets = [] } = this.props;
    const data = [];
    // iterate the object of grouped risks, where the key is the risk categoryId and value is the array of risks under this category
    for (const [categoryId, risksUnderCategory] of Object.entries(risks)) {
      const category = riskCategories.find(category => category.riskCategoryId === Number.parseInt(categoryId, 10));
      const totalCost = risksUnderCategory.reduce((acc, risk) => acc + risk.cost, 0);
      const totalAnnualisedValue = risksUnderCategory.reduce((acc, risk) => acc + risk.cost * risk.likelihoodValue, 0);
      const weightedLikelihood = risksUnderCategory.reduce(
        (acc, risk) => acc + ((risk.cost * risk.likelihoodValue) / totalAnnualisedValue) * risk.likelihoodId,
        0
      );
      const likelihood = riskLikelihoodPresets.find(
        preset => preset.likelihoodPresetId === Math.round(weightedLikelihood)
      );
      const likelihoodText = likelihood ? likelihood.text : null;
      data.push([likelihoodText, totalAnnualisedValue, totalCost, category.name, category.riskCategoryId]);
    }
    // this.setState({ categoryData: data, data });
    return data;
  };

  /**
   * Set the chart data for risks of a specified category, which has such form [[x, y, size, name], ...]
   * @param {object} category The category object containing object ID and category name
   */
  setRiskData = category => {
    const { risks } = this.props;
    const { categoryId } = category;
    const risksUnderCategory = risks[categoryId];
    const data = [];
    for (const risk of risksUnderCategory) {
      const { likelihoodText, likelihoodValue, cost, name, riskCategoryId } = risk;
      const annualisedValue = likelihoodValue * cost;
      data.push([likelihoodText, annualisedValue, cost, name, riskCategoryId]);
    }
    // this.setState({ data, title: categoryName });
    return data;
  };

  onClick = param => {
    const { riskCategories } = this.props;
    const { categoryData } = this.state;
    const clickedCategoryName = param.data[3];
    const clickedCategory = riskCategories.find(category => category.name === clickedCategoryName);
    if (clickedCategory) {
      // if the category is found, set the chart data for this specified category
      // then go to a second screen with the bubbles representing the risks of that category
      const { riskCategoryId, name } = clickedCategory;
      const data = this.setRiskData({ categoryId: riskCategoryId, categoryName: name });
      this.setState({ data });
    } else {
      // if the category is not found, which means it is a risk rather than a category
      // then we back to the first screen with the bubbles representing the categories
      this.setState({ data: categoryData, ...initialState });
    }
  };

  getToolTip = param => {
    const { riskCategories, currency } = this.props;
    const name = param.data[3];
    const category = riskCategories.find(category => category.name === name);
    if (category) {
      return (
        `${name}<br />` +
        `Loss Value: ${renderToString(
          <CurrencyFormat value={param.data[2]} displayType="text" thousandSeparator prefix={`${currency} `} />
        )}<br />` +
        `Annualised Value: ${renderToString(
          <CurrencyFormat
            value={(param.data[1].toFixed(2) * 100) / 100}
            displayType="text"
            thousandSeparator
            prefix={`${currency} `}
          />
        )}`
      );
    }
    return (
      `${name}<br />` +
      `Likelihood: ${param.data[0]}<br />` +
      `Loss Value: ${renderToString(
        <CurrencyFormat value={param.data[2]} displayType="text" thousandSeparator prefix={`${currency} `} />
      )}<br />` +
      `Annualised Value: ${renderToString(
        <CurrencyFormat
          value={(param.data[1].toFixed(2) * 100) / 100}
          displayType="text"
          thousandSeparator
          prefix={`${currency} `}
        />
      )}`
    );
  };

  sizeFunction = data => Math.min(80, Math.sqrt(data / 800) + 10);

  render() {
    const { height, embedded, currency, riskLikelihoodPresets = [], onChartReady } = this.props;
    const { data, title } = this.state;
    const xAxisCategories = riskLikelihoodPresets.map(preset => preset.text);

    // const legend = data.map(item => item.category);
    // legend.push('All');
    // const series = data.map(item => ({
    //   name: item.category,
    //   type: 'scatter',
    //   symbolSize: data => this.sizeFunction(data[2]),
    //   data: item.risks,
    //   label: {
    //     show: embedded,
    //     position: 'top',
    //     color: 'rgba(0, 0, 0, .75)',
    //     formatter: param => param.data[3]
    //   },
    //   itemStyle: itemStyle
    // }));
    // const selected = {};
    // legend.forEach(item => {
    //   selected[item] = true;
    // });

    const options = {
      title: {
        text: title,
        /* eslint no-nested-ternary: off */
        subtext: embedded
          ? null
          : title === initialState.title
          ? 'click the bubble to drill down into specific risk category'
          : 'click any bubble to go back to risk categories',
        show: true,
        left: 'center',
        textStyle: {
          color: 'rgba(0, 0, 0, 0.65)',
          fontSize: 16
        }
      },
      legend: {},
      // legend: {
      //   right: 'right',
      //   orient: 'vertical',
      //   data: legend,
      //   selectedMode: 'multiple',
      //   selected: selected,
      //   textStyle: {
      //     fontSize: 10
      //   }
      // },
      tooltip: {
        formatter: param => this.getToolTip(param)
      },
      grid: {
        show: true,
        containLabel: true,
        backgroundColor: new LinearGradient(0, 1, 1, 0, [
          {
            offset: 0,
            color: '#00685e'
          },
          {
            offset: 0.5,
            color: '#f1c400'
          },
          {
            offset: 1,
            color: '#cb2c30'
          }
        ])
      },
      xAxis: {
        type: 'category',
        data: xAxisCategories,
        axisLabel: {
          interval: 0,
          rotate: 45
        },
        splitLine: { show: false }
      },
      yAxis: {
        name: 'Annualised Value',
        type: 'log',
        boundaryGap: ['0', '20%'],
        splitLine: { show: false },
        axisLabel: {
          formatter: param =>
            renderToString(
              <CurrencyFormat value={param} displayType="text" thousandSeparator prefix={`${currency} `} />
            ).replace(/<[^>]+>/gi, '')
        }
      },
      series: [
        {
          type: 'scatter',
          animation: !embedded,
          symbolSize: data => this.sizeFunction(data[2]),
          data,
          label: {
            show: embedded,
            position: 'top',
            color: 'rgba(0, 0, 0, .75)',
            formatter: param => param.data[3]
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(25, 100, 150, 0.5)',
            shadowOffsetY: 5,
            color: param => {
              const riskCategoryId = param.data[4];
              return COLORS[riskCategoryId % COLORS.length];
            }
          }
        }
      ]
    };

    return (
      <ReactEcharts
        option={options}
        notMerge
        onChartReady={onChartReady}
        onEvents={{ click: this.onClick }}
        className="widget"
        style={{ height: `${height}px` }}
      />
    );
  }
}

const mapStateToProps = state => ({
  currency: currencySelector(state)
});

export default connect(mapStateToProps, null)(RaChart);
