import { createSelector } from 'reselect';
import _ from 'lodash';

export const strategicCriticalAreaCategoriesSelector = state => state.productReducer.strategicCriticalAreaCategories;

export const loadingStrategicAreaCategoriesSelector = state => state.productReducer.loadingStrategicAreaCategories;

export const productsSelector = createSelector(
  state => state.productReducer.products,
  products => _.groupBy(products, obj => obj.strategicCriticalAreaCategory.name)
);

export const loadingStrategicAreasSelector = state => state.productReducer.loadingStrategicAreas;
