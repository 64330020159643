import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Select, Button, message } from 'antd';
import { withOnFocus } from '../../../../../components/HOC';
import { fetchContactTypes, fetchWorkTypes, createContact, updateContact } from '../ContactAction';
import { orgIdSelector } from '../../../OrganisationSelectors';
import { contactTypesSelector, workTypesSelector, loadingContactsSelector } from '../ContactSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../constants';
import explanations from '../data/explanations.json';

const FormItem = Form.Item;
const { Option } = Select;

class ContactEdit extends Component {
  constructor(props) {
    super(props);
    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.SelectWithOnFocus = withOnFocus(Select, props.onExplanationChange);
  }

  componentDidMount() {
    const { fetchContactTypes, fetchWorkTypes } = this.props;
    fetchContactTypes();
    fetchWorkTypes();
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, orgId, contactInfo, updateContact, createContact, addToTeam, close } = this.props;
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          if (fieldsValue[key]) {
            if (typeof fieldsValue[key] === 'string') {
              if (fieldsValue[key].trim() === '') {
                acc[key] = null;
              } else {
                acc[key] = fieldsValue[key].trim();
              }
            } else {
              acc[key] = fieldsValue[key];
            }
          } else {
            acc[key] = null;
          }
          return acc;
        }, {});
        try {
          if (contactInfo) {
            const { contactId } = contactInfo;
            await updateContact(orgId, contactId, values);
            message.success(MSG_SAVE_SUCCESS);
          } else {
            const res = await createContact(orgId, values);
            if (addToTeam) {
              const data = { contactId: res.data.contactId };
              await addToTeam([data]);
            } else {
              message.success(MSG_SAVE_SUCCESS);
            }
          }
          close();
        } catch (err) {
          message.error(err.data || MSG_ERROR);
        }
      }
    });
  };

  render() {
    const { InputWithOnFocus, SelectWithOnFocus } = this;
    const { form, contactInfo = {}, contactTypes = [], workTypes = [], loading } = this.props;
    const { getFieldDecorator } = form;

    const contactTypesOptions = contactTypes.map(obj => (
      <Option key={obj.name} value={obj.contactTypeId}>
        {obj.name}
      </Option>
    ));

    const workTypeOptions = workTypes.map(obj => (
      <Option key={obj.name} value={obj.workTypeId}>
        {obj.name}
      </Option>
    ));

    return (
      <Form>
        <Row gutter={20}>
          <Col span={12}>
            <FormItem label="First Name">
              {getFieldDecorator('firstName', {
                initialValue: contactInfo.firstName,
                rules: [{ required: true, message: 'Please input the first name.' }]
              })(
                <InputWithOnFocus
                  placeholder="First Name"
                  disabled={loading}
                  spellCheck={false}
                  explanation={explanations.firstName}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Last Name">
              {getFieldDecorator('lastName', {
                initialValue: contactInfo.lastName,
                rules: [{ required: true, message: 'Please input the last name.' }]
              })(
                <InputWithOnFocus
                  placeholder="Last Name"
                  disabled={loading}
                  spellCheck={false}
                  explanation={explanations.lastName}
                />
              )}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="Email">
              {getFieldDecorator('email', {
                initialValue: contactInfo.email,
                validateTrigger: 'onBlur',
                rules: [{ type: 'email', message: 'Please input an valid email.' }]
              })(
                <InputWithOnFocus
                  placeholder="Email"
                  disabled={loading}
                  spellCheck={false}
                  explanation={explanations.email}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Phone">
              {getFieldDecorator('phone', {
                initialValue: contactInfo.phone
              })(<InputWithOnFocus placeholder="Phone" disabled={loading} explanation={explanations.phone} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Mobile/Cell Phone">
              {getFieldDecorator('mobile', {
                initialValue: contactInfo.mobile
              })(
                <InputWithOnFocus
                  placeholder="Mobile/Cell Phone"
                  disabled={loading}
                  explanation={explanations.mobile}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Contact Type">
              {getFieldDecorator('contactTypeId', {
                initialValue: contactInfo.contactTypeId,
                rules: [{ required: true, message: 'Please select the contact type.' }]
              })(
                <SelectWithOnFocus placeholder="Contact Type" disabled={loading} explanation={explanations.contactType}>
                  {contactTypesOptions}
                </SelectWithOnFocus>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Work Type">
              {getFieldDecorator('workTypeId', {
                initialValue: contactInfo.workTypeId,
                rules: [{ required: true, message: 'Please select the work type.' }]
              })(
                <SelectWithOnFocus placeholder="Work Type" disabled={loading} explanation={explanations.workType}>
                  {workTypeOptions}
                </SelectWithOnFocus>
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem label="Job Title">
          {getFieldDecorator('jobTitle', {
            initialValue: contactInfo.jobTitle
          })(<InputWithOnFocus placeholder="Job Title" disabled={loading} explanation={explanations.jobTitle} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" block loading={loading} onClick={this.onSubmit}>
            {loading ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  loading: loadingContactsSelector(state),
  contactTypes: contactTypesSelector(state),
  workTypes: workTypesSelector(state)
});

const mapDispatchToProps = {
  fetchContactTypes,
  fetchWorkTypes,
  createContact,
  updateContact
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ContactEdit));
