import React from 'react';

export default ({ children, seq, caption, chartDataUrl, setChartDataUrl }) => {
  const onChartReady = chart => {
    setChartDataUrl(chart.getDataURL());
  };

  const componentToRender = React.cloneElement(children, { onChartReady });

  return (
    <figure>
      {chartDataUrl ? <img src={chartDataUrl} alt="chart" /> : componentToRender}
      <figcaption>
        <strong>Figure {seq}: </strong>
        {caption}
      </figcaption>
    </figure>
  );
};
