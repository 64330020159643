import React, { Component } from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs } from 'react-google-maps';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { Input } from 'antd';

const API_GOOGLE_MAP = process.env.REACT_APP_API_GOOGLE_MAP;

const getAddressComponent = (addressComponents, field) => {
  const result = addressComponents.find(item => item.types.includes(field));
  return result ? result.long_name : '';
};

const AddressSearchBoxComponent = compose(
  withProps({
    googleMapURL: API_GOOGLE_MAP,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />
  }),
  lifecycle({
    componentDidMount() {
      const refs = {};
      this.setState({
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const { separate, onChange } = this.props;
          const places = refs.searchBox.getPlaces();
          if (separate) {
            const addressComponents = places[0].address_components;
            const streetNumber = getAddressComponent(addressComponents, 'street_number');
            const streetName = getAddressComponent(addressComponents, 'route');
            const city = getAddressComponent(addressComponents, 'locality');
            const postcode = getAddressComponent(addressComponents, 'postal_code');
            const state = getAddressComponent(addressComponents, 'administrative_area_level_1');
            const country = getAddressComponent(addressComponents, 'country');
            onChange({ address: `${streetNumber} ${streetName}`, city, postcode, state, country });
          } else {
            const address = places[0].formatted_address;
            onChange(address);
          }
        }
      });
    }
  }),
  withScriptjs
)(props => (
  <div data-standalone-searchbox="">
    <StandaloneSearchBox ref={props.onSearchBoxMounted} bounds={props.bounds} onPlacesChanged={props.onPlacesChanged}>
      <Input
        value={props.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        onFocus={props.onFocus}
      />
    </StandaloneSearchBox>
  </div>
));

export default class AddressSearchBox extends Component {
  onAddressChange = e => {
    const value = e.target ? e.target.value : e;
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const { value, placeholder, disabled, onFocus, separate } = this.props;
    return (
      <AddressSearchBoxComponent
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={this.onAddressChange}
        onFocus={onFocus}
        separate={separate}
      />
    );
  }
}
