import React from 'react';
import PropTypes from 'prop-types';
import './Table.scss';

const Table = ({ header, children }) => (
  <table className="plan-preview-table">
    <thead>
      <tr className="table-header">
        {header.map(item => (
          <th key={item.name} width={`${item.width}%`} className="table-top-row">
            {item.name}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
);

Table.propTypes = {
  header: PropTypes.array
};

Table.defaultProps = {
  header: [{ width: 12, name: '' }, { width: 73, name: 'ACTION' }, { width: 15, name: 'RESPONSIBLE' }]
};

export default Table;
