import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, Avatar, Button, Spin } from 'antd';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import BusinessEdit from '../BusinessEdit';
import { selectOrganisation, setDefaultOrganisation } from '../../OrganisationAction';
import {
  organisationListSelector,
  businessInfoSelector,
  loadingOrganisationsSelector
} from '../../OrganisationSelectors';

const pStyle = {
  marginRight: '1rem',
  marginBottom: '0',
  color: '#F1c400',
  fontWeight: '600'
};

const OrganisationList = ({
  history,
  organisationList,
  selectedOrganisation,
  selectOrganisation,
  setDefaultOrganisation,
  loading
}) => {
  const onSelect = id => {
    selectOrganisation(id);
    history.push('/organization');
  };

  const setDefault = (e, id) => {
    setDefaultOrganisation(id);
  };

  return loading ? (
    <Spin tip="Loading Organisations..." className="loading" />
  ) : (
    <div className="component-wrapper">
      <Card title="Organisation List">
        <List
          dataSource={organisationList}
          className="list"
          renderItem={item => (
            <List.Item
              actions={[
                item.isDefault ? (
                  <p style={pStyle}>Default</p>
                ) : (
                  <Button onClick={e => setDefault(e, item.organisationId)}>Set as default</Button>
                ),
                selectedOrganisation.organisationId === item.organisationId ? (
                  <Button type="primary" onClick={() => onSelect(item.organisationId)}>
                    Selected
                  </Button>
                ) : (
                  <Button onClick={() => onSelect(item.organisationId)}>Select</Button>
                )
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar size={50} src={item.avatarUrl} />}
                title={item.name}
                description={item.industryName}
              />
            </List.Item>
          )}
        />
        <div className="btn-container">
          <Modal
            explanation
            toggle={show => (
              <Button type="primary" icon="plus" onClick={show}>
                Add New
              </Button>
            )}
          >
            {(hide, setExplanation) => <BusinessEdit close={hide} onExplanationChange={setExplanation} />}
          </Modal>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  organisationList: organisationListSelector(state),
  selectedOrganisation: businessInfoSelector(state),
  loading: loadingOrganisationsSelector(state)
});

const mapDispatchToProps = { selectOrganisation, setDefaultOrganisation };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrganisationList);
