import { types } from './ProductAction';
import { types as organisationTypes } from '../../OrganisationAction';

const initialState = { loadingStrategicAreas: false, loadingStrategicAreaCategories: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case organisationTypes.SWITCH_ORGANISATION:
      return { ...initialState };
    case types.REQUEST_STRATEGIC_AREAS:
      return { ...state, loadingStrategicAreas: true };
    case types.RECEIVE_STRATEGIC_AREAS:
      return { ...state, loadingStrategicAreas: false, products: action.products };
    case types.REQUEST_STRATEGIC_AREA_CATEGORIES:
      return { ...state, loadingStrategicAreaCategories: true };
    case types.RECEIVE_STRATEGIC_AREA_CATEGORIES:
      return {
        ...state,
        loadingStrategicAreaCategories: false,
        strategicCriticalAreaCategories: action.strategicCriticalAreaCategories
      };
    default:
      return state;
  }
};
