import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import './ProcessBar.scss';

const VISIBILITY_HEIGHT = 120;

class ProcessBar extends Component {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subtitle: PropTypes.string,
    stepSeq: PropTypes.number,
    totalSteps: PropTypes.number,
    prevText: PropTypes.string,
    nextText: PropTypes.string,
    prev: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    next: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    disableNext: PropTypes.bool
  };

  static defaultProps = {
    color: '#f1c400',
    title: null,
    icon: null,
    subtitle: null,
    stepSeq: null,
    totalSteps: null,
    prevText: 'Previous',
    nextText: 'Next Step',
    prev: null,
    next: null,
    disableNext: false
  };

  state = { visible: false };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
    this.setState({ visible: scrollTop > VISIBILITY_HEIGHT });
  };

  onPrev = () => {
    const { prev, history } = this.props;
    if (typeof prev === 'string') {
      history.push(prev);
    } else if (typeof prev === 'function') {
      prev();
    }
    window.scrollTo(0, 0);
  };

  onNext = () => {
    const { next, history } = this.props;
    if (typeof next === 'string') {
      history.push(next);
    } else if (typeof next === 'function') {
      next();
    }
    window.scrollTo(0, 0);
  };

  render() {
    const {
      color,
      title,
      icon,
      subtitle,
      stepSeq,
      totalSteps,
      prev,
      next,
      prevText,
      nextText,
      disableNext
    } = this.props;
    const { visible } = this.state;
    return (
      <div className="process-bar" style={{ color }}>
        <div className="process-bar-title">
          {title}
          {icon}
        </div>
        {totalSteps && (
          <div className="process-bar-sub-step">
            <div className="process-bar-subtitle">{`Step ${stepSeq} out of ${totalSteps}`}</div>
            <div className="process-bar-subtitle">{subtitle}</div>
          </div>
        )}
        <div className="flex">
          {prev && (
            <Button onClick={this.onPrev} className="process-bar-btn btn-prev">
              <Icon type="left" />
              {prevText}
            </Button>
          )}
          {next && (
            <Button onClick={this.onNext} className="process-bar-btn btn-next" disabled={disableNext}>
              {nextText}
              <Icon type="right" />
            </Button>
          )}
        </div>
        <div className={classNames('process-bar-icon-btns', { visible })}>
          {prev && <Button className="process-bar-icon-btn" icon="left" onClick={this.onPrev} />}
          {next && <Button className="process-bar-icon-btn" icon="right" onClick={this.onNext} />}
        </div>
      </div>
    );
  }
}

export default withRouter(ProcessBar);
