import React from 'react';
import { Link } from 'react-router-dom';
import { Button, message } from 'antd';
import Table from '../../../../../../../components/Table/Table';

export default ({ subscriptions = [] }) => {
  const onCopy = key => {
    const input = document.createElement('input');
    input.value = key;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    message.success('Subscription Key copied');
  };

  const columns = [
    {
      key: 'copy',
      width: 40,
      render: (text, record) => <Button type="link" icon="copy" onClick={() => onCopy(record.key)} />
    },
    { key: 'company', title: 'Company', dataIndex: 'organisationName', render: text => text || '-' },
    { key: 'planName', title: 'Plan Name', dataIndex: 'planName', render: text => text || '-' },
    {
      key: 'subscriptionKey',
      title: 'Subscription Key',
      dataIndex: 'key',
      width: 300,
      span: 2
    },
    {
      key: 'activeDate',
      title: 'Active Date',
      dataIndex: 'activeDate',
      render: text => <span>{text || 'Not activated'}</span>
    },
    {
      key: 'expiryDate',
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      sorter: (a, b) => new Date(a.exactExpiryDate).getTime() - new Date(b.exactExpiryDate).getTime(),
      render: text => text || '-'
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'assignedTo',
      width: 200,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : '-')
    }
  ];

  return (
    <>
      <Table
        title="Subscriptions"
        columns={columns}
        dataSource={subscriptions}
        rowKey="subscriptionId"
        breakpoint={1024}
        searchFields={['userName', 'brokerName', 'assignedTo', 'organisationName', 'planName', 'activeDate']}
        showExpiredToggle
      />
      <div className="btn-container no-padding">
        <Button type="primary" className="btn-important">
          <Link to="/purchase">Buy Subscriptions</Link>
        </Button>
      </div>
    </>
  );
};
