import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Button } from 'antd';
import Card from '../../../../../../../components/Card/Card';
import SubscriptionChart from '../../../components/SubscriptionChart';
import { userSubscriptionsSummarySelector, loadingUserSubscriptionsSelector } from '../../../../User/UserSelectors';
import '../../../components/Summary.scss';

const Summary = ({ userSubscriptionsSummary = {}, loading }) => {
  const [summary, setSummary] = useState({ total: 0, remaining: 0, expired: 0 });

  useEffect(() => {
    if (userSubscriptionsSummary) {
      setSummary(s => ({ ...s, ...userSubscriptionsSummary }));
    }
  }, [userSubscriptionsSummary]);

  const data = [
    { name: 'REMAINING', value: summary.remaining, color: '#5BC2E7' },
    { name: 'EXPIRED', value: summary.expired, color: '#CB2C30' }
  ];

  return (
    <Spin spinning={loading}>
      <Card>
        <div className="summary">
          <h1 className="summary-title">SUBSCRIPTIONS SUMMARY</h1>
          <h2 className="summary-subtitle">
            <span className="summary-subtitle-number">{summary.remaining}</span> remaining out of{' '}
            <span className="summary-subtitle-number">{summary.total}</span> subscriptions
          </h2>
          <SubscriptionChart
            className="summary-chart"
            summary={{ total: summary.total, remaining: summary.remaining }}
          />
          <div className="summary-operations">
            <div className="operation">
              <Button size="large" type="primary" block className="operation-btn buy-btn">
                <Link to="/purchase">Buy more</Link>
              </Button>
              <div className="operation-desc">Click to purchase subscriptions</div>
            </div>
          </div>
          <div className="summary-breakdown">
            <h2 className="summary-breakdown-title">My subscriptions breakdown</h2>
            <div className="summary-breakdown-container">
              {data.map(item => (
                <div key={item.name} className="summary-breakdown-item-container">
                  <div className="summary-breakdown-item">
                    <h3 className="summary-breakdown-item-name">{item.name}</h3>
                    <div className="summary-breakdown-item-value" style={{ color: item.color }}>
                      {item.value}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </Spin>
  );
};

const mapStateToProps = state => ({
  userSubscriptionsSummary: userSubscriptionsSummarySelector(state),
  loading: loadingUserSubscriptionsSelector(state)
});

export default connect(mapStateToProps)(Summary);
