import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RiskExposure from './scenes/RiskExposure/RiskExposure';
import RiskAssessment from './scenes/RiskAssessment/RiskAssessment';
import RiskRegister from './scenes/RiskRegister/RiskRegister';
import RiskReport from './scenes/RiskReport/RiskReport';
import './Risk.scss';

const routes = Object.freeze([
  { route: 'exposures', component: RiskExposure },
  { route: 'assessment', component: RiskAssessment },
  { route: 'register', component: RiskRegister },
  { route: 'report', component: RiskReport }
]);

export default () => (
  <Switch>
    {routes.map(item => (
      <Route key={item.route} path={`/dashboard/risk/${item.route}`} component={item.component} />
    ))}
    <Redirect to="/not-found" />
  </Switch>
);
