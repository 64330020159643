import { createSelector } from 'reselect';
import moment from 'moment';

export const allPlansSelector = state => state.planReducer.allPlans;

export const loadingPlansSelector = state => state.planReducer.loadingPlans;

export const loadingPlanSubscriptionsSelector = state => state.planReducer.loadingPlanSubscriptions;

export const plansSelector = state => state.planReducer.plans;

export const planSelector = state => state.planReducer.currentPlan;

export const currenciesSelector = state => state.planReducer.currencies;

export const currencySelector = state => state.planReducer.currentPlan.currency;

export const planSubscriptionsSelector = state => state.planReducer.planSubscriptions;

export const planIdSelector = createSelector(planSelector, plan => (plan ? plan.planId : null));

export const planNameSelector = createSelector(planSelector, plan => (plan ? plan.name : null));

export const branchIdSelector = createSelector(planSelector, plan => (plan ? plan.branchId : null));

export const planDateSelector = createSelector(planSelector, plan => (plan ? plan.lastModifiedDate : null));

export const getExpiryDate = plan => {
  const dateFormat = 'DD/MM/YYYY';
  const expiryDates = [];
  if (plan && plan.subscriptions) {
    plan.subscriptions.forEach(subscription => {
      if (subscription.expiryDate) {
        expiryDates.push(moment(subscription.expiryDate).format(dateFormat));
      } else if (subscription.activeDate) {
        const activeDate = moment(subscription.activeDate);
        expiryDates.push(activeDate.add(1, 'y').format(dateFormat));
      }
    });
    expiryDates.sort((a, b) => {
      return moment(b, dateFormat).diff(moment(a, dateFormat));
    });
    return expiryDates[0];
  }
  return null;
};

export const planExpiryDateSelector = createSelector(
  planSelector,
  planSubscriptionsSelector,
  (plan, planSubscriptions) => {
    return getExpiryDate({ ...plan, subscriptions: planSubscriptions ? planSubscriptions[plan.planId] : [] });
  }
);

export const mtpdSelector = createSelector(planSelector, plan => (plan ? plan.mtpd : undefined));

export const mtpdDaysSelector = createSelector(mtpdSelector, mtpd => (mtpd ? mtpd.value : null));
