import axios from 'axios';
import { policyResponsiblePresetsSelector } from './PolicySelectors';

export const REQUEST_POLICY_RESPONSIBLE_PRESETS = 'policy/REQUEST_POLICY_RESPONSIBLE_PRESETS';
export const RECEIVE_POLICY_RESPONSIBLE_PRESETS = 'policy/RECEIVE_POLICY_RESPONSIBLE_PRESETS';
export const REQUEST_LATEST_POLICY = 'policy/REQUEST_LATEST_POLICY';
export const RECEIVE_LATEST_POLICY = 'policy/RECEIVE_LATEST_POLICY';
export const REQUEST_PLAN_POLICY = 'policy/REQUEST_PLAN_POLICY';
export const RECEIVE_PLAN_POLICY = 'policy/RECEIVE_PLAN_POLICY';
export const POLICY_REQUEST = 'policy/POLICY_REQUEST';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_POLICY_RESPONSIBLE_PRESETS = 'cmt_composition_presets';
const API_ORGS = 'orgs';
const API_PLANS = 'plans';
const API_POLICY = 'policy';
const API_POLICIES = 'policies';

const requestPolicyResponsiblePresets = () => ({ type: REQUEST_POLICY_RESPONSIBLE_PRESETS });
const receivePolicyResponsiblePresets = data => ({
  type: RECEIVE_POLICY_RESPONSIBLE_PRESETS,
  policyResponsiblePresets: data
});

const requestLatestPolicy = () => ({ type: REQUEST_LATEST_POLICY });
const receiveLatestPolicy = data => ({ type: RECEIVE_LATEST_POLICY, latestPolicy: data });

const requestPlanPolicy = () => ({ type: REQUEST_PLAN_POLICY });
const receivePlanPolicy = data => ({ type: RECEIVE_PLAN_POLICY, planPolicy: data });

const setPolicyRequest = policyRequest => ({ type: POLICY_REQUEST, policyRequest });

export const fetchPolicyResponsiblePresets = () => async (dispatch, getState) => {
  if (policyResponsiblePresetsSelector(getState())) {
    return;
  }
  dispatch(requestPolicyResponsiblePresets());
  const url = `${API_BASE}${API_POLICY_RESPONSIBLE_PRESETS}`;
  try {
    const res = await axios.get(url);
    dispatch(receivePolicyResponsiblePresets(res.data));
  } catch (err) {
    dispatch(receivePolicyResponsiblePresets());
  }
};

export const fetchLatestPolicy = orgId => async dispatch => {
  dispatch(requestLatestPolicy());
  const url = `${API_BASE}${API_ORGS}/${orgId}/${API_POLICIES}/latest`;
  try {
    const res = await axios.get(url);
    dispatch(receiveLatestPolicy(res.data));
  } catch (err) {
    dispatch(receiveLatestPolicy());
  }
};

export const createPolicy = (orgId, data) => async dispatch => {
  dispatch(setPolicyRequest(true));
  const url = `${API_BASE}${API_ORGS}/${orgId}/${API_POLICIES}`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchLatestPolicy(orgId));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(setPolicyRequest(false));
  }
};

export const fetchPlanPolicy = planId => async dispatch => {
  dispatch(requestPlanPolicy());
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_POLICY}/latest`;
  try {
    const res = await axios.get(url);
    dispatch(receivePlanPolicy(res.data));
  } catch (err) {
    dispatch(receivePlanPolicy());
  }
};

export const createPlanPolicy = (planId, data) => async dispatch => {
  dispatch(setPolicyRequest(true));
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_POLICY}`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchPlanPolicy(planId));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(setPolicyRequest(false));
  }
};
