import axios from 'axios';
import { contactTypesSelector, workTypesSelector } from './ContactSelectors';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_CONTACT_TYPES: 'contact/REQUEST_CONTACT_TYPES',
  RECEIVE_CONTACT_TYPES: 'contact/RECEIVE_CONTACT_TYPES',
  REQUEST_WORK_TYPES: 'contact/REQUEST_WORK_TYPES',
  RECEIVE_WORK_TYPES: 'contact/RECEIVE_WORK_TYPES',
  REQUEST_CONTACTS: 'contact/REQUEST_CONTACTS',
  RECEIVE_CONTACTS: 'contact/RECEIVE_CONTACTS'
};

const requestContactTypes = () => ({ type: types.REQUEST_CONTACT_TYPES });
const receiveContactTypes = data => ({ type: types.RECEIVE_CONTACT_TYPES, contactTypes: data });

const requestWorkTypes = () => ({ type: types.REQUEST_WORK_TYPES });
const receiveWorkTypes = data => ({ type: types.RECEIVE_WORK_TYPES, workTypes: data });

const requestContacts = () => ({ type: types.REQUEST_CONTACTS });
const receiveContacts = data => ({ type: types.RECEIVE_CONTACTS, contactList: data });

export const fetchContactTypes = () => async (dispatch, getState) => {
  if (contactTypesSelector(getState())) {
    return;
  }
  dispatch(requestContactTypes());
  const url = `${API_BASE}contact_types`;
  try {
    const res = await axios.get(url);
    dispatch(receiveContactTypes(res.data));
  } catch (err) {
    dispatch(receiveContactTypes());
  }
};

export const fetchWorkTypes = () => async (dispatch, getState) => {
  if (workTypesSelector(getState())) {
    return;
  }
  dispatch(requestWorkTypes());
  const url = `${API_BASE}work_types`;
  try {
    const res = await axios.get(url);
    dispatch(receiveWorkTypes(res.data));
  } catch (err) {
    dispatch(receiveWorkTypes());
  }
};

export const fetchContact = orgId => async dispatch => {
  dispatch(requestContacts());
  const url = `${API_BASE}orgs/${orgId}/contacts`;
  try {
    const res = await axios.get(url);
    dispatch(receiveContacts(res.data));
  } catch (err) {
    dispatch(receiveContacts());
  }
};

export const createContact = (orgId, data) => async dispatch => {
  dispatch(requestContacts());
  const url = `${API_BASE}orgs/${orgId}/contacts`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchContact(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveContacts());
    return Promise.reject(err);
  }
};

export const updateContact = (orgId, contactId, data) => async dispatch => {
  dispatch(requestContacts());
  const url = `${API_BASE}orgs/${orgId}/contacts/${contactId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchContact(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveContacts());
    return Promise.reject(err);
  }
};

export const deleteContact = (orgId, contactId) => async dispatch => {
  dispatch(requestContacts());
  const url = `${API_BASE}orgs/${orgId}/contacts/${contactId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchContact(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveContacts());
    return Promise.reject(err);
  }
};

export const importContact = (orgId, file) => async dispatch => {
  dispatch(requestContacts());
  const url = `${API_BASE}orgs/${orgId}/contacts/import`;
  try {
    const res = await axios.post(url, file);
    dispatch(fetchContact(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveContacts());
    return Promise.reject(err);
  }
};

export const downloadContactImportTemplate = () => () => {
  const url = `${API_BASE}contact_import_template`;
  return axios.get(url, { responseType: 'blob' });
};

export const exportContacts = orgId => () => {
  const url = `${API_BASE}orgs/${orgId}/contact_export_template`;
  return axios.get(url, { responseType: 'blob' });
};
