import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import { createBroker, updateBroker } from '../BrokerActions';
import { brokerageIdSelector, brokerEmailSelector, loadingBrokersSelector } from '../BrokerSelectors';
import { MSG_ERROR } from '../../../../../constants';

const FormItem = Form.Item;
const MSG_NOT_FOUND = 'The user is not found.';
const MSG_CONFLICT = 'The user is already a broker.';

const BrokerEdit = ({ form, broker, brokerageId, brokerEmail, loadingBrokers, createBroker, updateBroker, close }) => {
  const { getFieldDecorator } = form;

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          if (broker) {
            const { brokerId } = broker;
            await updateBroker(brokerId, values);
          } else {
            await createBroker(brokerageId, { ...values, createBy: brokerEmail });
          }
          message.success('The user has been successfully added as a broker');
          close();
        } catch (err) {
          switch (err.status) {
            case HttpStatus.NOT_FOUND:
              message.error(MSG_NOT_FOUND);
              break;
            case HttpStatus.CONFLICT:
              message.error(MSG_CONFLICT);
              break;
            default:
              message.error(MSG_ERROR);
          }
        }
      }
    });
  };

  return (
    <Form>
      <FormItem label="Email">
        {getFieldDecorator('email', {
          rules: [
            { type: 'email', message: 'Please input an valid email.' },
            { required: true, message: 'Please input the email.' }
          ]
        })(<Input placeholder="Email" disabled={loadingBrokers} spellCheck={false} />)}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loadingBrokers} onClick={onSubmit}>
          {loadingBrokers ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  brokerageId: brokerageIdSelector(state),
  brokerEmail: brokerEmailSelector(state),
  loadingBrokers: loadingBrokersSelector(state)
});

const mapDispatchToProps = { createBroker, updateBroker };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(BrokerEdit));
