import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider, connect } from 'react-redux';
import { Row, Col, Form, Select, InputNumber, Button, Spin, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import store from '../../../../../../store';
import Download from '../../../../../../components/Download/Download';
import ProcessBar from '../../../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../../../components/Card/Card';
import InfoBox from '../../../../../../components/InfoBox/InfoBox';
import Modal from '../../../../../../components/Modal/Modal';
import MoreInfo from '../../../../../../components/InfoBox/MoreInfo';
import { withOnFocus } from '../../../../../../components/HOC';
import RiskReportPreview, { Cover, Main } from '../RiskReportPreview/RiskReportPreview';
import { download } from '../../../../../../components/App/AppAction';
import { fetchHazardIndex } from '../../../../../Organisation/OrganisationAction';
import {
  fetchRisks,
  fetchRiskCategories,
  fetchRiskLikelihoodPresets,
  fetchRiskIndustries,
  fetchRiskExposures,
  fetchRiskReportIndemnityPresets,
  fetchRiskReportReviewPresets,
  fetchRiskReport,
  createRiskReport
} from '../../RiskAction';
import {
  businessNameSelector,
  industryCodeSelector,
  hazardIndexSelector
} from '../../../../../Organisation/OrganisationSelectors';
import { planIdSelector, currencySelector } from '../../../Plan/PlanSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../../constants';
import explanations from '../../data/explanations.json';
import {
  riskIndustrySelector,
  riskReportSelector,
  riskReportIndemnityPresetsSelector,
  riskReportReviewPresetsSelector
} from '../../RiskSelectors';

const FormItem = Form.Item;
const { Option } = Select;
const THEME_COLOR = '#a8c08f';
const INFO_MSG = (
  <>
    <p>
      <strong>Congratulations: you have now completed the Risk and Threat Assessment Phase.</strong>
    </p>
    <p>
      Before we move to the Business Continuity Management Section, we ask you to complete three more important
      questions before you can print off the Risk and Insurance Report for your location.
    </p>
    <p>
      Insurance is considered by many as an important part of their risk management process and we at ContinuityCoach
      believe that the report we are about to produce will prove invaluable in helping tailor your insurance program to
      the organization&apos;s needs.
    </p>
  </>
);

class RiskReport extends Component {
  constructor() {
    super();
    this.SelectWithOnFocus = withOnFocus(Select, this.onExplanationChange);
    this.InputNumberWithOnFocus = withOnFocus(InputNumber, this.onExplanationChange);
    this.state = {
      loaded: false,
      explanationTitle: null,
      explanationText: null,
      explanationMore: null
    };
    this.charts = {};
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {
      planId,
      industryCode,
      fetchRiskReportIndemnityPresets,
      fetchRiskReportReviewPresets,
      fetchRiskReport,
      fetchRiskIndustries,
      fetchHazardIndex,
      fetchRiskCategories,
      fetchRiskLikelihoodPresets,
      fetchRisks,
      fetchRiskExposures
    } = this.props;
    await fetchRiskReportIndemnityPresets();
    await fetchRiskReportReviewPresets();
    await fetchRiskReport(planId);
    await fetchRiskIndustries(industryCode);
    await fetchHazardIndex(industryCode);
    await fetchRiskCategories();
    await fetchRiskLikelihoodPresets();
    await fetchRisks(planId);
    await fetchRiskExposures(industryCode);
    const { riskIndustry, hazardIndex } = this.props;
    if (hazardIndex && riskIndustry && hazardIndex.length === 0 && riskIndustry.alias) {
      await fetchHazardIndex(riskIndustry.alias);
      await fetchRiskIndustries(riskIndustry.alias);
    }
    this.setState({ loaded: true });
  };

  onSubmit = e => {
    e.preventDefault();
    const { form, planId, createRiskReport } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await createRiskReport(planId, values);
          message.success(MSG_SAVE_SUCCESS);
        } catch (err) {
          switch (err.status) {
            case HttpStatus.PAYMENT_REQUIRED:
              message.error(MSG_PAYMENT_REQUIRED);
              break;
            default:
              message.error(MSG_ERROR);
          }
        }
      }
    });
  };

  onDownload = () => {
    const { businessName, download } = this.props;
    const params = {
      displayHeaderFooter: true,
      headerTemplate: `
        <div style="width:300px;margin-left:auto;padding:10px 60px;text-align:right;font-size:10px;line-height:1.5;border-bottom:1px solid #343579;">
          <div style="color:#676767;font-weight:400;">${businessName}</div>
          <div style="color:#f1c400;font-weight:700;">RISK ANALYSIS REPORT</div>
        </div>
      `,
      footerTemplate: `<div class="pageNumber" style="-webkit-print-color-adjust:exact;width:30px;height:18px;margin:0 0 0 auto;padding:0 0 0 10px;border-radius: 9px 0 0 9px;line-height:18px;font-size:10px;color:#fff;background-color:#343579;" />`,
      margin: {
        top: '120px',
        bottom: '60px'
      }
    };
    const content = renderToStaticMarkup(
      <Provider store={store}>
        <Main charts={this.charts} />
      </Provider>
    );
    const cover = renderToStaticMarkup(
      <Provider store={store}>
        <Cover />
      </Provider>
    );
    return download(content, cover, params);
  };

  onExplanationChange = value => {
    const { title, text, more } = value;
    this.setState({ explanationTitle: title, explanationText: text, explanationMore: more });
  };

  setCharts = (key, dataUrl) => {
    this.charts[key] = dataUrl;
  };

  render() {
    const { SelectWithOnFocus, InputNumberWithOnFocus, charts } = this;
    const {
      form,
      businessName,
      currency,
      riskReportIndemnityPresets,
      riskReportReviewPresets,
      riskReport,
      riskRequest
    } = this.props;
    const { getFieldDecorator } = form;
    const { loaded, explanationTitle, explanationText, explanationMore } = this.state;
    return (
      <>
        <ProcessBar color={THEME_COLOR} title="Risk &amp; Insurance Report" prev="/dashboard/risk/register" />
        <InfoBox color={THEME_COLOR} message={INFO_MSG} />
        {loaded ? (
          <div className="risk-container">
            <Row gutter={20}>
              {riskReport && (
                <Col xs={24} lg={16} className="preview">
                  <RiskReportPreview charts={charts} setCharts={this.setCharts} />
                </Col>
              )}
              <Col xs={24} lg={riskReport ? 8 : 24}>
                <Col xs={24} lg={riskReport ? 24 : 16}>
                  <Card color={THEME_COLOR}>
                    <Form onSubmit={this.onSubmit} className="risk-form">
                      <FormItem label="Question 1:">
                        <div className="risk-question">
                          What is the maximum period your organization could be effected by a catastrophic event causing
                          massive loss or damage?
                        </div>
                        {getFieldDecorator('indemnityPeriodId', {
                          initialValue: riskReport ? riskReport.indemnityPeriod.riskReportIndemnityPeriodId : undefined,
                          rules: [{ required: true, message: 'Please select an answer.' }]
                        })(
                          <SelectWithOnFocus
                            placeholder="Choose a Maximum Indemnity Period"
                            disabled={riskRequest}
                            explanation={explanations.riskReport.maximumIndemnityPeriod}
                          >
                            {(riskReportIndemnityPresets || []).map(item => (
                              <Option key={item.riskReportIndemnityPeriodId} value={item.riskReportIndemnityPeriodId}>
                                {item.value}
                              </Option>
                            ))}
                          </SelectWithOnFocus>
                        )}
                      </FormItem>
                      <FormItem label="Question 2:">
                        <div className="risk-question">How much would you need to fast track your recovery?</div>
                        {getFieldDecorator('fastTrackRecovery', {
                          initialValue: riskReport ? riskReport.fastTrackRecovery : undefined,
                          rules: [{ required: true, message: 'Please input a value.' }]
                        })(
                          <InputNumberWithOnFocus
                            formatter={value => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\D\s?|(,*)/g, '')}
                            precision={0}
                            disabled={riskRequest}
                            style={{ width: '100%' }}
                            explanation={explanations.riskReport.fastTrackRecovery}
                          />
                        )}
                      </FormItem>
                      <FormItem label="Question 3:">
                        <div className="risk-question">
                          When would you like to schedule a review of your risk and threat assessment Answers?
                        </div>
                        {getFieldDecorator('riskReportReviewId', {
                          initialValue: riskReport ? riskReport.riskReportReview.riskReportReviewId : undefined,
                          rules: [{ required: true, message: 'Please select an answer.' }]
                        })(
                          <SelectWithOnFocus
                            placeholder="Choose a Reoccurrence Period"
                            disabled={riskRequest}
                            explanation={explanations.riskReport.reviewPeriod}
                          >
                            {(riskReportReviewPresets || []).map(item => (
                              <Option key={item.riskReportReviewId} value={item.riskReportReviewId}>
                                {item.value}
                              </Option>
                            ))}
                          </SelectWithOnFocus>
                        )}
                      </FormItem>
                    </Form>
                    <div className="btn-container center">
                      <Button type="primary" onClick={this.onSubmit} loading={riskRequest} className="risk-btn">
                        {riskRequest ? 'Saving' : 'Save'}
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} lg={riskReport ? 24 : 8}>
                  <Card color={THEME_COLOR}>
                    <div className="risk-card left">
                      <div className="risk-card-title">{explanationTitle}</div>
                      <div className="risk-card-text" dangerouslySetInnerHTML={{ __html: explanationText }} />
                      {explanationMore && (
                        <Modal
                          title={explanationTitle}
                          width="auto"
                          footer={null}
                          wrapClassName="more-info-modal"
                          toggle={show => (
                            <Button type="primary" onClick={show} className="risk-card-btn">
                              READ MORE
                            </Button>
                          )}
                        >
                          {() => <MoreInfo message={explanationMore} />}
                        </Modal>
                      )}
                    </div>
                    <div className="risk-card">
                      <i className="risk-card-icon fa fa-pencil-square-o" />
                      <div className="risk-card-title">ACCEPTED AND AGREED BY</div>
                      {riskReport && <div className="risk-report-accepted-by">{riskReport.acceptedByUserName}</div>}
                    </div>
                    <div className="btn-container center">
                      <Download
                        fileName={`Risk_Analysis_Report_${businessName.replace(/\s+/g, '_')}.pdf`}
                        download={this.onDownload}
                      >
                        {(downloadFile, downloading) => (
                          <Button
                            type="primary"
                            icon="download"
                            disabled={!riskReport}
                            className="risk-btn"
                            loading={downloading}
                            onClick={downloadFile}
                          >
                            Download
                          </Button>
                        )}
                      </Download>
                    </div>
                  </Card>
                </Col>
              </Col>
            </Row>
          </div>
        ) : (
          <Spin tip="Loading..." className="loading" />
        )}
      </>
    );
  }
}

const WrappedRiskReport = Form.create()(RiskReport);

const mapStateToProps = state => ({
  businessName: businessNameSelector(state),
  industryCode: industryCodeSelector(state),
  riskIndustry: riskIndustrySelector(state),
  hazardIndex: hazardIndexSelector(state),
  planId: planIdSelector(state),
  riskReport: riskReportSelector(state),
  riskReportIndemnityPresets: riskReportIndemnityPresetsSelector(state),
  riskReportReviewPresets: riskReportReviewPresetsSelector(state),
  currency: currencySelector(state),
  riskRequest: state.riskReducer.riskRequest
});

const mapDispatchToProps = {
  download,
  fetchHazardIndex,
  fetchRisks,
  fetchRiskCategories,
  fetchRiskLikelihoodPresets,
  fetchRiskIndustries,
  fetchRiskExposures,
  fetchRiskReportIndemnityPresets,
  fetchRiskReportReviewPresets,
  fetchRiskReport,
  createRiskReport
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRiskReport);
