import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Select, Button, message } from 'antd';
import { withOnFocus } from '../../../../../components/HOC';
import AddressSearchBox from '../../../../../components/AddressSearchBox/AddressSearchBox';
import { createSupplier, updateSupplier } from '../SupplierAction';
import { orgIdSelector } from '../../../OrganisationSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../constants';
import explanations from '../data/explanations.json';

const FormItem = Form.Item;
const { Option } = Select;

const categoryPresets = ['Finance', 'Information Technology', 'Engineering', 'Call Centre', 'Human Resource', 'Sales'];

class SupplierEdit extends PureComponent {
  constructor(props) {
    super(props);
    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.SelectWithOnFocus = withOnFocus(Select, props.onExplanationChange);
    this.AddressSearchBoxWithOnFocus = withOnFocus(AddressSearchBox, props.onExplanationChange);
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, orgId, supplierInfo, updateSupplier, createSupplier, close } = this.props;
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          if (fieldsValue[key]) {
            if (typeof fieldsValue[key] === 'string') {
              acc[key] = fieldsValue[key].trim();
            } else if (typeof fieldsValue[key] === 'object') {
              acc[key] = JSON.stringify(fieldsValue[key]);
            } else {
              acc[key] = fieldsValue[key];
            }
          }
          return acc;
        }, {});
        try {
          if (supplierInfo) {
            const { supplierId } = supplierInfo;
            await updateSupplier(orgId, supplierId, values);
          } else {
            await createSupplier(orgId, values);
          }
          message.success(MSG_SAVE_SUCCESS);
          close();
        } catch (err) {
          message.error(MSG_ERROR);
        }
      }
    });
  };

  render() {
    const { InputWithOnFocus, SelectWithOnFocus, AddressSearchBoxWithOnFocus } = this;
    const { form, supplierInfo = {}, supplierRequest } = this.props;
    const { getFieldDecorator } = form;
    let initialCategory = [];
    if (supplierInfo.category) {
      try {
        initialCategory = JSON.parse(supplierInfo.category);
      } catch {
        initialCategory = supplierInfo.category;
      }
    }
    const categoryOptions = categoryPresets.map(categoryName => (
      <Option key={categoryName} value={categoryName}>
        {categoryName}
      </Option>
    ));

    return (
      <Form>
        <FormItem label="Name">
          {getFieldDecorator('name', {
            initialValue: supplierInfo.name,
            rules: [{ required: true, message: 'Please input the name.' }]
          })(
            <InputWithOnFocus
              placeholder="Name"
              disabled={supplierRequest}
              spellCheck={false}
              explanation={explanations.name}
            />
          )}
        </FormItem>
        <FormItem label="Category">
          {getFieldDecorator('category', {
            initialValue: initialCategory
          })(
            <SelectWithOnFocus
              mode="tags"
              placeholder="Category"
              disabled={supplierRequest}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              tokenSeparators={[',']}
              explanation={explanations.category}
            >
              {categoryOptions}
            </SelectWithOnFocus>
          )}
        </FormItem>
        <FormItem label="Contact Person">
          {getFieldDecorator('contactPerson', {
            initialValue: supplierInfo.contactPerson
          })(<InputWithOnFocus placeholder="Contact Person" disabled={supplierRequest} spellCheck={false} />)}
        </FormItem>
        <Row gutter={20}>
          <Col span={12}>
            <FormItem label="Email">
              {getFieldDecorator('email', {
                initialValue: supplierInfo.email,
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'Please input an valid email.'
                  },
                  {
                    required: true,
                    message: 'Please input the email.'
                  }
                ]
              })(
                <InputWithOnFocus
                  placeholder="Email"
                  disabled={supplierRequest}
                  spellCheck={false}
                  explanation={explanations.email}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Phone">
              {getFieldDecorator('phone', {
                initialValue: supplierInfo.phone
              })(<InputWithOnFocus placeholder="Phone" disabled={supplierRequest} explanation={explanations.phone} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem label="Address">
              {getFieldDecorator('address', {
                initialValue: supplierInfo.address
              })(
                <AddressSearchBoxWithOnFocus
                  placeholder="Address"
                  disabled={supplierRequest}
                  spellCheck={false}
                  explanation={explanations.address}
                />
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Unit/Suite/Floor">
              {getFieldDecorator('additionalAddressInformation', {
                initialValue: supplierInfo.additionalAddressInformation
              })(
                <InputWithOnFocus
                  placeholder="Unit/Suite/Floor"
                  disabled={supplierRequest}
                  explanation={explanations.additionalAddressInformation}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem label="Website">
          {getFieldDecorator('website', {
            initialValue: supplierInfo.website
          })(<InputWithOnFocus placeholder="Website" disabled={supplierRequest} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" block loading={supplierRequest} onClick={this.onSubmit}>
            {supplierRequest ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  supplierRequest: state.supplierReducer.supplierRequest
});

const mapDispatchToProps = { createSupplier, updateSupplier };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(SupplierEdit));
