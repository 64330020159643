import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import Logo from '../../../../../../../../components/Logo/Logo';
import { HazardIndex, RaChart } from '../../../../../../../../components/widget';
import Rating from '../../../../../../../../components/Rating/Rating';
import Table from '../Table/Table';
import Chart from '../Chart/Chart';
import {
  businessNameSelector,
  industryNameSelector,
  hazardIndexSelector
} from '../../../../../../../Organisation/OrganisationSelectors';
import {
  risksSelector,
  riskReportSelector,
  riskCategoriesSelector,
  riskLikelihoodPresetsSelector,
  riskExposuresSelector
} from '../../../../RiskSelectors';
import { currencySelector } from '../../../../../Plan/PlanSelectors';

const getExposure = rating => {
  if (rating > 0 && rating <= 30) {
    return 'Low';
  }
  if (rating > 30 && rating <= 60) {
    return 'Medium';
  }
  if (rating > 60 && rating <= 90) {
    return 'High';
  }
  if (rating > 90 && rating <= 100) {
    return 'Extreme';
  }
  return null;
};

const Main = ({
  businessName,
  industryName,
  hazardIndex,
  risks,
  riskCategories = [],
  riskLikelihoodPresets,
  riskExposures,
  riskReport,
  charts,
  setCharts,
  currency
}) => {
  const getHazardIndexTableData = hazardIndex => {
    const header = [
      { name: 'Hazard', width: 40 },
      { name: 'Rating', width: 30 },
      { name: 'Exposure', width: 30 }
    ];
    let hazardSeq = 0;
    const data = hazardIndex.map(item => ({
      seq: (hazardSeq += 1),
      hazard: item.category ? item.category.name : null,
      rating: item.rating,
      exposure: getExposure(item.rating),
      summary: item.comment
    }));
    return { header, data };
  };

  const getRiskExposureTableData = riskExposures => {
    const header = [{ name: 'Risk Exposures' }];
    return { header, data: riskExposures };
  };

  const getRiskTableData = (risks, riskCategories) => {
    const header = [
      { name: 'Risk', width: 40 },
      { name: 'Likelihood', width: 19 },
      { name: 'Loss Value', width: 19 },
      { name: 'Annualised Value', width: 22 }
    ];
    const mitigationHeader = [
      { name: 'Risk', width: 40 },
      { name: 'Mitigation Strategy', width: 38 },
      { name: 'Annualised Value', width: 22 }
    ];
    const data = [];
    let categorySeq = 0;
    Object.entries(risks).forEach(([categoryId, risksOfCategory]) => {
      const category = riskCategories.find(item => item.riskCategoryId === Number.parseInt(categoryId, 10));
      const categoryDescription = category ? category.description : null;
      let riskSeq = 0;
      const risksData = risksOfCategory.map(obj => ({
        id: obj.riskId,
        seq: (riskSeq += 1),
        name: obj.name,
        description: obj.description,
        likelihood: obj.likelihoodText,
        cost: <CurrencyFormat value={obj.cost} displayType="text" thousandSeparator prefix={`${currency} `} />,
        annualisedValue: (
          <CurrencyFormat
            value={obj.likelihoodValue * obj.cost}
            displayType="text"
            thousandSeparator
            prefix={`${currency} `}
          />
        ),
        mitigationStrategy: obj.mitigationStrategies.map(item => item.name).join(', '),
        /* eslint no-nested-ternary: off */
        insurance: obj.includeInsurance !== null ? (obj.includeInsurance ? 'Yes' : 'No') : null,
        mitigationStatus: obj.mitigationStatusName,
        approach: obj.mitigationProcess,
        comments: obj.comments
      }));
      const totalAnnualisedValue = risksOfCategory.reduce((acc, risk) => acc + risk.cost * risk.likelihoodValue, 0);
      data.push({
        id: categoryId,
        seq: (categorySeq += 1),
        categoryName: risksOfCategory[0].riskCategoryName,
        description: categoryDescription,
        risks: risksData,
        totalAnnualisedValue: (
          <CurrencyFormat value={totalAnnualisedValue} displayType="text" thousandSeparator prefix={`${currency} `} />
        )
      });
    });
    return { header, mitigationHeader, data };
  };

  const { createdAt, indemnityPeriod, fastTrackRecovery, riskReportReview } = riskReport;
  const riskTableData = getRiskTableData(risks, riskCategories);
  const hazardIndexTableData = getHazardIndexTableData(hazardIndex);
  const riskExposureTableData = getRiskExposureTableData(riskExposures);

  let figureSeq = 0;
  let sectionSeq = 0;

  /* eslint no-return-assign: off */
  return (
    <>
      <div className="risk-report-preview-main">
        <div className="title">
          <h1 className="shape light">{`${(sectionSeq += 1)}.`}</h1>
          <h1>INTRODUCTION</h1>
        </div>
        <div className="content">
          <p>
            Active risk management, is proactive, directing management attention to uncertainties and risks before the
            events have happened, when there are still opportunities to do something to avoid, mitigate, or manage them
            or to stop the project if they cannot be managed. Active risk management is an approach that allows managers
            to manage rather than just assign blame for failure.
          </p>
          <p>
            This report discusses the Hazards assessment and risk analysis process and results for the &quot;
            {businessName}&quot;.
          </p>
          <p>The report is designed with several objectives in mind.</p>
          <ol>
            <li>
              To provide a summary of the risks identified by the organization during the important Risk and Threat
              Assessment Phase of the Business Continuity Management Plan using LMI ContinuityCoach.com. this includes
              the date of the report so that the board, and or the Business Continuity Governance Committee can review
              and agree the findings and measure changes in the risks facing the organization over time.
            </li>
            <li>
              Provide a Hazard Index and Significant Exposures of insurable risks on the Occupation Code selected in the
              Organization Section of LMI ContinuityCoach. This is not reflective of your particular organization but is
              a general benchmark against which you can measure.
            </li>
            <li>
              A document to shared and or discussed with your insurance adviser(s) to address any gaps in coverage that
              the organization may not realise they have.
            </li>
            <li>
              An explanation on the potential financial shortfall that may arise due to under insurance on property and
              business interruption insurance should a claim under a policy be made.
            </li>
            <li>
              Provide a starting point for the next Risk and Threat Assessment Review, the date of which is also
              recorded
            </li>
          </ol>
          <p>
            The risks facing any organization are constantly changing and those recorded in this report are as
            identified and evaluated by &quot;{businessName}&quot; as at date of last assessment.
          </p>
        </div>
        <div className="title">
          <h1 className="shape light">{`${(sectionSeq += 1)}.`}</h1>
          <h1>SCOPE</h1>
        </div>
        <div className="content">
          <p>
            The Risk and Threat Assessment process that has been conducted is intended to estimate potential human,
            health, financial, economic reputational, property and environmental risks posed by current and potential
            future conditions facing the organization.
          </p>
          <p>
            The Risk and Threat Assessment Report outlines the estimated likelihood of an event occurring and the impact
            such an event would have should it actually occur.
          </p>
          <p>
            Coaching on most of the components of the risk and threat assessment have been provided in the Risk and
            Threat Assessment section of LMI ContinuityCoach.com so that any discrepancies or discussion may be
            addressed before the results are recorded and this report is prepared.
          </p>
          <p>
            By establishing the framework for the management of risks, the basic parameters within which risks are being
            managed are defined. Consequently, the scope for the rest of the Risk Management process is also set.
          </p>
          <p>In order to carry out this Risk and Threat Assessment it is important for those involved to:</p>
          <ul>
            <li>
              understand the background of the organization and its risks (e.g. its core processes, valuable assets,
              competitive areas etc.);
            </li>
            <li>
              have the skills and experience to reasonably evaluate the Risk Management activities being undertaken so
              far;
            </li>
            <li>
              develop a structure for the Risk Management initiatives and controls (countermeasures, security controls
              etc.) to follow.
            </li>
          </ul>
          <p>This approach is useful for:</p>
          <ul>
            <li>clarifying and gaining understanding of the organizational objectives;</li>
            <li>identifying the environment in which these objectives are set;</li>
            <li>
              specifying the main scope and objectives for Risk Management, applicable restrictions or specific
              conditions and the outcomes required;
            </li>
            <li>developing a set of criteria against which the risks will be measured;</li>
            <li>defining a set of key elements for structuring the risk identification and assessment process.</li>
            <li>
              Assisting in ensuring that the organization&apos;s insurance program provides adequate coverage and
              financial protection in line with the risks that have been identified and the risk appetite of the
              organization.
            </li>
          </ul>
        </div>
        {hazardIndex.length > 0 && (
          <>
            <div className="title">
              <h1 className="shape light">{`${(sectionSeq += 1)}.`}</h1>
              <h1>HAZARD ASSESSMENT</h1>
            </div>
            <div className="content">
              <p>
                Identifying hazards in the workplace involves finding things and situations that could potentially cause
                harm to the organization. The following chart is a graphical representation or the likelihood and
                severity of a loss occurring within any of the classes of insurance listed in the chart.
              </p>
              <Chart
                seq={(figureSeq += 1)}
                caption="Hazard Index"
                chartDataUrl={charts['hazard-index']}
                setChartDataUrl={dataUrl => setCharts('hazard-index', dataUrl)}
              >
                <HazardIndex industryName={industryName} data={hazardIndex.reverse()} direction="horizontal" embedded />
              </Chart>
              <Table header={hazardIndexTableData.header}>
                {hazardIndexTableData.data.map(item => (
                  <tr key={item.hazard}>
                    <td className="table-content">{item.hazard}</td>
                    <td className="table-content">{item.rating}</td>
                    <td className="table-content">{item.exposure}</td>
                  </tr>
                ))}
              </Table>
              <p>
                <span>Note: </span>
                These ratings have been developed using historical claims and loss data for a general business operating
                in this field. it is recommended that additional factors specific to your business be taken into account
                when assessing this information such as location and crime rates.
              </p>
              {hazardIndexTableData.data.map(item => (
                <Fragment key={item.hazard}>
                  <h2>{`${sectionSeq}.${item.seq} Hazard ${item.seq}: ${item.hazard}`}</h2>
                  <Table header={[{ name: item.hazard, span: 2 }]}>
                    <tr>
                      <td className="table-content" rowSpan="2" width="30%">
                        <Rating percent={item.rating} />
                      </td>
                      <td className="table-top-action-row" width="70%">
                        Exposure
                      </td>
                    </tr>
                    <tr>
                      <td className="table-content">{item.exposure}</td>
                    </tr>
                    <tr>
                      <td className="table-top-action-row" colSpan="2">
                        Risk Summary
                      </td>
                    </tr>
                    <tr>
                      <td className="table-content" colSpan="2" dangerouslySetInnerHTML={{ __html: item.summary }} />
                    </tr>
                  </Table>
                </Fragment>
              ))}
            </div>
          </>
        )}
        <div className="title">
          <h1 className="shape light">{`${(sectionSeq += 1)}.`}</h1>
          <h1>RISK EXPOSURES</h1>
        </div>
        <div className="content">
          <p>
            This is not a complete and exhaustive list which may be found in businesses falling into this occupation
            category. It is intended as a general guide only and is limited to insurable risk typically found in the
            specific occupation.
          </p>
          <Table header={riskExposureTableData.header}>
            {riskExposureTableData.data.map(item => (
              <tr key={item.id}>
                <td className="table-content">{item.name}</td>
              </tr>
            ))}
          </Table>
        </div>
        <div className="title">
          <h1 className="shape light">{`${(sectionSeq += 1)}.`}</h1>
          <h1>RISK AND THREAT ASSESSMENT</h1>
        </div>
        <div className="content">
          <p>
            Assessing risk involves considering what could happen if someone is exposed to a hazard and the likelihood
            of it happening. Carrying out a risk and threat assessment can help you evaluate the potential risks that
            may be involved in an activity or undertaking. The following bubble chart provides a graphical
            representation of the organization&apos;s monetary exposure to an adverse impact against time. The graph
            shows both your total and annualized monetary exposure.
          </p>
          <p>
            The table following each bubble chart provides a summary of the likelihood and impact for the risks
            identified under the particular broad category of risk, how the risk is being managed, the person
            responsible for managing that particular risk and a review date(s).
          </p>
          <Chart
            seq={(figureSeq += 1)}
            caption="Risk Analysis"
            chartDataUrl={charts['risk-analysis']}
            setChartDataUrl={dataUrl => setCharts('risk-analysis', dataUrl)}
          >
            <RaChart
              risks={risks}
              riskCategories={riskCategories}
              riskLikelihoodPresets={riskLikelihoodPresets}
              embedded
            />
          </Chart>
          <h3>Risk Determination Results (Calculation/Valuation)</h3>
          <p>
            The determination of risk for a particular threat source was expressed as a function of the Likelihood
            determination and Impact value.
          </p>
          <Table header={riskTableData.header}>
            {riskTableData.data.map(item => (
              <Fragment key={item.id}>
                <tr>
                  <td className="table-top-action-row" colSpan="4">
                    {item.categoryName}
                  </td>
                </tr>
                {item.risks.map(riskObj => (
                  <tr key={riskObj.id}>
                    <td className="table-content">{riskObj.name}</td>
                    <td className="table-content">{riskObj.likelihood}</td>
                    <td className="table-content">{riskObj.cost}</td>
                    <td className="table-content">{riskObj.annualisedValue}</td>
                  </tr>
                ))}
                <tr key="total">
                  <td className="table-content">
                    <strong>Total</strong>
                  </td>
                  <td className="table-content" />
                  <td className="table-content" />
                  <td className="table-content">
                    <strong>{item.totalAnnualisedValue}</strong>
                  </td>
                </tr>
              </Fragment>
            ))}
          </Table>
          <h3>Risk Mitigation Insurance Strategy Results</h3>
          <p>
            During this step of the process, controls that could mitigate or eliminate the identified risks, as
            appropriate to the organization&apos;s operations, were provided. The goal of the recommended controls is to
            reduce the level of risk to an acceptable level.
          </p>
          <Table header={riskTableData.mitigationHeader}>
            {riskTableData.data.map(item => (
              <Fragment key={item.id}>
                {item.risks.filter(riskObj => riskObj.insurance === 'Yes').length > 0 && (
                  <tr>
                    <td className="table-top-action-row" colSpan="3">
                      {item.categoryName}
                    </td>
                  </tr>
                )}
                {item.risks
                  .filter(riskObj => riskObj.insurance === 'Yes')
                  .map(riskObj => (
                    <tr key={riskObj.id}>
                      <td className="table-content">{riskObj.name}</td>
                      <td className="table-content">{riskObj.mitigationStrategy}</td>
                      <td className="table-content">{riskObj.annualisedValue}</td>
                    </tr>
                  ))}
              </Fragment>
            ))}
          </Table>
          {riskTableData.data.map(item => (
            <Fragment key={item.id}>
              <h2>{`${sectionSeq}.${item.seq} ${item.categoryName}`}</h2>
              <p dangerouslySetInnerHTML={{ __html: item.description }} />
              <Chart
                seq={(figureSeq += 1)}
                caption={item.categoryName}
                chartDataUrl={charts[item.categoryName]}
                setChartDataUrl={dataUrl => setCharts(item.categoryName, dataUrl)}
              >
                <RaChart
                  risks={risks}
                  riskCategories={riskCategories}
                  riskLikelihoodPresets={riskLikelihoodPresets}
                  category={{ categoryId: item.id, categoryName: item.categoryName }}
                  embedded
                />
              </Chart>
              {item.risks
                .filter(riskObj => riskObj.insurance === 'Yes')
                .map(riskObj => (
                  <Fragment key={riskObj.id}>
                    <h3>{`${sectionSeq}.${item.seq}.${riskObj.seq} ${riskObj.name}`}</h3>
                    <p dangerouslySetInnerHTML={{ __html: riskObj.description }} />
                    <Table header={[{ name: riskObj.name, span: 3 }]}>
                      <tr>
                        <td className="table-top-action-row" width="34%">
                          Likelihood
                        </td>
                        <td className="table-top-action-row" width="33%">
                          Loss Value
                        </td>
                        <td className="table-top-action-row" width="33%">
                          Annualised Value
                        </td>
                      </tr>
                      <tr>
                        <td className="table-content">{riskObj.likelihood}</td>
                        <td className="table-content">{riskObj.cost}</td>
                        <td className="table-content">{riskObj.annualisedValue}</td>
                      </tr>
                      <tr>
                        <td className="table-top-action-row">Mitigation Strategy</td>
                        <td className="table-top-action-row">Insurance</td>
                        <td className="table-top-action-row">Mitigation Status</td>
                      </tr>
                      <tr>
                        <td className="table-content">{riskObj.mitigationStrategy}</td>
                        <td className="table-content">{riskObj.insurance}</td>
                        <td className="table-content">{riskObj.mitigationStatus}</td>
                      </tr>
                      <tr>
                        <td className="table-top-action-row" colSpan="3">
                          Mitigation Strategy Approach
                        </td>
                      </tr>
                      <tr>
                        <td className="table-content" colSpan="3">
                          {riskObj.approach}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-top-action-row" colSpan="3">
                          Comments
                        </td>
                      </tr>
                      <tr>
                        <td className="table-content" colSpan="3">
                          {riskObj.comments}
                        </td>
                      </tr>
                    </Table>
                  </Fragment>
                ))}
            </Fragment>
          ))}
        </div>
        <div className="title">
          <h1 className="shape light">{`${(sectionSeq += 1)}.`}</h1>
          <h1>UNDER INSURANCE</h1>
        </div>
        <div className="content">
          <p>
            It is important to understand that two of the key coverages available in your business insurance, namely;
            Material Damage &amp; Business Interruption operate differently than many other policies such as your home
            and contents policy for example.
          </p>
          <p>
            The most notable difference is due to what is called the Average clause sometime referred to as the
            Co-Insurance clause. This clause effectively works out the proportion of the risk you have insured for and
            will pay only that proportion of any valid claim considering you to personally be the insurer of the other
            portion &apos;the co-insurer&apos;, even in a partial loss. It is vitally important that you understand
            this, as being under insured even in very large businesses can quickly lead to business failure or, at best,
            financial stress.
          </p>
          <p>
            The following example shows the full impact of being under-insured on a partial loss. The formula on a
            Material Damage or Business Interruption policy with 80% co-insurance/average (most insurance policies will
            give you a 20% buffer so as not to penalise you for an accidentally underestimating the true value at risk,
            they understand that it is not an easy task and have provided this tolerance because of this.)
          </p>
          <p>
            <span>Please note: </span>
            This is not the hard and fast rule however and you should always do your best to insurer fully
          </p>
          <p className="flex">
            <span className="formula formula1" />
            <span className="formula formula2" />
          </p>
          <p>
            If you selected $1,000,000 as the Sum Insured under either your Material Damage or Business Interruption
            Insurance Policy, but the value at risk (that is what you should have insured for to be fully protected) was
            say $2,000,000 with a loss of $250,000, the claim would be adjusted as follows.
          </p>
          <p className="flex">
            <span className="formula formula3" />
            <span className="formula formula4" />
          </p>
          <p>
            You can test the actual penalty you may suffer by using the Free LMI Group app - LMi Mobile, which has a
            calculator or speak to your Insurance Broker.
          </p>
        </div>
        <div className="title">
          <span className="shape" />
          <h1>CONCLUSION </h1>
        </div>
        <div className="content">
          <p>
            The next Risk and Threat Assessment Review for &apos;{businessName}&apos; is scheduled for{' '}
            {riskReport.riskReportReview &&
              moment(createdAt)
                .add(Number.parseInt(riskReport.riskReportReview.value, 10), 'months')
                .format('DD/MM/YYYY')}
          </p>
          <p>
            What is the maximum period your organization could be effected by a catastrophic event causing massive loss
            or damage?
          </p>
          <p>
            <strong>{indemnityPeriod ? indemnityPeriod.value : null}</strong>
          </p>
          <p>How much would you need to fast track your recovery?</p>
          <p>
            <strong>
              <CurrencyFormat value={fastTrackRecovery} displayType="text" thousandSeparator prefix={`${currency} `} />
            </strong>
          </p>
          <p>When would you like to schedule a review of your risk and threat assessment Answers?</p>
          <p>
            <strong>{riskReportReview ? riskReportReview.value : null}</strong>
          </p>
        </div>
      </div>
      <section>
        <div className="risk-report-preview-back-cover">
          <div className="company-intro">
            <div className="introduction">
              ContinuityCoach.com is structured and is updated where appropriate to reflect current best Business
              Continuity Management practice as stated in Business Continuity Institute Good Practice Guidelines &amp;
              International Organization for Standardization ISO 22301 Societal Security --- Business Continuity
              Management Systems --- Requirements.
            </div>
            <Logo className="logo" />
          </div>
          <div className="company-info">
            <ul>
              <li>
                <span>Support@ContinuityCoach.com</span>
                <Icon type="mail" />
              </li>
              <li>
                <span>www.ContinuityCoach.com</span>
                <Icon type="home" />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = state => ({
  businessName: businessNameSelector(state),
  industryName: industryNameSelector(state),
  hazardIndex: hazardIndexSelector(state),
  risks: risksSelector(state),
  riskCategories: riskCategoriesSelector(state),
  riskLikelihoodPresets: riskLikelihoodPresetsSelector(state),
  riskExposures: riskExposuresSelector(state),
  riskReport: riskReportSelector(state),
  currency: currencySelector(state)
});

export default connect(mapStateToProps)(Main);
