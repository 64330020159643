import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select, Button } from 'antd';
import { rolesSelector, loadingRolesSelector } from '../../../OrganisationSelectors';

const FormItem = Form.Item;
const { Option } = Select;

const OrganisationUserEdit = ({ form, roles = [], user, loading, inviteUser, updateUser, close }) => {
  const { getFieldDecorator } = form;

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (user) {
          updateUser(user.userId, values);
        } else {
          inviteUser(values);
        }
        close();
      }
    });
  };

  return (
    <Form>
      {!user && (
        <FormItem label="Email">
          {getFieldDecorator('email', {
            rules: [
              { type: 'email', message: 'Please input an valid email.' },
              { required: true, message: 'Please input the email.' }
            ]
          })(<Input placeholder="Email" disabled={loading} spellCheck={false} />)}
        </FormItem>
      )}
      <FormItem label="Role">
        {getFieldDecorator('roleId', {
          initialValue: user ? user.roleId : null,
          rules: [{ required: true, message: 'Please select the role.' }]
        })(
          <Select placeholder="Role" disabled={loading}>
            {roles.map(item => (
              <Option key={item.id}>{item.name}</Option>
            ))}
          </Select>
        )}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loading} onClick={onSubmit}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  roles: rolesSelector(state),
  loading: loadingRolesSelector(state)
});

export default connect(mapStateToProps)(Form.create()(OrganisationUserEdit));
