import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import BusinessCreateWizard from './components/BusinessCreateWizard';
import OrganisationList from './components/OrganisationList/OrganisationList';
import OrganisationUser from './components/OrganisationUser/OrganisationUser';
import Setting from './scenes/Setting/Setting';
import Contact from './scenes/Contact/Contact';
import Customer from './scenes/Customer/Customer';
import Supplier from './scenes/Supplier/Supplier';
import StrategicThirdParty from './scenes/StrategicThirdParty/StrategicThirdParty';
import StrategicCrisisExpert from './scenes/StrategicThirdParty/StrategicCrisisExpert';
import Product from './scenes/Product/Product';
import Policy from './scenes/Policy/Policy';
import Plan from '../Dashboard/scenes/Plan/Plan';
import { organisationUserRoleSelector } from '../Admin/scenes/User/UserSelectors';
import './Organisation.scss';

const routes = Object.freeze([
  { route: 'list', component: <OrganisationList /> },
  { route: 'contacts', component: <Contact /> },
  { route: 'customers', component: <Customer /> },
  { route: 'suppliers', component: <Supplier /> },
  { route: 'strategic-areas', component: <Product /> },
  { route: 'strategic-third-parties', component: <StrategicThirdParty /> },
  { route: 'strategic-crisis-experts', component: <StrategicCrisisExpert /> },
  { route: 'policy', component: <Policy /> },
  { route: 'plans', component: <Plan /> }
]);

const Organisation = props => {
  const { match, businessInfo, userRole } = props;
  return (
    <Layout hasSider>
      {'name' in businessInfo ? (
        <Switch>
          <Route exact path={`${match.url}`} component={Setting} />
          {routes.map(item => {
            const componentToRender = React.cloneElement(item.component, props);
            return (
              <Route
                key={item.route}
                path={`${match.url}/${item.route}`}
                render={() => <ErrorBoundary>{componentToRender}</ErrorBoundary>}
              />
            );
          })}
          {userRole && (userRole === 'owner' || userRole === 'broker') && (
            <Route
              path={`${match.url}/users`}
              render={() => (
                <ErrorBoundary>
                  <OrganisationUser />
                </ErrorBoundary>
              )}
            />
          )}
        </Switch>
      ) : (
        <BusinessCreateWizard />
      )}
    </Layout>
  );
};

const mapStateToProps = ({ userReducer, organisationReducer }) => ({
  userRole: organisationUserRoleSelector({ userReducer, organisationReducer }),
  ...organisationReducer
});

export default connect(mapStateToProps)(Organisation);
