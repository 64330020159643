import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_CUSTOMERS: 'customer/REQUEST_CUSTOMERS',
  RECEIVE_CUSTOMERS: 'customer/RECEIVE_CUSTOMERS'
};

const requestCustomers = () => ({ type: types.REQUEST_CUSTOMERS });
const receiveCustomers = data => ({ type: types.RECEIVE_CUSTOMERS, customerList: data });

export const fetchCustomer = orgId => async dispatch => {
  dispatch(requestCustomers());
  const url = `${API_BASE}orgs/${orgId}/customers`;
  try {
    const res = await axios.get(url);
    dispatch(receiveCustomers(res.data));
  } catch (err) {
    dispatch(receiveCustomers());
  }
};

export const createCustomer = (orgId, data) => async dispatch => {
  dispatch(requestCustomers());
  const url = `${API_BASE}orgs/${orgId}/customers`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchCustomer(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveCustomers());
    return Promise.reject(err);
  }
};

export const updateCustomer = (orgId, customerId, data) => async dispatch => {
  dispatch(requestCustomers());
  const url = `${API_BASE}orgs/${orgId}/customers/${customerId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchCustomer(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveCustomers());
    return Promise.reject(err);
  }
};

export const deleteCustomer = (orgId, customerId) => async dispatch => {
  dispatch(requestCustomers());
  const url = `${API_BASE}orgs/${orgId}/customers/${customerId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchCustomer(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveCustomers());
    return Promise.reject(err);
  }
};

export const importCustomer = (orgId, file) => async dispatch => {
  dispatch(requestCustomers());
  const url = `${API_BASE}orgs/${orgId}/customers/import`;
  try {
    const res = await axios.post(url, file);
    dispatch(fetchCustomer(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveCustomers());
    return Promise.reject(err);
  }
};

export const downloadCustomerImportTemplate = () => () => {
  const url = `${API_BASE}customer_import_template`;
  return axios.get(url, { responseType: 'arraybuffer' });
};

export const exportCustomers = orgId => () => {
  const url = `${API_BASE}orgs/${orgId}/customers/export`;
  return axios.get(url, { responseType: 'blob' });
};
