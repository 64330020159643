import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { ReactComponent as LockIcon } from './assets/lockIcon.svg';
import { setShowNotification } from '../../scenes/Admin/scenes/User/UserActions';

import './TwoFactorBanner.scss';

const TwoFactorBanner = () => {
  const dispatch = useDispatch();

  const closeBanner = () => dispatch(setShowNotification(false));

  return (
    <div className="two-factor-banner">
      <div className="close-icon-container">
        <Icon type="close" className="close-icon" onClick={closeBanner} />
      </div>
      <div className="arrow-top" />
      <div className="heading">
        <h3>Two-Factor Authentication Is On</h3>
        <Icon component={LockIcon} className="lock-icon" />
      </div>
      <Link to="/admin/settings" onClick={closeBanner}>
        <div className="contents">
          If you would like to change from email authentication to SMS-based authentication, please navigate to{' '}
          <span className="underline">Admin &gt; Settings</span> and add your phone number.
        </div>
      </Link>
    </div>
  );
};

export default TwoFactorBanner;
