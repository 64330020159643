import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, InputNumber, Button, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import { createAssets, updateAssets } from '../../../BiaAction';
import { planIdSelector } from '../../../../Plan/PlanSelectors';
import { loadingAssetsSelector } from '../../../BiaSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../../../constants';

const FormItem = Form.Item;
const { TextArea } = Input;

const AssetEditModal = ({
  form,
  planId,
  assetInfo,
  loading,
  departmentId,
  activeTypeId,
  createAssets,
  updateAssets,
  close
}) => {
  const { getFieldDecorator } = form;

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          acc[key] =
            fieldsValue[key] && typeof fieldsValue[key] === 'string' ? fieldsValue[key].trim() : fieldsValue[key];
          return acc;
        }, {});
        const data = { ...values, departmentId, assetTypeId: activeTypeId };
        try {
          if (assetInfo) {
            data.assetId = assetInfo.assetId;
            await updateAssets(planId, [data]);
          } else {
            await createAssets(planId, [data]);
          }
          message.success(MSG_SAVE_SUCCESS);
          close();
        } catch (err) {
          switch (err.status) {
            case HttpStatus.PAYMENT_REQUIRED:
              message.error(MSG_PAYMENT_REQUIRED);
              break;
            default:
              message.error(MSG_ERROR);
          }
        }
      }
    });
  };

  return (
    <Form>
      <FormItem label="Description">
        {getFieldDecorator('name', {
          initialValue: assetInfo ? assetInfo.name : null,
          rules: [{ required: true, message: 'Please input description' }]
        })(<Input placeholder="Description" disabled={loading} />)}
      </FormItem>
      <FormItem label="Quantity">
        {getFieldDecorator('quantity', {
          initialValue: assetInfo ? assetInfo.quantity : null,
          rules: [{ required: true, message: 'Please input quantity' }]
        })(<InputNumber min={0} placeholder="Quantity" disabled={loading} style={{ width: '100% ' }} />)}
      </FormItem>
      <FormItem label="Comments">
        {getFieldDecorator('comments', {
          initialValue: assetInfo ? assetInfo.comments : null
        })(<TextArea rows={1} placeholder="Comments" disabled={loading} />)}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loading} onClick={onSubmit}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  planId: planIdSelector(state),
  loading: loadingAssetsSelector(state)
});

const mapDispatchToProps = { createAssets, updateAssets };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AssetEditModal));
