import React from 'react';
import { Button } from 'antd';
import Table from '../../../../../components/Table/Table';

export default ({ organisationInvitees, resendInvitation }) => {
  const columns = [
    { key: 'email', title: 'Email', dataIndex: 'email', width: 200, span: 2 },
    { key: 'firstName', title: 'First Name', dataIndex: 'firstName' },
    { key: 'lastName', title: 'Last Name', dataIndex: 'lastName' },
    { key: 'addedBy', title: 'Added By', dataIndex: 'addedByEmail' },
    {
      key: 'action',
      render: (text, record) => (
        <Button type="link" onClick={() => resendInvitation(record)}>
          Resend Invitation
        </Button>
      )
    }
  ];

  return (
    <Table
      title="Invitees"
      loading={!organisationInvitees}
      columns={columns}
      dataSource={organisationInvitees}
      rowKey="userId"
      style={{ paddingBottom: '20px' }}
    />
  );
};
