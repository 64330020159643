import { types } from './BranchAction';
import { types as organisationTypes } from '../../OrganisationAction';

const initialState = { loadingBranches: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case organisationTypes.SWITCH_ORGANISATION:
      return { ...initialState };
    case types.REQUEST_BRANCHES:
      return { ...state, loadingBranches: true };
    case types.RECEIVE_BRANCHES:
      return { ...state, loadingBranches: false, branches: action.branches };
    default:
      return state;
  }
};
