import React, { useState } from 'react';
import { Input, Button } from 'antd';

export default ({ plan, deletePlan, close }) => {
  const [planNameEntered, setPlanNameEntered] = useState();

  const onChange = e => {
    setPlanNameEntered(e.target.value);
  };

  const onDelete = () => {
    deletePlan(plan.planId);
    close();
  };

  return plan ? (
    <>
      <p>
        This action cannot be undone. This will permanently delete the <strong>{plan.name}</strong> plan.
      </p>
      <p>Please type in the name of the plan to confirm.</p>
      <Input onChange={onChange} />
      <div className="btn-container no-padding">
        <Button type="danger" onClick={onDelete} disabled={plan.name !== planNameEntered}>
          Confirm
        </Button>
      </div>
    </>
  ) : null;
};
