import React, { Component } from 'react';
import { Form, Row, Col, Input, Button } from 'antd';
import AddressSearchBox from '../../../../components/AddressSearchBox/AddressSearchBox';

const FormItem = Form.Item;

class BillingAddress extends Component {
  onSubmit = e => {
    e.preventDefault();
    const { form, next } = this.props;
    form.validateFields(async err => {
      if (!err) {
        next();
      }
    });
  };

  render() {
    const { form, prev } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className="purchase-form">
        <Form onSubmit={this.onSubmit} style={{ width: 370 }}>
          <Row gutter={20}>
            <Col span={24}>
              <FormItem label="Billing Address">
                {getFieldDecorator('address', {
                  rules: [{ required: true }],
                  normalize: value => (typeof value === 'object' ? value.address : value)
                })(<AddressSearchBox placeholder="Billing Address" separate />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={16}>
              <FormItem label="City/Suburb">
                {getFieldDecorator('city', { rules: [{ required: true }] })(
                  <Input placeholder="City/Suburb" spellCheck={false} />
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={8}>
              <FormItem label="Postcode">
                {getFieldDecorator('postcode', { rules: [{ required: true }] })(
                  <Input placeholder="Postcode" spellCheck={false} />
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem label="State">
                {getFieldDecorator('state', { rules: [{ required: true }] })(<Input placeholder="State" />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem label="Country">
                {getFieldDecorator('country', { rules: [{ required: true }] })(<Input placeholder="Country" />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
        <div className="btn-container space-between no-padding">
          <Button type="primary" ghost icon="arrow-left" onClick={prev}>
            Edit Orders
          </Button>
          <Button id="payway-cc-submit" type="primary" onClick={this.onSubmit}>
            Continue
          </Button>
        </div>
      </div>
    );
  }
}

export default Form.create({
  mapPropsToFields(props) {
    const { billingAddress } = props;
    if (billingAddress) {
      return {
        address: Form.createFormField({ value: billingAddress.address }),
        city: Form.createFormField({ value: billingAddress.city }),
        postcode: Form.createFormField({ value: billingAddress.postcode }),
        state: Form.createFormField({ value: billingAddress.state }),
        country: Form.createFormField({ value: billingAddress.country })
      };
    }
    return null;
  },
  onValuesChange(props, changedValues) {
    if (changedValues.address && typeof changedValues.address === 'object') {
      props.updateBillingAddress(changedValues.address);
    } else {
      props.updateBillingAddress(changedValues);
    }
  }
})(BillingAddress);
