import { types } from './TransactionActions';
import { USER_LOGOUT } from '../../../../components/Login/LoginAction';

const initialState = { loadingTransactions: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initialState };
    case types.REQUEST_TRANSACTIONS:
      return { ...state, loadingTransactions: true };
    case types.RECEIVE_TRANSACTIONS:
      return { ...state, loadingTransactions: false, transactions: action.transactions };
    default:
      return state;
  }
};
