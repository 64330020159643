import React from 'react';
import { Row, Col } from 'antd';

const DEFAULT_HEIGHT = 16;
const PROCESS = {
  risk_analysis: { name: 'Risk Analysis', color: '#a8c08f' },
  policy_programme: { name: 'Program', color: '#5bc2e7' },
  analysis_planning: { name: 'Analysis', color: '#343579' },
  planning_design: { name: 'Design', color: '#f1c400' },
  implementation: { name: 'Implementation', color: '#cb2c30' },
  validation: { name: 'Validation', color: '#00685e' }
};

const ProgressBar = ({ height, progress, color, title }) => (
  <Row type="flex" justify="space-between" align="middle" style={{ flex: 1 }}>
    <Col
      span={16}
      style={{
        height: `${height}px`,
        borderRadius: `${height / 2}px`,
        backgroundColor: '#eee'
      }}
    >
      <div
        style={{
          width: `${progress}%`,
          height: '100%',
          borderRadius: `${height / 2}px`,
          backgroundColor: color,
          transition: 'width .4s ease-out'
        }}
      />
    </Col>
    <Col span={2} style={{ color }}>
      {progress}%
    </Col>
    <Col
      span={1}
      style={{
        width: `${height}px`,
        height: `${height}px`,
        borderRadius: `${height}px`,
        backgroundColor: color
      }}
    />
    <Col span={4} style={{ textTransform: 'capitalize' }}>
      {title}
    </Col>
  </Row>
);

ProgressBar.defaultProps = {
  height: DEFAULT_HEIGHT
};

const ProgressItem = ({ color, title, progress }) => (
  <div
    style={{
      height: '100%',
      padding: '20px 10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      color,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 700
    }}
  >
    <div>{title}</div>
    <div>{progress}%</div>
  </div>
);

export default ({ progress }) => {
  const progressList = [];
  Object.entries(PROCESS).forEach(([key, value]) => {
    if (key in progress) {
      const { name, color } = value;
      progressList.push({ key, name, color, progress: Math.min((progress[key] * 100).toFixed(0), 100) });
    }
  });

  return (
    <div className="widget">
      <div
        style={{
          height: '360px',
          padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          borderBottom: '1px solid #d9d9d9'
        }}
      >
        {progressList.map(data => (
          <ProgressBar key={data.key} progress={data.progress} title={data.name} color={data.color} />
        ))}
      </div>
      <Row type="flex" justify="space-around" align="middle" style={{ height: '140px' }}>
        {progressList.map(data => (
          <Col span={4} key={data.key}>
            <ProgressItem progress={data.progress} title={data.name} color={data.color} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
