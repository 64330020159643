import React from 'react';
import PropTypes from 'prop-types';
import { Affix } from 'antd';

const style = {
  container: {
    minHeight: '360px',
    maxHeight: '88vh',
    padding: '20px 10px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    overflowY: 'auto'
  },
  title: {
    marginBottom: '10px',
    fontWeight: 700
  },
  description: {
    fontSize: '12px'
  }
};

const InformationPanel = ({ title, description }) => (
  <Affix>
    <div style={style.container}>
      <div style={style.title}>{title}</div>
      {typeof description === 'string' ? (
        <div style={style.description} dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <div style={style.description}>{description}</div>
      )}
    </div>
  </Affix>
);

InformationPanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

InformationPanel.defaultProps = {
  title: '',
  description: ''
};

export default InformationPanel;
