import { types } from './DepartmentAction';

const initialState = { loadingDepartments: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_DEPARTMENTS:
      return { ...state, loadingDepartments: true };
    case types.RECEIVE_DEPARTMENTS:
      return { ...state, loadingDepartments: false, departmentList: action.departmentList };
    default:
      return state;
  }
};
