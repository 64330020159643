import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Avatar } from 'antd';
import MenuSwitch from './components/MenuSwitch';
import PlanMenu from './components/PlanMenu';
import OrganisationMenu from './components/OrganisationMenu';
import { organisationUserRoleSelector } from '../../scenes/Admin/scenes/User/UserSelectors';
import './Sider.scss';

const MENU_KEY_ORG = '/organization';

const Sider = ({ match, location, userRole, businessInfo, currentPlan, collapsed }) => {
  const [subMenu, setSubMenu] = useState(match.url);
  const businessName = businessInfo.name;
  const logo = businessInfo.avatarUrl;
  const selectedKey = location.pathname;
  return (
    'name' in businessInfo && (
      <>
        <div className="sider-top">
          <Avatar size="large" icon="home" src={logo} className="sider-top-logo" />
          <div className="sider-top-title">{businessName || 'Create Business'}</div>
        </div>
        <MenuSwitch collapsed={collapsed} selectedKey={subMenu || match.url} onChange={({ key }) => setSubMenu(key)} />
        {subMenu === MENU_KEY_ORG ? (
          <>
            {!collapsed && (
              <div className="sider-plan">
                <div className="sider-plan-name">{businessInfo ? businessInfo.name : 'No Organisation'}</div>
                <Link to="/organization/list">{businessInfo ? 'Change Organisation' : 'Select Organisation'}</Link>
              </div>
            )}
            <OrganisationMenu selectedKey={selectedKey} userRole={userRole} />
          </>
        ) : (
          <>
            {!collapsed && (
              <div className="sider-plan">
                <div className="sider-plan-name">
                  {currentPlan && 'planId' in currentPlan ? currentPlan.name : 'No Plan'}
                </div>
                <Link to="/dashboard/plans">
                  {currentPlan && 'planId' in currentPlan ? 'Change Plan' : 'Select Plan'}
                </Link>
              </div>
            )}
            {currentPlan && 'planId' in currentPlan && <PlanMenu selectedKey={selectedKey} />}
          </>
        )}
      </>
    )
  );
};

const mapStateToProps = state => {
  const { organisationReducer, planReducer } = state;
  return {
    userRole: organisationUserRoleSelector(state),
    businessInfo: organisationReducer.businessInfo,
    currentPlan: planReducer.currentPlan
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Sider)
);
