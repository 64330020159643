import React from 'react';

export default () => (
  <article className="terms-conditions-modal-inner">
    <h1 className="border">THE PROCESSING OF PERSONAL DATA: AGREEMENT</h1>
    <p>(GDPR and EU Standard Contractual Clauses)</p>
    <p>
      <strong>This Agreement is made between:</strong>
    </p>
    <p>
      <strong>You</strong> (including Your Authorised Affiliates) as Customer and as Controller of Personal Data
    </p>
    <p>
      <strong>-and-</strong>
    </p>
    <p>
      <strong>Us</strong> as Processor of Personal Data
    </p>
    <p>(&apos;the Parties&apos;)</p>
    <p>
      for Your purchase from Continuity Coach Pty. Ltd. of Our online products and services (including any associated
      offline or mobile components), (the Services), and constitutes the Parties&apos; agreement with regard to the use
      of Our Website and the Processing of Personal Data.
    </p>
    <p>All capitalised terms shall have the meanings set out within this Agreement.</p>
    <p>
      In consideration of the mutual obligations set out below, the Parties hereby agree to the terms and conditions of
      this Agreement.
    </p>
    <p>
      You enter into the Agreement on behalf of Yourself and, to the extent required under applicable Data Protection
      Laws and Regulations (including Applicable Laws as defined herein), in the name and on behalf of Your Authorized
      Affiliates, if and to the extent We process Personal Data for which such Authorized Affiliates qualify as
      Controller. For the purposes of this Agreement only, unless indicated otherwise, the term &quot;Customer&quot;
      shall include Customer and Authorized Affiliates.
    </p>
    <p>
      The Parties acknowledge and agree, as regards the use of Our Website and Processing of Personal Data, to comply
      with the following provisions regarding Personal Data, and act reasonably and in good faith:
    </p>
    <ol>
      <li>
        <strong>Your Processing of Personal Data</strong>
        <p>
          In using Our Services and Website, You will Process Personal Data in accordance with the requirements of all
          relevant Data Protection Laws and Regulations and all Applicable Laws. That is, Your instructions for
          Processing of Personal Data shall comply with all such Laws and Regulations. You have sole responsibility for
          the accuracy, quality and legality (including obtaining all relevant consents) of Personal Data and the method
          You acquired Personal Data.
        </p>
      </li>
      <li>
        <strong>Our Processing of Personal Data</strong>
        <p>
          In providing the Services to You under this Agreement, We may Process Personal Data on Your behalf. We shall
          treat Your Personal Data as confidential information and only Process Personal Data on Your behalf to allow
          You to assess and manage Your risks and in accordance with instructions You provide in writing to Us and/or by
          Your access and/or use of Our Services as agreed with Us. We do not engage sub-processors to undertake any of
          Our processing operations under this Agreement.
        </p>
      </li>
      <li>
        <strong>Details of the Processing</strong>
        <p>
          The subject matter of Processing of Personal Data by Us in performing the Services under this Agreement, as
          well as the duration of the Processing, the nature and purpose of the Processing, the types of Personal Data
          and categories of Data Subjects Processed under this Agreement are specified in{' '}
          <a href="#schedule_one">Schedule 1</a> (Details of the Processing of Your Personal Data) of this Agreement.
        </p>
      </li>
      <li>
        <strong>Data Subject Request</strong>
        <p>
          To the extent legally permitted, We shall promptly notify You if We receive a request from a Data Subject to
          exercise its right of access, right to rectification, restriction of Processing, right of erasure (that is, to
          be forgotten), data portability, object to Processing, or right not to be subject to automated individual
          decision making (&quot;Data Subject Request&quot;). Taking into account the nature of the Processing, We shall
          assist You with appropriate technical and organisational measures, as far as possible, to fulfil Your
          obligation to respond to a Data Subject Request under Data Protection Laws and Regulations. Also, to the
          extent that You, in using the Services, are unable to address a Data Subject Request, We shall on Your
          request, provide commercially reasonable efforts to assist You in responding to such Data Subject Request, to
          the extent We are legally permitted, and the response to such Data Subject Request is required under Data
          Protection Laws and Regulations. You are responsible for any costs of Our provision of such assistance to the
          extent legally permitted.
        </p>
      </li>
      <li>
        <strong>Confidentiality</strong>
        <p>
          We shall ensure that Our personnel engaged in Processing of Personal Data are informed of the confidential
          nature of that Data, have received appropriate training on their responsibilities and have executed written
          confidentiality agreements. We shall also ensure such confidentiality obligations survive termination of Our
          relevant personnel&apos;s engagement.
        </p>
      </li>
      <li>
        <strong>Reliability</strong>
        <p>
          We shall take commercially reasonable steps to ensure the reliability of Our personnel engaged in Processing
          of Personal Data.
        </p>
      </li>
      <li>
        <strong>Limitation of Access</strong>
        <p>
          We shall ensure Our access to Personal Data is limited to the personnel requiring such access to perform their
          duties under this Agreement.
        </p>
      </li>
      <li>
        <strong>Data Protection Officer</strong>
        <p>
          Our data protection officer, appointed under the Data Protection Laws and Regulations, may be reached at
          email: <a href="mailto:dpo@lmigroup.com">DPO@LMIGroup.com</a>
        </p>
      </li>
      <li>
        <strong>Security: controls for protection of Personal Data</strong>
        <p>
          We maintain physical, administrative and technical safeguards designed to protect the security (such as
          protection against: unauthorised or unlawful Processing accidental or unlawful destruction, loss or alteration
          or damage, unauthorised access or disclosure of Customer Data), as well as confidentiality and integrity of
          Customer Data, including Personal Data, in accordance with <a href="#schedule_two">Schedule 2</a> (Standard
          Contractual Clauses). During the term of this Agreement, We will not materially decrease the overall security
          of the Services.
        </p>
      </li>
      <li>
        <strong>Security: breach management and notification</strong>
        <p>
          We maintain security incident management policies and procedures. We shall notify You without undue delay,
          after becoming aware of any accidental or unlawful destruction, loss, alteration, unauthorised access or
          disclosure of, Customer Data, including Personal Data, stored, transmitted or otherwise Processed by Us, of
          which We become aware (&quot;Customer Data Incident&quot;). We shall undertake reasonable endeavours to
          identify the cause of any Customer Data Incident and take such steps as We deem reasonable and necessary to
          remediate that cause (to the extent the remediation is within Our reasonable control). Our obligations shall
          not apply to incidents caused by You or Your Users.
        </p>
      </li>
      <li>
        <strong>Return and Deletion of Customer Data</strong>
        <p>
          To the extent legally permitted, We shall return (where applicable) and delete all Customer Data within twelve
          (12) months from the expiry date of Our agreement with You or in accordance with any other timeframe and
          procedures which may be agreed with You.
        </p>
      </li>
      <li>
        <strong>Additional Terms for Standard Contractual Clauses (SCC) Services</strong>
        <ol type="a">
          <li>
            <span>Customers covered by the Standard Contractual Clauses (SCC)</span>
            <p>
              The SCC and the additional terms set out in this Clause (a) apply to: (i) the legal entity that has
              entered into the SCC as a data exporter and its Authorized Affiliates and, (ii) all Affiliates of Customer
              established within the European Economic Area, Switzerland and the United Kingdom, which have agreed to
              the SCC Services. For the purposes of the SCC and this Clause the above entities are deemed &quot;data
              exporters&quot;.
            </p>
          </li>
          <li>
            <span>Instructions</span>
            <p>
              This Agreement constitutes the Customer&apos;s complete and final instructions at the time of entering the
              Agreement with Us for Processing Personal Data. Any other instructions must be agreed separately. For the
              purposes of Clause 5(a) of the SCC the following is deemed an instruction by the Customer to process
              Personal Data: (a) Processing pursuant to the Agreement; (b) Processing initiated by Users in using the
              SCC Services and (c) Processing to comply with other reasonable instructions provided by Customer (such as
              via email) which are consistent with the terms of the Agreement.
            </p>
          </li>
          <li>
            <span>Audits and Certifications</span>
            <p>
              The Parties agree that the audits described in Clause 5(f) and Clause 11(2) of the SCC shall be carried
              out in accordance with the following provisions: Upon the Customer&apos;s request, and subject to the
              confidentiality obligations in the Agreement, We shall make available to Customer (or Customer&apos;s
              independent, third-party auditor that is not a competitor of Ours and has signed a nondisclosure agreement
              reasonably acceptable to Us) information regarding Our compliance with the obligations under this
              Agreement. Following any notice by Us to the Customer of an actual or reasonably suspected unauthorised
              disclosure of Personal Data, upon Customer&apos;s reasonable belief that We are in breach of Our
              obligations of protection of Personal Data under this Agreement or if such audit is required by the
              Customer&apos;s Supervisory Authority, the Customer may contact Us via email:{' '}
              <a href="mailto:dpo@lmigroup.com">DPO@LMIGroup.com</a> to request an audit at Our premises of the
              procedures relevant to the protection of Personal Data. Any such request shall occur no more than once
              annually, save for any actual or reasonably suspected unauthorised access to Personal Data. Customer shall
              reimburse Us for any time expended for such on-site audit at Our then-current professional services rates,
              which shall be provided to Customer on request. Before commencement of any such on-site audit, We and
              Customer shall mutually agree upon the audit&apos;s scope, timing, and duration as well as reimbursement
              rate for which Customer shall be responsible. Reimbursement rates shall be reasonable, taking account of
              the resources expended by Us. Customer shall promptly notify Us with information regarding any
              non-compliance discovered in the course of an audit.
            </p>
          </li>
          <li>
            <span>Certification of Deletion</span>
            <p>
              The Parties agree that the certification of deletion of Personal Data as described in Clause 11(1) of the
              SCC shall be provided by Us to Customer only upon Customer&apos;s request.
            </p>
          </li>
          <li>
            <span>Conflict</span>
            <p>
              In the event of any conflict or inconsistency between this Agreement and any of its Schedules (not
              including the SCC) and the SCC in Schedule 2 (refer above LINK), the SCC shall prevail.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <strong>Website Terms and Conditions</strong>
        <p>
          By completing this Agreement, you also confirm that You have accepted and agreed to all the{' '}
          <a href="#terms_of_use">&quot;Terms of Use (Terms and Conditions)&quot;</a> specified in Our Website.
        </p>
      </li>
      <li>
        <strong>Definitions</strong>
        <p>
          In this Agreement, the following terms shall have the meanings set out below and similar terms shall be
          construed accordingly.
        </p>
        <p>
          <strong>Affiliate</strong> means any entity that directly or indirectly controls, is controlled by, or is
          under common control with the subject entity. &quot;Control&quot; for purposes of this definition, means
          direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.
        </p>
        <p>
          <strong>Applicable Laws</strong> means (a) European Union or member state laws with respect to Personal Data
          which is subject to EU Data Protection Laws; and (b) any other applicable law with respect to Personal Data
          which is subject to any other Data Protection Laws.
        </p>
        <p>
          <strong>Authorized Affiliate</strong> means any of Customer&apos;s Affiliate(s) which (a) is subject to the
          data protection laws and regulations of the European Union, the European Economic Area and/or their member
          states, Switzerland and/or the United Kingdom, and (b) is permitted to use the Services pursuant to the
          Agreement between Customer and Us, but has not signed an agreement with Us and is not a &quot;Customer&quot;
          as defined in the Agreement.
        </p>
        <p>
          <strong>Continuity Coach Pty. Ltd.</strong> means Us as the Processor of Personal Data under this Agreement.
        </p>
        <p>
          <strong>Controller</strong> means the entity which determines the purposes and means of Processing of Personal
          Data.
        </p>
        <p>
          <strong>Customer Data</strong> means what is defined in the Agreement as Customer Data or Your Data.
        </p>
        <p>
          <strong>Data Protection Laws and Regulations</strong> means all laws and regulations, including laws and
          regulations of the European Union, the European Economic Area and their member states, applicable to the
          Processing of Personal Data under this Agreement.
        </p>
        <p>
          <strong>Data Subject</strong> means the individual to whom Personal Data relates.
        </p>
        <p>
          <strong>GDPR</strong> means the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27
          April 2016 on the protection of natural persons regarding the processing of personal data and on the free
          movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).
        </p>
        <p>
          <strong>Parties</strong> means You and Us.
        </p>
        <p>
          <strong>Personal Data</strong> means any information relating to (a) an identified or identifiable natural
          person and (b) an identified or identifiable legal entity (where such information is protected similarly as
          personal data or personally identifiable information under applicable Data Protection Laws and Regulations),
          where for each (a) or (b), such data is Customer Data.
        </p>
        <p>
          <strong>Processing</strong> means any operation or set of operations which is performed upon Personal Data,
          whether by automatic means or otherwise, such as collection, recording, organisation, storage, adaptation or
          alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making
          available, alignment or combination, blocking, erasure or destruction.
        </p>
        <p>
          <strong>Processor</strong> means the entity which Processes Personal Data on behalf of the Controller.
        </p>
        <p>
          <strong>Standard Contractual Clauses and SCC</strong> means the contractual clauses set out in Schedule 2
          which are entered into by Customer and Us pursuant to the European Commission&apos;s decision of 5 February
          2010 on Standard Contractual Clauses for the transfer of personal data to processors established in third
          countries which do not ensure an adequate level of data protection.
        </p>
        <p>
          <strong>Supervisory Authority</strong> means an independent public authority which is established by an EU
          member state pursuant to the GDPR.
        </p>
        <p>
          <strong>We, Us, Our</strong> means Continuity Coach Pty. Ltd.
        </p>
        <p>
          <strong>You, Your, Yours</strong> means the Customer and Controller and includes Your Users, related entities
          and Authorised Affiliate(s).
        </p>
        <p>
          The terms &quot;commission&quot; and &quot;member state&quot; shall have the same meaning as in the GDPR, and
          their like terms shall be construed accordingly.
        </p>
      </li>
      <li>
        <strong>Legal Effect</strong>
        <p>
          This Agreement shall only become legally binding between the Parties when You have carefully considered and
          accepted all its provisions and You have provided your consent and acceptance via Continuity Coach Pty.
          Ltd.&apos;s online registration process.
        </p>
        <p>
          As Processor, Continuity Coach Pty. Ltd. confirms that this Agreement commences upon the due and proper
          completion of Your online registration.
        </p>
      </li>
    </ol>
    <p>
      <strong>List of Schedules/Appendices</strong>
    </p>
    <p>
      <strong>Schedule 1</strong>: Details of the Processing of Your Personal Data
    </p>
    <p>
      <strong>Schedule 2</strong>: Standard Contractual Clauses
    </p>
    <p>
      <strong>Appendix 1</strong> to the Standard Contractual Clauses
    </p>
    <p>
      <strong>Appendix 2</strong> to the Standard Contractual Clauses
    </p>
    <div className="break" />
    <h1 id="schedule_one">SCHEDULE 1 - DETAILS OF THE PROCESSING OF YOUR PERSONAL DATA</h1>
    <p>
      <strong>Nature and Purpose of Processing</strong>
    </p>
    <p>
      Continuity Coach Pty Ltd (We/Us) will process Personal Data as necessary to perform the Services pursuant to this
      Agreement and as instructed by You, including the provision to You of Our online storage vault which enables You
      to:
    </p>
    <ul>
      <li>Input, record and manage Your data in a private and secure online environment.</li>
      <li>
        Enter details including Personal Data of Your employees and third parties for retrieval and use as and when You
        require, such as Your assignment of specific tasks to employees, the management and mitigation of Your business
        risks.
      </li>
      <li>
        Access Your own safe online environment for other purposes without the need to input Personal Data if required.
      </li>
    </ul>
    <p>
      <strong>Duration of Processing</strong>
    </p>
    <p>
      We will Process Personal Data for the duration of this Agreement, unless otherwise agreed with You in writing.
    </p>
    <p>
      <strong>Categories of Data Subjects</strong>
    </p>
    <p>
      You may submit Personal Data to Us (as determined and controlled by You in Your sole discretion) which may include
      (but is not limited to) Personal Data relating to the following categories of data subjects:
    </p>
    <ul>
      <li>You, Your business partners, vendors and subcontractors (who are natural persons);</li>
      <li>Employees or contact persons of Your customers, business partners, vendors and subcontractors;</li>
      <li>Employees, agents, advisors and contractors of Yours (who are natural persons); and</li>
      <li>Your Users, as authorized by You to use the Services.</li>
    </ul>
    <p>
      <strong>Type of Personal Data</strong>
    </p>
    <p>
      You may submit Personal Data to Us (as determined and controlled by You in Your sole discretion) which may include
      (but is not limited to) the following categories of Personal Data:
    </p>
    <ul>
      <li>First and Last name</li>
      <li>Title</li>
      <li>Position</li>
      <li>Employer</li>
      <li>Contact information (company, email, phone, physical business address)</li>
    </ul>
    <div className="break" />
    <h1 id="schedule_two">SCHEDULE 2 - STANDARD CONTRACTUAL CLAUSES</h1>
    <p>
      <strong>Standard Contractual Clauses (processors)</strong>
    </p>
    <p>
      For the purposes of Article 26(2) of Directive 95/46/EC for the transfer of personal data to processors
      established in third countries which do not ensure an adequate level of data protection
    </p>
    <p>
      You, as Controller and Customer pursuant to the Agreement with Us and as fully identified by You on entering this
      Agreement via the details of Your online Registration
    </p>
    <p>(the data exporter)</p>
    <br />
    <p>And</p>
    <p>
      Us, Continuity Coach Pty. Ltd., as Processor pursuant to this Agreement and as fully identified via Our Website,
      Our Terms of Use (Terms &amp; Conditions) and Your online Registration
    </p>
    <p>(the data importer)</p>
    <p>each a &quot;party&quot;; together &quot;the parties&quot;,</p>
    <br />
    <p>
      HAVE AGREED on the following Contractual Clauses (the Clauses) in order to adduce adequate safeguards with respect
      to the protection of privacy and fundamental rights and freedoms of individuals for the transfer by the data
      exporter to the data importer of the personal data specified in <a href="#appendix_one">Appendix 1</a>.
    </p>
    <em className="center">
      Clause 1<br />
      <strong>Definitions</strong>
    </em>
    <p>For the purposes of the Clauses:</p>
    <ol type="a">
      <li>
        &apos;personal data&apos;, &apos;special categories of data&apos;, &apos;process/processing&apos;,
        &apos;controller&apos;, &apos;processor&apos;, &apos;data subject&apos; and &apos;supervisory authority&apos;
        shall have the same meaning as in Directive 95/46/EC of the European Parliament and of the Council of 24 October
        1995 on the protection of individuals with regard to the processing of personal data and on the free movement of
        such data;
      </li>
      <li>&apos;the data exporter&apos; means the controller who transfers the personal data;</li>
      <li>
        &apos;the data importer&apos; means the processor who agrees to receive from the data exporter personal data
        intended for processing on his behalf after the transfer in accordance with his instructions and the terms of
        the Clauses and who is not subject to a third country&apos;s system ensuring adequate protection within the
        meaning of Article 25(1) of Directive 95/46/EC;
      </li>
      <li>
        &apos;the applicable data protection law&apos; means the legislation protecting the fundamental rights and
        freedoms of individuals and, in particular, their right to privacy with respect to the processing of personal
        data applicable to a data controller in the Member State in which the data exporter is established;
      </li>
      <li>
        &apos;technical and organisational security measures&apos; means those measures aimed at protecting personal
        data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or
        access, in particular where the processing involves the transmission of data over a network, and against all
        other unlawful forms of processing.
      </li>
    </ol>
    <em className="center">
      Clause 2<br />
      <strong>Details of the transfer</strong>
    </em>
    <p>
      The details of the transfer and in particular the special categories of personal data where applicable are
      specified in <a href="#appendix_one">Appendix 1</a> which forms an integral part of the Clauses.
    </p>
    <em className="center">
      Clause 3<br />
      <strong>Third-party beneficiary clause</strong>
    </em>
    <ol>
      <li>
        The data subject can enforce against the data exporter this Clause, Clause 4(b) to (h), Clause 5(a) to (e), and
        (g), Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 11 as third-party beneficiary.
      </li>
      <li>
        The data subject can enforce against the data importer this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause
        7, Clause 8(2), and Clauses 9 to 11, in cases where the data exporter has factually disappeared or has ceased to
        exist in law unless any successor entity has assumed the entire legal obligations of the data exporter by
        contract or by operation of law, as a result of which it takes on the rights and obligations of the data
        exporter, in which case the data subject can enforce them against such entity.
      </li>
      <li>
        The parties do not object to a data subject being represented by an association or other body if the data
        subject so expressly wishes and if permitted by national law.
      </li>
    </ol>
    <em className="center">
      Clause 4<br />
      <strong>Obligations of the data exporter</strong>
    </em>
    <p>The data exporter agrees and warrants:</p>
    <ol type="a">
      <li>
        that the processing, including the transfer itself, of the personal data has been and will continue to be
        carried out in accordance with the relevant provisions of the applicable data protection law (and, where
        applicable, has been notified to the relevant authorities of the Member State where the data exporter is
        established) and does not violate the relevant provisions of that State;
      </li>
      <li>
        that it has instructed and throughout the duration of the personal data processing services will instruct the
        data importer to process the personal data transferred only on the data exporter&apos;s behalf and in accordance
        with the applicable data protection law and the Clauses;
      </li>
      <li>
        that the data importer will provide sufficient guarantees in respect of the technical and organisational
        security measures specified in <a href="#appendix_two">Appendix 2</a>;
      </li>
      <li>
        that after assessment of the requirements of the applicable data protection law, the security measures are
        appropriate to protect personal data against accidental or unlawful destruction or accidental loss, alteration,
        unauthorised disclosure or access, in particular where the processing involves the transmission of data over a
        network, and against all other unlawful forms of processing, and that these measures ensure a level of security
        appropriate to the risks presented by the processing and the nature of the data to be protected having regard to
        the state of the art and the cost of their implementation;
      </li>
      <li>that it will ensure compliance with the security measures;</li>
      <li>
        that, if the transfer involves special categories of data, the data subject has been informed or will be
        informed before, or as soon as possible after, the transfer that its data could be transmitted to a third
        country not providing adequate protection within the meaning of Directive 95/46/EC;
      </li>
      <li>
        to forward any notification received from the data importer pursuant to Clause 5(b) and Clause 8(3) to the data
        protection supervisory authority if the data exporter decides to continue the transfer or to lift the
        suspension;
      </li>
      <li>
        to make available to the data subjects upon request a copy of the Clauses, with the exception of Appendix 2, and
        a summary description of the security measures, unless the Clauses contain commercial information, in which case
        it may remove such commercial information; and
      </li>
      <li>that it will ensure compliance with Clause 4(a) to (h).</li>
    </ol>
    <em className="center">
      Clause 5<br />
      <strong>Obligations of the data importer</strong>
    </em>
    <p>The data importer agrees and warrants:</p>
    <ol type="a">
      <li>
        to process the personal data only on behalf of the data exporter and in compliance with its instructions and the
        Clauses; if it cannot provide such compliance for whatever reasons, it agrees to inform promptly the data
        exporter of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data
        and/or terminate the contract;
      </li>
      <li>
        that it has no reason to believe that the legislation applicable to it prevents it from fulfilling the
        instructions received from the data exporter and its obligations under the contract and that in the event of a
        change in this legislation which is likely to have a substantial adverse effect on the warranties and
        obligations provided by the Clauses, it will promptly notify the change to the data exporter as soon as it is
        aware, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the
        contract;
      </li>
      <li>
        that it has implemented the technical and organisational security measures specified in Appendix 2 before
        processing the personal data transferred;
      </li>
      <li>that it will promptly notify the data exporter about:</li>
      <ol type="i">
        <li>
          any legally binding request for disclosure of the personal data by a law enforcement authority unless
          otherwise prohibited, such as a prohibition under criminal law to preserve the confidentiality of a law
          enforcement investigation,
        </li>
        <li>any accidental or unauthorised access, and</li>
        <li>
          any request received directly from the data subjects without responding to that request, unless it has been
          otherwise authorised to do so;
        </li>
      </ol>
      <li>
        to deal promptly and properly with all inquiries from the data exporter relating to its processing of the
        personal data subject to the transfer and to abide by the advice of the supervisory authority with regard to the
        processing of the data transferred;
      </li>
      <li>
        at the request of the data exporter to submit its data processing facilities for audit of the processing
        activities covered by the Clauses which shall be carried out by the data exporter or an inspection body composed
        of independent members and in possession of the required professional qualifications bound by a duty of
        confidentiality, selected by the data exporter, where applicable, in agreement with the supervisory authority;
      </li>
      <li>
        to make available to the data subject upon request a copy of the Clauses, unless the Clauses contain commercial
        information, in which case it may remove such commercial information, with the exception of Appendix 2 which
        shall be replaced by a summary description of the security measures in those cases where the data subject is
        unable to obtain a copy from the data exporter.
      </li>
    </ol>
    <em className="center">
      Clause 6<br />
      <strong>Liability</strong>
    </em>
    <ol>
      <li>
        The parties agree that any data subject, who has suffered damage as a result of any breach of the obligations
        referred to in Clause 3 by any party is entitled to receive compensation from the data exporter for the damage
        suffered.
      </li>
      <li>
        If a data subject is not able to bring a claim for compensation in accordance with paragraph 1 against the data
        exporter, arising out of a breach by the data importer of any of their obligations referred to in Clause 3,
        because the data exporter has factually disappeared or ceased to exist in law or has become insolvent, the data
        importer agrees that the data subject may issue a claim against the data importer as if it were the data
        exporter, unless any successor entity has assumed the entire legal obligations of the data exporter by contract
        of by operation of law, in which case the data subject can enforce its rights against such entity.
      </li>
    </ol>
    <em className="center">
      Clause 7<br />
      <strong>Mediation and jurisdiction</strong>
    </em>
    <ol>
      <li>
        The data importer agrees that if the data subject invokes against its third-party beneficiary rights and/or
        claims compensation for damages under the Clauses, the data importer will accept the decision of the data
        subject:
      </li>
      <ol type="a">
        <li>
          to refer the dispute to mediation, by an independent person or, where applicable, by the supervisory
          authority;
        </li>
        <li>to refer the dispute to the courts in the Member State in which the data exporter is established.</li>
      </ol>
      <li>
        The parties agree that the choice made by the data subject will not prejudice its substantive or procedural
        rights to seek remedies in accordance with other provisions of national or international law.
      </li>
    </ol>
    <em className="center">
      Clause 8<br />
      <strong>Cooperation with supervisory authorities</strong>
    </em>
    <ol>
      <li>
        The data exporter agrees to deposit a copy of this contract with the supervisory authority if it so requests or
        if such deposit is required under the applicable data protection law.
      </li>
      <li>
        The parties agree that the supervisory authority has the right to conduct an audit of the data importer which
        has the same scope and is subject to the same conditions as would apply to an audit of the data exporter under
        the applicable data protection law.
      </li>
      <li>
        The data importer shall promptly inform the data exporter about the existence of legislation applicable to it
        preventing the conduct of an audit of the data importer pursuant to paragraph 2. In such a case the data
        exporter shall be entitled to take the measures foreseen in Clause 5 (b).
      </li>
    </ol>
    <em className="center">
      Clause 9<br />
      <strong>Governing Law</strong>
    </em>
    <p>The Clauses shall be governed by the law of the Member State in which the data exporter is established.</p>
    <em className="center">
      Clause 10
      <br />
      <strong>Variation of the contract</strong>
    </em>
    <p>
      The parties undertake not to vary or modify the Clauses. This does not preclude the parties from adding clauses on
      business related issues where required as long as they do not contradict the Clause.
    </p>
    <em className="center">
      Clause 11
      <br />
      <strong>Obligation after the termination of personal data processing services</strong>
    </em>
    <ol>
      <li>
        The parties agree that on the termination of the provision of data processing services, the data importer shall,
        at the choice of the data exporter, return all the personal data transferred and the copies thereof to the data
        exporter or shall destroy all the personal data and certify to the data exporter that it has done so, unless
        legislation imposed upon the data importer prevents it from returning or destroying all or part of the personal
        data transferred. In that case, the data importer warrants that it will guarantee the confidentiality of the
        personal data transferred and will not actively process the personal data transferred anymore.
      </li>
      <li>
        The data importer warrants that upon request of the data exporter and/or of the supervisory authority, it will
        submit its data processing facilities for an audit of the measures referred to in paragraph 1.
      </li>
    </ol>
    <p>
      <strong>These Contractual Clauses shall only become legally binding</strong> between the parties when the data
      exporter has carefully considered and accepted them and has provided its consent and acceptance via Continuity
      Coach Pty. Ltd.&apos;s online registration process.
    </p>
    <p>
      As the data importer, Continuity Coach Pty. Ltd. confirms its acceptance of the Contractual Clauses upon the data
      exporter&apos;s due and proper completion of the online registration.
    </p>
    <div className="break" />
    <h1 id="appendix_one" className="border center">
      APPENDIX 1 TO THE STANDARD CONTRACTUAL CLAUSES
    </h1>
    <p>
      This Appendix forms part of the Clauses and must be completed and entered into by the parties via the data
      importer&apos;s (Continuity Coach Pty. Ltd.&apos;s) online registration process.
    </p>
    <p>
      The Member States may complete or specify, according to their national procedures, any additional necessary
      information to be contained in this Appendix.
    </p>
    <p>
      <strong>Data exporter</strong>
    </p>
    <p>
      The data exporter will access the data importer&apos;s online storage vault service for any (or all) of the
      following purposes:
    </p>
    <ul>
      <li>Input, record and manage its data in a private and secure online environment.</li>
      <li>
        Enter details including personal data of its employees and third parties for retrieval and use as and when
        required, such as the assignment of specific tasks to employees, the management and mitigation of its business
        risks.
      </li>
      <li>
        Access its own safe online environment for other purposes without the need to input personal data if required.
      </li>
    </ul>
    <p>
      <strong>Data importer</strong>
    </p>
    <p>
      The data importer (Continuity Coach Pty. Ltd.) is a provider of the abovementioned online storage vault service
      which processes personal data upon the instruction of the data exporter in accordance with the terms of the
      parties&apos; Agreement.
    </p>
    <p>
      <strong>Data subjects</strong>
    </p>
    <p>The personal data transferred concern the following categories of data subjects:</p>
    <p>At the data exporter&apos;s sole discretion and may include (but not limited to) Personal Data relating to:</p>
    <ul>
      <li>
        Prospects, customers, business partners, vendors and subcontractors of the data exporter (who are natural
        persons)
      </li>
      <li>
        Employees or contact persons of the data exporter&apos;s customers, business partners, vendors and
        subcontractors
      </li>
      <li>
        Employees, agents, advisors, freelancers of the data exporter (who are natural persons), and their family
        members
      </li>
      <li>The data exporter&apos;s Users authorised by the data exporter to use the Services</li>
    </ul>
    <p>
      <strong>Categories of data</strong>
    </p>
    <p>
      The personal data transferred concern the following categories of data: The data exporter may submit Personal Data
      to the Services, the extent of which is determined and controlled by the data exporter in its sole discretion, and
      which may include (but not limited to) the following categories of Personal Data:
    </p>
    <ul>
      <li>First and last name</li>
      <li>Title</li>
      <li>Position</li>
      <li>Employer</li>
      <li>Contact information (company, email, phone, physical business address)</li>
      <li>Localisation data</li>
    </ul>
    <p>
      <strong>Processing operations</strong>
    </p>
    <p>The personal data transferred will be subject to the following basic processing activities:</p>
    <p>
      The objective of Processing of Personal Data by data importer is the performance of the SCC Services pursuant to
      the Agreement.
    </p>
    <p>
      <strong>The provisions of this Appendix 1 shall only become legally binding</strong> between the parties when the
      data exporter has carefully considered and accepted them and has provided its consent and acceptance via
      Continuity Coach Pty. Ltd.&apos;s online registration process.
    </p>
    <p>
      As the data importer, Continuity Coach Pty. Ltd. confirms its acceptance of the provisions of this Appendix 1 upon
      the data exporter&apos;s due and proper completion of the online registration.
    </p>
    <div className="break" />
    <h1 id="appendix_two" className="border center">
      APPENDIX 2 TO THE STANDARD CONTRACTUAL CLAUSES
    </h1>
    <p>
      This Appendix forms part of the Clauses and must be completed and entered into by the parties via the data
      importer&apos;s (Continuity Coach Pty. Ltd.&apos;s) online registration process.
    </p>
    <p>
      Description of the technical and organisational security measures implemented by the data importer in accordance
      with Clauses 4(d) and 5(c):
    </p>
    <ol>
      <li>
        <strong>General Controls.</strong> Continuity Coach Pty. Ltd. shall implement measures designed to:
      </li>
      <ol type="a">
        <li>
          deny unauthorised persons access to data-processing equipment used for processing Personal Data (equipment
          access control);
        </li>
        <li>
          prevent the unauthorised reading, copying, modification or removal of data media containing Personal Data
          (data media control);
        </li>
        <li>
          prevent the unauthorised input of Personal Data and the unauthorised inspection, modification or deletion of
          stored Personal Data (storage control);
        </li>
        <li>
          prevent the use of automated data-processing systems by unauthorised persons using data communication
          equipment used to process Personal Data (user control);
        </li>
        <li>
          ensure that persons authorised to use an automated data-processing system only have access to the Personal
          Data covered by their access authorisation (data access control);
        </li>
        <li>
          ensure that it is possible to verify which individuals Personal Data have been or may be transmitted or made
          available using data communication equipment (communication control);
        </li>
        <li>
          ensure that the functions of the system used to process Personal Data perform, that the appearance of faults
          in the functions is reported (reliability) and to prevent stored Personal Data from corruption by means of a
          malfunctioning of the system (integrity).
        </li>
      </ol>
      <li>
        <strong>Personnel.</strong> Continuity Coach Pty. Ltd. shall take reasonable steps to ensure that no person
        shall be appointed by Continuity Coach Pty. Ltd. to process Personal Data unless that person:
      </li>
      <ol type="a">
        <li>is competent and qualified to perform the specific tasks assigned to him by Continuity Coach Pty. Ltd.;</li>
        <li>has been authorised by Continuity Coach Pty. Ltd.; and</li>
        <li>
          has been instructed by Continuity Coach Pty. Ltd. in the requirements relevant to the performance of the
          obligations of Continuity Coach Pty. Ltd. under these Clauses, in particular the limited purpose of the data
          processing.
        </li>
      </ol>
      <li>
        <strong>Copy Control.</strong> Continuity Coach Pty. Ltd. shall not make copies of Personal Data, provided,
        however, that Continuity Coach Pty. Ltd. may retain copies of Personal Data provided to it for backup and
        archive purposes.
      </li>
      <li>
        <strong>Security Controls.</strong> The Service includes a variety of security controls. These controls include:
      </li>
      <ul>
        <li>
          Unique User identifiers (User IDs) to ensure that activities can be attributed to the responsible individual.
        </li>
        <li>Controls to revoke access after several consecutive failed login attempts.</li>
        <li>The ability to specify the lockout time period.</li>
        <li>Controls on the number of invalid login requests before locking out a User.</li>
        <li>Controls to ensure generated initial passwords must be reset on first use.</li>
        <li>Controls to terminate a User session after a period of inactivity.</li>
        <li>Password history controls to limit password reuse.</li>
        <li>Password length controls.</li>
        <li>Password complexity requirements (requires letters and numbers).</li>
        <li>Email verification before resetting password.</li>
      </ul>
      <li>
        <strong>Security Procedures, Policies and Logging.</strong> The Services are operated in accordance with the
        following procedures to enhance security:
      </li>
      <ul>
        <li>
          User passwords are stored using a one-way hashing algorithm (SHA-256) and are never transmitted unencrypted.
        </li>
        <li>User access log entries will be maintained, containing date, time and User ID.</li>
        <li>Passwords are not logged under any circumstances.</li>
        <li>
          Processor&apos;s personnel will not set a defined password for a User. Passwords are reset to a random value
          (which must be changed on first use) and delivered automatically via email to the requesting User.
        </li>
      </ul>
      <li>
        <strong>Intrusion Detection.</strong> Continuity Coach Pty. Ltd., or an authorised third party (subject to the
        terms of these Clauses), will monitor the Services for unauthorised intrusions using network-based intrusion
        detection mechanisms.
      </li>
      <li>
        <strong>User Authentication.</strong> Access to the Services requires a valid User ID and password combination,
        which are encrypted via SSL while in transmission. Following a successful authentication, a random session ID is
        generated and stored in the user&apos;s browser to preserve and track session state.
      </li>
      <li>
        <strong>Incident Management.</strong> Continuity Coach Pty. Ltd. maintains security incident management policies
        and procedures. Continuity Coach Pty. Ltd. will promptly notify Customer in the event Continuity Coach Pty. Ltd.
        becomes aware of an actual or reasonably suspected unauthorised disclosure of Personal Data.
      </li>
      <li>
        <strong>Viruses.</strong> The Services will not introduce any viruses to Customer&apos;s systems; however, the
        Services do not scan for viruses that could be included in attachments or other Personal Data uploaded into the
        Services by Customer. Any such uploaded attachments will not be executed in the Services and therefore will not
        damage or compromise the Service.
      </li>
      <li>
        <strong>Data Encryption.</strong> The Services use industry-accepted encryption products to protect Customer
        Data and communications during transmissions between a customer&apos;s network and the Services, including
        128-bit TLS Certificates and 2048-bit RSA public keys at a minimum. Additionally, Customer Data is encrypted
        during transmission between data centres for replication purposes.
      </li>
      <li>
        <strong>System Changes and Enhancements.</strong> Continuity Coach Pty. Ltd. plans to enhance and maintain the
        Services during the term of the Agreement. Security controls, procedures, policies and features may change or be
        added. Continuity Coach Pty. Ltd. will provide security controls that deliver a level of security protection
        that is not materially lower than that provided as of the Effective Date.
      </li>
    </ol>
    <p>
      <strong>The provisions of this Appendix 2 shall only become legally binding</strong> between the parties when the
      data exporter has carefully considered and accepted them and has provided its consent and acceptance via
      Continuity Coach Pty. Ltd.&apos;s online registration process.
    </p>
    <p>
      As the data importer, Continuity Coach Pty. Ltd. confirms its acceptance of the provisions of this Appendix 2 upon
      the data exporter&apos;s due and proper completion of the online registration.
    </p>
    <div className="break" />
  </article>
);
