import React, { Component } from 'react';
import { Row, Col, Form, Input, Button, Alert } from 'antd';
import * as HttpStatus from 'http-status-codes';
import Card from '../../Card/Card';
import Modal from '../../Modal/Modal';
import Login from '../Login';
import { MSG_ERROR } from '../../../constants';

const FormItem = Form.Item;
const MSG_ALERT_SEND_SUCCESS =
  'If this email address is registered, you will receive an email with password reset instructions in a moment.';
const MSG_ALERT_SEND_UNAUTHORIZED = `Sorry, we couldn't find an account with the email`;
const MSG_ALERT_RESET_SUCCESS = 'Your password has been successfully updated.';
const MSG_ALERT_RESET_FAIL =
  'Your password cannot be updated. This is possibly because the link we sent to you is expired. Please send verification email again.';
const SPECIAL_CHARACTER = '~`!@#$%^&*()\\-_=+\\[\\]{};:\'",.<>/?|';

const initialState = {
  userId: null,
  token: null,
  valid: false,
  confirmDirty: false,
  alertType: null,
  alertMessage: null
};

class ResetPasswordForm extends Component {
  state = { ...initialState };

  componentDidMount() {
    const parsedUrl = new URL(window.location);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const userId = searchParams.get('id');
    const token = searchParams.get('token');
    let valid = false;
    if (userId && token) {
      valid = true;
    }
    this.setState({ userId, token, valid });
  }

  onSendVerificationEmail = e => {
    e.preventDefault();
    const { form, sendVerificationEmail, setResetPasswordRequest } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const email = values.resetEmail;
        try {
          const res = await sendVerificationEmail({ email });
          if (res.status === HttpStatus.OK) {
            this.setState({ alertType: 'success', alertMessage: MSG_ALERT_SEND_SUCCESS });
          }
        } catch (err) {
          if (err && err.status === HttpStatus.UNAUTHORIZED) {
            this.setState({ alertType: 'error', alertMessage: `${MSG_ALERT_SEND_UNAUTHORIZED} ${email}` });
          } else {
            this.setState({ alertType: 'error', alertMessage: MSG_ERROR });
          }
        }
        setResetPasswordRequest(false);
      }
    });
  };

  onUpdatePassword = e => {
    e.preventDefault();
    const { form, updatePassword, setResetPasswordRequest } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const { userId, token } = this.state;
        if (userId && token) {
          try {
            const { newPassword } = values;
            const data = { userId, token, newPassword };
            await updatePassword(data);
            this.setState({ alertType: 'success', alertMessage: MSG_ALERT_RESET_SUCCESS });
          } catch (err) {
            if (err && err.status === HttpStatus.INTERNAL_SERVER_ERROR) {
              this.setState({ alertType: 'error', alertMessage: MSG_ALERT_RESET_FAIL });
            } else {
              this.setState({ alertType: 'error', alertMessage: MSG_ERROR });
            }
          }
          setResetPasswordRequest(false);
        }
      }
    });
  };

  onConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Two passwords that you enter is inconsistent.');
    } else {
      callback();
    }
  };

  checkConfirm = (rule, value, callback) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value && confirmDirty) {
      form.validateFields(['confirmNewPassword'], { force: true });
    }
    callback();
  };

  redirect = () => {
    const { history } = this.props;
    this.setState(initialState, () => {
      history.replace('/reset-password');
    });
  };

  render() {
    const { form, resetPasswordRequest } = this.props;
    const { getFieldDecorator } = form;
    const { valid, alertType, alertMessage } = this.state;
    return (
      <div className="reset-password">
        {valid ? (
          <Card title="Reset Your Password">
            <Row gutter={20} className="reset-password-inner">
              <Col span={24}>
                {alertMessage && <Alert type={alertType} message={alertMessage} className="reset-password-alert" />}
              </Col>
              <Col span={24}>
                {/* eslint no-nested-ternary: off */}
                {alertType === 'error' || alertType === 'success' ? (
                  alertType === 'error' ? (
                    <Button type="primary" onClick={this.redirect} className="reset-password-btn">
                      Send Verification Email
                    </Button>
                  ) : (
                    <Modal
                      toggle={show => (
                        <Button type="primary" onClick={show} className="reset-password-btn">
                          Log in
                        </Button>
                      )}
                    >
                      {hide => <Login close={hide} />}
                    </Modal>
                  )
                ) : (
                  <Form onSubmit={this.onUpdatePassword}>
                    <FormItem label="New Password" hasFeedback>
                      {getFieldDecorator('newPassword', {
                        rules: [
                          { required: true, message: 'Please input your password.' },
                          {
                            pattern: RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${SPECIAL_CHARACTER}])(?=.{6,})`),
                            message:
                              'Password must be at least 6 characters and contain at least one lowercase letter, one uppercase letter, one digit and one special character.'
                          },
                          { validator: this.checkConfirm }
                        ]
                      })(<Input type="password" placeholder="New Password" disabled={resetPasswordRequest} />)}
                    </FormItem>
                    <FormItem label="Confirm Password" hasFeedback>
                      {getFieldDecorator('confirmNewPassword', {
                        rules: [
                          { required: true, message: 'Please confirm your password.' },
                          { validator: this.checkPassword }
                        ]
                      })(
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          onBlur={this.onConfirmBlur}
                          disabled={resetPasswordRequest}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={resetPasswordRequest}
                        className="reset-password-btn"
                      >
                        {`${resetPasswordRequest ? `Updating` : `Update`} my password`}
                      </Button>
                    </FormItem>
                  </Form>
                )}
              </Col>
            </Row>
          </Card>
        ) : (
          <Card title="Send Verification Email">
            <Row gutter={20} className="reset-password-inner">
              <Col span={24}>
                {alertMessage && <Alert type={alertType} message={alertMessage} className="reset-password-alert" />}
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <p>
                  Please provide the email address that you used when you signed up for your ContinuityCoach account.
                </p>
                <p>We will send you an email that will allow you to reset your password.</p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form onSubmit={this.onSendVerificationEmail}>
                  <FormItem hasFeedback>
                    {getFieldDecorator('resetEmail', {
                      validateTrigger: 'onBlur',
                      rules: [
                        { type: 'email', message: 'Please input an valid email.' },
                        { required: true, message: 'Please input your email.' }
                      ]
                    })(<Input placeholder="Email" spellCheck={false} disabled={resetPasswordRequest} />)}
                  </FormItem>
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={resetPasswordRequest}
                      className="reset-password-btn"
                    >
                      {`${resetPasswordRequest ? `Sending` : `Send`} verification email`}
                    </Button>
                  </FormItem>
                </Form>
              </Col>
            </Row>
          </Card>
        )}
      </div>
    );
  }
}

export default Form.create()(ResetPasswordForm);
