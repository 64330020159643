import { types } from './UserActions';
import { USER_LOGOUT } from '../../../../components/Login/LoginAction';

const initialState = { loadingUser: false, loadingUserSubscriptions: false, showNotification: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initialState };
    case types.REQUEST_USER:
      return { ...state, loadingUser: true };
    case types.RECEIVE_USER:
      return { ...state, loadingUser: false, userInfo: action.userInfo };
    case types.RECEIVE_OTP_VERIFICATION:
      return { ...state, loadingUser: false };
    case types.REQUEST_USER_SUBSCRIPTIONS:
      return { ...state, loadingUserSubscriptions: true };
    case types.RECEIVE_USER_SUBSCRIPTIONS:
      return { ...state, loadingUserSubscriptions: false, subscriptions: action.subscriptions };
    case types.SET_SHOW_NOTIFICATION:
      return { ...state, showNotification: action.showNotification };
    default:
      return state;
  }
};
