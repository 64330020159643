import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';

export const userInfoSelector = state => state.userReducer.userInfo;

export const userNotificationsSelector = createSelector(userInfoSelector, userInfo =>
  userInfo ? userInfo.notifications : null
);

export const showSmsSettingBannerSelector = createSelector(
  userNotificationsSelector,
  notifications => (notifications ? notifications.find(n => n.type === 1)?.isDisplay : null) // type 1 is for SMS setting banner Id
);

export const showNotificationSelector = state => state.userReducer.showNotification;

export const userIdSelector = createSelector(userInfoSelector, userInfo => (userInfo ? userInfo.id : null));

export const userAvatarSelector = createSelector(userInfoSelector, userInfo => (userInfo ? userInfo.avatarUrl : null));

export const personNameSelector = createSelector(userInfoSelector, userInfo =>
  userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : ''
);

export const organisationUserRoleSelector = createSelector(
  userInfoSelector,
  state => state.organisationReducer.businessInfo,
  (userInfo, businessInfo) => {
    if (userInfo && userInfo.organisationUserRoles && businessInfo) {
      const organisationUser = userInfo.organisationUserRoles.find(
        item => item.organisationId === businessInfo.organisationId
      );
      return organisationUser ? organisationUser.roleName.toLowerCase() : null;
    }
    return null;
  }
);

export const subscriptionsSelector = state => state.userReducer.subscriptions;

export const userSubscriptionsSelector = createSelector(subscriptionsSelector, subscriptions =>
  subscriptions
    ? _.chain(subscriptions.subscriptions)
        .map(item => {
          const activeDate = item.activeDate ? moment(item.activeDate).format('DD/MM/YYYY') : null;
          const exactExpiryDate = item.expiryDate ? item.expiryDate : null;
          // if a Subscription has no expiry and is not assigned to a Plan, then it has no expiry.
          // if a Subscription has no expiry but is assigned to a Plan, then that Subscription expires 1 year after the activation date
          let isExpired = false;
          let expiryDate = null;
          if (!item.promoCodeId && !item.isRevoked) {
            if (!item.expiryDate) {
              if (item.planId && item.activeDate) {
                expiryDate = moment(item.activeDate)
                  .add(1, 'year')
                  .format('DD/MM/YYYY');
                const currentDate = moment();
                isExpired = moment(item.activeDate)
                  .add(1, 'year')
                  .isBefore(currentDate);
              }
            } else {
              expiryDate = moment(item.expiryDate).format('DD/MM/YYYY');
              const currentDate = moment();
              isExpired = moment(item.expiryDate).isBefore(currentDate);
            }
          }
          return { ...item, activeDate, expiryDate, exactExpiryDate, isExpired };
        })
        .value()
    : undefined
);

export const expiredUserSubscriptionsCountSelector = createSelector(userSubscriptionsSelector, subscriptions => {
  let expiredCount = 0;
  if (subscriptions) {
    subscriptions.forEach(item => {
      if (item.isExpired) {
        expiredCount += 1;
      }
    });
    return expiredCount;
  }
  return expiredCount;
});

export const userSubscriptionsSummarySelector = createSelector(
  subscriptionsSelector,
  expiredUserSubscriptionsCountSelector,
  (subscriptions, expiredCount) => (subscriptions ? { ...subscriptions.summary, expired: expiredCount } : null)
);

export const loadingUserSubscriptionsSelector = state => state.userReducer.loadingUserSubscriptions;
