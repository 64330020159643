import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import Card from '../../Card/Card';
import BiaChart from './BiaChart';
import CriticalAreasTable from './components/CriticalAreasTable';
import CriticalPeriodsChart from './components/CriticalPeriodsChart';
import { departmentListSelector } from '../../../scenes/Dashboard/scenes/Department/DepartmentSelectors';
import {
  biaListSelector,
  strategicBiasSelector,
  drsCategoriesSelector
} from '../../../scenes/Dashboard/scenes/Bia/BiaSelectors';

const BiaDetail = ({ departmentList = [], biaList = [], strategicBias, drsCategories, toggleBia }) => {
  const criticalBiaList = biaList.filter(obj => !!obj.strategicBiaId);

  return (
    <Row gutter={20}>
      <Col span={24} className="bia-detail-back">
        <Button type="link" onClick={toggleBia}>
          {'<<< Back to dashboard'}
        </Button>
      </Col>
      <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
        <Card color="#343579" title="Disruption Risk Scales">
          <BiaChart strategicBias={strategicBias} drsCategories={drsCategories} />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <Card color="#343579" title="Critical Periods">
          <CriticalPeriodsChart biaList={criticalBiaList} />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
        <Card color="#343579" title="Critical Areas">
          <CriticalAreasTable biaList={criticalBiaList} />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <Card color="#343579" title="Business Units">
          <div className="bia-detail-container">
            {departmentList.length > 0 ? (
              departmentList.map(item => (
                <div key={item.departmentId} className="bia-detail-item">
                  {item.name}
                </div>
              ))
            ) : (
              <div style={{ textAlign: 'center', marginTop: '10px' }}>No Business Units</div>
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  departmentList: departmentListSelector(state),
  biaList: biaListSelector(state),
  strategicBias: strategicBiasSelector(state),
  drsCategories: drsCategoriesSelector(state)
});

export default connect(mapStateToProps)(BiaDetail);
