import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import StrategicThirdPartyClass from './components/StrategicThirdPartyClass';
import * as actions from './StrategicThirdPartyAction';
import { orgIdSelector } from '../../OrganisationSelectors';
import {
  strategicThirdPartyListSelector,
  strategicThirdPartyCategoriesSelector,
  loadingStrategicThirdPartySelector
} from './StrategicThirdPartySelectors';

const CLASS_NAME = 'Strategic Third Parties';

const StrategicThirdParty = ({
  orgId,
  loading,
  strategicThirdPartyList,
  strategicThirdPartyCategories,
  fetchStrategicThirdPartyCategories,
  fetchStrategicThirdParty
}) => {
  useEffect(() => {
    fetchStrategicThirdPartyCategories();
    fetchStrategicThirdParty(orgId);
  }, [orgId, fetchStrategicThirdPartyCategories, fetchStrategicThirdParty]);

  return (
    <>
      <ProcessBar
        title="Strategic Third Parties"
        prev="/organization/suppliers"
        next="/organization/strategic-crisis-experts"
      />
      <InfoBox message="Strategic Third Parties are external Organizations that may be required following an event. Some recommended Strategic Third Parties have been included but can be deleted (or changed) if not required." />
      {strategicThirdPartyList && strategicThirdPartyCategories ? (
        <div className="component-wrapper">
          <Spin spinning={loading}>
            <StrategicThirdPartyClass
              strategicThirdPartyList={strategicThirdPartyList[CLASS_NAME]}
              strategicThirdPartyClass={CLASS_NAME}
              strategicThirdPartyCategories={strategicThirdPartyCategories[CLASS_NAME]}
            />
          </Spin>
        </div>
      ) : (
        <Spin tip="Loading Strategic Third Parties..." className="loading" />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  strategicThirdPartyList: strategicThirdPartyListSelector(state),
  strategicThirdPartyCategories: strategicThirdPartyCategoriesSelector(state),
  loading: loadingStrategicThirdPartySelector(state)
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(StrategicThirdParty);
