import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import { LinearGradient } from 'echarts/lib/util/graphic';
import './BiaChart.scss';

const icons = ['dollar', 'users', 'gavel', 'cogs', 'thumbs-up', 'line-chart'];
const scaleList = ['Severe', 'Major', 'Moderate', 'Minor', 'Negligible'];
const colors = ['#C9E094', '#FFFA84', '#F9C761', '#DD7049', '#241023'];

/* eslint no-restricted-syntax: off */
export default class BiaChart extends Component {
  static propTypes = {
    strategicBias: PropTypes.array.isRequired,
    drsCategories: PropTypes.array.isRequired
  };

  state = {
    allDrsData: null,
    biaCategories: [],
    defaultTitle: 'Consolidated Disruption Risk Scales',
    title: null,
    chartData: [],
    selectedDrsCategory: null
  };

  componentDidMount() {
    this.setState(({ defaultTitle }) => ({ title: defaultTitle }));
    this.setAllDrsData();
  }

  setAllDrsData = () => {
    const { strategicBias, drsCategories } = this.props;
    const biaCategories = strategicBias.map(item => item[0]);
    const allDrsData = {};
    for (const [category, biaList] of strategicBias) {
      for (const bia of biaList) {
        const biaDrsList = bia.strategicBiaDrsList || [];
        for (const drs of biaDrsList) {
          const drsCategoryId = drs.disruptionRiskScaleCategoryId;
          const drsValue = drs.disruptionRiskScaleValue;
          if (!allDrsData[drsCategoryId]) {
            allDrsData[drsCategoryId] = {};
          }
          if (!allDrsData[drsCategoryId][category]) {
            allDrsData[drsCategoryId][category] = { value: 0, biaList: [] };
          }
          allDrsData[drsCategoryId][category].value += drsValue;
          allDrsData[drsCategoryId][category].biaList.push({ name: bia.strategicCriticalArea.name, value: drsValue });
        }
      }
    }
    for (const [drsCategoryId, allBias] of Object.entries(allDrsData)) {
      for (const [category, biasOfCategory] of Object.entries(allBias)) {
        allDrsData[drsCategoryId][category].value = biasOfCategory.value / biasOfCategory.biaList.length;
        allDrsData[drsCategoryId][category].biaList = biasOfCategory.biaList.sort((a, b) => b.value - a.value);
      }
    }
    const selectedDrsCategory = drsCategories[0].disruptionRiskScaleCategoryId;
    this.setChartData(allDrsData[selectedDrsCategory], biaCategories);
    this.setState({ biaCategories, allDrsData, selectedDrsCategory });
  };

  setChartData = (data, xAxisData) => {
    if (!data) {
      return;
    }
    let chartData = [];
    for (const x of xAxisData) {
      const value = data[x] ? data[x].value : 0;
      chartData.push({ name: x, value });
    }
    chartData = chartData.sort((a, b) => b.value - a.value);
    this.setState({ chartData });
  };

  onDrsCategoryChange = drsCategoryId => {
    const { allDrsData, biaCategories, defaultTitle } = this.state;
    this.setChartData(allDrsData[drsCategoryId], biaCategories);
    this.setState({ title: defaultTitle, selectedDrsCategory: drsCategoryId });
  };

  onChartBarClick = param => {
    const { allDrsData, selectedDrsCategory, biaCategories } = this.state;
    const biaCategory = param.name;
    if (!biaCategories.includes(biaCategory)) {
      return;
    }
    const { biaList } = allDrsData[selectedDrsCategory][biaCategory];
    this.setState({ title: biaCategory, chartData: biaList });
  };

  render() {
    const { drsCategories } = this.props;
    const { title, defaultTitle, chartData, selectedDrsCategory } = this.state;
    const data = chartData.map(item => item.value);
    const xAxisData = chartData.map(item => item.name);
    const options = {
      title: {
        text: title,
        subtext: title === defaultTitle ? 'click the bar to drill down into the strategic areas' : null,
        show: true,
        left: 'center',
        textStyle: {
          color: 'rgba(0, 0, 0, 0.65)',
          fontSize: 16
        }
      },
      tooltip: {
        formatter: '{b}: {c}'
      },
      legend: {},
      grid: {
        bottom: 150
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: 45
        },
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        max: 5,
        min: 0
      },
      dataZoom: {
        type: 'inside',
        show: false
      },
      series: [
        {
          type: 'bar',
          data,
          barWidth: '50%',
          itemStyle: {
            color: param => {
              const offset = [];
              for (let i = 0; i < 5; i += 1) {
                if (i / param.data <= 1) {
                  offset.push({ offset: i / param.data, color: colors[i] });
                }
              }
              // return new echarts.graphic.LinearGradient(0, 1, 0, 0, offset);
              return new LinearGradient(0, 1, 0, 0, offset);
            }
          }
        }
      ]
    };

    const events = {
      click: this.onChartBarClick
    };

    return (
      <div className="widget bia-chart-container">
        <ReactEchartsCore
          echarts={echarts}
          option={options}
          notMerge
          lazyUpdate
          onEvents={events}
          style={{ width: '80%', height: '80%' }}
        />
        <ul className="scale-list">
          {scaleList.map(item => (
            <li key={item} className="scale-item">
              <span className="scale-item-circle" />
              <span className="scale-item-name">{item}</span>
            </li>
          ))}
        </ul>
        <ul className="drs-category">
          {drsCategories.map(item => (
            /* eslint-disable */
            <li
              key={item.disruptionRiskScaleCategoryId}
              className={`drs-category-item ${selectedDrsCategory === item.disruptionRiskScaleCategoryId && 'active'}`}
              onClick={() => this.onDrsCategoryChange(item.disruptionRiskScaleCategoryId)}
            >
              <i className={`fa fa-${icons[item.disruptionRiskScaleCategoryId - 1]} drs-category-icon`} />
              <div>{item.name.replace('/', ' / ')}</div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
