import { PURCHASE_COUNT_UPDATE } from './PurchaseAction';

const initialState = {};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE_COUNT_UPDATE:
      return { ...state, purchaseCount: action.purchaseCount };
    default:
      return state;
  }
};

export default purchaseReducer;
