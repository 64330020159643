import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import Card from '../../../components/Card/Card';
import Alert from '../../../components/Alert/Alert';
import Modal from '../../../components/Modal/Modal';
import BusinessEdit from './BusinessEdit';

const BusinessCreateWizard = () => (
  <div className="component-wrapper">
    <Card title="Create Business">
      <Alert
        message="No business, please create new."
        button={
          <Modal
            explanation
            toggle={show => (
              <Button type="primary" icon="plus" onClick={show}>
                Create New
              </Button>
            )}
          >
            {(hide, setExplanation) => <BusinessEdit close={hide} onExplanationChange={setExplanation} />}
          </Modal>
        }
      />
    </Card>
  </div>
);

export default withRouter(BusinessCreateWizard);
