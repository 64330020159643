import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popconfirm, Divider, Spin, Tabs, Button, message } from 'antd';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import Table from '../../../../components/Table/Table';
import ProductEdit from './components/ProductEdit';
import { fetchProduct, deleteProduct, fetchStrategicCriticalAreaCategories } from './ProductAction';
import { orgIdSelector } from '../../OrganisationSelectors';
import {
  productsSelector,
  strategicCriticalAreaCategoriesSelector,
  loadingStrategicAreaCategoriesSelector,
  loadingStrategicAreasSelector
} from './ProductSelectors';
import explanations from './data/explanations.json';
import { MSG_ERROR } from '../../../../constants';

const { TabPane } = Tabs;
const INFO_MSG = `This area of the site is designed to identify and record the key pieces of equipment, product lines, services and other strategically important components of the organization. These are areas that with their loss it would significantly damage the organization's brand or the ongoing viability of the organization.`;

class Product extends Component {
  state = {
    activeCategoryKey: null,
    activeCategoryId: null
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { orgId, fetchProduct, fetchStrategicCriticalAreaCategories } = this.props;
    const { activeCategoryKey } = this.state;
    fetchProduct(orgId);
    await fetchStrategicCriticalAreaCategories();
    const { strategicCriticalAreaCategories } = this.props;
    if (strategicCriticalAreaCategories && strategicCriticalAreaCategories.length > 0 && !activeCategoryKey) {
      const activeCategoryKey = strategicCriticalAreaCategories[0].name;
      const activeCategoryId = strategicCriticalAreaCategories[0].strategicCriticalAreaCategoryId;
      this.setState({ activeCategoryKey, activeCategoryId });
    }
  };

  onChange = key => {
    const { strategicCriticalAreaCategories = [] } = this.props;
    const activeCategory = strategicCriticalAreaCategories.find(item => item.name === key);
    const activeCategoryId = activeCategory.strategicCriticalAreaCategoryId;
    this.setState({ activeCategoryKey: key, activeCategoryId });
  };

  onDelete = async product => {
    const { orgId, deleteProduct } = this.props;
    try {
      await deleteProduct(orgId, product.strategicCriticalAreaId);
      message.success('The strategic area has been successfully deleted');
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  render() {
    const { products, strategicCriticalAreaCategories = [], loading } = this.props;
    const { activeCategoryKey, activeCategoryId } = this.state;
    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
      { title: 'Category', key: 'category', render: (text, record) => record.strategicCriticalAreaCategory.name },
      { title: 'Description', dataIndex: 'description', key: 'description' },
      {
        key: 'action',
        width: 115,
        render: (text, record) => (
          <span>
            <Modal title={activeCategoryKey} toggle={show => <Button type="link" icon="edit" onClick={show} />}>
              {hide => <ProductEdit productInfo={record} activeCategoryId={activeCategoryId} close={hide} />}
            </Modal>
            <Divider type="vertical" />
            <Popconfirm title="Sure to delete?" onConfirm={() => this.onDelete(record)}>
              <Button type="link" icon="delete" />
            </Popconfirm>
          </span>
        )
      }
    ];

    return (
      <>
        <ProcessBar title="Strategic Areas" prev="/organization/strategic-crisis-experts" next="/organization/policy" />
        <InfoBox message={INFO_MSG} />

        <div className="component-wrapper">
          <Spin spinning={loading}>
            <Card title="Strategic Areas">
              <Tabs className="tabs" onChange={this.onChange}>
                {strategicCriticalAreaCategories.map(item => (
                  <TabPane tab={item.name} key={item.name}>
                    {explanations[item.name] && (
                      <InfoBox color="#F1C400" type="fill" message={explanations[item.name]} />
                    )}
                    <Table
                      title="Strategic Areas"
                      columns={columns}
                      dataSource={products[item.name]}
                      rowKey="strategicCriticalAreaId"
                      searchFields={['name', 'category', 'description']}
                    />
                  </TabPane>
                ))}
              </Tabs>
              <div className="btn-container">
                <Modal
                  title={activeCategoryKey}
                  toggle={show => (
                    <Button type="primary" icon="plus" onClick={show}>
                      Add new
                    </Button>
                  )}
                >
                  {hide => <ProductEdit activeCategoryId={activeCategoryId} close={hide} />}
                </Modal>
              </div>
            </Card>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  products: productsSelector(state),
  strategicCriticalAreaCategories: strategicCriticalAreaCategoriesSelector(state),
  loading: loadingStrategicAreaCategoriesSelector(state) || loadingStrategicAreasSelector(state)
});

const mapDispatchToProps = {
  fetchProduct,
  deleteProduct,
  fetchStrategicCriticalAreaCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
