import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_USER: 'user/REQUEST_USER',
  RECEIVE_USER: 'user/RECEIVE_USER',
  RECEIVE_OTP_VERIFICATION: 'user/RECEIVE_OTP_VERIFICATION',
  REQUEST_USER_SUBSCRIPTIONS: 'user/REQUEST_USER_SUBSCRIPTIONS',
  RECEIVE_USER_SUBSCRIPTIONS: 'user/RECEIVE_USER_SUBSCRIPTIONS',
  SET_SHOW_NOTIFICATION: 'user/SET_SHOW_NOTIFICATION'
};

const requestUser = () => ({ type: types.REQUEST_USER });
const receiveUser = data => ({ type: types.RECEIVE_USER, userInfo: data });
const receiveOtpVerification = () => ({ type: types.RECEIVE_OTP_VERIFICATION });
const requestUserSubscriptions = () => ({ type: types.REQUEST_USER_SUBSCRIPTIONS });
const receiveUserSubscriptions = data => ({ type: types.RECEIVE_USER_SUBSCRIPTIONS, subscriptions: data });

export const fetchUser = () => async dispatch => {
  dispatch(requestUser());
  const url = `${API_BASE}user`;
  try {
    const res = await axios.get(url);
    dispatch(receiveUser(res.data));
  } catch (err) {
    dispatch(receiveUser());
  }
};

export const setShowNotification = showNotification => dispatch => {
  dispatch({ type: types.SET_SHOW_NOTIFICATION, showNotification });
};

export const sendMobileOtp = values => async dispatch => {
  dispatch(requestUser());
  const url = `${API_BASE}user/sendmobileotp`;
  try {
    const res = await axios.post(url, values);
    dispatch(receiveOtpVerification());
    return Promise.resolve(res);
  } catch (err) {
    const reason = err?.data || err?.response?.data || 'Please enter a valid number';
    return Promise.reject(reason);
  } finally {
    dispatch(receiveOtpVerification());
  }
};

export const verifyMobileOtp = values => async dispatch => {
  dispatch(requestUser());
  const url = `${API_BASE}user/verifymobileotp`;
  try {
    const res = await axios.post(url, values);
    dispatch(fetchUser());
    return Promise.resolve(res);
  } catch (err) {
    const reason = err?.data || err?.response?.data || 'Please enter a valid code';
    return Promise.reject(reason);
  } finally {
    dispatch(receiveOtpVerification());
  }
};

export const updateUser = data => async dispatch => {
  dispatch(requestUser());
  const url = `${API_BASE}user`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchUser());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveUser());
    return Promise.reject(err);
  }
};

/**
 * Upload user avatar to the server
 * @param {file} file The image file that is uploaded
 */
export const uploadUserAvatar = file => async dispatch => {
  dispatch(requestUser());
  const url = `${API_BASE}user/avatar`;
  try {
    const res = await axios.put(url, file);
    dispatch(fetchUser());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveUser());
    return Promise.reject(err);
  }
};

export const fetchUserSubscriptions = () => async dispatch => {
  dispatch(requestUserSubscriptions());
  const url = `${API_BASE}user/subscriptions`;
  try {
    const res = await axios.get(url);
    dispatch(receiveUserSubscriptions(res.data));
  } catch (err) {
    dispatch(receiveUserSubscriptions());
  }
};
