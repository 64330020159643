import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import { withOnFocus } from '../../../../../components/HOC';
import AddressSearchBox from '../../../../../components/AddressSearchBox/AddressSearchBox';
import IndustrySelectBox from '../../../../Organisation/components/IndustrySelectBox';
import { createOrgFromBroker } from '../../Broker/BrokerActions';
import { requestBrokerOrgsSelector } from '../../Broker/BrokerSelectors';
import { MSG_ERROR } from '../../../../../constants';
import explanations from '../../../../Organisation/data/explanations.json';

const FormItem = Form.Item;
const { TextArea } = Input;
const DEFAULT_BRANCH_NAME = 'Head Office';

const MSG_CREATE_SUCCESS = 'Create successfully';
const MSG_CANNOT_CREATE_FOR_YOURSELF = 'Sorry, you cannot create Organisation and Plan for yourself.';
const MSG_PAYMENT_REQUIRED = 'Sorry, there is not enough quota remaining.';
const MSG_FORBIDDEN = `Sorry, you don't have the authorization to create a organisation or plan.`;

class AddOrganisationEdit extends Component {
  constructor(props) {
    super(props);
    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.SelectWithOnFocus = withOnFocus(IndustrySelectBox, props.onExplanationChange);
    this.SearchBoxWithOnFocus = withOnFocus(AddressSearchBox, props.onExplanationChange);
    this.TextAreaWithOnFocus = withOnFocus(TextArea, props.onExplanationChange);
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, close, createOrgFromBroker } = this.props;
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const formData = new FormData();
        formData.append('legalName', fieldsValue.legalName);
        formData.append('name', fieldsValue.name);
        formData.append('address', fieldsValue.address);
        formData.append('userEmail', fieldsValue.userEmail);
        formData.append('industryName', fieldsValue.industry.industryName);
        formData.append('industryCode', fieldsValue.industry.industryCode);
        try {
          await createOrgFromBroker(formData);
          message.success(MSG_CREATE_SUCCESS);
          close();
        } catch (err) {
          const status = err ? err.status : null;
          switch (status) {
            case HttpStatus.BAD_REQUEST:
              message.error(MSG_CANNOT_CREATE_FOR_YOURSELF);
              break;
            case HttpStatus.PAYMENT_REQUIRED:
              message.error(MSG_PAYMENT_REQUIRED);
              break;
            case HttpStatus.FORBIDDEN:
              message.error(MSG_FORBIDDEN);
              break;
            default:
              message.error(MSG_ERROR);
              break;
          }
        }
      }
    });
  };

  validateIndustry = (rule, value, callback) => {
    if (value.industryCode && value.industryName) {
      callback();
    }
    callback('Please select industry.');
  };

  render() {
    const { InputWithOnFocus, SelectWithOnFocus, SearchBoxWithOnFocus } = this;
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form>
        <FormItem label="Full Business Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input the name.' }]
          })(
            <InputWithOnFocus
              placeholder="Full Business Name"
              disabled={loading}
              explanation={explanations.fullBusinessName}
            />
          )}
        </FormItem>
        <FormItem label="Trading Name">
          {getFieldDecorator('legalName', {
            rules: [{ required: true, message: 'Please input the trading name.' }]
          })(<InputWithOnFocus placeholder="Trading Name" disabled={loading} explanation={explanations.tradingName} />)}
        </FormItem>

        <FormItem label="Industry">
          {getFieldDecorator('industry', {
            rules: [{ required: true, message: 'Please select industry.' }, { validator: this.validateIndustry }]
          })(<SelectWithOnFocus disabled={loading} explanation={explanations.industry} />)}
        </FormItem>
        <FormItem label={`${DEFAULT_BRANCH_NAME} Address`}>
          {getFieldDecorator('address', {
            rules: [{ required: true, message: 'Please input the address.' }]
          })(<SearchBoxWithOnFocus placeholder="Address" disabled={loading} explanation={explanations.address} />)}
        </FormItem>

        <FormItem label="User Email">
          {getFieldDecorator('userEmail', {
            rules: [
              { type: 'email', message: 'Please input an valid email.' },
              { required: true, message: 'Please input a user email.' }
            ]
          })(<InputWithOnFocus placeholder="User Email" disabled={loading} />)}
        </FormItem>

        <FormItem>
          <Button type="primary" block loading={loading} onClick={this.onSubmit}>
            {loading ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  loading: requestBrokerOrgsSelector(state)
});

const mapDispatchToProps = { createOrgFromBroker };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Form.create()(AddOrganisationEdit));
