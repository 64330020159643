import axios from 'axios';
import { recoveryStrategyTypesSelector } from './RecoveryStrategySelectors';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_RECOVERY_STRATEGY_TYPES: 'recovery/REQUEST_RECOVERY_STRATEGY_TYPES',
  RECEIVE_RECOVERY_STRATEGY_TYPES: 'recovery/RECEIVE_RECOVERY_STRATEGY_TYPES',
  REQUEST_RECOVERY_STRATEGIES: 'recovery/REQUEST_RECOVERY_STRATEGIES',
  RECEIVE_RECOVERY_STRATEGIES: 'recovery/RECEIVE_RECOVERY_STRATEGIES'
};

const requestRecoveryStrategyTypes = () => ({ type: types.REQUEST_RECOVERY_STRATEGY_TYPES });
const receiveRecoveryStrategyTypes = data => ({
  type: types.RECEIVE_RECOVERY_STRATEGY_TYPES,
  recoveryStrategyTypes: data
});

const requestRecoveryStrategies = () => ({ type: types.REQUEST_RECOVERY_STRATEGIES });
const receiveRecoveryStrategies = data => ({ type: types.RECEIVE_RECOVERY_STRATEGIES, recoveryStrategy: data });

export const fetchRecoveryStrategyTypes = () => async (dispatch, getState) => {
  if (recoveryStrategyTypesSelector(getState())) {
    return;
  }
  dispatch(requestRecoveryStrategyTypes());
  const url = `${API_BASE}recovery_strategy_types`;
  try {
    const res = await axios.get(url);
    dispatch(receiveRecoveryStrategyTypes(res.data));
  } catch (err) {
    dispatch(receiveRecoveryStrategyTypes());
  }
};

export const fetchRecoveryStrategy = planId => async dispatch => {
  dispatch(requestRecoveryStrategies());
  const url = `${API_BASE}plans/${planId}/recovery_strategies`;
  try {
    const res = await axios.get(url);
    dispatch(receiveRecoveryStrategies(res.data));
  } catch (err) {
    dispatch(receiveRecoveryStrategies());
  }
};

export const createRecoveryStrategy = (planId, data) => dispatch => {
  dispatch(requestRecoveryStrategies());
  const url = `${API_BASE}plans/${planId}/recovery_strategies/create`;
  return axios.post(url, data);
};

export const updateRecoveryStrategy = (planId, data) => dispatch => {
  dispatch(requestRecoveryStrategies());
  const url = `${API_BASE}plans/${planId}/recovery_strategies/update`;
  return axios.post(url, data);
};

export const deleteRecoveryStrategy = planId => async dispatch => {
  const url = `${API_BASE}plans/${planId}/recovery_strategies/delete`;
  try {
    await axios.post(url);
    dispatch(fetchRecoveryStrategy(planId));
  } catch (err) {
    dispatch(receiveRecoveryStrategies());
  }
};
