import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, InputNumber, Button, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import { createPromoCode } from '../../../../Broker/BrokerActions';
import { loadingBrokerSelector, loadingBrokerageSubscriptionsSelector } from '../../../../Broker/BrokerSelectors';
import { MSG_ERROR } from '../../../../../../../constants';

const FormItem = Form.Item;

const CreatePromoCodeForm = ({ form, loading, createPromoCode, close }) => {
  const { getFieldDecorator } = form;

  const submit = e => {
    e.preventDefault();
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          acc[key] =
            fieldsValue[key] && typeof fieldsValue[key] === 'string' ? fieldsValue[key].trim() : fieldsValue[key];
          return acc;
        }, {});
        try {
          await createPromoCode(values);
          message.success(`The promo code '${values.code}' has been successfully created`);
          close();
        } catch (err) {
          if (err && err.status === HttpStatus.CONFLICT) {
            message.error(err.data);
          } else {
            message.error(MSG_ERROR);
          }
        }
      }
    });
  };

  return (
    <Form>
      <FormItem label="Quantity">
        {getFieldDecorator('quantity', {
          rules: [{ required: true, message: 'Please input the quantity.' }]
        })(<InputNumber min={0} placeholder="Quantity" disabled={loading} style={{ width: '100% ' }} />)}
      </FormItem>
      <FormItem label="Code">
        {getFieldDecorator('code', {
          rules: [{ required: true, message: 'Please input the code.' }]
        })(<Input placeholder="Code" disabled={loading} spellCheck={false} />)}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loading} onClick={submit}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  loading: loadingBrokerageSubscriptionsSelector(state) || loadingBrokerSelector(state)
});

const mapDispatchToProps = { createPromoCode };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreatePromoCodeForm));
