import {
  FETCH_RISKS,
  FETCH_RISK_CATEGORIES,
  FETCH_RISK_PRESETS,
  FETCH_RISK_LIKELIHOOD_PRESETS,
  FETCH_MITIGATION_STRATEGIES,
  FETCH_MITIGATION_STATUS,
  RISK_REQUEST,
  FETCH_RISK_INDUSTRY,
  FETCH_RISK_EXPOSURE,
  FETCH_RISK_REPORT_INDEMNITY_PRESETS,
  FETCH_RISK_REPORT_REVIEW_PRESETS,
  FETCH_RISK_REPORT
} from './RiskAction';

const initialState = { riskRequest: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RISKS:
      return { ...state, riskList: action.riskList, risks: action.risks };
    case FETCH_RISK_CATEGORIES:
      return { ...state, riskCategories: action.riskCategories };
    case FETCH_RISK_PRESETS:
      return { ...state, riskPresets: action.riskPresets };
    case FETCH_RISK_LIKELIHOOD_PRESETS:
      return { ...state, riskLikelihoodPresets: action.riskLikelihoodPresets };
    case FETCH_MITIGATION_STRATEGIES:
      return { ...state, mitigationStrategies: action.mitigationStrategies };
    case FETCH_MITIGATION_STATUS:
      return { ...state, mitigationStatus: action.mitigationStatus };
    case RISK_REQUEST:
      return { ...state, riskRequest: action.riskRequest };
    case FETCH_RISK_INDUSTRY:
      return { ...state, riskIndustry: action.riskIndustry };
    case FETCH_RISK_EXPOSURE:
      return { ...state, riskExposures: action.riskExposures };
    case FETCH_RISK_REPORT_INDEMNITY_PRESETS:
      return { ...state, riskReportIndemnityPresets: action.riskReportIndemnityPresets };
    case FETCH_RISK_REPORT_REVIEW_PRESETS:
      return { ...state, riskReportReviewPresets: action.riskReportReviewPresets };
    case FETCH_RISK_REPORT:
      return { ...state, riskReport: action.riskReport };
    default:
      return state;
  }
};
