import axios from 'axios';
import {
  mtpdListSelector,
  disruptionRiskScalesSelector,
  drsCategoriesSelector,
  assetTypesSelector
} from './BiaSelectors';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_MTPD: 'bia/REQUEST_MTPD',
  RECEIVE_MTPD: 'bia/RECEIVE_MTPD',
  REQUEST_DISRUPTION_RISK_SCALES: 'bia/REQUEST_DISRUPTION_RISK_SCALES',
  RECEIVE_DISRUPTION_RISK_SCALES: 'bia/RECEIVE_DISRUPTION_RISK_SCALES',
  REQUEST_DISRUPTION_RISK_SCALE_CATEGORIES: 'bia/REQUEST_DISRUPTION_RISK_SCALE_CATEGORIES',
  RECEIVE_DISRUPTION_RISK_SCALE_CATEGORIES: 'bia/RECEIVE_DISRUPTION_RISK_SCALE_CATEGORIES',
  REQUEST_STRATEGIC_BIAS: 'bia/REQUEST_STRATEGIC_BIAS',
  RECEIVE_STRATEGIC_BIAS: 'bia/RECEIVE_STRATEGIC_BIAS',
  REQUEST_TACTICAL_BIAS: 'bia/REQUEST_TACTICAL_BIAS',
  RECEIVE_TACTICAL_BIAS: 'bia/RECEIVE_TACTICAL_BIAS',
  REQUEST_CRITICAL_PERIODS: 'bia/REQUEST_CRITICAL_PERIODS',
  RECEIVE_CRITICAL_PERIODS: 'bia/RECEIVE_CRITICAL_PERIODS',
  REQUEST_ASSET_TYPES: 'bia/REQUEST_ASSET_TYPES',
  RECEIVE_ASSET_TYPES: 'bia/RECEIVE_ASSET_TYPES',
  REQUEST_ASSETS: 'bia/REQUEST_ASSETS',
  RECEIVE_ASSETS: 'bia/RECEIVE_ASSETS'
};

const requestMtpd = () => ({ type: types.REQUEST_MTPD });
const receiveMtpd = data => ({ type: types.RECEIVE_MTPD, mtpdList: data });

const requestDisruptionRiskScales = () => ({ type: types.REQUEST_DISRUPTION_RISK_SCALES });
const receiveDisruptionRiskScales = data => ({
  type: types.RECEIVE_DISRUPTION_RISK_SCALES,
  disruptionRiskScales: data
});

const requestDrsCategories = () => ({ type: types.REQUEST_DISRUPTION_RISK_SCALE_CATEGORIES });
const receiveDrsCategories = data => ({ type: types.RECEIVE_DISRUPTION_RISK_SCALE_CATEGORIES, drsCategories: data });

const requestStrategicBias = () => ({ type: types.REQUEST_STRATEGIC_BIAS });
const receiveStrategicBias = data => ({ type: types.RECEIVE_STRATEGIC_BIAS, biaList: data });

const requestTacticalBias = () => ({ type: types.REQUEST_TACTICAL_BIAS });
const receiveTacticalBias = data => ({ type: types.RECEIVE_TACTICAL_BIAS, tacticalBias: data });

const requestCriticalPeriods = () => ({ type: types.REQUEST_CRITICAL_PERIODS });
const receiveCriticalPeriods = data => ({ type: types.RECEIVE_CRITICAL_PERIODS, criticalPeriodPresets: data });

const requestAssetTypes = () => ({ type: types.REQUEST_ASSET_TYPES });
const receiveAssetTypes = data => ({ type: types.RECEIVE_ASSET_TYPES, assetTypes: data });

const requestAssets = () => ({ type: types.REQUEST_ASSETS });
const receiveAssets = data => ({ type: types.RECEIVE_ASSETS, assets: data });

export const fetchMtpd = () => async (dispatch, getState) => {
  if (mtpdListSelector(getState())) {
    return;
  }
  dispatch(requestMtpd());
  const url = `${API_BASE}mtpd`;
  try {
    const res = await axios.get(url);
    dispatch(receiveMtpd(res.data));
  } catch (err) {
    dispatch(receiveMtpd());
  }
};

export const fetchDisruptionRiskScales = () => async (dispatch, getState) => {
  if (disruptionRiskScalesSelector(getState())) {
    return;
  }
  dispatch(requestDisruptionRiskScales());
  const url = `${API_BASE}disruption_risk_scales`;
  try {
    const res = await axios.get(url);
    dispatch(receiveDisruptionRiskScales(res.data));
  } catch (err) {
    dispatch(receiveDisruptionRiskScales());
  }
};

export const fetchDrsCategories = () => async (dispatch, getState) => {
  if (drsCategoriesSelector(getState())) {
    return;
  }
  dispatch(requestDrsCategories());
  const url = `${API_BASE}disruption_risk_scale_categories`;
  try {
    const res = await axios.get(url);
    dispatch(receiveDrsCategories(res.data));
  } catch (err) {
    dispatch(receiveDrsCategories());
  }
};

export const fetchStrategicBia = planId => async dispatch => {
  dispatch(requestStrategicBias());
  const url = `${API_BASE}plans/${planId}/strategic_bias`;
  try {
    const res = await axios.get(url);
    dispatch(receiveStrategicBias(res.data));
  } catch (err) {
    dispatch(receiveStrategicBias());
  }
};

export const createStrategicBia = (planId, data) => async dispatch => {
  dispatch(requestStrategicBias());
  const url = `${API_BASE}plans/${planId}/strategic_bias`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchStrategicBia(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicBias());
    return Promise.reject(err);
  }
};

export const updateStrategicBia = (planId, sbId, data) => async dispatch => {
  dispatch(requestStrategicBias());
  const url = `${API_BASE}plans/${planId}/strategic_bias/${sbId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchStrategicBia(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicBias());
    return Promise.reject(err);
  }
};

export const deleteStrategicBia = (planId, sbId) => async dispatch => {
  dispatch(requestStrategicBias());
  const url = `${API_BASE}plans/${planId}/strategic_bias/${sbId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchStrategicBia(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicBias());
    return Promise.reject(err);
  }
};

export const fetchTacticalBia = planId => async dispatch => {
  dispatch(requestTacticalBias());
  const url = `${API_BASE}plans/${planId}/tactical_bias`;
  try {
    const res = await axios.get(url);
    dispatch(receiveTacticalBias(res.data));
  } catch (err) {
    dispatch(receiveTacticalBias());
  }
};

export const createTacticalBia = (planId, data) => async dispatch => {
  dispatch(requestTacticalBias());
  const url = `${API_BASE}plans/${planId}/tactical_bias/create`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchTacticalBia(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveTacticalBias());
    return Promise.reject(err);
  }
};

export const updateTacticalBia = (planId, data) => async dispatch => {
  dispatch(requestTacticalBias());
  const url = `${API_BASE}plans/${planId}/tactical_bias/update`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchTacticalBia(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveTacticalBias());
    return Promise.reject(err);
  }
};

export const deleteTacticalBia = (planId, data) => async dispatch => {
  dispatch(requestTacticalBias());
  const url = `${API_BASE}plans/${planId}/tactical_bias/remove`;
  try {
    const res = await axios.delete(url, data);
    dispatch(fetchTacticalBia(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveTacticalBias());
    return Promise.reject(err);
  }
};

export const fetchCriticalPeriods = planId => async dispatch => {
  dispatch(requestCriticalPeriods());
  const url = `${API_BASE}plans/${planId}/critical_periods`;
  try {
    const res = await axios.get(url);
    dispatch(receiveCriticalPeriods(res.data));
  } catch (err) {
    dispatch(receiveCriticalPeriods());
  }
};

export const fetchAssetTypes = () => async (dispatch, getState) => {
  if (assetTypesSelector(getState())) {
    return;
  }
  dispatch(requestAssetTypes());
  const url = `${API_BASE}asset_types`;
  try {
    const res = await axios.get(url);
    dispatch(receiveAssetTypes(res.data));
  } catch (err) {
    dispatch(receiveAssetTypes());
  }
};

export const fetchAssets = planId => async dispatch => {
  dispatch(requestAssets());
  const url = `${API_BASE}plans/${planId}/assets`;
  try {
    const res = await axios.get(url);
    dispatch(receiveAssets(res.data));
  } catch (err) {
    dispatch(receiveAssets());
  }
};

export const createAssets = (planId, data) => async dispatch => {
  dispatch(requestAssets());
  const url = `${API_BASE}plans/${planId}/assets/create`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchAssets(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveAssets());
    return Promise.reject(err);
  }
};

export const updateAssets = (planId, data) => async dispatch => {
  dispatch(requestAssets());
  const url = `${API_BASE}plans/${planId}/assets/update`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchAssets(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveAssets());
    return Promise.reject(err);
  }
};

export const deleteAssets = (planId, data) => async dispatch => {
  dispatch(requestAssets());
  const url = `${API_BASE}plans/${planId}/assets/delete`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchAssets(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveAssets());
    return Promise.reject(err);
  }
};

export const downloadAssetImportTemplate = planId => () => {
  const url = `${API_BASE}plans/${planId}/assets/asset_import_template`;
  return axios.get(url, { responseType: 'blob' });
};

export const importAssets = (planId, file) => async dispatch => {
  dispatch(requestAssets());
  const url = `${API_BASE}plans/${planId}/assets/import`;
  try {
    const res = await axios.post(url, file);
    dispatch(fetchAssets(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveAssets());
    return Promise.reject(err);
  }
};

export const exportAssets = planId => () => {
  const url = `${API_BASE}plans/${planId}/assets/asset_export_template`;
  return axios.get(url, { responseType: 'blob' });
};
