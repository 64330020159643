import axios from 'axios';
import _ from 'lodash';
import { createBranch } from './scenes/Branch/BranchAction';
import { fetchPlans } from '../Dashboard/scenes/Plan/PlanAction';
import { fetchUser } from '../Admin/scenes/User/UserActions';
import { rolesSelector, orgIdSelector } from './OrganisationSelectors';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_RISK_COACH = process.env.REACT_APP_API_RISK_COACH;
const RISK_COACH_API_KEY = process.env.REACT_APP_RISK_COACH_API_KEY;
const DEFAULT_BRANCH_NAME = 'Head Office';

export const types = {
  REQUEST_ORGANISATION: 'organisation/REQUEST_ORGANISATION',
  RECEIVE_ORGANISATION: 'organisation/RECEIVE_ORGANISATION',
  REQUEST_ORGANISATIONS: 'organisation/REQUEST_ORGANISATIONS',
  RECEIVE_ORGANISATIONS: 'organisation/RECEIVE_ORGANISATIONS',
  REQUEST_HAZARD_INDEX: 'organisation/REQUEST_HAZARD_INDEX',
  RECEIVE_HAZARD_INDEX: 'organisation/RECEIVE_HAZARD_INDEX',
  REQUEST_ROLES: 'organisation/REQUEST_ROLES',
  RECEIVE_ROLES: 'organisation/RECEIVE_ROLES',
  REQUEST_ORGANISATION_USERS: 'organisation/REQUEST_ORGANISATION_USERS',
  RECEIVE_ORGANISATION_USERS: 'organisation/RECEIVE_ORGANISATION_USERS',
  REQUEST_ORGANISATION_INVITEES: 'organisation/REQUEST_ORGANISATION_INVITEES',
  RECEIVE_ORGANISATION_INVITEES: 'organisation/RECEIVE_ORGANISATION_INVITEES',
  REQUEST_ORGANISATION_BRANCHES: 'organisation/REQUEST_ORGANISATION_BRANCHES',
  RECEIVE_ORGANISATION_BRANCHES: 'organisation/RECEIVE_ORGANISATION_BRANCHES',
  SWITCH_ORGANISATION: 'organisation/SWITCH_ORGANISATION',
  STORE_GEOCODE: 'organisation/STORE_GEOCODE'
};

const requestOrganisation = () => ({ type: types.REQUEST_ORGANISATION });
const receiveOrganisation = data => ({ type: types.RECEIVE_ORGANISATION, businessInfo: data });

const requestOrganisations = () => ({ type: types.REQUEST_ORGANISATIONS });
const receiveOrganisations = data => ({ type: types.RECEIVE_ORGANISATIONS, organisationList: data });

const requestHazardIndex = () => ({ type: types.REQUEST_HAZARD_INDEX });
const receiveHazardIndex = data => ({ type: types.RECEIVE_HAZARD_INDEX, hazardIndex: data });

const requestRoles = () => ({ type: types.REQUEST_ROLES });
const receiveRoles = data => ({ type: types.RECEIVE_ROLES, roles: data });

const requestOrganisationUsers = () => ({ type: types.REQUEST_ORGANISATION_USERS });
const receiveOrganisationUsers = data => ({ type: types.RECEIVE_ORGANISATION_USERS, organisationUsers: data });

const requestOrganisationInvitees = () => ({ type: types.REQUEST_ORGANISATION_INVITEES });
const receiveOrganisationInvitees = data => ({
  type: types.RECEIVE_ORGANISATION_INVITEES,
  organisationInvitees: data
});

export const requestOrganisationBranches = () => ({ type: types.REQUEST_ORGANISATION_BRANCHES });
export const receiveOrganisationBranches = (orgId, data) => ({
  type: types.RECEIVE_ORGANISATION_BRANCHES,
  orgId,
  orgBranches: data
});

export const storeGeoCode = geoCode => ({ type: types.STORE_GEOCODE, geoCode });

export const switchOrganisation = () => ({ type: types.SWITCH_ORGANISATION });

export const fetchBusiness = orgId => async (dispatch, getState) => {
  dispatch(requestOrganisation());
  const url = `${API_BASE}orgs/${orgId || orgIdSelector(getState())}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveOrganisation(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisation());
    return Promise.reject(err);
  }
};

export const fetchOrganisations = () => async (dispatch, getState) => {
  dispatch(requestOrganisations());
  const url = `${API_BASE}orgs`;
  try {
    const res = await axios.get(url, {
      params: {
        archived: true
      }
    });
    const organisationList = res.data;
    dispatch(receiveOrganisations(organisationList));
    const selectedOrgId = orgIdSelector(getState());
    if (!selectedOrgId) {
      let organisationInfo = {};
      if (organisationList && organisationList.length > 0) {
        const defaultOrganisation = _.find(organisationList, { isDefault: true });
        organisationInfo = defaultOrganisation || organisationList[0];
      }
      dispatch(receiveOrganisation(organisationInfo));
    }
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisations());
    return Promise.reject(err);
  }
};

export const setArchive = (orgId, data) => async dispatch => {
  const url = `${API_BASE}orgs/${orgId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchOrganisations());
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const selectOrganisation = orgId => async dispatch => {
  await dispatch(switchOrganisation());
  await dispatch(fetchBusiness(orgId));
  await dispatch(fetchPlans(orgId));
};

export const setDefaultOrganisation = orgId => async dispatch => {
  const url = `${API_BASE}orgs/${orgId}/default`;
  try {
    await axios.patch(url);
    dispatch(fetchOrganisations());
  } catch (err) {
    console.error(err);
  }
};

export const createBusiness = data => async dispatch => {
  dispatch(requestOrganisations());
  const url = `${API_BASE}orgs`;
  try {
    const res = await axios.post(url, data);
    const orgId = res.data.organisationId;
    dispatch(fetchOrganisations());
    dispatch(fetchUser());
    dispatch(selectOrganisation(orgId));
    const branchData = { name: DEFAULT_BRANCH_NAME, address: data.address };
    dispatch(createBranch(orgId, branchData));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisations());
    return Promise.reject(err);
  }
};

export const updateBusiness = (orgId, data) => async dispatch => {
  dispatch(requestOrganisation());
  const url = `${API_BASE}orgs/${orgId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchBusiness(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisation());
    return Promise.reject(err);
  }
};

/**
 * Upload organisation logo to the server
 * @param {number} orgId The organisation Id
 * @param {file} file The image file that is uploaded
 */
export const uploadOrganisationLogo = (orgId, file) => async dispatch => {
  const url = `${API_BASE}orgs/${orgId}/logo`;
  try {
    const res = await axios.put(url, file);
    dispatch(fetchBusiness(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisation());
    return Promise.reject(err);
  }
};

export const fetchHazardIndex = industryId => async dispatch => {
  dispatch(requestHazardIndex());
  const url = `${API_RISK_COACH}industries/${industryId}/hazard_index`;
  try {
    const instance = axios.create({
      baseURL: url,
      headers: { 'x-api-key': RISK_COACH_API_KEY, Accept: 'application/json;v=1.0' }
    });
    const res = await instance.get();
    dispatch(receiveHazardIndex(res.data));
  } catch (err) {
    dispatch(receiveHazardIndex());
  }
};

export const fetchRoles = () => async (dispatch, getState) => {
  if (rolesSelector(getState())) {
    return;
  }
  dispatch(requestRoles());
  const url = `${API_BASE}roles`;
  try {
    const res = await axios.get(url);
    dispatch(receiveRoles(res.data));
  } catch (err) {
    dispatch(receiveRoles());
  }
};

export const fetchOrganisationUsers = orgId => async dispatch => {
  dispatch(requestOrganisationUsers());
  const url = `${API_BASE}orgs/${orgId}/users`;
  try {
    const res = await axios.get(url);
    dispatch(receiveOrganisationUsers(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisationUsers());
    return Promise.reject(err);
  }
};

export const fetchOrganisationInvitees = orgId => async dispatch => {
  dispatch(requestOrganisationInvitees());
  const url = `${API_BASE}orgs/${orgId}/invitees`;
  try {
    const res = await axios.get(url);
    dispatch(receiveOrganisationInvitees(res.data));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisationInvitees());
    return Promise.reject(err);
  }
};

export const inviteOrganisationUser = (orgId, data) => async dispatch => {
  dispatch(requestOrganisationUsers());
  const url = `${API_BASE}orgs/${orgId}/invitees`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchOrganisationUsers(orgId));
    dispatch(fetchOrganisationInvitees(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisationUsers());
    return Promise.reject(err);
  }
};

export const updateOrganisationUser = (orgId, userId, data) => async dispatch => {
  dispatch(requestOrganisationUsers());
  const url = `${API_BASE}orgs/${orgId}/users/${userId}`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchOrganisationUsers(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisationUsers());
    return Promise.reject(err);
  }
};

export const revokeOrganisationUser = (orgId, data) => async dispatch => {
  dispatch(requestOrganisationUsers());
  const url = `${API_BASE}orgs/${orgId}/users/revoke`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchOrganisationUsers(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveOrganisationUsers());
    return Promise.reject(err);
  }
};

export const resendInvitation = data => () => {
  const url = `${API_BASE}invitation/email/resend`;
  return axios.post(url, data);
};

export const acceptOrganisationInvitation = (orgId, data) => () => {
  const url = `${API_BASE}orgs/${orgId}/invitees/accept`;
  return axios.post(url, data);
};
