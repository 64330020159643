import { types } from './CustomerAction';
import { types as organisationTypes } from '../../OrganisationAction';

const initialState = { loadingCustomers: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case organisationTypes.SWITCH_ORGANISATION:
      return { ...initialState };
    case types.REQUEST_CUSTOMERS:
      return { ...state, loadingCustomers: true };
    case types.RECEIVE_CUSTOMERS:
      return { ...state, loadingCustomers: false, customerList: action.customerList || state.customerList };
    default:
      return state;
  }
};
