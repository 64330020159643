import React from 'react';
import { Icon } from 'antd';
import Layout from '../../components/Layout/Layout';
import Card from '../../components/Card/Card';
import './ContactUs.scss';
import bg from './images/bg.jpg';

export default () => (
  <Layout bg={bg}>
    <div className="contact-us">
      <div className="contact-us-info">
        <h1>Contact Us</h1>
        <p>Our sales and support teams are here to answer any questions you may have about Continuity Coach.</p>
        <p>Reach out to us and we will respond as soon as we can.</p>
        <div className="flex space-between contact-links">
          <a href="https://www.lmigroup.com" target="_blank" rel="noopener noreferrer">
            LMI GROUP
          </a>
          <a href="https://twitter.com/LMIGroup" target="_blank" rel="noopener noreferrer">
            <Icon type="twitter" />
          </a>
          <a href="https://www.facebook.com/LMIGroupPtyLtd/" target="_blank" rel="noopener noreferrer">
            <Icon type="facebook" />
          </a>
          <a href="https://www.youtube.com/user/LMIGROUP" target="_blank" rel="noopener noreferrer">
            <Icon type="youtube" />
          </a>
        </div>
      </div>
      <Card className="contact-us-locations">
        <h1>Locations</h1>
        <p>Call us or send a message with any questions you may have.</p>
        <div className="contact-country">
          <h3>International Support</h3>
          <p>
            <a className="contact-email" href="mailto:support@continuitycoach.com">
              support@continuitycoach.com
            </a>
          </p>
          <p>
            <a className="contact-phone" href="tel:+61398359900">
              +61 3 9835 9900
            </a>
          </p>
        </div>
        <div className="contact-country">
          <h3>Australia</h3>
          <p>
            <a className="contact-email" href="mailto:aus@continuitycoach.com">
              aus@continuitycoach.com
            </a>
          </p>
        </div>
        <div className="contact-country">
          <h3>South Africa</h3>
          <p>
            <a className="contact-email" href="mailto:rsa@continuitycoach.com">
              rsa@continuitycoach.com
            </a>
          </p>
        </div>
        <div className="contact-country">
          <h3>New Zealand</h3>
          <p>
            <a className="contact-email" href="mailto:nz@continuitycoach.com">
              nz@continuitycoach.com
            </a>
          </p>
        </div>
      </Card>
    </div>
  </Layout>
);
