import React, { Fragment, useState } from 'react';
import Card from '../../../../../components/Card/Card';

const Item = ({ selectable, color, item, isSelect, onSelect }) => {
  const [hover, setHover] = useState(false);
  const active = hover || isSelect(item.id);

  const toggleHover = () => {
    setHover(!hover);
  };

  return selectable ? (
    <li
      role="menuitem"
      className="nav-item"
      onClick={() => onSelect(item)}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onKeyDown={e => e.preventDefault()}
      style={{ color: active ? '#fff' : 'inherit', backgroundColor: active ? color : '#f0f0f0' }}
    >
      {item.name}
    </li>
  ) : (
    <li className="nav-item-unselectable" style={{ color }}>
      {item.name}
    </li>
  );
};

export default ({ color = '#f1c400', title, description, navList, selectable = true, selectedItem, onItemSelect }) => {
  const onSelect = item => {
    if (onItemSelect) {
      onItemSelect(item);
    }
  };

  const isSelect = id => selectedItem && id === selectedItem.id;

  return (
    navList.length > 0 && (
      <Card color={color} title={title} description={description}>
        <div className="bia-nav-list">
          {navList.map(([key, value]) => (
            <Fragment key={key}>
              <div className="nav-list-category">{key}</div>
              <ul>
                {value.map(item => (
                  <Item
                    key={item.id}
                    selectable={selectable}
                    color={color}
                    item={item}
                    isSelect={isSelect}
                    onSelect={onSelect}
                  />
                ))}
              </ul>
            </Fragment>
          ))}
        </div>
      </Card>
    )
  );
};
