import { createSelector } from 'reselect';
import _ from 'lodash';
import { allPlansSelector } from '../Dashboard/scenes/Plan/PlanSelectors';

export const loadingOrganisationSelector = state => state.organisationReducer.loadingOrganisation;

export const businessInfoSelector = state => state.organisationReducer.businessInfo;

export const orgIdSelector = createSelector(businessInfoSelector, businessInfo =>
  businessInfo ? businessInfo.organisationId : null
);

export const businessNameSelector = createSelector(businessInfoSelector, businessInfo =>
  businessInfo ? businessInfo.name : ''
);

export const businessLogoSelector = createSelector(businessInfoSelector, businessInfo =>
  businessInfo ? businessInfo.avatarUrl : null
);

export const governanceSelector = createSelector(businessInfoSelector, businessInfo =>
  businessInfo ? businessInfo.governance : null
);

export const industryCodeSelector = createSelector(businessInfoSelector, businessInfo =>
  businessInfo ? businessInfo.industryCode : null
);

export const industryNameSelector = createSelector(businessInfoSelector, businessInfo =>
  businessInfo ? businessInfo.industryName : null
);

export const loadingHazardIndexSelector = state => state.organisationReducer.loadingHazardIndex;

export const hazardIndexSelector = createSelector(
  state => state.organisationReducer.hazardIndex,
  hazardIndex => hazardIndex
);

export const loadingOrganisationsSelector = state => state.organisationReducer.loadingOrganisations;

export const organisationListSelector = state =>
  [...state.organisationReducer.organisationList].filter(item => !item.isArchived);

export const loadingRolesSelector = state => state.organisationReducer.loadingRoles;

export const rolesSelector = state => state.organisationReducer.roles;

export const loadingOrganisationUsersSelector = state => state.organisationReducer.loadingOrganisationUsers;

export const organisationUsersSelector = state => state.organisationReducer.organisationUsers;

export const loadingOrganisationInviteesSelector = state => state.organisationReducer.loadingOrganisationInvitees;

export const organisationInviteesSelector = state => state.organisationReducer.organisationInvitees;

export const organisationPlansSelector = createSelector(
  state => state.organisationReducer.organisationList,
  allPlansSelector,
  (organisationList, allPlans) => {
    const branchOrganisationMapping = _.chain(organisationList)
      .flatMap(item =>
        _.map(item.branches, branch => ({
          branchId: branch.branchId,
          branchName: branch.name,
          organisationId: item.organisationId
        }))
      )
      .keyBy('branchId')
      .value();

    const groupedPlans = _.chain(allPlans)
      .map(item => ({
        ...item,
        organisationId: branchOrganisationMapping[item.branchId]?.organisationId,
        branch: branchOrganisationMapping[item.branchId]?.branchName
      }))
      .groupBy('organisationId')
      .value();

    return _.map(organisationList, item => ({
      avatarUrl: item.avatarUrl,
      organisationId: item.organisationId,
      name: item.name,
      industry: item.industryName,
      isArchived: item.isArchived,
      plans: groupedPlans[item.organisationId] || [],
      branch: item.branch
    }));
  }
);

export const geoCodeSelector = state => state.organisationReducer.geoCode;
