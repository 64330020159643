import React from 'react';
import { Icon, Button } from 'antd';
import Modal from '../Modal/Modal';
import MoreInfo from './MoreInfo';
import './InfoBox.scss';

export default ({
  message,
  color = '#f1c400',
  size = 'default',
  type = 'normal',
  more = false,
  moreBtnText = 'Learn More',
  video,
  image,
  moreMessage
}) => {
  const infoBoxStyle = {
    color: type === 'fill' ? '#fff' : 'inherit',
    backgroundColor: type === 'fill' ? color : '#fff',
    padding: size === 'large' ? '20px' : '10px 20px',
    borderLeft: type === 'fill' ? 'none' : `4px solid ${color}`
  };
  const infoBoxIconStyle = {
    color: type === 'fill' ? '#fff' : color,
    fontSize: size === 'large' ? '22px' : '16px'
  };
  const infoBoxBtnStyle = {
    color: type === 'fill' ? 'none' : '#fff',
    backgroundColor: type === 'fill' ? 'none' : color,
    borderColor: type === 'fill' ? 'none' : color
  };

  return (
    <div className="info-box-container" style={{ ...infoBoxStyle }}>
      <Icon type="info-circle-o" className="info-box-icon" style={{ ...infoBoxIconStyle }} />
      <div className="info-box-message">
        {typeof message === 'string' ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message}
        {more && (
          <Modal
            title="Learn More"
            width="auto"
            wrapClassName="more-info-modal"
            toggle={show => (
              <Button size="small" onClick={show} className="info-box-btn" style={{ ...infoBoxBtnStyle }}>
                {moreBtnText}
              </Button>
            )}
          >
            {() => <MoreInfo video={video} image={image} message={moreMessage} />}
          </Modal>
        )}
      </div>
    </div>
  );
};
