import axios from 'axios';
import { strategicThirdPartyCategoriesSelector } from './StrategicThirdPartySelectors';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_STRATEGIC_THIRD_PARTY_CATEGORIES: 'strategicThirdParty/REQUEST_STRATEGIC_THIRD_PARTY_CATEGORIES',
  RECEIVE_STRATEGIC_THIRD_PARTY_CATEGORIES: 'strategicThirdParty/RECEIVE_STRATEGIC_THIRD_PARTY_CATEGORIES',
  REQUEST_STRATEGIC_THIRD_PARTY: 'strategicThirdParty/REQUEST_STRATEGIC_THIRD_PARTY',
  RECEIVE_STRATEGIC_THIRD_PARTY: 'strategicThirdParty/RECEIVE_STRATEGIC_THIRD_PARTY'
};

const requestStrategicThirdPartyCategories = () => ({ type: types.REQUEST_STRATEGIC_THIRD_PARTY_CATEGORIES });
const receiveStrategicThirdPartyCategories = data => ({
  type: types.RECEIVE_STRATEGIC_THIRD_PARTY_CATEGORIES,
  strategicThirdPartyCategories: data
});

const requestStrategicThirdParty = () => ({ type: types.REQUEST_STRATEGIC_THIRD_PARTY });
const receiveStrategicThirdParty = data => ({
  type: types.RECEIVE_STRATEGIC_THIRD_PARTY,
  strategicThirdPartyList: data
});

export const fetchStrategicThirdParty = orgId => async dispatch => {
  dispatch(requestStrategicThirdParty());
  const url = `${API_BASE}orgs/${orgId}/strategic_third_parties`;
  try {
    const res = await axios.get(url);
    dispatch(receiveStrategicThirdParty(res.data));
  } catch (err) {
    dispatch(receiveStrategicThirdParty());
  }
};

export const createStrategicThirdParty = (orgId, data) => async dispatch => {
  dispatch(requestStrategicThirdParty());
  const url = `${API_BASE}orgs/${orgId}/strategic_third_parties`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchStrategicThirdParty(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicThirdParty());
    return Promise.reject(err);
  }
};

export const updateStrategicThirdParty = (orgId, strategicThirdPartyId, data) => async dispatch => {
  dispatch(requestStrategicThirdParty());
  const url = `${API_BASE}orgs/${orgId}/strategic_third_parties/${strategicThirdPartyId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchStrategicThirdParty(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicThirdParty());
    return Promise.reject(err);
  }
};

export const deleteStrategicThirdParty = (orgId, strategicThirdPartyId) => async dispatch => {
  dispatch(requestStrategicThirdParty());
  const url = `${API_BASE}orgs/${orgId}/strategic_third_parties/${strategicThirdPartyId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchStrategicThirdParty(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicThirdParty());
    return Promise.reject(err);
  }
};

export const importStrategicThirdParty = (orgId, file, classId) => async dispatch => {
  dispatch(requestStrategicThirdParty());
  const url = `${API_BASE}orgs/${orgId}/strategic_third_parties/import`;
  try {
    const res = await axios.post(url, file, { params: { classId } });
    dispatch(fetchStrategicThirdParty(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicThirdParty());
    return Promise.reject(err);
  }
};

export const fetchStrategicThirdPartyCategories = () => async (dispatch, getState) => {
  if (strategicThirdPartyCategoriesSelector(getState())) {
    return;
  }
  dispatch(requestStrategicThirdPartyCategories());
  const url = `${API_BASE}strategic_third_party_categories`;
  try {
    const res = await axios.get(url);
    dispatch(receiveStrategicThirdPartyCategories(res.data));
  } catch (err) {
    dispatch(receiveStrategicThirdPartyCategories());
  }
};

export const downloadStrategicThirdPartyImportTemplate = classId => () => {
  const url = `${API_BASE}strategic_third_party_import_template`;
  return axios.get(url, { params: { classId }, responseType: 'arraybuffer' });
};

export const exportStrategicThirdParty = (orgId, classId) => () => {
  const url = `${API_BASE}orgs/${orgId}/strategic_third_parties/export`;
  return axios.get(url, { params: { classId }, responseType: 'blob' });
};
