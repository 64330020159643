import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import Table from '../../../../../../../components/Table/Table';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../../../constants';

export default ({ promoCodes = [], revoke }) => {
  const onRevoke = async ({ code }) => {
    try {
      await revoke({ promoCode: code });
      message.success(MSG_SAVE_SUCCESS);
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const onCopy = key => {
    const input = document.createElement('input');
    input.value = key;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    message.success('Promo code copied');
  };

  const columns = [
    {
      key: 'copy',
      width: 40,
      render: (text, record) => <Button type="link" icon="copy" onClick={() => onCopy(record.code)} />
    },
    { key: 'code', title: 'Code', dataIndex: 'code' },
    { key: 'createdBy', title: 'Created By', dataIndex: 'brokerName' },
    {
      key: 'expiryDate',
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      sorter: (a, b) => new Date(a.exactExpiryDate).getTime() - new Date(b.exactExpiryDate).getTime()
    },
    { key: 'quantity', title: 'Quantity', dataIndex: 'quantity' },
    {
      key: 'revoked',
      title: 'Revoked',
      width: 100,
      filters: [{ text: 'Hide Revoked', value: true }],
      onFilter: (value, record) => !record.isRevoked,
      render: (text, record) => (record.isRevoked ? <span className="subscription-revoked">Yes</span> : <span>No</span>)
    },
    {
      key: 'action',
      width: 100,
      render: (text, record) =>
        !record.isRevoked && (
          <Popconfirm title="Sure to revoke?" onConfirm={() => onRevoke(record)}>
            <Button type="link">Revoke</Button>
          </Popconfirm>
        )
    }
  ];

  return (
    <Table
      title="Promo Codes"
      columns={columns}
      dataSource={promoCodes}
      rowKey="promoCodeId"
      searchFields={['code', 'brokerName', 'expiryDate']}
      style={{ paddingBottom: '60px' }}
      showExpiredToggle
    />
  );
};
