import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import PolicyDocument from './components/PolicyDocument';
import PolicyCreation from './components/PolicyCreation';
import { fetchLatestPolicy } from './PolicyAction';
import { orgIdSelector } from '../../OrganisationSelectors';
import './Policy.scss';

const Policy = ({ orgId, loading, fetchLatestPolicy }) => {
  const [creatingPolicy, setCreatingPolicy] = useState(false);

  useEffect(() => {
    fetchLatestPolicy(orgId);
  }, [orgId, fetchLatestPolicy]);

  const onToggle = () => {
    setCreatingPolicy(!creatingPolicy);
  };

  /* eslint no-nested-ternary: off */
  return loading ? (
    <Spin tip="Loading..." className="loading" />
  ) : creatingPolicy ? (
    <PolicyCreation back={onToggle} />
  ) : (
    <PolicyDocument create={onToggle} />
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  loading: state.policyReducer.loadingLatestPolicy
});

const mapDispatchToProps = { fetchLatestPolicy };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Policy);
