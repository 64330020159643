import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import './Page.scss';

export default ({ children, anchor, businessName, planName, date, color, pageNumber, dynamic = false }) => (
  <section className={classNames('plan-preview-page', { dynamic })} id={anchor}>
    {!dynamic && (
      <header>
        <div className="client-name">{businessName || 'client name'}</div>
        <div className="plan-document">{planName} BUSINESS CONTINUITY PLAN</div>
        <div className="header-bar" />
      </header>
    )}
    <main>{children}</main>
    {!dynamic && (
      <footer>
        <div className="date">{moment(date).format('DD MMMM YYYY')}</div>
        <div className="page-number" style={{ background: color }}>
          {pageNumber}
        </div>
      </footer>
    )}
  </section>
);
