import React from 'react';
import { connect } from 'react-redux';
import Page from '../Page/Page';
import Table from '../Table/Table';
import { supplierListSelector } from '../../../../../../../Organisation/scenes/Supplier/SupplierSelectors';

const getTableData = (rawData = []) => {
  const header = [
    { width: 20, name: 'NAME' },
    { width: 20, name: 'EMAIL' },
    { width: 40, name: 'ADDRESS' },
    { width: 20, name: 'PHONE' }
  ];
  const data = rawData.map(item => ({
    id: item.name,
    name: item.name,
    email: item.email,
    address: item.address,
    phone: item.phone
  }));
  return { header, data };
};

const Supplier = ({ supplierList }) => {
  const { header, data } = getTableData(supplierList);
  return (
    <Page dynamic>
      <div className="title">
        <span className="shape" />
        <h1>APPENDIX</h1>
      </div>
      <div className="content">
        <h2>A. Strategic Suppliers</h2>
        <Table header={header}>
          {data.map(item => (
            <tr key={item.id}>
              <td className="table-content">{item.name}</td>
              <td className="table-content">{item.email}</td>
              <td className="table-content">{item.address}</td>
              <td className="table-content">{item.phone}</td>
            </tr>
          ))}
        </Table>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  supplierList: supplierListSelector(state)
});

export default connect(mapStateToProps)(Supplier);
