import axios from 'axios';
import {
  riskCategoriesSelector,
  riskPresetsSelector,
  riskLikelihoodPresetsSelector,
  riskReportIndemnityPresetsSelector,
  riskReportReviewPresetsSelector,
  mitigationStrategiesSelector,
  mitigationStatusSelector
} from './RiskSelectors';

export const FETCH_RISKS = 'risk/FETCH_RISKS';
export const FETCH_RISK_CATEGORIES = 'risk/FETCH_RISK_CATEGORIES';
export const FETCH_RISK_PRESETS = 'risk/FETCH_RISK_PRESETS';
export const FETCH_RISK_LIKELIHOOD_PRESETS = 'risk/FETCH_RISK_LIKELIHOOD_PRESETS';
export const FETCH_MITIGATION_STRATEGIES = 'risk/FETCH_MITIGATION_STRATEGIES';
export const FETCH_MITIGATION_STATUS = 'risk/FETCH_MITIGATION_STATUS';
export const RISK_REQUEST = 'risk/RISK_REQUEST';
export const FETCH_RISK_INDUSTRY = 'risk/FETCH_RISK_INDUSTRY';
export const FETCH_RISK_EXPOSURE = 'risk/FETCH_RISK_EXPOSURE';
export const FETCH_RISK_REPORT_INDEMNITY_PRESETS = 'risk/FETCH_RISK_REPORT_INDEMNITY_PRESETS';
export const FETCH_RISK_REPORT_REVIEW_PRESETS = 'risk/FETCH_RISK_REPORT_REVIEW_PRESETS';
export const FETCH_RISK_REPORT = 'risk/FETCH_RISK_REPORT';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_RISK_COACH = process.env.REACT_APP_API_RISK_COACH;
const API_PLANS = 'plans';
const API_RISKS = 'risks';
const API_RISK_CATEGORIES = 'risk_categories';
const API_RISK_PRESETS = 'risk_presets';
const API_RISK_LIKELIHOOD_OPTIONS = 'likelihood_presets';
const API_MITIGATION_STRATEGIES = 'mitigation_strategies';
const API_MITIGATION_STATUS = 'mitigation_status';
const API_RISK_REPORT_INDEMNITY_PRESETS = 'risk_report_indemnity_presets';
const API_RISK_REPORT_REVIEW_PRESETS = 'risk_report_review_presets';
const API_RISK_REPORT = 'risk_report';
const API_INDUSTRIES = 'industries';
const API_RISK_EXPOSURE = 'significant_exposures/au';

const RISK_COACH_API_KEY = process.env.REACT_APP_RISK_COACH_API_KEY;

const instance = axios.create({ headers: { 'x-api-key': RISK_COACH_API_KEY, Accept: 'application/json;v=1.0' } });

export const riskRequest = riskRequest => ({ type: RISK_REQUEST, riskRequest });

export const fetchRiskCategories = () => async (dispatch, getState) => {
  if (riskCategoriesSelector(getState())) {
    return;
  }
  const url = `${API_BASE}${API_RISK_CATEGORIES}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_RISK_CATEGORIES, riskCategories: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchRiskPresets = () => async (dispatch, getState) => {
  if (riskPresetsSelector(getState())) {
    return;
  }
  const url = `${API_BASE}${API_RISK_PRESETS}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_RISK_PRESETS, riskPresets: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchRiskLikelihoodPresets = () => async (dispatch, getState) => {
  // if the array of risk likelihood options is not empty, which means already fetched, just return
  if (riskLikelihoodPresetsSelector(getState())) {
    return;
  }
  const url = `${API_BASE}${API_RISK_LIKELIHOOD_OPTIONS}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_RISK_LIKELIHOOD_PRESETS, riskLikelihoodPresets: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchRisks = planId => async dispatch => {
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_RISKS}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_RISKS, riskList: res.data });
  } catch (err) {
    console.error(err);
  }
  dispatch(riskRequest(false));
};

/**
 * Create an array of risks
 * @param {number} planId The ID of the plan
 * @param {array} data The array of the risks information that are created
 */
export const createRisks = (planId, data) => async dispatch => {
  dispatch(riskRequest(true));
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_RISKS}/create`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchRisks(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(riskRequest(false));
    return Promise.reject(err);
  }
};

/**
 * Update an array of risks
 * @param {number} planId The ID of the plan
 * @param {array} data The array of the risks information that are updated
 */
export const updateRisks = (planId, data) => async dispatch => {
  dispatch(riskRequest(true));
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_RISKS}/update`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchRisks(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(riskRequest(false));
    return Promise.reject(err);
  }
};

/**
 * Delete an array of risks
 * @param {number} planId The ID of the plan
 * @param {array} data The array of riskId that are deleted
 */
export const deleteRisks = (planId, data) => async dispatch => {
  dispatch(riskRequest(true));
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_RISKS}/delete`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchRisks(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(riskRequest(false));
    return Promise.reject(err);
  }
};

export const fetchMitigationStrategies = () => async (dispatch, getState) => {
  // if the array of mitigation strategies is not empty, which means already fetched, just return
  if (mitigationStrategiesSelector(getState())) {
    return;
  }
  const url = `${API_BASE}${API_MITIGATION_STRATEGIES}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_MITIGATION_STRATEGIES, mitigationStrategies: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchMitigationStatus = () => async (dispatch, getState) => {
  // if the array of mitigation status is not empty, which means already fetched, just return
  if (mitigationStatusSelector(getState())) {
    return;
  }
  const url = `${API_BASE}${API_MITIGATION_STATUS}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_MITIGATION_STATUS, mitigationStatus: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchRiskIndustries = industryId => async dispatch => {
  const url = `${API_RISK_COACH}${API_INDUSTRIES}/${industryId}`;
  try {
    const res = await instance.get(url);
    dispatch({ type: FETCH_RISK_INDUSTRY, riskIndustry: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchRiskExposures = industryId => async dispatch => {
  const url = `${API_RISK_COACH}${API_INDUSTRIES}/${industryId}/${API_RISK_EXPOSURE}`;
  try {
    const res = await instance.get(url);
    dispatch({ type: FETCH_RISK_EXPOSURE, riskExposures: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchRiskReportIndemnityPresets = () => async (dispatch, getState) => {
  if (riskReportIndemnityPresetsSelector(getState())) {
    return;
  }
  const url = `${API_BASE}${API_RISK_REPORT_INDEMNITY_PRESETS}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_RISK_REPORT_INDEMNITY_PRESETS, riskReportIndemnityPresets: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchRiskReportReviewPresets = () => async (dispatch, getState) => {
  if (riskReportReviewPresetsSelector(getState())) {
    return;
  }
  const url = `${API_BASE}${API_RISK_REPORT_REVIEW_PRESETS}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_RISK_REPORT_REVIEW_PRESETS, riskReportReviewPresets: res.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchRiskReport = planId => async dispatch => {
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_RISK_REPORT}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: FETCH_RISK_REPORT, riskReport: res.data });
  } catch (err) {
    console.error(err);
  }
  dispatch(riskRequest(false));
};

export const createRiskReport = (planId, data) => async dispatch => {
  dispatch(riskRequest(true));
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_RISK_REPORT}`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchRiskReport(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(riskRequest(false));
    return Promise.reject(err);
  }
};

export const exportRisks = planId => () => {
  const url = `${API_BASE}${API_PLANS}/${planId}/${API_RISKS}/export`;
  return axios.get(url, { responseType: 'blob' });
};
