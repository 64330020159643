import { types } from './StrategicThirdPartyAction';
import { types as organisationTypes } from '../../OrganisationAction';

const initialState = { loadingStrategicThirdParty: false, loadingStrategicThirdPartyCategories: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case organisationTypes.SWITCH_ORGANISATION:
      return { ...initialState };
    case types.REQUEST_STRATEGIC_THIRD_PARTY:
      return { ...state, loadingStrategicThirdParty: true };
    case types.RECEIVE_STRATEGIC_THIRD_PARTY:
      return {
        ...state,
        loadingStrategicThirdParty: false,
        strategicThirdPartyList: action.strategicThirdPartyList || state.strategicThirdPartyList
      };
    case types.REQUEST_STRATEGIC_THIRD_PARTY_CATEGORIES:
      return { ...state, loadingStrategicThirdPartyCategories: true };
    case types.RECEIVE_STRATEGIC_THIRD_PARTY_CATEGORIES:
      return {
        ...state,
        loadingStrategicThirdPartyCategories: false,
        strategicThirdPartyCategories: action.strategicThirdPartyCategories
      };
    default:
      return state;
  }
};
