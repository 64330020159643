import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tabs, Badge } from 'antd';
import Card from '../../../../../../components/Card/Card';
import BrokerageSummary from './components/BrokerageSummary';
import SubscriptionTable from './components/BrokerageSubscriptionsTable';
import PromoCodeTable from './components/PromoCodeTable';
import { revokePromoCode, revokeSubscriptionKey, fetchBrokerageSubscriptions } from '../../../Broker/BrokerActions';
import {
  brokerageSubscriptionsSelector,
  promoCodesSelector,
  brokerageSubscriptionsExpiredCountSelector,
  promoCodesExpiredSelector,
  brokerageIdSelector
} from '../../../Broker/BrokerSelectors';
import './BrokerageSubscriptions.scss';

const { TabPane } = Tabs;

const Subscription = ({
  brokerageId,
  subscriptions,
  promoCodes,
  expiredSubscriptionsCount,
  expiredPromoCodesCount,
  revokeSubscriptionKey,
  revokePromoCode,
  fetchBrokerageSubscriptions
}) => {
  useEffect(() => {
    fetchBrokerageSubscriptions(brokerageId);
  }, [fetchBrokerageSubscriptions, brokerageId]);

  return (
    <div className="component-wrapper">
      <Row gutter={20} type="flex">
        <Col span={24}>
          <BrokerageSummary />
        </Col>
        <Col span={24}>
          <Card>
            <Tabs className="tabs">
              <TabPane
                key="subscription"
                tab={
                  <Badge count={expiredSubscriptionsCount} offset={[20, 7]}>
                    Brokerage Subscriptions
                  </Badge>
                }
              >
                <SubscriptionTable subscriptions={subscriptions} revoke={revokeSubscriptionKey} />
              </TabPane>
              <TabPane
                key="promoCode"
                tab={
                  <Badge count={expiredPromoCodesCount} offset={[20, 7]}>
                    Promo Codes
                  </Badge>
                }
              >
                <PromoCodeTable promoCodes={promoCodes} revoke={revokePromoCode} />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  subscriptions: brokerageSubscriptionsSelector(state),
  promoCodes: promoCodesSelector(state),
  expiredSubscriptionsCount: brokerageSubscriptionsExpiredCountSelector(state),
  expiredPromoCodesCount: promoCodesExpiredSelector(state),
  brokerageId: brokerageIdSelector(state)
});

const mapDispatchToProps = { revokePromoCode, revokeSubscriptionKey, fetchBrokerageSubscriptions };

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
