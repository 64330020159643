import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios';
import { debounce } from 'lodash';

const { Option } = Select;
const API_RISK_COACH = process.env.REACT_APP_API_RISK_COACH;
const API_INDUSTRIES = 'industries';
const RISK_COACH_API_KEY = process.env.REACT_APP_RISK_COACH_API_KEY;
const PAGE_SIZE = 100;

const instance = axios.create({ headers: { 'x-api-key': RISK_COACH_API_KEY, Accept: 'application/json;v=1.0' } });
const parseLinkHeader = header => {
  if (!header || header.length === 0) {
    return {};
  }
  const parts = header.split(',');
  const links = {};
  parts.forEach(p => {
    const section = p.split(';');
    if (section.length === 2) {
      const url = section[0].replace(/<(.*)>/, '$1').trim();
      const name = section[1].replace(/rel="(.*)"/, '$1').trim();
      links[name] = url;
    }
  });
  return links;
};

export default class IndustrySelectBox extends Component {
  constructor(props) {
    super(props);
    const value = props.value || {};
    this.onSearch = debounce(this.onSearch, 300);
    this.state = {
      industryName: value.industryName || '',
      industryList: [],
      fetching: false,
      searching: false,
      nextUrl: null
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return { ...nextProps.value };
    }
    return null;
  }

  loadMore = async () => {
    const { fetching, nextUrl } = this.state;
    if (fetching || typeof nextUrl === 'undefined') {
      return;
    }
    this.setState({ fetching: true });
    try {
      const res = await instance.get(nextUrl, { params: { pageSize: PAGE_SIZE } });
      const {
        data,
        headers: { link }
      } = res;
      const parsedLink = parseLinkHeader(link);
      this.setState(({ industryList }) => ({ industryList: [...industryList, ...data], nextUrl: parsedLink.next }));
    } catch (err) {
      this.setState({ industryList: [], nextUrl: null });
    }
    this.setState({ fetching: false });
  };

  onSearch = async value => {
    if (value === '') {
      this.setState({ industryList: [] });
      return;
    }
    this.setState({ industryList: [], searching: true });
    const url = `${API_RISK_COACH}${API_INDUSTRIES}`;
    try {
      const res = await instance.get(url, { params: { q: value, pageSize: PAGE_SIZE } });
      const {
        data,
        headers: { link }
      } = res;
      const parsedLink = parseLinkHeader(link);
      this.setState({ industryList: data, nextUrl: parsedLink.next });
    } catch (err) {
      this.setState({ nextUrl: null });
    }
    this.setState({ searching: false });
  };

  onSelect = (value, option) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange({ industryCode: value, industryName: option.props.children });
    }
  };

  onPopupScroll = async e => {
    const { fetching } = this.state;
    if (!fetching) {
      const element = e.target;
      if (element.scrollHeight - element.scrollTop < element.clientHeight + 10) {
        this.loadMore();
      }
    }
  };

  render() {
    const { disabled, onFocus } = this.props;
    const { industryName, industryList, searching } = this.state;
    const options = industryList.map(obj => (
      <Option key={obj.id} title={obj.name}>
        {obj.name}
      </Option>
    ));

    return (
      <Select
        defaultValue={industryName}
        placeholder="Industry"
        disabled={disabled}
        showSearch
        showArrow={false}
        notFoundContent={searching ? <Spin size="small" /> : null}
        onSearch={this.onSearch}
        onSelect={this.onSelect}
        onPopupScroll={this.onPopupScroll}
        onFocus={onFocus}
        filterOption={false}
      >
        {options}
      </Select>
    );
  }
}
