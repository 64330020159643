import React, { useState } from 'react';
import { Modal, Row, Col } from 'antd';
import './Modal.scss';

export default ({ children, title, toggle, explanation, ...props }) => {
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState(explanation);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <>
      {toggle(show)}
      <Modal
        title={title}
        visible={visible}
        width={description ? 600 : 500}
        footer={null}
        keyboard={false}
        destroyOnClose
        onCancel={hide}
        className="modal"
        {...props}
      >
        {explanation ? (
          <Row gutter={48} type="flex">
            <Col span={16}>{children(hide, setDescription)}</Col>
            <Col span={8} className="modal-description">
              <div className="modal-description-title">{description.title}</div>
              {typeof description.text === 'string' ? (
                <div dangerouslySetInnerHTML={{ __html: description.text }} />
              ) : (
                <div className="modal-description-text">{description.text}</div>
              )}
            </Col>
          </Row>
        ) : (
          children(hide)
        )}
      </Modal>
    </>
  );
};
