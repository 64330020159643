import React from 'react';
import { connect } from 'react-redux';
import { Button, Spin, Popconfirm, message } from 'antd';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import Table from '../../../../components/Table/Table';
import BrokerEdit from './components/BrokerEdit';
import { deleteBroker } from './BrokerActions';
import { brokersSelector, brokerIdSelector, loadingBrokersSelector } from './BrokerSelectors';
import { MSG_ERROR } from '../../../../constants';

const Broker = ({ brokers, brokerId, deleteBroker, loadingBrokers }) => {
  const onDelete = async broker => {
    const { brokerId } = broker;
    try {
      await deleteBroker(brokerId);
      message.success('The broker has been successfully deleted');
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const columns = [
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null)
    },
    { key: 'firstName', title: 'First Name', dataIndex: 'firstName' },
    { key: 'lastName', title: 'Last Name', dataIndex: 'lastName' },
    {
      key: 'action',
      width: 64,
      render: (text, record) =>
        brokerId === record.brokerId ? null : (
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
            <Button type="link" icon="delete" />
          </Popconfirm>
        )
    }
  ];

  return (
    <div className="component-wrapper">
      <Spin spinning={loadingBrokers}>
        <Card title="My Admin Users">
          <Table
            title="My Admin Users"
            columns={columns}
            dataSource={brokers}
            rowKey="brokerId"
            searchFields={['email', 'firstName', 'lastName']}
          />
          <div className="btn-container">
            <Modal
              toggle={show => (
                <Button type="primary" icon="plus" htmlType="submit" onClick={show}>
                  Add new
                </Button>
              )}
            >
              {hide => <BrokerEdit close={hide} />}
            </Modal>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

const mapStateToProps = state => ({
  brokerId: brokerIdSelector(state),
  brokers: brokersSelector(state),
  loadingBrokers: loadingBrokersSelector(state)
});

const mapDispatchToProps = { deleteBroker };

export default connect(mapStateToProps, mapDispatchToProps)(Broker);
