import axios from 'axios';
import { fetchPlans } from '../../../Dashboard/scenes/Plan/PlanAction';
// eslint-disable-next-line import/no-cycle
import { requestOrganisationBranches, receiveOrganisationBranches } from '../../OrganisationAction';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_BRANCHES: 'branch/REQUEST_BRANCHES',
  RECEIVE_BRANCHES: 'branch/RECEIVE_BRANCHES'
};

const requestBranches = () => ({ type: types.REQUEST_BRANCHES });
const receiveBranches = data => ({ type: types.RECEIVE_BRANCHES, branches: data });

export const fetchBranch = orgId => async dispatch => {
  dispatch(requestBranches());
  dispatch(requestOrganisationBranches());
  const url = `${API_BASE}orgs/${orgId}/branches`;
  try {
    const res = await axios.get(url);
    dispatch(receiveBranches(res.data));
    dispatch(receiveOrganisationBranches(orgId, res.data));
  } catch (err) {
    dispatch(receiveBranches());
    dispatch(receiveOrganisationBranches());
  }
};

export const createBranch = (orgId, data) => async dispatch => {
  dispatch(requestBranches());
  const url = `${API_BASE}orgs/${orgId}/branches`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchBranch(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBranches());
    return Promise.reject(err);
  }
};

export const updateBranch = (orgId, branchId, data) => async dispatch => {
  dispatch(requestBranches());
  const url = `${API_BASE}orgs/${orgId}/branches/${branchId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchBranch(orgId));
    dispatch(fetchPlans());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBranches());
    return Promise.reject(err);
  }
};

export const deleteBranch = (orgId, branchId) => async dispatch => {
  dispatch(requestBranches());
  const url = `${API_BASE}orgs/${orgId}/branches/${branchId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchBranch(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveBranches());
    return Promise.reject(err);
  }
};
