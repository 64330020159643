import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import 'core-js/stable';
import store from './store';
import Routes from './routes';
import App from './components/App/App';
import { hotjar } from './utils';
import { unregister } from './registerServiceWorker';
import './index.scss';

hotjar(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <App>
        <Routes />
      </App>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

unregister();
