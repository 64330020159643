import React, { useState } from 'react';
import { Upload, Button, Icon, Alert, Collapse, notification, Spin } from 'antd';
import FileSaver from 'file-saver';
import _ from 'lodash';
import * as HttpStatus from 'http-status-codes';
import { MSG_ERROR } from '../../constants';

const { Dragger } = Upload;
const { Panel } = Collapse;
const FEEDBACK_SUCCESS = 'success';
const FEEDBACK_ERROR = 'error';

export default ({ warning, templateName = 'template.xlsx', downloadTemplate, importFromTemplate }) => {
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [duplicatesMsg, setDuplicatesMsg] = useState('');
  const [validationErrorsMsg, setValidationErrorsMsg] = useState('');

  const onDownloadTemplate = async () => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    notification.open({
      key: 'downloading',
      message: 'Downloading',
      description: 'Thanks for your patience.',
      duration: 0,
      icon: <Spin />
    });
    try {
      const res = await downloadTemplate();
      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, templateName);
      notification.close('downloading');
      notification.success({
        key: 'download_success',
        message: 'Download successfully'
      });
    } catch (err) {
      notification.close('downloading');
      notification.error({
        key: 'download_fail',
        message: 'Download failed',
        description: MSG_ERROR
      });
    }
    setDownloading(false);
  };

  const setErrorMessage = err => {
    const { duplicates, validationErrors } = err;
    setFeedbackStatus(FEEDBACK_ERROR);
    if (validationErrors || duplicates) {
      const msgDuplicated = duplicates
        ? duplicates.filter((item, key, self) => self.indexOf(item) === key).join(', ')
        : '';
      const msgValidation = Object.entries(
        _.mapValues(_.groupBy(validationErrors, 'rowNumber'), errList =>
          errList.map(item => item.validationResults[0]?.errorMessage || item.message)
        )
      ).map(item => <div key={item[0]}>{`Row ${item[0]}: ${item[1].join(', ')}`}</div>);
      setValidationErrorsMsg(msgValidation);
      setDuplicatesMsg(msgDuplicated);
      setFeedbackMessage('Import fails due to the following reasons');
    } else {
      setFeedbackMessage(err);
      setValidationErrorsMsg();
      setDuplicatesMsg('');
    }
  };

  const onImport = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      setUploading(true);
      await importFromTemplate(formData);
      onSuccess(null, file);
      setFeedbackStatus(FEEDBACK_SUCCESS);
      setFeedbackMessage('Imported successfully');
      setDuplicatesMsg('');
      setValidationErrorsMsg('');
      setUploading(false);
    } catch (err) {
      onError();
      switch (err?.status) {
        case HttpStatus.BAD_REQUEST:
          setErrorMessage(err.data);
          setUploading(false);
          break;
        default:
          setFeedbackStatus(FEEDBACK_ERROR, setFeedbackMessage(MSG_ERROR));
          setUploading(false);
          break;
      }
    }
  };

  return (
    <>
      <Spin spinning={uploading}>
        <Dragger customRequest={onImport} showUploadList={false}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Please download the template and insert records you want to import, then upload the template file
          </p>
        </Dragger>
      </Spin>
      {warning && (
        <Alert type="warning" showIcon message="Warning" description={warning} style={{ marginTop: '10px' }} />
      )}
      <div className="btn-container no-padding">
        <Button type="primary" icon="download" loading={downloading} onClick={onDownloadTemplate}>
          Download Template
        </Button>
      </div>
      {feedbackMessage && feedbackMessage !== '' && (
        <Alert message={feedbackMessage} type={feedbackStatus} style={{ marginTop: '10px' }} />
      )}
      {duplicatesMsg !== '' && (
        <Collapse bordered={false}>
          <Panel header="The following records are either duplicate or already exist">
            <Alert message={duplicatesMsg} type="error" />
          </Panel>
        </Collapse>
      )}
      {validationErrorsMsg && validationErrorsMsg.length > 0 && (
        <Collapse bordered={false}>
          <Panel header="The following rows are invalid. Please make sure you input all required fields for each record.">
            <Alert message={validationErrorsMsg} type="error" />
          </Panel>
        </Collapse>
      )}
    </>
  );
};
