import React from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Divider, message } from 'antd';
import Card from '../../../../../components/Card/Card';
import Modal from '../../../../../components/Modal/Modal';
import Table from '../../../../../components/Table/Table';
import Export from '../../../../../components/Export/Export';
import Import from '../../../../../components/Import/Import';
import StrategicThirdPartyEdit from './StrategicThirdPartyEdit';
import * as actions from '../StrategicThirdPartyAction';
import { orgIdSelector, businessNameSelector } from '../../../OrganisationSelectors';
import { loadingStrategicThirdPartySelector } from '../StrategicThirdPartySelectors';
import { MSG_ERROR } from '../../../../../constants';

const StrategicThirdPartyClass = ({
  orgId,
  businessName,
  strategicThirdPartyClass,
  strategicThirdPartyList,
  strategicThirdPartyCategories,
  deleteStrategicThirdParty,
  downloadStrategicThirdPartyImportTemplate,
  exportStrategicThirdParty,
  importStrategicThirdParty
}) => {
  const onDelete = async strategicThirdParty => {
    try {
      await deleteStrategicThirdParty(orgId, strategicThirdParty.strategicThirdPartyId);
      message.success('The strategic third party has been successfully deleted');
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const strategicThirdPartyCategoryFilters = (strategicThirdPartyCategories || []).map(obj => ({
    text: obj.name,
    value: obj.name
  }));

  const columns = [
    { key: 'name', title: 'Business Name', dataIndex: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
    {
      key: 'category',
      title: 'Category',
      filters: strategicThirdPartyCategoryFilters,
      onFilter: (value, record) => record.strategicThirdPartyCategory.name.indexOf(value) === 0,
      render: (text, record) => (
        <span>
          {record.strategicThirdPartyCategory.name === 'Other'
            ? record.strategicThirdPartyCategoryText
            : record.strategicThirdPartyCategory.name}
        </span>
      )
    },
    {
      key: 'contactPerson',
      title: 'Contact Person',
      dataIndex: 'contactPerson'
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null)
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      render: text => (text ? <a href={`tel:${text}`}>{text}</a> : null)
    },
    {
      key: 'action',
      width: 115,
      render: (text, record) => (
        <span>
          <Modal explanation toggle={show => <Button type="link" icon="edit" onClick={show} />}>
            {(hide, setExplanation) => (
              <StrategicThirdPartyEdit
                strategicThirdPartyInfo={record}
                strategicThirdPartyCategories={strategicThirdPartyCategories}
                close={hide}
                onExplanationChange={setExplanation}
              />
            )}
          </Modal>
          <Divider type="vertical" />
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
            <Button type="link" icon="delete" />
          </Popconfirm>
        </span>
      )
    }
  ];

  const strategicThirdPartyClassId =
    strategicThirdPartyCategories && strategicThirdPartyCategories.length > 0
      ? strategicThirdPartyCategories[0].strategicThirdPartyClassId
      : 0;

  return (
    <>
      <Card title={strategicThirdPartyClass}>
        <Table
          title={strategicThirdPartyClass}
          columns={columns}
          dataSource={strategicThirdPartyList}
          rowKey="strategicThirdPartyId"
          searchFields={['name', 'category', 'contactPerson', 'email', 'phone']}
        />
        <div className="btn-container">
          <Export
            fileName={`${businessName.replace(/\s+/g, '_')}_${strategicThirdPartyClass.replace(/\s+/g, '_')}.xlsx`}
            exportFile={() => exportStrategicThirdParty(orgId, strategicThirdPartyClassId)}
          >
            {onExport => (
              <Button type="primary" icon="export" onClick={onExport}>
                Export
              </Button>
            )}
          </Export>
          <Modal
            title={`Import ${strategicThirdPartyClass}`}
            toggle={show => (
              <Button type="primary" icon="contacts" onClick={show}>
                Import
              </Button>
            )}
          >
            {() => (
              <Import
                templateName={`${strategicThirdPartyClass.replace(/\s+/g, '_')}_template.xlsx`}
                downloadTemplate={() => downloadStrategicThirdPartyImportTemplate(strategicThirdPartyClassId)}
                importFromTemplate={file => importStrategicThirdParty(orgId, file, strategicThirdPartyClassId)}
              />
            )}
          </Modal>
          <Modal
            explanation
            toggle={show => (
              <Button type="primary" icon="plus" onClick={show}>
                Add new
              </Button>
            )}
          >
            {(hide, setExplanation) => (
              <StrategicThirdPartyEdit
                strategicThirdPartyCategories={strategicThirdPartyCategories}
                close={hide}
                onExplanationChange={setExplanation}
              />
            )}
          </Modal>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  businessName: businessNameSelector(state),
  loading: loadingStrategicThirdPartySelector(state)
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(StrategicThirdPartyClass);
