import React from 'react';
import { Icon } from 'antd';
import './Navigation.scss';

export default () => (
  <section className="plan-preview-navigation">
    <div className="instruction">
      <div className="title">In the event of an incident</div>
      <div className="description">Please proceed immediately to the relevant sections</div>
    </div>
    <ul className="teams">
      <li className="team-type">
        <div className="team-icon">
          <Icon type="medicine-box" />
        </div>
        <div className="team-title">Emergency Response Team:</div>
        <div className="team-sections">
          <a href="#emergency-response-team" className="team-section-item" style={{ background: '#c86d6a' }}>
            <strong>Section B</strong> - Emergency Response -
          </a>
          <a href="#security" className="team-section-item" style={{ background: '#c86d6a' }}>
            <strong>Section C</strong> - Security -
          </a>
          <a href="#human-management" className="team-section-item" style={{ background: '#c86d6a' }}>
            <strong>Section D</strong> - Human Management -
          </a>
          <a href="#damage-assessment" className="team-section-item" style={{ background: '#c86d6a' }}>
            <strong>Section E</strong> - Damage Assessment -
          </a>
        </div>
      </li>
      <li className="team-type">
        <div className="team-icon">
          <Icon type="bar-chart" />
        </div>
        <div className="team-title">Crisis Management Team:</div>
        <div className="team-sections">
          <a href="#crisis-management-team" className="team-section-item" style={{ background: '#1f5993' }}>
            <strong>Section F</strong>
          </a>
        </div>
      </li>
      <li className="team-type">
        <div className="team-icon">
          <Icon type="desktop" />
        </div>
        <div className="team-title">IT Response Team:</div>
        <div className="team-sections">
          <a href="#information-technology-team" className="team-section-item" style={{ background: '#729618' }}>
            <strong>Section G</strong>
          </a>
        </div>
      </li>
    </ul>
  </section>
);
