import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Tabs, Button, Spin, message } from 'antd';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import UserTable from './components/UserTable';
import InviteeTable from './components/InviteeTable';
import OrganisationUserEdit from './components/OrganisationUserEdit';
import {
  fetchRoles,
  fetchOrganisationUsers,
  fetchOrganisationInvitees,
  inviteOrganisationUser,
  updateOrganisationUser,
  revokeOrganisationUser,
  resendInvitation
} from '../../OrganisationAction';
import {
  orgIdSelector,
  organisationUsersSelector,
  organisationInviteesSelector,
  loadingOrganisationUsersSelector,
  loadingOrganisationInviteesSelector
} from '../../OrganisationSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../constants';

const { TabPane } = Tabs;

const OrganisationUser = ({
  orgId,
  organisationUsers,
  organisationInvitees,
  loading,
  fetchRoles,
  fetchOrganisationUsers,
  fetchOrganisationInvitees,
  inviteOrganisationUser,
  updateOrganisationUser,
  revokeOrganisationUser,
  resendInvitation
}) => {
  useEffect(() => {
    fetchRoles();
    fetchOrganisationUsers(orgId);
    fetchOrganisationInvitees(orgId);
  }, [orgId, fetchRoles, fetchOrganisationUsers, fetchOrganisationInvitees]);

  const inviteUser = async data => {
    try {
      await inviteOrganisationUser(orgId, data);
      message.success('Invitation has been sent.');
    } catch (err) {
      message.error(err.data || MSG_ERROR);
    }
  };

  const updateUser = async (userId, data) => {
    try {
      await updateOrganisationUser(orgId, userId, data);
      message.success(`The user's role has been successfully updated`);
    } catch (err) {
      message.error(err.data || MSG_ERROR);
    }
  };

  const revokeUser = async ({ organisationId, userId, isRevoked }) => {
    try {
      await revokeOrganisationUser(organisationId, { userId, isRevoked: !isRevoked });
      message.success(MSG_SAVE_SUCCESS);
    } catch (err) {
      message.error(err.data || MSG_ERROR);
    }
  };

  const resend = async ({ organisationId, email }) => {
    try {
      await resendInvitation({ organisationId, email });
      message.success('Sent successfully');
    } catch (err) {
      message.error(err.data || MSG_ERROR);
    }
  };

  return (
    <div className="component-wrapper">
      <Spin spinning={loading}>
        <Card>
          <Tabs className="tabs">
            <TabPane key="organisationUsers" tab="Organisation Users">
              <UserTable
                organisationUsers={organisationUsers}
                updateOrganisationUser={updateUser}
                revokeOrganisationUser={revokeUser}
              />
            </TabPane>
            <TabPane key="organisationInvitees" tab="Invitees">
              <InviteeTable organisationInvitees={organisationInvitees} resendInvitation={resend} />
            </TabPane>
          </Tabs>
          <div className="btn-container">
            <Modal
              toggle={show => (
                <Button type="primary" icon="plus" onClick={show}>
                  Add User
                </Button>
              )}
            >
              {hide => <OrganisationUserEdit inviteUser={inviteUser} close={hide} />}
            </Modal>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  organisationUsers: organisationUsersSelector(state),
  organisationInvitees: organisationInviteesSelector(state),
  loading: loadingOrganisationUsersSelector(state) || loadingOrganisationInviteesSelector(state)
});

const mapDispatchToProps = {
  fetchRoles,
  fetchOrganisationUsers,
  fetchOrganisationInvitees,
  inviteOrganisationUser,
  updateOrganisationUser,
  revokeOrganisationUser,
  resendInvitation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationUser);
