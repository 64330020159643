import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Button } from 'antd';
import Card from '../../../../../../../components/Card/Card';
import Modal from '../../../../../../../components/Modal/Modal';
import CreatePromoCodeForm from './CreatePromoCodeForm';
import SubscriptionChart from '../../../components/SubscriptionChart';
import TransferForm from './TransferForm';
import {
  brokerageSummarySelector,
  loadingBrokerageSelector,
  brokerInfoSelector
} from '../../../../Broker/BrokerSelectors';
import '../../../components/Summary.scss';

const Summary = ({ brokerInfo, brokerageSummary = {}, loading }) => {
  const [summary, setSummary] = useState({
    total: 0,
    remaining: 0,
    subscriptions: 0,
    promoCodes: 0,
    revoked: 0,
    sent: 0
  });
  useEffect(() => {
    if (brokerageSummary) {
      setSummary(s => ({ ...s, ...brokerageSummary }));
    }
  }, [brokerageSummary]);

  const data = [
    { name: 'REMAINING', value: summary.remaining, color: '#5BC2E7' },
    { name: 'ASSIGNED', value: summary.subscriptions, color: '#F1C400' },
    { name: 'PROMO CODES', value: summary.promoCodes, color: '#00685E' },
    { name: 'REVOKED', value: summary.revoked, color: '#CB2C30' },
    { name: 'TRANSFERRED', value: summary.sent, color: '#353679' }
  ];
  return (
    <Spin spinning={loading}>
      <Card>
        <div className="summary">
          <h1 className="summary-title">SUBSCRIPTIONS SUMMARY</h1>
          <h2 className="summary-subtitle">
            <span className="summary-subtitle-number">{summary.remaining}</span> remaining out of{' '}
            <span className="summary-subtitle-number">{summary.total}</span> subscriptions
          </h2>
          <SubscriptionChart
            className="summary-chart"
            summary={{ total: summary.total, remaining: summary.remaining }}
          />
          <div className="summary-operations">
            <div className="operation">
              <Button size="large" type="primary" block className="operation-btn buy-btn">
                <Link to="/purchase">Buy more</Link>
              </Button>
              <div className="operation-desc">Click to purchase subscriptions</div>
            </div>
            {brokerInfo && (
              <>
                <Modal
                  toggle={show => (
                    <div className="operation">
                      <Button
                        size="large"
                        type="primary"
                        block
                        className="operation-btn create-btn"
                        onClick={show}
                        disabled={summary.remaining === 0}
                      >
                        Create Promo codes
                      </Button>
                      <div className="operation-desc">Click to create promo for a group of customers</div>
                    </div>
                  )}
                >
                  {hide => <CreatePromoCodeForm close={hide} />}
                </Modal>
                <Modal
                  toggle={show => (
                    <div className="operation">
                      <Button
                        size="large"
                        type="primary"
                        block
                        className="operation-btn transfer-btn"
                        onClick={show}
                        disabled={summary.remaining === 0}
                      >
                        Transfer
                      </Button>
                      <div className="operation-desc">Click to transfer your subscriptions to a sub-brokerage</div>
                    </div>
                  )}
                >
                  {hide => <TransferForm close={hide} />}
                </Modal>
              </>
            )}
          </div>
          <div className="summary-breakdown">
            <h2 className="summary-breakdown-title">Brokerage subscriptions breakdown</h2>
            <div className="summary-breakdown-container">
              {data.map(item => (
                <div key={item.name} className="summary-breakdown-item-container">
                  <div className="summary-breakdown-item">
                    <h3 className="summary-breakdown-item-name">{item.name}</h3>
                    <div className="summary-breakdown-item-value" style={{ color: item.color }}>
                      {item.value}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </Spin>
  );
};

const mapStateToProps = state => ({
  brokerInfo: brokerInfoSelector(state),
  brokerageSummary: brokerageSummarySelector(state),
  loading: loadingBrokerageSelector(state)
});

export default connect(mapStateToProps)(Summary);
