import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tabs, Badge } from 'antd';
import Card from '../../../../../../components/Card/Card';
import MySummary from './components/MySummary';
import MySubscriptionsTable from './components/MySubscriptionsTable';
import { fetchUserSubscriptions } from '../../../User/UserActions';
import { userSubscriptionsSelector, expiredUserSubscriptionsCountSelector } from '../../../User/UserSelectors';

const { TabPane } = Tabs;

const MySubscriptions = ({ subscriptions, expiredCount, fetchUserSubscriptions }) => {
  useEffect(() => {
    fetchUserSubscriptions();
  }, [fetchUserSubscriptions]);

  return (
    <div className="component-wrapper">
      <Row gutter={20} type="flex">
        <Col span={24}>
          <MySummary />
        </Col>
        <Col span={24}>
          <Card>
            <Tabs className="tabs">
              <TabPane
                key="mySubscription"
                tab={
                  <Badge count={expiredCount} offset={[20, 7]}>
                    My Subscriptions
                  </Badge>
                }
              >
                <MySubscriptionsTable subscriptions={subscriptions} />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  subscriptions: userSubscriptionsSelector(state),
  expiredCount: expiredUserSubscriptionsCountSelector(state)
});

const mapDispatchToProps = {
  fetchUserSubscriptions
};

export default connect(mapStateToProps, mapDispatchToProps)(MySubscriptions);
