import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Divider, Spin, Tag, message } from 'antd';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import Table from '../../../../components/Table/Table';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import Export from '../../../../components/Export/Export';
import Import from '../../../../components/Import/Import';
import SupplierEdit from './components/SupplierEdit';
import * as actions from './SupplierAction';
import { orgIdSelector, businessNameSelector } from '../../OrganisationSelectors';
import { supplierListSelector, loadingSuppliersSelector } from './SupplierSelectors';
import { MSG_ERROR } from '../../../../constants';
import './Supplier.scss';

const INFO_MSG = (
  <>
    <p>
      Being able to contact any or all of your key or &quot;Strategic Suppliers&quot; which includes public utilities is
      extremely important in the event of a crisis.
    </p>
    <p>You have two options to complete this important section.</p>
    <ol>
      <li>
        For an organization with a relatively small number of Strategic Suppliers it may be easier to simply complete
        the fields below by using the &quot;+ Add new&quot; button.
      </li>
      <li>For larger organizations we suggest using the &quot;Import&quot; function.</li>
    </ol>
    <p>
      To Import your Strategic Suppliers first click the &quot;Import&quot; button so you can download the required
      Template. Ensure you complete the template without adding or deleting any columns, then save the spreadsheet and
      import it straight into ContinuityCoach by dragging and dropping the spreadsheet into the clearly marked
      &quot;drag and drop&quot; field, or click the field to allow a search of your computer&apos;s files.
    </p>
    <p>
      There are typically some important or &quot;Strategic Suppliers&quot; that you may feel obliged to contact in the
      event of a crisis which means you potentially cannot receive normal orders or you may require deliveries be sent
      to a different address. Keeping your Strategic Suppliers up to date with developments typically means that
      relationships are maintained in the long term and they are more forgiving and understanding should a disruption to
      your supply to them occur.
    </p>
    <p>
      As with all parts of ContinuityCoach, the more time you invest in this planning process the more valuable the plan
      is likely to be in the event of a crisis.
    </p>
  </>
);

const Supplier = ({
  orgId,
  businessName,
  loading,
  supplierList,
  fetchSupplier,
  deleteSupplier,
  downloadSupplierImportTemplate,
  exportSuppliers,
  importSupplier
}) => {
  useEffect(() => {
    fetchSupplier(orgId);
  }, [orgId, fetchSupplier]);

  const onDelete = async supplier => {
    try {
      await deleteSupplier(orgId, supplier.supplierId);
      message.success('The supplier has been successfully deleted');
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const columns = [
    { key: 'name', title: 'Name', dataIndex: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      render: category => {
        try {
          const parsedCategory = JSON.parse(category);
          return (
            <div>
              {parsedCategory.map(tag => (
                <Tag className='category-name' key={tag}>
                  {tag}
                </Tag>
              )
              )}
            </div>
          )
        } catch {
          return (
            <Tag className='category-name' key={category}>
              {category}
            </Tag>
          )
        }
      }
    },
    {
      key: 'contactPerson',
      title: 'Contact Person',
      dataIndex: 'contactPerson'
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null)
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      render: text => (text ? <a href={`tel:${text}`}>{text}</a> : null)
    },
    {
      key: 'action',
      width: 115,
      render: (text, record) => (
        <span>
          <Modal explanation toggle={show => <Button type="link" icon="edit" onClick={show} />}>
            {(hide, setExplanation) => (
              <SupplierEdit supplierInfo={record} close={hide} onExplanationChange={setExplanation} />
            )}
          </Modal>
          <Divider type="vertical" />
          <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
            <Button type="link" icon="delete" />
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <>
      <ProcessBar
        title="Strategic Suppliers"
        prev="/organization/customers"
        next="/organization/strategic-third-parties"
      />
      <InfoBox message={INFO_MSG} />
      {supplierList ? (
        <div className="component-wrapper">
          <Spin spinning={loading}>
            <Card
              title="Strategic Suppliers"
              description="Please add the details of your Strategic Suppliers. A Strategic Supplier is one that plays a particular role in the continuation and/or survival of your business."
            >
              <Table
                title="Suppliers"
                columns={columns}
                dataSource={supplierList}
                rowKey="supplierId"
                searchFields={['name', 'category', 'contactPerson', 'email', 'phone']}
              />
              <div className="btn-container">
                <Export
                  fileName={`${businessName.replace(/\s+/g, '_')}_Suppliers.xlsx`}
                  exportFile={() => exportSuppliers(orgId)}
                >
                  {onExport => (
                    <Button type="primary" icon="export" onClick={onExport}>
                      Export
                    </Button>
                  )}
                </Export>
                <Modal
                  title="Import Suppliers"
                  toggle={show => (
                    <Button type="primary" icon="contacts" onClick={show}>
                      Import
                    </Button>
                  )}
                >
                  {() => (
                    <Import
                      templateName="suppliers_template.xlsx"
                      downloadTemplate={downloadSupplierImportTemplate}
                      importFromTemplate={file => importSupplier(orgId, file)}
                    />
                  )}
                </Modal>
                <Modal
                  explanation
                  toggle={show => (
                    <Button type="primary" icon="plus" onClick={show}>
                      Add new
                    </Button>
                  )}
                >
                  {(hide, setExplanation) => <SupplierEdit close={hide} onExplanationChange={setExplanation} />}
                </Modal>
              </div>
            </Card>
          </Spin>
        </div>
      ) : (
          <Spin tip="Loading Suppliers..." className="loading" />
        )}
    </>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  businessName: businessNameSelector(state),
  supplierList: supplierListSelector(state),
  loading: loadingSuppliersSelector(state)
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
