import React from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Divider, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import Card from '../../../../../components/Card/Card';
import Modal from '../../../../../components/Modal/Modal';
import Table from '../../../../../components/Table/Table';
import InfoBox from '../../../../../components/InfoBox/InfoBox';
import TeamContactAdd from './TeamContactAdd';
import TeamContactEdit from './TeamContactEdit';
import ContactEdit from '../../../../Organisation/scenes/Contact/components/ContactEdit';
import { createTeamContact, updateTeamContact, deleteTeamContact } from '../TeamAction';
import { planIdSelector } from '../../Plan/PlanSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../constants';

const TeamType = ({ planId, team, description = null, createTeamContact, updateTeamContact, deleteTeamContact }) => {
  const { teamId, teamType, teamContacts } = team;
  const teamContactList = teamContacts.map(obj => ({ ...obj.contact, designation: obj.designation }));

  const onCreateTeamContact = async data => {
    try {
      await createTeamContact(planId, teamId, data);
      message.success(MSG_SAVE_SUCCESS);
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  const onUpdateTeamContact = async (contactId, data) => {
    try {
      await updateTeamContact(planId, teamId, contactId, data);
      message.success(MSG_SAVE_SUCCESS);
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  const onDeleteTeamContact = async contactId => {
    try {
      await deleteTeamContact(planId, teamId, contactId);
      message.success(MSG_SAVE_SUCCESS);
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  const columns = [
    { key: 'firstName', title: 'First Name', dataIndex: 'firstName' },
    { key: 'lastName', title: 'Last Name', dataIndex: 'lastName' },
    { key: 'jobTitle', title: 'Job Title', dataIndex: 'jobTitle' },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      render: text =>
        text ? (
          <a className="team-link" href={`mailto:${text}`}>
            {text}
          </a>
        ) : null
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      render: text =>
        text ? (
          <a className="team-link" href={`tel:${text}`}>
            {text}
          </a>
        ) : null
    },
    {
      key: 'mobile',
      title: 'Mobile',
      dataIndex: 'mobile',
      render: text =>
        text ? (
          <a className="team-link" href={`tel:${text}`}>
            {text}
          </a>
        ) : null
    },
    { key: 'designation', title: 'Designation', dataIndex: 'designation' },
    {
      key: 'action',
      width: 115,
      render: (text, record) => (
        <span>
          <Modal
            title={`${record.firstName} ${record.lastName}`}
            toggle={show => <Button type="link" icon="edit" className="team-link" onClick={show} />}
          >
            {hide => <TeamContactEdit teamContactInfo={record} onUpdate={onUpdateTeamContact} close={hide} />}
          </Modal>
          <Divider type="vertical" />
          <Popconfirm title="Sure to delete?" onConfirm={() => onDeleteTeamContact(record.contactId)}>
            <Button type="link" icon="delete" className="team-link" />
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <Card
      color="#5BC2E7"
      title={`Team - ${teamType.name}`}
      description={`Add team members by clicking the "Add new contact" or "Select from contacts" buttons to assign your team members.`}
    >
      {description && <InfoBox color="#5BC2E7" type="fill" message={description} />}
      <Table
        title="Team Contacts"
        columns={columns}
        dataSource={teamContactList}
        rowKey="contactId"
        breakpoint={1024}
      />
      <div className="btn-container">
        <Modal
          explanation
          toggle={show => (
            <Button type="primary" icon="plus" onClick={show} className="team-btn">
              Add new contact
            </Button>
          )}
        >
          {(hide, setExplanation) => (
            <ContactEdit
              addToTeam={data => onCreateTeamContact(data)}
              close={hide}
              onExplanationChange={setExplanation}
            />
          )}
        </Modal>
        <Modal
          toggle={show => (
            <Button type="primary" icon="contacts" onClick={show} className="team-btn">
              Select from contacts
            </Button>
          )}
        >
          {hide => <TeamContactAdd teamInfo={team} onCreate={onCreateTeamContact} close={hide} />}
        </Modal>
      </div>
    </Card>
  );
};

const mapStateToProps = state => ({
  planId: planIdSelector(state)
});

const mapDispatchToProps = { createTeamContact, updateTeamContact, deleteTeamContact };

export default connect(mapStateToProps, mapDispatchToProps)(TeamType);
