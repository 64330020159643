import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, InputNumber, Button, message } from 'antd';
import { transferQuota } from '../../../../Broker/BrokerActions';
import { brokerageInfoSelector, loadingBrokerageSelector } from '../../../../Broker/BrokerSelectors';
import { MSG_ERROR } from '../../../../../../../constants';

const FormItem = Form.Item;
const { Option } = Select;

const TransferForm = ({ form, brokerageInfo = {}, loading, transferQuota, close }) => {
  const { getFieldDecorator } = form;
  const { brokerageId, subBrokerages = [], quota = {} } = brokerageInfo;

  const remaining = Math.max(quota.remaining, 0);
  const brokerageOptions = subBrokerages.map(item => <Option key={item.brokerageId}>{item.name}</Option>);

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await transferQuota(brokerageId, values);
          message.success(
            `${values.amount} ${values.amount > 1 ? 'keys have' : 'key has'} been successfully transferred`
          );
          close();
        } catch (err) {
          message.error(MSG_ERROR);
        }
      }
    });
  };

  return (
    <Form>
      <FormItem label="Brokerage">
        {getFieldDecorator('toBrokerageId', {
          rules: [{ required: true, message: 'Please select the brokerage.' }]
        })(
          <Select placeholder="Brokerage" disabled={loading} notFoundContent="No other brokerages found">
            {brokerageOptions}
          </Select>
        )}
      </FormItem>
      <FormItem label="Amount" extra={`The maximum subscriptions you can transfer is: ${remaining}`}>
        {getFieldDecorator('amount', {
          rules: [{ required: true, message: 'Please input the number of subscriptions you want to transfer.' }]
        })(<InputNumber placeholder="Amount" min={0} max={remaining} disabled={loading} style={{ width: '100% ' }} />)}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loading} onClick={onSubmit}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  brokerageInfo: brokerageInfoSelector(state),
  loading: loadingBrokerageSelector(state)
});

const mapDispatchToProps = { transferQuota };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(TransferForm));
