import React, { useState, useEffect } from 'react';
import { Input, Table, Icon, List, Descriptions, Checkbox } from 'antd';
import _ from 'lodash';
import './Table.scss';

export default ({
  title = 'data',
  columns = [],
  dataSource = [],
  breakpoint = 768,
  searchFields,
  showExpiredToggle,
  ...props
}) => {
  const [mode, setMode] = useState();
  const [filterText, setFilterText] = useState('');
  const [showExpired, setShowExpired] = useState(false);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth <= breakpoint) {
        setMode('list');
      } else {
        setMode('table');
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [breakpoint]);

  const onFilterTextChange = e => {
    const query = e.target.value.replace(/\W|_/g, '[$&]');
    setFilterText(query);
  };

  const filter = (data, filterText) => {
    let newData = data;
    if (!showExpired) {
      newData = data.filter(item => !item.isExpired);
    }
    if (filterText === '') {
      return newData;
    }
    const regex = new RegExp(filterText, 'i');
    return newData.filter(o =>
      _.reduce(searchFields, (result, value) => result || !!(o[value] && o[value].match(regex)), false)
    );
  };

  const onToggleChange = e => {
    setShowExpired(e.target.checked);
  };

  const renderDescriptionItem = (item, column) => {
    const { dataIndex, render } = column;
    return render ? render(item[dataIndex], item) : item[dataIndex];
  };

  const filteredData = filter(dataSource, filterText);

  return (
    <div className="table-container">
      {searchFields && searchFields.length > 0 && (
        <div className="table-search-container">
          {showExpiredToggle && (
            <Checkbox onChange={onToggleChange} className="table-toggle">
              Show Expired
            </Checkbox>
          )}
          <Input
            placeholder={`Search ${title}...`}
            prefix={<Icon type="search" />}
            onChange={onFilterTextChange}
            className="table-filter"
          />
        </div>
      )}

      {mode === 'table' ? (
        <Table
          columns={columns}
          dataSource={filteredData}
          rowClassName={record => (record.isExpired ? 'isExpired' : '')}
          pagination={{ hideOnSinglePage: true }}
          locale={{ emptyText: `No ${title}` }}
          scroll={{ x: columns.length * 120 }}
          {...props}
        />
      ) : (
        <List
          grid
          split={false}
          dataSource={filteredData}
          itemLayout="vertical"
          pagination={{ hideOnSinglePage: true }}
          renderItem={item => (
            <List.Item>
              <Descriptions bordered size="small" column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}>
                {columns.map(
                  column =>
                    (column.title || column.key === 'action') && (
                      <Descriptions.Item key={column.key} label={column.title} span={column.span}>
                        {renderDescriptionItem(item, column)}
                      </Descriptions.Item>
                    )
                )}
              </Descriptions>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};
