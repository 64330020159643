import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import { fetchProduct, createProduct, updateProduct } from '../ProductAction';
import { orgIdSelector } from '../../../OrganisationSelectors';
import { loadingStrategicAreasSelector } from '../ProductSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../constants';

const FormItem = Form.Item;

const ProductEdit = ({ form, orgId, loading, productInfo, activeCategoryId, createProduct, updateProduct, close }) => {
  const { getFieldDecorator } = form;
  const { name, description } = productInfo || {};

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          acc[key] =
            fieldsValue[key] && typeof fieldsValue[key] === 'string' ? fieldsValue[key].trim() : fieldsValue[key];
          return acc;
        }, {});
        values.strategicCriticalAreaCategoryId = activeCategoryId;
        try {
          if (productInfo) {
            const { strategicCriticalAreaId } = productInfo;
            await updateProduct(orgId, strategicCriticalAreaId, values);
          } else {
            await createProduct(orgId, values);
          }
          message.success(MSG_SAVE_SUCCESS);
          close();
        } catch (err) {
          message.error(MSG_ERROR);
        }
      }
    });
  };

  return (
    <Form>
      <FormItem label="Name">
        {getFieldDecorator('name', {
          initialValue: name,
          rules: [{ required: true, message: 'Please input the name.' }]
        })(<Input placeholder="Name" disabled={loading} spellCheck={false} />)}
      </FormItem>
      <FormItem label="Description">
        {getFieldDecorator('description', { initialValue: description })(
          <Input placeholder="Description" disabled={loading} spellCheck={false} />
        )}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loading} onClick={onSubmit}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  loading: loadingStrategicAreasSelector(state)
});

const mapDispatchToProps = {
  fetchProduct,
  createProduct,
  updateProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ProductEdit));
