import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'antd';
import './Card.scss';

export default class extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.node,
    onMore: PropTypes.func,
    onMoreText: PropTypes.string
  };

  static defaultProps = {
    className: null,
    style: {},
    color: '#f1c400',
    title: null,
    subtitle: null,
    description: null,
    icon: null,
    onMore: null,
    onMoreText: 'View more'
  };

  constructor() {
    super();
    this.description = React.createRef();
    this.state = {
      showDescription: false,
      showArrow: false
    };
  }

  componentDidMount() {
    if (this.description.current) {
      const showArrow = this.description.current.clientWidth < this.description.current.scrollWidth;
      this.setState({ showArrow });
    }
  }

  onToggleDescription = () => {
    this.setState(({ showDescription }) => ({ showDescription: !showDescription }));
  };

  render() {
    const { children, className, style, color, title, subtitle, description, icon, onMore, onMoreText } = this.props;
    const { showDescription, showArrow } = this.state;
    return (
      <div className={classNames('card', className)} style={{ ...style, borderColor: color }}>
        {title && (
          <div className="card-title">
            <div className="flex">
              {title && (
                <div className="title single-line">
                  {title}
                  {icon}
                </div>
              )}
              {onMore && (
                <Button type="link" onClick={onMore} className="more" style={{ color }}>
                  {onMoreText}
                </Button>
              )}
            </div>
            {subtitle && (
              <div className="subtitle single-line" style={{ color }}>
                {subtitle}
              </div>
            )}
            {description && (
              <div className="flex">
                <div ref={this.description} className={classNames('description', { 'single-line': !showDescription })}>
                  {description}
                </div>
                {showArrow && (
                  <Button
                    size="small"
                    type="link"
                    icon={showDescription ? 'up' : 'down'}
                    onClick={this.onToggleDescription}
                    style={{ color }}
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className="card-container">{children}</div>
      </div>
    );
  }
}
