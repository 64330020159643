import axios from 'axios';
import { fetchBranch } from '../../../Organisation/scenes/Branch/BranchAction';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_DEPARTMENTS: 'department/REQUEST_DEPARTMENTS',
  RECEIVE_DEPARTMENTS: 'department/RECEIVE_DEPARTMENTS'
};

const requestDepartments = () => ({ type: types.REQUEST_DEPARTMENTS });
const receiveDepartments = data => ({ type: types.RECEIVE_DEPARTMENTS, departmentList: data });

export const fetchDepartment = (orgId, branchId) => async dispatch => {
  dispatch(requestDepartments());
  const url = `${API_BASE}orgs/${orgId}/branches/${branchId}/departments`;
  try {
    const res = await axios.get(url);
    dispatch(receiveDepartments(res.data));
  } catch (err) {
    dispatch(receiveDepartments());
  }
};

export const createDepartment = (orgId, branchId, data) => async dispatch => {
  dispatch(requestDepartments());
  const url = `${API_BASE}orgs/${orgId}/branches/${branchId}/departments`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchBranch(orgId));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(receiveDepartments());
  }
};

export const updateDepartment = (orgId, branchId, departmentId, data) => async dispatch => {
  dispatch(requestDepartments());
  const url = `${API_BASE}orgs/${orgId}/branches/${branchId}/departments/${departmentId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchBranch(orgId));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(receiveDepartments());
  }
};

export const deleteDepartment = (orgId, branchId, data) => async dispatch => {
  dispatch(requestDepartments());
  const url = `${API_BASE}orgs/${orgId}/branches/${branchId}/departments/remove`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchBranch(orgId));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(receiveDepartments());
  }
};
