import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

export const riskListSelector = state => state.riskReducer.riskList;

export const risksSelector = createSelector(
  riskListSelector,
  riskList => (riskList ? _.groupBy(riskList, item => item.riskCategoryId) : undefined)
);

export const riskCategoriesSelector = state => state.riskReducer.riskCategories;

export const riskPresetsSelector = createSelector(
  state => state.riskReducer.riskPresets,
  riskPresets => (riskPresets ? _.groupBy(riskPresets, item => item.riskCategoryId) : undefined)
);

export const riskLikelihoodPresetsSelector = state => state.riskReducer.riskLikelihoodPresets;

export const mitigationStrategiesSelector = state => state.riskReducer.mitigationStrategies;

export const mitigationStatusSelector = state => state.riskReducer.mitigationStatus;

const calculateAnnualisedValue = (likelihoodValue, totalValue) => {
  if (!likelihoodValue || !totalValue) {
    return null;
  }
  return likelihoodValue * totalValue;
};

export const allRisksSelector = createSelector(
  riskListSelector,
  riskPresetsSelector,
  (riskList, riskPresets) => {
    if (riskList && riskPresets) {
      const allRisks = {};
      _.forEach(riskPresets, (value, key) => {
        allRisks[key] = [...value];
      });
      _.forEach(riskList, risk => {
        const { riskId, riskPresetId, riskCategoryId, likelihoodValue, cost } = risk;
        const annualisedValue = calculateAnnualisedValue(likelihoodValue, cost);
        if (riskPresetId) {
          const index = allRisks[riskCategoryId].findIndex(item => item.riskPresetId === riskPresetId);
          allRisks[riskCategoryId][index] = { ...allRisks[riskCategoryId][index], ...risk, annualisedValue };
        } else if (!allRisks[riskCategoryId].find(item => item.riskId === riskId)) {
          allRisks[riskCategoryId].push({ ...risk, annualisedValue });
        }
      });
      return allRisks;
    }
    return undefined;
  }
);

export const selectedRisksSelector = createSelector(
  risksSelector,
  risks =>
    _.reduce(
      risks,
      (result, value, key) => {
        /* eslint-disable-next-line no-param-reassign */
        result[key] = _.map(value, item => item.name);
        return result;
      },
      {}
    )
);

export const riskIndustrySelector = state => state.riskReducer.riskIndustry;

export const riskExposuresSelector = state => state.riskReducer.riskExposures;

export const riskReportSelector = state => state.riskReducer.riskReport;

export const riskReportDateSelector = createSelector(
  riskReportSelector,
  riskReport => (riskReport ? moment(riskReport.createdAt).format('DD/MM/YYYY') : null)
);

export const riskReportIndemnityPresetsSelector = state => state.riskReducer.riskReportIndemnityPresets;

export const riskReportReviewPresetsSelector = state => state.riskReducer.riskReportReviewPresets;
