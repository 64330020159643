import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Page from '../Page/Page';
import Table from '../Table/Table';
import { assetsSelector, assetTypesSelector } from '../../../../../Bia/BiaSelectors';
import { departmentListSelector } from '../../../../../Department/DepartmentSelectors';

const getTableData = (rawData = [], assetTypes, departmentList) => {
  const header = [
    { width: 25, name: 'CATEGORY' },
    { width: 20, name: 'DESCRIPTION' },
    { width: 5, name: 'QUANTITY' },
    { width: 50, name: 'COMMENTS' }
  ];
  const data = _.chain(rawData)
    .map((assetsOfDepartment, departmentId) => {
      const department = departmentList.find(item => `${item.departmentId}` === departmentId);
      const assets = _.map(assetsOfDepartment, (assetsOfType, assetTypeId) => {
        const assetType = assetTypes.find(item => `${item.assetTypeId}` === assetTypeId);
        return { assetTypeId, assetTypeName: assetType.name, assets: assetsOfType };
      });
      return { departmentId, departmentName: department.name, assets };
    })
    .value();
  return { header, data };
};

const Asset = ({ assets = {}, assetTypes, departmentList }) => {
  const { header, data } = getTableData(assets, assetTypes, departmentList);
  return (
    <Page dynamic>
      <div className="content">
        <h2>D. Disaster Recovery Requirements</h2>
        {data.map(({ departmentName, assets }) => (
          <Fragment key={departmentName}>
            <h3>{departmentName}</h3>
            <Table header={header}>
              {assets.map(item =>
                item.assets.map(asset => (
                  <tr key={asset.assetId}>
                    <td className="table-content">{item.assetTypeName}</td>
                    <td className="table-content">{asset.name}</td>
                    <td className="table-content">{asset.quantity}</td>
                    <td className="table-content">{asset.comments}</td>
                  </tr>
                ))
              )}
            </Table>
          </Fragment>
        ))}
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  assets: assetsSelector(state),
  assetTypes: assetTypesSelector(state),
  departmentList: departmentListSelector(state)
});

export default connect(mapStateToProps)(Asset);
