import { types } from './PlanAction';
import { USER_LOGOUT } from '../../../../components/Login/LoginAction';

const initialState = { loadingPlans: false, loadingPlanSubscriptions: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initialState };
    case types.REQUEST_ALL_PLANS:
      return { ...state, loadingPlans: true };
    case types.RECEIVE_ALL_PLANS:
      return { ...state, loadingPlans: false, allPlans: action.allPlans };
    case types.REQUEST_PLAN_SUBSCRIPTIONS:
      return { ...state, loadingPlanSubscriptions: true };
    case types.RECEIVE_PLAN_SUBSCRIPTIONS: {
      return {
        ...state,
        loadingPlanSubscriptions: false,
        planSubscriptions: { ...state.planSubscriptions, [action.planId]: action.planSubsciptions }
      };
    }
    case types.REQUEST_PLANS:
      return { ...state, loadingPlans: true };
    case types.RECEIVE_PLANS:
      return { ...state, loadingPlans: false, plans: action.plans || state.plans };
    case types.REQUEST_CURRENCIES:
      return { ...state, loadingPlans: true };
    case types.RECEIVE_CURRENCIES:
      return { ...state, loadingPlans: false, currencies: action.currencies || state.currencies };
    case types.CURRENT_PLAN:
      return { ...state, currentPlan: action.currentPlan };
    default:
      return state;
  }
};
