import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, message, Radio, Alert } from 'antd';
import * as HttpStatus from 'http-status-codes';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { updateUser, sendMobileOtp, verifyMobileOtp } from '../UserActions';
import { userInfoSelector } from '../UserSelectors';
import { MSG_ERROR } from '../../../../../constants';
import 'react-phone-number-input/style.css';

const FormItem = Form.Item;

const otpStatusMessage = {
  required: {
    type: 'warning',
    message: 'Phone number verification is required. Please click the button to send the verification code.'
  },
  sent: { type: 'success', message: 'Verification code has been sent.' },
  verified: { type: 'success', message: 'Successfully verified.' },
  errorWrongMobile: { type: 'error', message: 'Please add a valid number.' }
};

const UserEdit = ({ form, userInfo = {}, userRequest, updateUser, sendMobileOtp, verifyMobileOtp, close }) => {
  const { getFieldDecorator } = form;
  const [requireMobileVerification, setRequireMobileVerification] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [codeSent, setCodeSent] = useState(false);
  const [otpCode, setOtpCode] = useState();
  const [otpStatus, setOtpStatus] = useState(otpStatusMessage.required);

  const codeInput = useCallback(inputElement => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const onPhoneNumberChange = value => {
    if (value !== userInfo.phoneNumber) {
      setRequireMobileVerification(true);
      setOtpStatus(otpStatusMessage.required);
      setCodeSent(false);
      setPhoneNumber(value);
    } else {
      setRequireMobileVerification(false);
    }
  };

  const onOtpCodeChange = e => {
    setOtpCode(e.target.value);
  };

  // eslint-disable-next-line consistent-return
  const sendOtp = async () => {
    try {
      if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
        await sendMobileOtp({ userId: userInfo.id, action: 1, phoneNumber });
        setOtpStatus(otpStatusMessage.sent);
        setCodeSent(true);
      } else {
        setOtpStatus(otpStatusMessage.errorWrongMobile);
      }
    } catch (err) {
      setOtpStatus({ type: 'error', message: err });
    }
  };

  const verifyOtp = async () => {
    try {
      await verifyMobileOtp({ userId: userInfo.id, action: 1, code: `${otpCode}` });
      setOtpStatus(otpStatusMessage.verified);
      setRequireMobileVerification(false);
    } catch (err) {
      setOtpStatus({ type: 'error', message: err });
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, fieldsValue) => {
      if (!err && !requireMobileVerification) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          acc[key] =
            fieldsValue[key] && typeof fieldsValue[key] === 'string' ? fieldsValue[key].trim() : fieldsValue[key];
          return acc;
        }, {});
        try {
          await updateUser(values);
          close();
          if (values.email !== userInfo.email) {
            message.success(
              'You request changing your email address, an confirmation letter has been sent to your new email address, please check your inbox to complete the update',
              10
            );
          } else {
            message.success('The user information has been successfully updated');
          }
        } catch (err) {
          if (err && err.status === HttpStatus.CONFLICT) {
            message.error('Sorry, this email is already exist');
          } else {
            message.error(MSG_ERROR);
          }
        }
      }
    });
  };

  return (
    <Form>
      <Row gutter={20}>
        <Col span={12}>
          <FormItem label="First Name">
            {getFieldDecorator('firstName', { initialValue: userInfo.firstName })(
              <Input placeholder="First Name" disabled={userRequest} spellCheck={false} />
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Last Name">
            {getFieldDecorator('lastName', { initialValue: userInfo.lastName })(
              <Input placeholder="Last Name" disabled={userRequest} spellCheck={false} />
            )}
          </FormItem>
        </Col>
      </Row>
      <FormItem label="Email">
        {getFieldDecorator('email', {
          initialValue: userInfo.email,
          validateTrigger: 'onBlur',
          rules: [{ type: 'email', message: 'Please input an valid email.' }]
        })(<Input placeholder="Email" disabled={userRequest} spellCheck={false} />)}
      </FormItem>
      <FormItem label="Phone Number">
        {getFieldDecorator('phoneNumber', {
          initialValue: userInfo?.phoneNumber,
          validateTrigger: 'onBlur'
        })(
          <PhoneInput
            countrySelectProps={{ unicodeFlags: true }}
            countryCallingCodeEditable={false}
            defaultCountry="AU"
            disabled={userRequest}
            onChange={onPhoneNumberChange}
          />
        )}
      </FormItem>
      {requireMobileVerification && (
        <>
          <Alert type={otpStatus?.type} showIcon message={otpStatus?.message} style={{ marginBottom: '10px' }} />
          {codeSent && (
            <>
              <FormItem label="Verification Code">
                {getFieldDecorator('otp', {
                  validateTrigger: 'onBlur'
                })(
                  <Input
                    maxLength={6}
                    onChange={onOtpCodeChange}
                    placeholder="Verification code"
                    disabled={userRequest}
                    spellCheck={false}
                    autoComplete="one-time-code"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    ref={codeInput}
                  />
                )}
              </FormItem>
              <Button type="primary" loading={userRequest} disabled={userRequest} onClick={verifyOtp}>
                {userRequest ? 'Verifying' : 'Verify'}
              </Button>{' '}
              <Button type="secondary" disabled={userRequest} onClick={sendOtp}>
                {userRequest ? 'Sending' : 'Resend'}
              </Button>
            </>
          )}
          {!codeSent && (
            <Button type="primary" loading={userRequest} disabled={userRequest} onClick={sendOtp}>
              {userRequest ? 'Sending' : 'Send One-time Passcode'}
            </Button>
          )}
        </>
      )}
      {otpStatus?.message === otpStatusMessage.verified.message && (
        <Alert
          type={otpStatusMessage.verified.type}
          showIcon
          message={otpStatusMessage.verified.message}
          style={{ marginBottom: '10px' }}
        />
      )}
      <FormItem label="2FA Type">
        {getFieldDecorator('otpType', {
          initialValue: userInfo.otpType
        })(
          <Radio.Group disabled={userRequest}>
            <Radio value={2}>Email</Radio>
            {(phoneNumber || userInfo.phoneNumber) && (
              <Radio disabled={requireMobileVerification} value={1}>
                SMS
              </Radio>
            )}
          </Radio.Group>
        )}
      </FormItem>
      <FormItem>
        <Button type="primary" disabled={requireMobileVerification} block loading={userRequest} onClick={onSubmit}>
          Save
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  userInfo: userInfoSelector(state),
  userRequest: state.userReducer.loadingUser
});

const mapDispatchToProps = { updateUser, sendMobileOtp, verifyMobileOtp };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(UserEdit));
