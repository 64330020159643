import { types } from './DocumentAction';

const initialState = { loadingFolders: false, loadingDocuments: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_FOLDERS:
      return { ...state, loadingFolders: true };
    case types.RECEIVE_FOLDERS:
      return { ...state, loadingFolders: false, folders: action.folders };
    case types.REQUEST_DOCUMENTS:
      return { ...state, loadingDocuments: true };
    case types.RECEIVE_DOCUMENTS:
      return { ...state, loadingDocuments: false, documents: action.documents };
    default:
      return state;
  }
};
