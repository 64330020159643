import React, { PureComponent } from 'react';
import { Table } from 'antd';

export default class CriticalAreasTable extends PureComponent {
  render() {
    const { biaList } = this.props;
    const columns = [
      {
        key: 'name',
        title: 'Critical Area',
        width: '15%',
        render: (text, record) => <div>{record.strategicCriticalArea.name}</div>,
        sorter: (a, b) => a.strategicCriticalArea.name.localeCompare(b.strategicCriticalArea.name)
      },
      {
        key: 'category',
        title: 'Category',
        render: (text, record) => <div>{record.strategicCriticalArea.strategicCriticalAreaCategory.name}</div>,
        sorter: (a, b) =>
          a.strategicCriticalArea.strategicCriticalAreaCategory.name.localeCompare(
            b.strategicCriticalArea.strategicCriticalAreaCategory.name
          )
      },
      {
        key: 'criticality',
        title: 'Criticality',
        dataIndex: 'criticality',
        sorter: (a, b) => a.criticality - b.criticality
      },
      {
        key: 'period',
        title: 'Critical Period',
        render: (text, record) => <div>{(record.criticalPeriods || []).map(item => item.name).join(', ')}</div>
      },
      {
        key: 'penalties',
        title: 'Delivery Penalties',
        dataIndex: 'penalty'
      }
    ];

    return (
      <div className="bia-detail-container">
        <Table
          size="middle"
          columns={columns}
          dataSource={biaList}
          rowKey={record => record.strategicCriticalArea.strategicCriticalAreaId}
          pagination={{ hideOnSinglePage: true }}
          locale={{ emptyText: 'No Critical Areas' }}
          className="table"
        />
      </div>
    );
  }
}
