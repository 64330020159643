import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Icon } from 'antd';
import classNames from 'classnames';
import Modal from '../../../../../components/Modal/Modal';
import DocumentAdd from './DocumentAdd';
import icons from '../images/icons.png';

const ICONS = {
  'Evacuation Plan': 'doc-icon-exit',
  'Floor Plans': 'doc-icon-floor',
  'Insurance Policy': 'doc-icon-umbrella',
  'Insurance Schedule': 'doc-icon-umbrella',
  'IT Systems Configuration': 'doc-icon-settings',
  'Lease Agreement': 'doc-icon-sign',
  'Network Diagrams': 'doc-icon-network'
};

const DOCS = [
  { title: 'BUSINESS CONTINUITY POLICY', icon: 'doc-icon-sign', link: '/organization/policy' },
  { title: 'BUSINESS CONTINUITY PLAN', icon: 'doc-icon-book', link: '/dashboard/plan-document' },
  { title: 'RISK & INSURANCE REPORT', icon: 'doc-icon-config', link: '/dashboard/risk/report' },
  { title: 'CERTIFICATE', icon: 'doc-icon-sign', link: '/organization/policy' }
];

export default ({ folders, documents, selectedFolder, onFolderSelect, onUploadDocument, onAddUrl }) => (
  <>
    <h3 className="doc-header">Files</h3>
    <Row gutter={20} type="flex" className="folder-list">
      {DOCS.map(item => (
        <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3} key={item.title}>
          <Link to={item.link} target={item.target}>
            <div className="folder-tile">
              <i style={{ backgroundImage: `url(${icons})` }} className={item.icon} />
              <p className="folder-tile-title">{item.title}</p>
              <Button size="small" type="primary" className="folder-tile-edit">
                View
              </Button>
            </div>
          </Link>
        </Col>
      ))}
    </Row>
    <h3 className="doc-header">Folders</h3>
    <Row gutter={20} type="flex" className="folder-list">
      {folders.map(obj => (
        <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3} key={obj.name}>
          <div
            role="menuitem"
            tabIndex="0"
            onClick={() => onFolderSelect(obj)}
            onKeyDown={e => e.preventDefault()}
            className={classNames('folder-tile', { selected: selectedFolder && obj.name === selectedFolder.name })}
          >
            {ICONS[obj.name] ? (
              <i style={{ backgroundImage: `url(${icons})` }} className={ICONS[obj.name]} />
            ) : (
              <Icon type="folder-open" className="folder-tile-icon" />
            )}
            <p className="folder-tile-title">{obj.name}</p>
            <p className="folder-tile-count">
              {/* eslint no-nested-ternary: off */}
              {documents[obj.id]
                ? documents[obj.id].filter(item => (item.documentFolderText || item.documentFolder.name) === obj.name)
                    .length > 1
                  ? `${
                      documents[obj.id].filter(
                        item => (item.documentFolderText || item.documentFolder.name) === obj.name
                      ).length
                    } items`
                  : '1 item'
                : 'no items'}
            </p>
          </div>
        </Col>
      ))}
      <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={3}>
        <Modal
          title="Upload Document / URL"
          toggle={show => (
            <div
              className="folder-tile"
              role="menuitem"
              tabIndex="0"
              onClick={show}
              onKeyDown={e => e.preventDefault()}
              style={{ justifyContent: 'center' }}
            >
              <Icon type="plus" className="folder-tile-icon" />
            </div>
          )}
        >
          {hide => <DocumentAdd onUploadDocument={onUploadDocument} onAddUrl={onAddUrl} close={hide} />}
        </Modal>
      </Col>
    </Row>
  </>
);
