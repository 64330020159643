import React, { Component } from 'react';
import { Form, Row, Col, Input, DatePicker, Button } from 'antd';
import moment from 'moment';
import { withOnFocus } from '../../../../../components/HOC';
import explanations from '../data/explanations.json';

const FormItem = Form.Item;
const { TextArea } = Input;

class DocumentEdit extends Component {
  constructor(props) {
    super(props);
    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.DatePickerWithOnFocus = withOnFocus(DatePicker, props.onExplanationChange);
    this.TextAreaWithOnFocus = withOnFocus(TextArea, props.onExplanationChange);
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, documentInfo = {}, onUpdateDocument, close } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const { documentId, documentFolderId, documentFolderText } = documentInfo;
        const data = {
          ...values,
          documentFolderId,
          documentFolderText,
          effectiveDate: values.effectiveDate ? values.effectiveDate.format('YYYY-MM-DD') : null
        };
        onUpdateDocument(documentId, data);
        close();
      }
    });
  };

  render() {
    const { InputWithOnFocus, DatePickerWithOnFocus, TextAreaWithOnFocus } = this;
    const { form, documentInfo = {}, loadingDocuments } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.onSubmit}>
        <FormItem label="File Name">
          {getFieldDecorator('name', {
            initialValue: documentInfo.name
          })(<InputWithOnFocus placeholder="File Name" readOnly explanation={explanations.name} />)}
        </FormItem>
        <Row gutter={20}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <FormItem label="Date Uploaded">
              {getFieldDecorator('createDate', {
                initialValue: moment(documentInfo.createDate).format('DD/MM/YYYY')
              })(<InputWithOnFocus placeholder="Date Uploaded" readOnly explanation={explanations.createDate} />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <FormItem label="Effective Date">
              {getFieldDecorator('effectiveDate', {
                initialValue: documentInfo.effectiveDate ? moment(documentInfo.effectiveDate) : null
              })(
                <DatePickerWithOnFocus
                  placeholder="DD/MM/YYYY"
                  disabled={loadingDocuments}
                  format="DD/MM/YYYY"
                  explanation={explanations.effectiveDate}
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem label="Friendly Name">
          {getFieldDecorator('displayName', {
            initialValue: documentInfo.displayName
          })(
            <InputWithOnFocus
              placeholder="Friendly Name"
              disabled={loadingDocuments}
              explanation={explanations.displayName}
            />
          )}
        </FormItem>
        <FormItem label="Comments">
          {getFieldDecorator('comments', {
            initialValue: documentInfo.comments
          })(<TextAreaWithOnFocus disabled={loadingDocuments} explanation={explanations.comments} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" block loading={loadingDocuments} onClick={this.onSubmit}>
            {loadingDocuments ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(DocumentEdit);
