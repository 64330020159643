import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import Logo from '../../../../../../components/Logo/Logo';
import { businessNameSelector, businessLogoSelector } from '../../../../OrganisationSelectors';
import { policyResponsibleSelector, policyDateSelector, policyMembersSelector } from '../../PolicySelectors';
import './PolicyPreview.scss';

const Page = ({ children, businessName, date, pageNumber }) => (
  <section className="policy-preview-page">
    <header>
      <div className="client-name">{businessName || 'client name'}</div>
      <div className="policy-document">POLICY DOCUMENT</div>
      <div className="header-bar" />
    </header>
    <main>{children}</main>
    <footer>
      <div className="date">{date}</div>
      <div className="page-number">{pageNumber}</div>
    </footer>
  </section>
);

const PolicyPreview = ({ businessName, businessLogo, policyResponsible, policyDate, policyMembers }) => (
  <article className="policy-preview">
    <section className="policy-preview-cover">
      <Logo className="lmi-logo" />
      <div className="organisation">
        <div className="organisation-logo" style={{ backgroundImage: `url(${businessLogo}` }} />
        <div className="organisation-name">{businessName || 'PREPARED FOR\nORGANISATION\nNAME'}</div>
        <div className="organisation-date">{`DATE ISSUED ${policyDate}`}</div>
      </div>
      <div className="title">
        <h1>
          <strong>POLICY</strong>
          &amp;
        </h1>
        <h2>PROGRAM</h2>
      </div>
    </section>
    <section className="policy-preview-disclaimer">
      <div className="disclaimer">
        <div className="disclaimer-content">
          <h2>Disclaimer</h2>
          <p>
            The information in this Policy document was as received from senior members of staff at {businessName}. This
            document serves as a guideline and framework to assist in the initiation of a program of risk.
          </p>
          <p>
            This document may be amended from time to time, or as the business evolves. Ensure that you use the latest
            version of this document at all times.
          </p>
          <p>
            LMI Group Pty Ltd shall in no event whatsoever, be responsible for the results or outcomes of any actions
            taken on the basis of reliance on the material contained herein, nor accept any liability for loss or damage
            suffered by any person or body due to information provided, nor for any error in or omission from the
            material contained herein.
          </p>
        </div>
      </div>
    </section>
    <Page businessName={businessName} pageNumber={3} date={policyDate}>
      <div className="policy-preview-table-of-content">
        <div className="title">
          <span className="shape" />
          <h1>TABLE OF CONTENT</h1>
        </div>
        <ul>
          <li>
            <div className="item">01. SCOPE</div>
            <div className="page">05</div>
          </li>
          <li>
            <div className="item">02. PURPOSE</div>
            <div className="page">05</div>
          </li>
          <li>
            <div className="item">03. OBJECTIVES</div>
            <div className="page">06</div>
          </li>
          <li>
            <div className="item">04. GOVERNANCE STRUCTURE</div>
            <div className="page">07</div>
          </li>
          <li>
            <div className="item">05. MANAGEMENT OF CHANGE</div>
            <div className="page">09</div>
          </li>
          <li>
            <div className="item">06. STANDARDS</div>
            <div className="page">09</div>
          </li>
        </ul>
      </div>
    </Page>
    <Page businessName={businessName} pageNumber={4} date={policyDate}>
      <div className="policy-preview-statement">
        <div className="policy-statement-title">
          <h1>{businessName}</h1>
          <h1 className="bold">POLICY STATEMENT</h1>
        </div>
        <div className="content">
          <p>
            {businessName} acknowledges its responsibility to plan for resilience of the business, to identify and
            measure its risks, and as far as possible, to mitigate the period and result of disruption and/or loss at
            the time of an incident.
          </p>
          <p>
            {businessName} is committed to ensuring it provides a sustainable and safe service to its stakeholders and
            the environment, and therefore commits to implementing, maintaining and improving a Business Continuity
            Program within its essential business services and critical risk areas.
          </p>
          <p>
            The {businessName} Board/Crisis Management Team is committed to ongoing support and monitoring of this
            program, and is accountable for its strategic leadership and governance.
          </p>
        </div>
      </div>
    </Page>
    <Page businessName={businessName} pageNumber={5} date={policyDate}>
      <div className="title">
        <h1 className="shape light">1.</h1>
        <h1>SCOPE</h1>
      </div>
      <div className="content">
        <p>
          The scope of this document encompasses all the critical operations at {businessName}. The Business Continuity
          Management System and the Business Continuity Plan form a vital part of this company&apos;s business
          protection program and will be implemented and maintained as an integral part of managing the business.
        </p>
      </div>
      <div className="break" />
      <div className="break" />
      <div className="title">
        <h1 className="shape light">2.</h1>
        <h1>PURPOSE</h1>
      </div>
      <div className="content">
        <p>
          The purpose of the {businessName} Business Continuity Policy, is to provide a framework around which the{' '}
          {businessName} Risk and Resilience capability is designed and built.
        </p>
        <p>The {businessName} Business Continuity policy provides a structure through which:</p>
        <ol type="a">
          <li>a Business Continuity Management system is established and maintained;</li>
        </ol>
      </div>
    </Page>
    <Page businessName={businessName} pageNumber={6} date={policyDate}>
      <div className="content">
        <ol type="a" start="2">
          <li>key critical activities and services as well as risks and resources, will be identified;</li>
          <li>
            a Business Impact Analysis (BIA) and/or Risk and Threat Assessment (RA) will be applied to key services and
            their supporting critical activities, processes and resources;
          </li>
          <li>risk mitigation strategies will be applied to reduce the impact of disruption on key services.</li>
        </ol>
        <p>This will ensure that:</p>
        <ol type="a">
          <li>
            the interests of {businessName}&apos;s key stakeholders, reputation and value creating activities are
            safeguarded;
          </li>
          <li>regulatory and corporate governance requirements are complied with;</li>
          <li>
            Business Continuity Plans will be developed to ensure continuity of key services at a minimum acceptable
            standard following a disruption;
          </li>
          <li>activation of Business Continuity Plans can be managed;</li>
          <li>plans are subject to on-going exercising and revision.</li>
        </ol>
      </div>
      <div className="break" />
      <div className="title">
        <h1 className="shape light">3.</h1>
        <h1>OBJECTIVES</h1>
      </div>
      <div className="content">
        <p>In the event of an incident, it is {businessName}&apos;s aim to meet the following objectives:</p>
        <ol type="a">
          <li>
            maintain a healthy and safe work environment to ensure staff&apos;s, clients&apos;, visitors&apos; and suppliers&apos; safety, welfare and confidence;
          </li>
          <li>fulfill regulatory requirements;</li>
          <li>maintain integrity of customer information;</li>
        </ol>
      </div>
    </Page>
    <Page businessName={businessName} pageNumber={7} date={policyDate}>
      <div className="content">
        <ol type="a" start="4">
          <li>maintain customer, staff and stakeholder contract and confidence;</li>
          <li>control of expenditure and any extraordinary costs caused by an event;</li>
          <li>manage risk and apply the Business Continuity management structure to priority areas;</li>
          <li>provide timely availability of all key resources necessary to operate critical business processes;</li>
          <li>exercising and revision of plans.</li>
        </ol>
      </div>
      <div className="break" />
      <div className="title">
        <h1 className="shape light">4.</h1>
        <h1>GOVERNANCE STRUCTURE</h1>
      </div>
      <div className="content">
        <h2>4.1. THE CRISIS MANAGEMENT TEAM (&apos;CMT&apos;)</h2>
        <p>{policyResponsible}</p>
        <p>The Crisis Management Team will:</p>
        <ol type="a">
          <li>define the Business Continuity strategies;</li>
          <li>provide the organization with leadership and support to implement Business Continuity solutions;</li>
          <li>provide oversight to the approach and management of the Business Continuity program;</li>
          <li>obtain agreement on the Business Continuity Management budget and projects;</li>
          <li>take final responsibility for the strategic direction of the Business Continuity Management program.</li>
        </ol>
      </div>
    </Page>
    <Page businessName={businessName} pageNumber={8} date={policyDate}>
      <div className="content">
        <p>This will include:</p>
        <ol type="a">
          <li>policy setting;</li>
          <li>ratification of processes and approaches;</li>
          <li>
            maintain a risk register of business continuity risks across the business with a view to identifying common
            risk elements and their mitigation activities;
          </li>
          <li>promote the development of a business continuity culture;</li>
          <li>
            undertake the process of Business Impact Assessments (BIA) and/or Risk and Threat Assessments (RA) and
            analysis;
          </li>
          <li>
            appropriate Business Continuity Plans which include:
            <ul>
              <li>
                <h3>Strategic Level information</h3>
                <div>Emergency Response Team</div>
                <div>Crisis Management Team</div>
              </li>
              <li>
                <h3>Tactical Level information</h3>
                <div>IT Coordinator</div>
              </li>
              <li>
                <h3>Operational Level information</h3>
                <div>Operational Response Team/s</div>
              </li>
            </ul>
          </li>
        </ol>
        <h2>4.2 Responsibilities</h2>
        <ol type="a">
          <li>
            The Crisis Management Team take final responsibility for the strategic direction of the Business Continuity
            Management program;
          </li>
          <li>All other appointed teams are expected to report to the Crisis Management team.</li>
        </ol>
      </div>
    </Page>
    <Page businessName={businessName} pageNumber={9} date={policyDate}>
      <div className="title">
        <h1 className="shape light">5.</h1>
        <h1>MANAGEMENT OF CHANGE</h1>
      </div>
      <div className="content">
        <p>
          Changes to existing systems, technical infrastructure and business processes or implementation of new ones,
          must be applied to the Business Continuity Plan and Recovery strategy. This may require the revision of this
          policy document. These changes must be tested within a reasonable and risk-prudent timeframe.
        </p>
      </div>
      <div className="break" />
      <div className="break" />
      <div className="title">
        <h1 className="shape light">6.</h1>
        <h1>STANDARDS</h1>
      </div>
      <div className="content">
        <p>
          The standard against which ({businessName}) capability shall be measured are components of the following: ISO
          22301:2012 Societal Security-Business Continuity Management Systems and ISO 31000:2009 Risk Management, and
          the Business Continuity Institute GPG 2018, or their updated equivalents.
        </p>
      </div>
    </Page>
    <Page businessName={businessName} pageNumber={10} date={policyDate}>
      <div className="title">
        <span className="shape" />
        <h1>ACCEPTANCE</h1>
      </div>
      <div className="content">
        <p>Accepted &amp; Agreed by</p>
        <h3 className="italic">{policyMembers.join(', ')}</h3>
        <h3 className="italic">{policyDate}</h3>
        <div className="break" />
        <p>on behalf of</p>
        <h3 className="italic">{businessName}</h3>
        <h3 className="italic">{policyDate}</h3>
      </div>
    </Page>
    <section className="policy-preview-back-cover">
      <div className="company-intro">
        <div className="introduction">
          ContinuityCoach.com is structured and is updated where appropriate to reflect current best Business Continuity
          Management practice as stated in Business Continuity Institute Good Practice Guidelines &amp; International
          Organization for Standardization ISO 22301 Societal Security --- Business Continuity Management Systems ---
          Requirements.
        </div>
        <Logo width={200} />
      </div>
      <div className="company-info">
        <ul>
          <li>
            <span>Support@ContinuityCoach.com</span>
            <Icon type="mail" />
          </li>
          <li>
            <span>www.ContinuityCoach.com</span>
            <Icon type="home" />
          </li>
        </ul>
      </div>
    </section>
  </article>
);

const mapStateToProps = state => ({
  businessName: businessNameSelector(state),
  businessLogo: businessLogoSelector(state),
  policyResponsible: policyResponsibleSelector(state),
  policyDate: policyDateSelector(state),
  policyMembers: policyMembersSelector(state)
});

export default connect(mapStateToProps)(PolicyPreview);
