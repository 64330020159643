import { types } from './ContactAction';
import { types as organisationTypes } from '../../OrganisationAction';

const initialState = { loadingContactTypes: false, loadingWorkTypes: false, loadingContacts: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case organisationTypes.SWITCH_ORGANISATION:
      return { ...initialState };
    case types.REQUEST_CONTACT_TYPES:
      return { ...state, loadingContactTypes: true };
    case types.RECEIVE_CONTACT_TYPES:
      return { ...state, loadingContactTypes: false, contactTypes: action.contactTypes };
    case types.REQUEST_WORK_TYPES:
      return { ...state, loadingWorkTypes: true };
    case types.RECEIVE_WORK_TYPES:
      return { ...state, loadingWorkTypes: false, workTypes: action.workTypes };
    case types.REQUEST_CONTACTS:
      return { ...state, loadingContacts: true };
    case types.RECEIVE_CONTACTS:
      return { ...state, loadingContacts: false, contactList: action.contactList || state.contactList };
    default:
      return state;
  }
};
