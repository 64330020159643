import React, { Component } from 'react';
import axios from 'axios';
import { Button, message, Spin, Icon } from 'antd';
import CurrencyFormat from 'react-currency-format';
import './Payment.scss';

const style = {
  'div.payway-card': { 'background-color': '#fff', 'border-radius': 0, margin: '0 auto' },
  '.payway-card label': { color: 'rgba(0, 0, 0, 0.85)' },
  '.payway-card legend': { color: 'rgba(0, 0, 0, 0.85)' },
  '.payway-card input': {
    color: 'rgba(0, 0, 0, 0.65)'
    // height: '32px',
    // padding: '4px 11px',
    // 'font-size': '14px',
    // 'box-sizing': 'border-box'
  },
  '.payway-card input:focus': { 'border-color': '#f1c400' },
  '.payway-card select': { color: 'rgba(0, 0, 0, 0.65)' },
  '.payway-card select:focus': { 'border-color': '#f1c400' }
};

// 4564710000000004
export default class Payment extends Component {
  state = { paying: false, priceSummary: {} };

  async componentDidMount() {
    const { product, discountCode, calculateTotal } = this.props;
    const res = await calculateTotal({ quantity: product.quantity, discountCode });
    this.setState({ priceSummary: res.data });
    this.submit = document.getElementById('payway-cc-submit');
    window.payway.createCreditCardFrame(
      {
        publishableApiKey: process.env.REACT_APP_PAYWAY_API_KEY,
        style,
        tokenMode: 'callback',
        onValid: () => {
          this.submit.disabled = false;
        },
        onInvalid: () => {
          this.submit.disabled = false;
        }
      },
      this.createdCallback
    );
  }

  createdCallback = (err, frame) => {
    if (!err) {
      this.submit.addEventListener('click', () => {
        this.setState({ paying: true });
        frame.getToken(this.tokenCallback);
      });
    }
  };

  tokenCallback = async (err, { singleUseTokenId }) => {
    if (err) {
      message.error(err);
    } else {
      const { product, discountCode, afterPay, next, billingAddress } = this.props;
      const { priceSummary } = this.state;
      const url = `${process.env.REACT_APP_API_BASE}purchase/subscription`;
      const data = { singleUseToken: singleUseTokenId, quantity: product.quantity, discountCode, billingAddress };
      try {
        await axios.post(url, data);
        afterPay(priceSummary);
        next();
      } catch (err) {
        message.error('Payment was failed');
      }
    }
    this.setState({ paying: false });
  };

  render() {
    const { prev } = this.props;
    const { paying, priceSummary } = this.state;
    return (
      <Spin spinning={paying} size="large" indicator={<Icon type="loading" />}>
        <form className="purchase-form">
          <div id="payway-credit-card" />
          <div className="btn-container space-between no-padding">
            <Button type="primary" ghost icon="arrow-left" disabled={paying} onClick={prev}>
              Back
            </Button>
            <Button id="payway-cc-submit" type="primary" disabled loading={paying}>
              <CurrencyFormat value={priceSummary.total} displayType='text' thousandSeparator prefix='Pay AU$ ' />
            </Button>
          </div>
        </form>
      </Spin>
    );
  }
}
