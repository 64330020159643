import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Admin from './scenes/Admin/Admin';
import Landing from './scenes/Landing/Landing';
import Dashboard from './scenes/Dashboard/Dashboard';
import Organisation from './scenes/Organisation/Organisation';
import EmailConfirmation from './scenes/EmailConfirmation';
import EmailInvitation from './scenes/EmailInvitation';
import EmailUpdate from './scenes/EmailUpdate';
import ResetPassword from './scenes/ResetPassword';
import ContactUs from './scenes/ContactUs/ContactUs';
import Purchase from './scenes/Purchase/Purchase';
import NotFound from './scenes/404';
import Tracker from './components/Tracker/Tracker';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('token') || sessionStorage.getItem('token') ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export default () => (
  <Router>
    <Tracker />
    <Switch>
      <Route exact path="/" component={Landing} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/admin" component={Admin} />
      <PrivateRoute path="/organization" component={Organisation} />
      <Route path="/confirmation/email" component={EmailConfirmation} />
      <Route path="/invitation/email" component={EmailInvitation} />
      <Route path="/update-email" component={EmailUpdate} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/contact-us" component={ContactUs} />
      <PrivateRoute path="/purchase" component={Purchase} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);
