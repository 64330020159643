import axios from 'axios';
import { strategicCriticalAreaCategoriesSelector } from './ProductSelectors';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_STRATEGIC_AREAS: 'product/REQUEST_STRATEGIC_AREAS',
  RECEIVE_STRATEGIC_AREAS: 'product/RECEIVE_STRATEGIC_AREAS',
  REQUEST_STRATEGIC_AREA_CATEGORIES: 'product/REQUEST_STRATEGIC_AREA_CATEGORIES',
  RECEIVE_STRATEGIC_AREA_CATEGORIES: 'product/RECEIVE_STRATEGIC_AREA_CATEGORIES'
};

const requestStrategicAreas = () => ({ type: types.REQUEST_STRATEGIC_AREAS });
const receiveStrategicAreas = data => ({ type: types.RECEIVE_STRATEGIC_AREAS, products: data });

const requestStrategicAreaCategories = () => ({ type: types.REQUEST_STRATEGIC_AREA_CATEGORIES });
const receiveStrategicAreaCategories = data => ({
  type: types.RECEIVE_STRATEGIC_AREA_CATEGORIES,
  strategicCriticalAreaCategories: data
});

export const fetchProduct = orgId => async dispatch => {
  dispatch(requestStrategicAreas());
  const url = `${API_BASE}orgs/${orgId}/strategic_critical_areas`;
  try {
    const res = await axios.get(url);
    dispatch(receiveStrategicAreas(res.data));
  } catch (err) {
    dispatch(receiveStrategicAreas());
  }
};

export const createProduct = (orgId, data) => async dispatch => {
  dispatch(requestStrategicAreas());
  const url = `${API_BASE}orgs/${orgId}/strategic_critical_areas`;
  try {
    const res = await axios.post(url, data);
    dispatch(fetchProduct(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicAreas());
    return Promise.reject(err);
  }
};

export const updateProduct = (orgId, strategicCriticalAreaId, data) => async dispatch => {
  dispatch(requestStrategicAreas());
  const url = `${API_BASE}orgs/${orgId}/strategic_critical_areas/${strategicCriticalAreaId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchProduct(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicAreas());
    return Promise.reject(err);
  }
};

export const deleteProduct = (orgId, strategicCriticalAreaId) => async dispatch => {
  dispatch(requestStrategicAreas());
  const url = `${API_BASE}orgs/${orgId}/strategic_critical_areas/${strategicCriticalAreaId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchProduct(orgId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveStrategicAreas());
    return Promise.reject(err);
  }
};

export const fetchStrategicCriticalAreaCategories = () => async (dispatch, getState) => {
  if (strategicCriticalAreaCategoriesSelector(getState())) {
    return;
  }
  dispatch(requestStrategicAreaCategories());
  const url = `${API_BASE}strategic_critical_area_categories`;
  try {
    const res = await axios.get(url);
    dispatch(receiveStrategicAreaCategories(res.data));
  } catch (err) {
    dispatch(receiveStrategicAreaCategories());
  }
};
