import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Card from '../../../../components/Card/Card';
import Table from '../../../../components/Table/Table';
import { transactionsSelector, loadingTransactionsSelector } from './TransactionSelectors';
import { brokerageIdSelector } from '../Broker/BrokerSelectors';

const TRANSACTION_TYPE = Object.freeze({ PURCHASE: 'PURCHASE', TRANSFER: 'TRANSFER' });

const Transaction = ({ transactions, loading, brokerageId }) => {
  const getAmount = record => {
    if (record.type === TRANSACTION_TYPE.TRANSFER && brokerageId === record.fromBrokerageId) {
      return -record.amount;
    }
    return record.amount;
  };

  const columns = [
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      filters: [
        { text: TRANSACTION_TYPE.TRANSFER, value: TRANSACTION_TYPE.TRANSFER },
        { text: TRANSACTION_TYPE.PURCHASE, value: TRANSACTION_TYPE.PURCHASE }
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0
    },
    {
      key: 'data',
      title: 'Date',
      render: (text, record) => <span>{record.date ? moment(record.date).format('DD/MM/YYYY') : null}</span>,
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    },
    {
      key: 'amount',
      title: 'Amount',
      render: (text, record) => (
        <span style={{ color: getAmount(record) < 0 ? '#cb2c30' : '#00685e' }}>{getAmount(record)}</span>
      )
    },
    {
      key: 'from',
      title: 'Transferred From',
      render: (text, record) => <span>{record.fromBrokerageName || '-'}</span>,
      align: 'center'
    },
    {
      key: 'to',
      title: 'Transferred To',
      render: (text, record) => <span>{record.toBrokerageName || '-'}</span>,
      align: 'center'
    }
  ];

  return (
    <div className="component-wrapper">
      <Card title="Transaction History">
        <Table
          title="Transactions"
          loading={loading}
          columns={columns}
          dataSource={transactions}
          rowKey="transactionId"
          searchField={['fromBrokerageName', 'toBrokerageName', 'date']}
          style={{ paddingBottom: '60px' }}
        />
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  transactions: transactionsSelector(state),
  loading: loadingTransactionsSelector(state),
  brokerageId: brokerageIdSelector(state)
});

export default connect(mapStateToProps)(Transaction);
