import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import DashboardMainPanel from './components/DashboardMainPanel';
import Plan from './scenes/Plan/Plan';
import Risk from './scenes/Risk/Risk';
import Bia from './scenes/Bia/Bia';
import Department from './scenes/Department/Department';
import PlanDocument from './scenes/PlanDocument/PlanDocument';
import Team from './scenes/Team/Team';
import RecoveryStrategy from './scenes/RecoveryStrategy/RecoveryStrategy';
import InvocationLevel from './scenes/InvocationLevel/InvocationLevel';
import Document from './scenes/Document/Document';
import Glossary from './scenes/Glossary/Glossary';
import PlanPolicy from '../Organisation/scenes/Policy/PlanPolicy';
import { businessInfoSelector } from '../Organisation/OrganisationSelectors';
import { planSelector } from './scenes/Plan/PlanSelectors';
import './Dashboard.scss';

const routes = Object.freeze([
  { route: 'plans', component: <Plan /> },
  { route: 'business-units', component: <Department /> },
  { route: 'teams', component: <Team /> },
  { route: 'policy', component: <PlanPolicy /> },
  { route: 'risk', component: <Risk /> },
  { route: 'business-impact-analysis', component: <Bia /> },
  { route: 'recovery-strategy', component: <RecoveryStrategy /> },
  { route: 'plan-document', component: <PlanDocument /> },
  { route: 'documents', component: <Document /> },
  { route: 'activation-levels', component: <InvocationLevel /> },
  { route: 'glossary', component: <Glossary /> }
]);

const Dashboard = props => {
  const { match, businessInfo, currentPlan } = props;

  return (
    <Layout hasSider>
      {'name' in businessInfo ? (
        currentPlan &&
        ('planId' in currentPlan ? (
          <Switch>
            <Route exact path={match.url} component={DashboardMainPanel} />
            {routes.map(item => {
              const componentToRender = React.cloneElement(item.component, props);
              return (
                <Route
                  key={item.route}
                  path={`${match.url}/${item.route}`}
                  render={() => <ErrorBoundary>{componentToRender}</ErrorBoundary>}
                />
              );
            })}
            <Redirect to="/not-found" />
          </Switch>
        ) : (
          <Plan />
        ))
      ) : (
        <Redirect to="/organization" />
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  businessInfo: businessInfoSelector(state),
  currentPlan: planSelector(state)
});

export default connect(mapStateToProps)(Dashboard);
