import { types } from './SupplierAction';
import { types as organisationTypes } from '../../OrganisationAction';

const initialState = { loadingSuppliers: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case organisationTypes.SWITCH_ORGANISATION:
      return { ...initialState };
    case types.REQUEST_SUPPLIERS:
      return { ...state, loadingSuppliers: true };
    case types.RECEIVE_SUPPLIERS:
      return { ...state, loadingSuppliers: false, supplierList: action.supplierList || state.supplierList };
    default:
      return state;
  }
};
