import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Spin, message } from 'antd';
import moment from 'moment';
import Card from '../../../../../components/Card/Card';
import InfoBox from '../../../../../components/InfoBox/InfoBox';
import OrganisationLogo from '../../../components/OrganisationLogo';
import PolicyMember from './PolicyMember';
import { fetchPolicyResponsiblePresets, createPolicy } from '../PolicyAction';
import { orgIdSelector, businessNameSelector, businessLogoSelector } from '../../../OrganisationSelectors';
import { policyResponsiblePresetsSelector } from '../PolicySelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../constants';
import explanations from '../data/explanations.json';

const CMT_COMPOSITION_FIRST =
  'The Crisis Management Team is the steering and oversight group for the Business Continuity program within';
const CMT_COMPOSITION_SECOND = 'and comprises of';

const PolicyCreation = ({
  orgId,
  businessName,
  businessLogo,
  policyRequest,
  loading,
  policyResponsiblePresets = [],
  fetchPolicyResponsiblePresets,
  createPolicy,
  back
}) => {
  const [memberContactIds, setMemberContactIds] = useState([]);
  const [memberNames, setMemberNames] = useState([]);
  const [activeResponsibleKey, setActiveResponsibleKey] = useState(null);
  const [activeResponsible, setActiveResponsible] = useState(null);

  useEffect(() => {
    fetchPolicyResponsiblePresets();
  }, [fetchPolicyResponsiblePresets]);

  const getPolicyResponsible = value => `${CMT_COMPOSITION_FIRST} ${businessName} ${CMT_COMPOSITION_SECOND} ${value}.`;

  const onResponsibleClick = item => {
    setActiveResponsibleKey(item.cmtCompositionPresetId);
    setActiveResponsible(getPolicyResponsible(item.value));
  };

  const isResponsibleActive = key => key === activeResponsibleKey;

  const addMember = addedMemberContacts => {
    const memberContactIds = addedMemberContacts.map(contact => contact.contactId);
    const memberNames = addedMemberContacts.map(contact => `${contact.firstName} ${contact.lastName}`);
    setMemberContactIds(memberContactIds);
    setMemberNames(memberNames);
  };

  const onSave = async () => {
    const data = { contacts: memberContactIds, responsible: activeResponsible };
    try {
      await createPolicy(orgId, data);
      message.success(MSG_SAVE_SUCCESS);
      back();
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  return (
    <>
      <InfoBox
        color="#f1c400"
        message={explanations.infoMessage}
        more
        video={
          <iframe
            title="policy-video"
            src="https://www.youtube.com/embed/29l-vK3bdbM?rel=0&amp;showinfo=0"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            className="responsive-video"
          />
        }
      />
      <Row gutter={20} className="component-wrapper">
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
          <PolicyMember title="Business Continuity Governance Committee" addMember={addMember} />
          <Card
            title="Business Continuity Responsibility"
            description="Please select the statement that best describes the Crisis Management Team you assembled in the previous step."
          >
            <div className="policy-responsibles">
              {loading ? (
                <Spin tip="Loading..." className="loading" />
              ) : (
                policyResponsiblePresets.map(item => (
                  /* eslint-disable-next-line */
                  <div
                    key={item.cmtCompositionPresetId}
                    className={
                      isResponsibleActive(item.cmtCompositionPresetId)
                        ? 'policy-responsibles-item active'
                        : 'policy-responsibles-item'
                    }
                    onClick={() => onResponsibleClick(item)}
                  >
                    {getPolicyResponsible(item.value)}
                  </div>
                ))
              )}
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <Card title="Business Continuity Policy">
            <div className="policy-card">
              <OrganisationLogo logoUrl={businessLogo} editable={false} width={120} />
              <div className="policy-tile-title margin">
                ORGANIZATION
                <br />
                NAME
              </div>
              <div className="policy-tile-text">{businessName}</div>
              <div className="policy-tile-subtitle margin">
                *The business name that
                <br />
                willl appear in this document
              </div>
            </div>
            <div className="policy-card">
              <i className="policy-icon fa fa-calendar" />
              <div className="policy-tile-title margin">DATED</div>
              <div className="policy-tile-text">{moment().format('DD/MM/YYYY')}</div>
              <div className="policy-tile-subtitle margin">*Today&apos;s date</div>
            </div>
            <div className="policy-card">
              <i className="policy-icon fa fa-thumbs-o-up" />
              <div className="policy-tile-title margin">POLICY STATEMENT</div>
              <div className="policy-tile-paragraph">
                <p>
                  <span>{businessName}</span> acknowledges its&apos; responsibility to plan for resilience of the
                  business, to identify and measure its&apos; risks, and as far as possible, to mitigate the period and
                  result of disruption and/or loss at the time of an incident.
                </p>
                <p>
                  <span>{businessName}</span> is committed to ensuring it provides a sustainable and safe service to its
                  stakeholders and the environment, and therefore commits to implementing, maintaining and improving a
                  Business Continuity Program within its&apos; essential business services and critical risk areas.
                </p>
                <p>
                  The <span>{businessName}</span> Board/Crisis Management Ect is committed to ongoing support and
                  monitoring of this program, and is accountable for its strategic leadership and governance.
                </p>
              </div>
            </div>
            <div className="policy-card">
              <i className="policy-icon fa fa-pencil-square-o" />
              <div className="policy-tile-title margin">ACCEPTED AND AGREED BY</div>
              {memberNames.length > 0 && (
                <div className="policy-accepted-by">
                  <div className="policy-accepted-names">{memberNames.join(', ')}</div>
                </div>
              )}
            </div>
            <div className="btn-container space-between">
              <Button onClick={back}>Cancel</Button>
              <Button
                type="primary"
                icon="save"
                onClick={onSave}
                disabled={activeResponsible === null || memberNames.length === 0}
                loading={policyRequest}
              >
                {policyRequest ? 'Saving' : 'Save'}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.policyReducer.loadingPolicyResponsiblePresets,
  policyResponsiblePresets: policyResponsiblePresetsSelector(state),
  orgId: orgIdSelector(state),
  businessName: businessNameSelector(state),
  businessLogo: businessLogoSelector(state)
});

const mapDispatchToProps = { fetchPolicyResponsiblePresets, createPolicy };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyCreation);
