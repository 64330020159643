import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button } from 'antd';
import defaultOrganisationLogo from '../images/company_logo.png';

export default class OrganisationLogo extends Component {
  static propTypes = {
    logoUrl: PropTypes.string,
    orgId: PropTypes.number,
    upload: PropTypes.func,
    editable: PropTypes.bool,
    width: PropTypes.number,
    bgColor: PropTypes.string
  };

  static defaultProps = {
    logoUrl: null,
    orgId: null,
    upload: null,
    editable: true,
    width: 200,
    bgColor: '#ddd'
  };

  onUploadLogo = async ({ file, onSuccess, onError }) => {
    const { orgId, upload } = this.props;
    if (!upload) {
      return;
    }
    const formData = new FormData();
    formData.append('logo', file);
    try {
      await upload(orgId, formData);
      onSuccess(null, file);
    } catch (err) {
      onError();
      console.error(err);
    }
  };

  render() {
    const { logoUrl, editable, width, bgColor } = this.props;
    const containerStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    };
    const logoStyle = {
      position: 'relative',
      width: `${width}px`,
      height: `${width}px`,
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: bgColor,
      marginBottom: editable ? '20px' : 0
    };
    const imgStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%'
    };

    return (
      <div style={containerStyle}>
        <div style={logoStyle}>
          {logoUrl ? (
            <img src={logoUrl} alt="logo" style={imgStyle} />
          ) : (
            <img src={defaultOrganisationLogo} alt="logo" style={imgStyle} />
          )}
        </div>
        {editable && (
          <Upload name="logo" showUploadList={false} customRequest={this.onUploadLogo}>
            <Button type="link" icon="camera">
              {logoUrl ? ' Change' : ' Add Logo'}
            </Button>
          </Upload>
        )}
      </div>
    );
  }
}
