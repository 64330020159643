import React from 'react';
import { connect } from 'react-redux';
import Page from '../Page/Page';
import Table from '../Table/Table';
import { strategicThirdPartyListSelector } from '../../../../../../../Organisation/scenes/StrategicThirdParty/StrategicThirdPartySelectors';

const getTableData = (rawData = []) => {
  const header = [
    { width: 30, name: 'CATEGORY' },
    { width: 25, name: 'NAME' },
    { width: 25, name: 'EMAIL' },
    { width: 20, name: 'PHONE' }
  ];
  const data = rawData.map(item => ({
    id: item.name,
    category: item.strategicThirdPartyCategory ? item.strategicThirdPartyCategory.name : null,
    name: item.name,
    email: item.email,
    phone: item.phone
  }));
  return { header, data };
};

const StrategicThirdParty = ({ strategicThirdPartyList = {} }) => {
  const { header, data } = getTableData(strategicThirdPartyList['Strategic Third Parties']);
  return (
    <Page dynamic>
      <div className="content">
        <h2>C. Strategic Third Parties</h2>
        <Table header={header}>
          {data.map(item => (
            <tr key={item.id}>
              <td className="table-content">{item.category}</td>
              <td className="table-content">{item.name}</td>
              <td className="table-content">{item.email}</td>
              <td className="table-content">{item.phone}</td>
            </tr>
          ))}
        </Table>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  strategicThirdPartyList: strategicThirdPartyListSelector(state)
});

export default connect(mapStateToProps)(StrategicThirdParty);
