import React, { Component } from 'react';
import Slider, { Rail, Handles, Tracks } from 'react-compound-slider';
import './Slider.scss';

/* eslint no-unused-vars: off */
const Handle = ({ handle: { id, value, percent }, color, getHandleProps }) => (
  <div
    className="slider-handle"
    style={{
      left: `${percent}%`,
      backgroundColor: color
    }}
    {...getHandleProps(id)}
  />
);

const Track = ({ color, source, target, getTrackProps }) => (
  <div
    className="slider-track"
    style={{
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
      backgroundColor: color
    }}
    {...getTrackProps()}
  />
);

export default class SliderComponent extends Component {
  render() {
    const { min, max, value, color, reversed, style, onChange } = this.props;
    const trackStyle = reversed ? { left: false, right: true } : { left: true, right: false };
    return (
      <Slider
        domain={[min, max]}
        step={1}
        values={[value]}
        reversed={reversed}
        onChange={value => onChange(value[0])}
        onUpdate={value => onChange(value[0])}
        className="slider"
        rootStyle={style}
      >
        <Rail>{({ getRailProps }) => <div className="slider-rail" {...getRailProps()} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map(handle => (
                <Handle key={handle.id} color={color} handle={handle} getHandleProps={getHandleProps} />
              ))}
            </div>
          )}
        </Handles>
        <Tracks {...trackStyle}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <Track key={id} color={color} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    );
  }
}
