import React, { Component } from 'react';
import { Input, Button } from 'antd';
import './Counter.scss';

export default class Counter extends Component {
  increment = () => {
    const { count } = this.props;
    this.triggerChange(count + 1);
  };

  decrement = () => {
    const { count } = this.props;
    this.triggerChange(count > 0 ? count - 1 : 0);
  };

  onChange = e => {
    const { value } = e.target;
    const reg = /^[1-9]\d*$/g;
    if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
      this.triggerChange(value);
    }
  };

  triggerChange = value => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const { count } = this.props;
    return (
      <div className="counter">
        <Button type="link" icon="minus" onClick={this.decrement} disabled={count === 0} />
        <Input {...this.props} value={count} onChange={this.onChange} />
        <Button type="link" icon="plus" onClick={this.increment} />
      </div>
    );
  }
}
