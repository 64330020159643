import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Provider, connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import store from '../../../../../store';
import ProcessBar from '../../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../../components/Card/Card';
import Alert from '../../../../../components/Alert/Alert';
import Download from '../../../../../components/Download/Download';
import PolicyPreview from '../scenes/PolicyPreview/PolicyPreview';
import Certificate from '../scenes/Certificate/Certificate';
import { download } from '../../../../../components/App/AppAction';
import { businessNameSelector } from '../../../OrganisationSelectors';
import { latestPolicySelector } from '../PolicySelectors';

const PolicyDocument = ({ businessName, latestPolicy, create, download }) => {
  const onDownload = () => {
    const content = renderToStaticMarkup(
      <Provider store={store}>
        <PolicyPreview />
      </Provider>
    );
    return download(content);
  };

  const onViewCertificate = () => {
    const content = renderToStaticMarkup(
      <Provider store={store}>
        <Certificate />
      </Provider>
    );
    return download(content);
  };

  return (
    <>
      <ProcessBar
        title="Business Continuity Policy"
        prev="/organization/strategic-areas"
        next="/organization/plans"
        nextText="Go to Plans"
      />
      <div className="policy-document-container">
        <Card title="Business Continuity Policy">
          {latestPolicy ? (
            <Row>
              <Col xs={24} sm={24} md={24} lg={19} xl={20} xxl={20} className="policy-document-preview">
                <PolicyPreview />
              </Col>
              <Col xs={24} sm={24} md={24} lg={5} xl={4} xxl={4} className="policy-document-functions">
                <Button type="primary" icon="plus" className="policy-btn" onClick={create}>
                  Create New
                </Button>
                <Download fileName={`Policy_Program_${businessName.replace(/\s+/g, '_')}.pdf`} download={onDownload}>
                  {(downloadFile, downloading) => (
                    <Button
                      type="primary"
                      icon="download"
                      className="policy-btn"
                      loading={downloading}
                      onClick={downloadFile}
                    >
                      Download
                    </Button>
                  )}
                </Download>
                <Download
                  fileName={`Certificate_${businessName.replace(/\s+/g, '_')}.pdf`}
                  download={onViewCertificate}
                >
                  {(downloadFile, downloading) => (
                    <Button
                      type="primary"
                      icon="safety"
                      className="policy-btn"
                      loading={downloading}
                      onClick={downloadFile}
                    >
                      Certificate
                    </Button>
                  )}
                </Download>
              </Col>
            </Row>
          ) : (
            <Alert
              message="No policy document, please create new."
              button={
                <Button type="primary" icon="plus" className="policy-btn" onClick={create}>
                  Create New
                </Button>
              }
            />
          )}
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  businessName: businessNameSelector(state),
  latestPolicy: latestPolicySelector(state)
});

const mapDispatchToProps = { download };

export default connect(mapStateToProps, mapDispatchToProps)(PolicyDocument);
