import React, { Component } from 'react';
import { Form, Input, Checkbox, Button, Alert } from 'antd';
import Modal from '../../Modal/Modal';
import TermsConditions from './TermsConditions';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const CHECKBOX_OPTIONS = [
  'I have read and agree to the terms and conditions set out in THE PROCESSING OF PERSONAL DATA: AGREEMENT (GDPR)',
  'I have read and agree to the terms and conditions set out in SCHEDULE 1 - DETAILS OF THE PROCESSING OF YOUR PERSONAL DATA',
  'I have read and agree to the terms and conditions set out in SCHEDULE 2 - STANDARD CONTRACTUAL CLAUSES including APPENDIX 1 and APPENDIX 2',
  'I have read and agree to the terms and conditions set out in Terms of Use (Terms and Conditions)'
];
const SPECIAL_CHARACTER = '~`!@#$%^&*()\\-_=+\\[\\]{};:\'",.<>/?|';

class RegistrationForm extends Component {
  state = { confirmDirty: false, checked: 0 };

  onSubmit = e => {
    e.preventDefault();
    const { form, register } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        register(values);
        form.resetFields();
      }
    });
  };

  onConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent.');
    } else {
      callback();
    }
  };

  checkConfirm = (rule, value, callback) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;
    if (value && confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  onCheckboxChange = value => {
    this.setState({ checked: value.length });
  };

  render() {
    const { form, registerRequest, registerHasError, errorMessage, cancelModal } = this.props;
    const { getFieldDecorator } = form;
    const { checked } = this.state;
    return (
      <>
        {registerHasError && errorMessage !== '' && <Alert message={errorMessage} type="error" />}
        {!registerHasError && errorMessage !== '' ? (
          <>
            <Alert
              message="Thank you for registering."
              description={errorMessage}
              type="success"
              showIcon
              className="login-alert"
            />
            <Button type="primary" onClick={cancelModal} className="login-alert-btn">
              Done
            </Button>
          </>
        ) : (
          <Form onSubmit={this.onSubmit} className="login-form">
            <FormItem hasFeedback>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  { type: 'email', message: 'Please input an valid email.' },
                  { required: true, message: 'Please input your email.' }
                ]
              })(<Input placeholder="Email" spellCheck={false} />)}
            </FormItem>
            <FormItem hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your password.' },
                  {
                    pattern: RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${SPECIAL_CHARACTER}])(?=.{6,})`),
                    message:
                      'Password must be at least 6 characters and contain at least one lowercase letter, one uppercase letter, one digit and one special character.'
                  },
                  { validator: this.checkConfirm }
                ]
              })(<Input type="password" placeholder="Password" />)}
            </FormItem>
            <FormItem hasFeedback>
              {getFieldDecorator('confirmPassword', {
                rules: [{ required: true, message: 'Please confirm your password.' }, { validator: this.checkPassword }]
              })(<Input type="password" placeholder="Confirm Password" onBlur={this.onConfirmBlur} />)}
            </FormItem>
            <FormItem hasFeedback>
              {getFieldDecorator('firstName', {
                rules: [{ required: true, message: 'Please input your first name.', whitespace: true }]
              })(<Input placeholder="First Name" spellCheck={false} />)}
            </FormItem>
            <FormItem hasFeedback>
              {getFieldDecorator('lastName', {
                rules: [{ required: true, message: 'Please input your last name.', whitespace: true }]
              })(<Input placeholder="Last Name" spellCheck={false} />)}
            </FormItem>
            <FormItem>
              <Modal
                title="THE PROCESSING OF PERSONAL DATA: AGREEMENT"
                width="auto"
                wrapClassName="terms-conditions-modal"
                toggle={show => (
                  <Button type="link" onClick={show}>
                    Click here to read the AGREEMENT (GDPR)
                  </Button>
                )}
              >
                {() => <TermsConditions />}
              </Modal>
              <CheckboxGroup options={CHECKBOX_OPTIONS} onChange={this.onCheckboxChange} />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                loading={registerRequest}
                className="login-form-btn"
                disabled={checked < CHECKBOX_OPTIONS.length}
              >
                {registerRequest ? 'Registering' : 'Register'}
              </Button>
            </FormItem>
          </Form>
        )}
      </>
    );
  }
}

export default Form.create()(RegistrationForm);
