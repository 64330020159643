import React, { useEffect } from 'react';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Spin, message } from 'antd';
import moment from 'moment';
import * as HttpStatus from 'http-status-codes';
import Modal from '../../../../../components/Modal/Modal';
import Table from '../../../../../components/Table/Table';
import PlanEdit, { PLAN_ACTIONS } from './PlanEdit';
import PlanDeleteConfirm from './PlanDeleteConfirm';
import {
  createPlan,
  clonePlan,
  updatePlan,
  deletePlan,
  renewPlan,
  currentPlan,
  fetchPlanSubscriptions
} from '../PlanAction';
import { fetchBranch } from '../../../../Organisation/scenes/Branch/BranchAction';
import { fetchContact } from '../../../../Organisation/scenes/Contact/ContactAction';
import { selectOrganisation } from '../../../../Organisation/OrganisationAction';
import {
  loadingPlansSelector,
  loadingPlanSubscriptionsSelector,
  currenciesSelector,
  getExpiryDate,
  planSubscriptionsSelector
} from '../PlanSelectors';
import { MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../constants';
import explanations from '../data/explanations.json';

const PlanList = ({
  history,
  orgId,
  planList = [],
  loadingPlans,
  loadingPlanSubscriptions,
  fetchBranch,
  fetchContact,
  setCurrentPlan,
  createPlan,
  updatePlan,
  deletePlan,
  // clonePlan,
  renewPlan,
  selectOrganisation,
  currencies,
  fetchPlanSubscriptions,
  planSubscriptions
}) => {
  useEffect(() => {
    fetchBranch(orgId);
    fetchContact(orgId);
  }, [orgId, fetchBranch, fetchContact]);

  useEffect(() => {
    planList.forEach(plan => {
      if (
        !planSubscriptions ||
        (planSubscriptions && !Object.prototype.hasOwnProperty.call(planSubscriptions, plan.planId))
      ) {
        fetchPlanSubscriptions(plan.planId);
      }
    });
  }, []);

  const goToPlan = async plan => {
    await selectOrganisation(plan.organisationId);
    setCurrentPlan(plan);
    history.push('/dashboard');
  };

  const onCreate = async data => {
    try {
      await createPlan(data);
      message.success('The plan has been successfully created');
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(err.data || MSG_ERROR);
      }
    }
  };

  const onUpdate = async (planId, data) => {
    try {
      await updatePlan(planId, data);
      message.success('The plan information has been successfully updated');
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  const onDelete = async planId => {
    try {
      await deletePlan(planId);
      message.success('The plan has been successfully deleted');
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  // const onClone = async data => {
  //   try {
  //     await clonePlan(data);
  //   } catch (err) {
  //     switch (err.status) {
  //       case HttpStatus.PAYMENT_REQUIRED:
  //         message.error(MSG_PAYMENT_REQUIRED);
  //         break;
  //       default:
  //         message.error(MSG_ERROR);
  //     }
  //   }
  // };

  const onRenew = async (planId, data) => {
    try {
      await renewPlan(planId, data);
      message.success('The plan has been successfully renewed');
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  const isPlanExpired = plan => {
    return moment().isAfter(moment(getExpiryDate(plan), 'DD/MM/YYYY'));
  };

  const columns = [
    { key: 'name', title: 'Name', dataIndex: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
    { key: 'branch', title: 'Branch', dataIndex: 'branch' },
    {
      key: 'expiryDate',
      title: 'Expiry Date',
      render: (text, record) => {
        return getExpiryDate({ ...record, subscriptions: planSubscriptions ? planSubscriptions[record.planId] : [] });
      }
    },
    {
      key: 'currency',
      title: 'Currency',
      render: (text, record) => {
        const currency = _.find(currencies, { symbol: record.currency });
        return <span>{currency?.name}</span>;
      }
    },
    {
      key: 'action',
      render: (text, record) => (
        <div className="btn-container inline">
          {isPlanExpired({ ...record, subscriptions: planSubscriptions ? planSubscriptions[record.planId] : [] }) ? (
            <Modal toggle={show => <Button onClick={show}>Renew</Button>}>
              {hide => <PlanEdit plan={record} action={PLAN_ACTIONS.RENEW} renewPlan={onRenew} close={hide} />}
            </Modal>
          ) : (
            <Button type="primary" onClick={() => goToPlan(record)}>
              Go
            </Button>
          )}
          {/* <Modal
            explanation={{ title: explanations.clone.title, text: explanations.clone.text }}
            toggle={show => <Button onClick={show}>Clone</Button>}
          >
            {(hide, setExplanation) => (
              <PlanEdit
                plan={record}
                action={PLAN_ACTIONS.CLONE}
                clonePlan={onClone}
                close={hide}
                onExplanationChange={setExplanation}
              />
            )}
          </Modal> */}
          <Modal
            explanation={{ title: explanations.rename.title, text: explanations.rename.text }}
            toggle={show => <Button onClick={show}>Edit</Button>}
          >
            {(hide, setExplanation) => (
              <PlanEdit
                plan={record}
                action={PLAN_ACTIONS.EDIT}
                updatePlan={onUpdate}
                close={hide}
                onExplanationChange={setExplanation}
              />
            )}
          </Modal>
          <Modal
            title="Are you absolutely sure?"
            toggle={show => (
              <Button type="danger" onClick={show}>
                Delete
              </Button>
            )}
          >
            {hide => <PlanDeleteConfirm plan={record} deletePlan={onDelete} close={hide} />}
          </Modal>
        </div>
      )
    }
  ];

  return (
    <Spin spinning={loadingPlans || loadingPlanSubscriptions}>
      <Table title="Plans" columns={columns} dataSource={planList} rowKey="planId" searchFields={['name', 'branch']} />
      <div className="btn-container">
        <Button type="primary" className="btn-important">
          <Link to="/purchase">Buy Subscriptions</Link>
        </Button>
        <Modal
          explanation={{ title: explanations.create.title, text: explanations.create.text }}
          toggle={show => (
            <Button type="primary" icon="plus" onClick={show}>
              Add new
            </Button>
          )}
        >
          {(hide, setExplanation) => (
            <PlanEdit
              action={PLAN_ACTIONS.CREATE}
              createPlan={onCreate}
              close={hide}
              onExplanationChange={setExplanation}
            />
          )}
        </Modal>
      </div>
    </Spin>
  );
};

const mapStateToProps = state => ({
  loadingPlans: loadingPlansSelector(state),
  currencies: currenciesSelector(state),
  planSubscriptions: planSubscriptionsSelector(state),
  loadingPlanSubscriptions: loadingPlanSubscriptionsSelector(state)
});

const mapDispatchToProps = {
  createPlan,
  clonePlan,
  updatePlan,
  deletePlan,
  renewPlan,
  setCurrentPlan: currentPlan,
  fetchBranch,
  fetchContact,
  selectOrganisation,
  fetchPlanSubscriptions
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PlanList);
