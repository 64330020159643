import React from 'react';
import { Row, Col, Avatar, Button, Upload, message } from 'antd';
import { connect } from 'react-redux';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';
import UserEdit from './components/UserEdit';
import { uploadUserAvatar } from './UserActions';
import { userInfoSelector } from './UserSelectors';
import { MSG_ERROR } from '../../../../constants';
import './User.scss';

const User = ({ userInfo = {}, logoWidth, uploadUserAvatar }) => {
  const { userName, firstName, lastName, email, phoneNumber, otpType, avatarUrl } = userInfo;

  const onUploadAvatar = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('logo', file);
    try {
      await uploadUserAvatar(formData);
      onSuccess(null, file);
      message.success('Avatar has been successfully uploaded');
    } catch (err) {
      onError();
      message.error(MSG_ERROR);
    }
  };

  return (
    <div className="component-wrapper">
      <Card>
        <Row gutter={20} type="flex" justify="space-around" className="user-inner">
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <div className="user-avatar-container">
              <Avatar icon="user" size={logoWidth} src={avatarUrl} />
              <Upload
                className="user-avatar-change"
                name="avatar"
                showUploadList={false}
                customRequest={onUploadAvatar}
              >
                <Button type="link" icon="camera">
                  {avatarUrl ? ' Change' : ' Add Avatar'}
                </Button>
              </Upload>
            </div>
          </Col>
          <Col xs={24} sm={16} md={15} lg={15} xl={15} xxl={15}>
            <div className="user-info">
              <div className="user-info-item">
                <strong>Username</strong>
                <div className="single-line">{userName}</div>
              </div>
              <div className="user-info-item">
                <strong>First Name</strong>
                <div className="single-line">{firstName}</div>
              </div>
              <div className="user-info-item">
                <strong>Last Name</strong>
                <div className="single-line">{lastName}</div>
              </div>
              <div className="user-info-item">
                <strong>Email</strong>
                <div className="single-line">{email}</div>
              </div>
              {phoneNumber && (
                <div className="user-info-item">
                  <strong>Phone Number</strong>
                  <div className="single-line">{phoneNumber}</div>
                </div>
              )}
              <div className="user-info-item">
                <strong>2FA Type</strong>
                <div className="single-line">{otpType === 1 ? 'SMS' : 'Email'}</div>
              </div>
            </div>
            <div className="btn-container inline">
              <Modal
                className="user-edit-modal"
                toggle={show => (
                  <Button type="primary" icon="edit" onClick={show}>
                    Edit
                  </Button>
                )}
              >
                {hide => <UserEdit close={hide} />}
              </Modal>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: userInfoSelector(state)
});

const mapDispatchToProps = { uploadUserAvatar };

export default connect(mapStateToProps, mapDispatchToProps)(User);
