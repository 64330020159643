import React, { Component } from 'react';
import { Input, Form, Button } from 'antd';

const FormItem = Form.Item;
const style = {
  display: 'flex',
  justifyContent: 'flex-end'
};

class DocumentAddLink extends Component {
  onSubmit = e => {
    e.preventDefault();
    const { form, folder, onAddUrl, close } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        onAddUrl(folder, values.url, values.friendlyName);
        close();
      }
    });
  };

  render() {
    const { form, loadingDocuments } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.onSubmit}>
        <FormItem label="URL">
          {getFieldDecorator('url', {
            rules: [
              { required: true, message: 'Please input an URL' },
              { type: 'url', message: 'Please input a valid URL.' }
            ]
          })(<Input />)}
        </FormItem>
        <FormItem label="Friendly Name">
          {getFieldDecorator('friendlyName', {
            rules: [{ required: true, message: 'Please input an friendly name.' }]
          })(<Input />)}
        </FormItem>
        <FormItem style={style}>
          <Button type="primary" loading={loadingDocuments} onClick={this.onSubmit}>
            {loadingDocuments ? 'Adding' : 'Add'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(DocumentAddLink);
