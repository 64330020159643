import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_BASE;

export const types = {
  REQUEST_FOLDERS: 'document/REQUEST_FOLDERS',
  RECEIVE_FOLDERS: 'document/RECEIVE_FOLDERS',
  REQUEST_DOCUMENTS: 'document/REQUEST_DOCUMENTS',
  RECEIVE_DOCUMENTS: 'document/RECEIVE_DOCUMENTS'
};

const requestFolders = () => ({ type: types.REQUEST_FOLDERS });
const receiveFolders = data => ({ type: types.RECEIVE_FOLDERS, folders: data });

const requestDocuments = () => ({ type: types.REQUEST_DOCUMENTS });
const receiveDocuments = data => ({ type: types.RECEIVE_DOCUMENTS, documents: data });

export const fetchFolders = planId => async dispatch => {
  dispatch(requestFolders());
  const url = `${API_BASE}plans/${planId}/folders`;
  try {
    const res = await axios.get(url);
    dispatch(receiveFolders(res.data));
  } catch (err) {
    dispatch(receiveFolders());
  }
};

export const fetchDocuments = planId => async dispatch => {
  dispatch(requestDocuments());
  const url = `${API_BASE}plans/${planId}/documents`;
  try {
    const res = await axios.get(url);
    dispatch(receiveDocuments(res.data));
  } catch (err) {
    dispatch(receiveDocuments());
  }
};

export const uploadDocument = (planId, data) => async dispatch => {
  dispatch(requestDocuments());
  const url = `${API_BASE}plans/${planId}/documents`;
  try {
    const res = await axios.put(url, data);
    dispatch(fetchDocuments(planId));
    dispatch(fetchFolders(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveDocuments());
    return Promise.reject(err);
  }
};

export const updateDocument = (planId, documentId, data) => async dispatch => {
  dispatch(requestDocuments());
  const url = `${API_BASE}plans/${planId}/documents/${documentId}`;
  try {
    const res = await axios.patch(url, data);
    dispatch(fetchDocuments(planId));
    dispatch(fetchFolders(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveDocuments());
    return Promise.reject(err);
  }
};

export const deleteDocument = (planId, documentId) => async dispatch => {
  dispatch(requestDocuments());
  const url = `${API_BASE}plans/${planId}/documents/${documentId}`;
  try {
    const res = await axios.delete(url);
    dispatch(fetchDocuments(planId));
    dispatch(fetchFolders(planId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveDocuments());
    return Promise.reject(err);
  }
};
