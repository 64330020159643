import axios from 'axios';
import * as HttpStatus from 'http-status-codes';
// eslint-disable-next-line import/no-cycle
import { initialize } from '../App/AppAction';

export const LOGIN_REQUEST = 'login/LOGIN_REQUEST';
export const LOGIN_STATUS = 'login/LOGIN_STATUS';
export const REGISTER_REQUEST = 'login/REGISTER_REQUEST';
export const REGISTER_STATUS = 'login/REGISTER_STATUS';
export const SEND_CONFIRMATION_REQUEST = 'login/SEND_CONFIRMATION_REQUEST';
export const ERROR_MESSAGE = 'login/ERROR_MESSAGE';
export const USER_LOGOUT = 'login/USER_LOGOUT';
export const RESET_PASSWORD_REQUEST = 'login/RESET_PASSWORD_REQUEST';

const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';
const MSG_LOGIN_FAIL = 'The email or password is incorrect.';
const MSG_MFA_LOGIN_FAIL = 'The code is invalid. Please resend the code and try again.';
const MSG_LOGIN_RESTRICT =
  'Sorry, we could not authenticate you. Please activate your account. If you did not receive the confirmation email, please click the Resend button.';
const MSG_REGISTER_SUCCESS = 'An email with a link to activate your account has been sent to';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_AUTH_TOKEN = 'auth/token';
const API_AUTH_LMI = 'auth/token/usermanagement';
const API_USERS = 'users';
const API_RESEND = 'confirmation/email/resend';
const API_SEND_VERIFICATION_EMAIL = 'reset-password/email';
const API_UPDATE_PASSWORD = 'user/password';

export const loginRequest = loginRequest => ({ type: LOGIN_REQUEST, loginRequest });

export const loginStatus = loginStatus => ({ type: LOGIN_STATUS, loginStatus });

export const registerRequest = registerRequest => ({ type: REGISTER_REQUEST, registerRequest });

export const registerStatus = registerStatus => ({ type: REGISTER_STATUS, registerStatus });

export const sendConfirmationRequest = sendConfirmationRequest => ({
  type: SEND_CONFIRMATION_REQUEST,
  sendConfirmationRequest
});

export const setErrorMessage = errorMessage => ({ type: ERROR_MESSAGE, errorMessage });

export const resetPasswordRequest = resetPasswordRequest => ({ type: RESET_PASSWORD_REQUEST, resetPasswordRequest });

/**
 * Logs an user in
 * @param {object} data Contains email, password and rememberMe
 */
export const login = (data, accountType) => async dispatch => {
  // show the loading indicator, disable the form input, and empty the last error message
  dispatch(loginRequest(true));
  dispatch(setErrorMessage(''));
  const url = accountType === 'LMI' ? `${API_BASE}${API_AUTH_LMI}` : `${API_BASE}${API_AUTH_TOKEN}`;
  try {
    const res = await axios.post(url, data);
    // if successfully log in, get user token and expiry from response
    const token = res.data.access_token;
    const { expiry } = res.data;

    localStorage.setItem('token', token);
    localStorage.setItem('expiry', expiry);
    dispatch(initialize());
    // set the login status be 200 (success)
    dispatch(loginStatus(res.status));
    return Promise.resolve(res);
  } catch (err) {
    const status = err ? err.status : null;
    // set the login status, either be 401 (fail to authenticate), 403 (restrict),
    // or undefined (network issues or system crash, so no response returned)
    dispatch(loginStatus(status));
    // set the error message according to the status returned from the API
    switch (status) {
      case HttpStatus.UNAUTHORIZED:
        dispatch(setErrorMessage(MSG_LOGIN_FAIL));
        break;
      case HttpStatus.FORBIDDEN:
        dispatch(setErrorMessage(MSG_LOGIN_RESTRICT));
        break;
      default:
        dispatch(setErrorMessage(MSG_ERROR));
        break;
    }
    return Promise.reject(err);
  } finally {
    dispatch(loginRequest(false));
  }
};

/**
 * Verify OTP sent to user
 * @param {object} data Contains OTP Code
 */
export const verifyOtp = data => async dispatch => {
  dispatch(loginRequest(true));
  dispatch(setErrorMessage(''));
  const url = `${API_BASE}auth/token_new`;
  try {
    const res = await axios.post(url, data);
    const token = res.data.access_token;
    const { expiry } = res.data;
    localStorage.setItem('token', token);
    localStorage.setItem('expiry', expiry);
    dispatch(initialize());
    // set the login status be 200 (success)
    dispatch(loginStatus(res.status));
    // window.location.reload();
    return Promise.resolve(res);
  } catch (err) {
    const status = err ? err.status : null;
    dispatch(loginStatus(status));
    switch (status) {
      case HttpStatus.UNAUTHORIZED:
        dispatch(setErrorMessage(err?.data || MSG_MFA_LOGIN_FAIL));
        break;
      case HttpStatus.FORBIDDEN:
        dispatch(setErrorMessage(MSG_LOGIN_RESTRICT));
        break;
      default:
        if (typeof err?.data === 'string') {
          dispatch(setErrorMessage(err.data));
        } else {
          dispatch(setErrorMessage(MSG_ERROR));
        }
        break;
    }
    return Promise.reject(err);
  } finally {
    dispatch(loginRequest(false));
  }
};

/**
 * Send OTP to user
 * @param {object} data Contains email, password and rememberMe
 */
export const sendOtp = data => async dispatch => {
  dispatch(loginRequest(true));
  dispatch(setErrorMessage(''));
  const url = `${API_BASE}/auth/credential`;
  try {
    const res = await axios.post(url, data);
    dispatch(loginStatus(res.status));
    return res.data;
  } catch (err) {
    const status = err ? err.status : null;
    dispatch(loginStatus(status));
    switch (status) {
      case HttpStatus.UNAUTHORIZED:
        dispatch(setErrorMessage(MSG_LOGIN_FAIL));
        break;
      case HttpStatus.FORBIDDEN:
        dispatch(setErrorMessage(MSG_LOGIN_RESTRICT));
        break;
      default:
        if (typeof err?.data === 'string') {
          dispatch(setErrorMessage(err.data));
        } else {
          dispatch(setErrorMessage(MSG_ERROR));
        }
        break;
    }
    return Promise.reject(err);
  } finally {
    dispatch(loginRequest(false));
  }
};

/**
 * Logs the current user out
 */
export const logout = () => dispatch => {
  dispatch(setErrorMessage(''));
  dispatch({ type: USER_LOGOUT });
  localStorage.removeItem('token');
  localStorage.removeItem('expiry');
};

/**
 * Register a user
 * @param {object} data Contains email, password, confirm password, first name, and last name
 */
export const register = data => async dispatch => {
  // show the loading indicator, and empty the last error message
  dispatch(registerRequest(true));
  dispatch(setErrorMessage(''));
  const url = `${API_BASE}${API_USERS}`;
  try {
    const res = await axios.post(url, data);
    // set the register status be 200 (success)
    dispatch(registerStatus(res.status));
    // prompt user to activate their account
    dispatch(setErrorMessage(`${MSG_REGISTER_SUCCESS} ${data.email}.`));
  } catch (err) {
    const status = err ? err.status : null;
    // set the register status, either be 400 (email is already registered),
    // or undefined (network issues or system crash, so no response returned)
    dispatch(registerStatus(status));
    switch (status) {
      case HttpStatus.BAD_REQUEST:
        if (typeof err?.data === 'string') {
          dispatch(setErrorMessage(err.data));
        } else {
          dispatch(setErrorMessage(MSG_ERROR));
        }
        break;
      default:
        dispatch(setErrorMessage(MSG_ERROR));
        break;
    }
  }
  // when the register request is finished, hide the loading indicator
  dispatch(registerRequest(false));
};

/**
 * Resend the confirmation email
 * @param {object} data Contains email and password
 */
export const sendConfirmationEmail = data => dispatch => {
  // show the loading indicator
  dispatch(sendConfirmationRequest(true));
  const url = `${API_BASE}${API_RESEND}`;
  return axios.post(url, data);
};

export const sendVerificationEmail = data => dispatch => {
  dispatch(resetPasswordRequest(true));
  const url = `${API_BASE}${API_SEND_VERIFICATION_EMAIL}`;
  return axios.post(url, data);
};

export const updatePassword = data => dispatch => {
  dispatch(resetPasswordRequest(true));
  const url = `${API_BASE}${API_UPDATE_PASSWORD}`;
  return axios.patch(url, data);
};
