import React, { Component } from 'react';
import { Alert, Button } from 'antd';
import { connect } from 'react-redux';
import axios from 'axios';
import * as HttpStatus from 'http-status-codes';
import Layout from '../components/Layout/Layout';
import Modal from '../components/Modal/Modal';
import Login from '../components/Login/Login';
import { logout } from '../components/Login/LoginAction';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_EMAIL_CONFIRMATION = 'confirmation/email-update/confirm';

class EmailUpdate extends Component {
  state = {
    confirmStatus: undefined,
    confirmMsg: '',
    confirmDes: ''
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { logout, history } = this.props;
    const parsedUrl = new URL(window.location);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const userId = searchParams.get('id');
    const token = searchParams.get('token');
    if (userId && token) {
      const url = `${API_BASE}${API_EMAIL_CONFIRMATION}`;
      const data = { id: userId, token };
      try {
        const res = await axios.post(url, data);
        logout();
        this.setState({
          confirmStatus: res.status,
          confirmMsg: 'Email Successfully Updated',
          confirmDes: 'Please log in with your new email.'
        });
      } catch (err) {
        const confirmMsg = 'Sorry, an error occurred';
        const confirmDes = err ? err.data : 'Please try again later.';
        this.setState({ confirmStatus: err.status, confirmMsg, confirmDes });
      }
    } else {
      history.replace('/');
    }
  };

  render() {
    const { confirmStatus, confirmMsg, confirmDes } = this.state;
    return (
      <Layout>
        {confirmStatus && (
          <div style={{ width: '50%', maxWidth: '800px', margin: '100px auto' }}>
            <Alert
              message={confirmMsg}
              description={confirmDes}
              type={confirmStatus === HttpStatus.OK ? 'success' : 'error'}
              showIcon
            />
            <Modal
              footer={null}
              toggle={show => (
                <Button type="primary" style={{ marginTop: '20px', float: 'right' }} onClick={show}>
                  Log in
                </Button>
              )}
            >
              {hide => <Login close={hide} />}
            </Modal>
          </div>
        )}
      </Layout>
    );
  }
}

const mapDispatchToProps = { logout };

export default connect(
  null,
  mapDispatchToProps
)(EmailUpdate);
