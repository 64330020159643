import React, { Component } from 'react';
import { Row, Col, Table, Avatar, Input, Button, Alert } from 'antd';
import CurrencyFormat from 'react-currency-format';
import Counter from '../Counter/Counter';
import './ShoppingCart.scss';
import licenceIcon from '../../images/licence-icon.svg';

const MSG_SUCCESS = 'Discount code applied.';
const MSG_FAIL = 'This discount code is invalid.';

export default class ShoppingCart extends Component {
  state = {
    discountCode: '',
    isValidatingDiscountCode: false,
    validateStatus: null,
    validateMsg: null
  };

  onQuantityChange = (product, value) => {
    const { products, updateProduct } = this.props;
    const index = products.findIndex(item => item.id === product.id);
    updateProduct(index, 'quantity', value);
  };

  onDiscountCodeChange = e => {
    this.setState({ discountCode: e.target.value });
  };

  onDiscountCodeApply = async () => {
    const { validateDiscountCode, updateDiscount } = this.props;
    const { discountCode } = this.state;
    this.setState({ isValidatingDiscountCode: true });
    let discount = null;
    try {
      const res = await validateDiscountCode({ discountCode });
      discount = res.data;
      this.setState({ validateStatus: 'success', validateMsg: MSG_SUCCESS });
    } catch (err) {
      this.setState({ validateStatus: 'error', validateMsg: MSG_FAIL });
    }
    updateDiscount(discount);
    this.setState({ isValidatingDiscountCode: false });
  };

  render() {
    const { products, discount, next } = this.props;
    const { discountCode, isValidatingDiscountCode, validateStatus, validateMsg } = this.state;
    const subtotal = (products || []).reduce((acc, item) => acc + item.price * item.quantity, 0);
    const discountValue = discount ? discount.discountAmount || (discount.discountPercentage / 100) * subtotal : 0;
    const total = Math.max(subtotal - discountValue, 0);
    const columns = [
      { key: 'avatar', render: () => <Avatar icon="key" src={licenceIcon} size={60} /> },
      { key: 'title', title: 'PRODUCT', dataIndex: 'title' },
      {
        key: 'quantity',
        title: 'QUANTITY',
        align: 'center',
        render: (text, record) => (
          <Counter count={record.quantity} onChange={value => this.onQuantityChange(record, value)} />
        )
      },
      {
        key: 'price',
        title: 'PRICE',
        align: 'right',
        render: (text, record) => <span><CurrencyFormat value={record.price} displayType='text' thousandSeparator prefix='AU$ ' /></span>
      },
      {
        key: 'subtotal',
        title: 'SUBTOTAL',
        align: 'right',
        render: (text, record) => <strong><CurrencyFormat value={record.price * record.quantity} displayType='text' thousandSeparator prefix='AU$ ' /></strong>
      }
    ];

    return (
      <div className="shopping-cart">
        <Table columns={columns} dataSource={products} rowKey="id" pagination={false} loading={!products} />
        <Row type="flex" justify="end">
          <Col xs={24} sm={10}>
            <div className="discount-code">
              <div className="flex">
                <Input placeholder="Discount Code" onChange={this.onDiscountCodeChange} />
                <Button
                  type="primary"
                  loading={isValidatingDiscountCode}
                  disabled={!discountCode}
                  onClick={this.onDiscountCodeApply}
                >
                  Apply
                </Button>
              </div>
              {validateMsg && <Alert type={validateStatus} message={validateMsg} className="discount-alert" />}
            </div>
            <div className="fee">
              <span>Subtotal</span>
              <strong><CurrencyFormat value={subtotal} displayType='text' thousandSeparator prefix='AU$ ' /></strong>
            </div>
            {discountValue > 0 && (
              <div className="fee discount">
                <span>Discount</span>
                <strong><CurrencyFormat value={discountValue} displayType='text' thousandSeparator prefix='-AU$ ' /></strong>
              </div>
            )}
            <div className="fee">
              <span>Total (Includes GST)</span>
              <strong><CurrencyFormat value={total} displayType='text' thousandSeparator prefix='AU$ ' /></strong>
            </div>
            <Button type="primary" className="btn-pay" disabled={total === 0} onClick={next}>
              Check Out
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
