import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, Table } from 'antd';
import ProcessBar from '../../../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../../../components/Card/Card';
import InfoBox from '../../../../../../components/InfoBox/InfoBox';
import { fetchRiskExposures } from '../../RiskAction';
import { industryCodeSelector } from '../../../../../Organisation/OrganisationSelectors';
import { riskExposuresSelector } from '../../RiskSelectors';

const THEME_COLOR = '#a8c08f';
const INFO_MSG = (
  <>
    <p>
      This is a read only page to bring to your attention Risk Exposures identified as being considerations for
      Organizations operating in your industry.
    </p>
    <p>This is not a complete and exhaustive list and is intended as a general guide only.</p>
  </>
);

const RiskExposure = ({ industryId, riskExposures, fetchRiskExposures }) => {
  useEffect(() => {
    if (industryId) {
      fetchRiskExposures(industryId);
    }
  }, [industryId, fetchRiskExposures]);

  const columns = [{ key: 'name', dataIndex: 'name' }];

  return (
    <>
      <ProcessBar color={THEME_COLOR} title="Risk Exposures" next="/dashboard/risk/assessment" />
      <InfoBox
        color={THEME_COLOR}
        message={INFO_MSG}
        more
        video={
          <iframe
            title="risk-exposure"
            src="https://www.youtube.com/embed/sa6yHXSWpew?rel=0&amp;showinfo=0"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            className="responsive-video"
          />
        }
      />
      {riskExposures ? (
        <div className="risk-container">
          <Card color={THEME_COLOR} title="Risk Exposures">
            <Table
              columns={columns}
              dataSource={riskExposures}
              rowKey={record => record.id}
              pagination={{ hideOnSinglePage: true }}
              showHeader={false}
              className="table"
            />
          </Card>
        </div>
      ) : (
        <Spin tip="Loading Risk Exposures..." className="loading" />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  industryId: industryCodeSelector(state),
  riskExposures: riskExposuresSelector(state)
});

const mapDispatchToProps = { fetchRiskExposures };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskExposure);
