import { types } from './BiaAction';

const initialState = {
  loadingMtpd: false,
  loadingDisruptionRiskScales: false,
  loadingDrsCategories: false,
  loadingStrategicBias: false,
  loadingTacticalBias: false,
  loadingCriticalPeriodPresets: false,
  loadingAssetTypes: false,
  loadingAssets: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_MTPD:
      return { ...state, loadingMtpd: true };
    case types.RECEIVE_MTPD:
      return { ...state, loadingMtpd: false, mtpdList: action.mtpdList };
    case types.REQUEST_DISRUPTION_RISK_SCALES:
      return { ...state, loadingDisruptionRiskScales: true };
    case types.RECEIVE_DISRUPTION_RISK_SCALES:
      return { ...state, loadingDisruptionRiskScales: false, disruptionRiskScales: action.disruptionRiskScales };
    case types.REQUEST_DISRUPTION_RISK_SCALE_CATEGORIES:
      return { ...state, loadingDrsCategories: true };
    case types.RECEIVE_DISRUPTION_RISK_SCALE_CATEGORIES:
      return { ...state, loadingDrsCategories: false, drsCategories: action.drsCategories };
    case types.REQUEST_STRATEGIC_BIAS:
      return { ...state, loadingStrategicBias: true };
    case types.RECEIVE_STRATEGIC_BIAS:
      return { ...state, loadingStrategicBias: false, biaList: action.biaList || state.biaList };
    case types.REQUEST_TACTICAL_BIAS:
      return { ...state, loadingTacticalBias: true };
    case types.RECEIVE_TACTICAL_BIAS:
      return { ...state, loadingTacticalBias: false, tacticalBias: action.tacticalBias || state.tacticalBias };
    case types.REQUEST_CRITICAL_PERIODS:
      return { ...state, loadingCriticalPeriodPresets: true };
    case types.RECEIVE_CRITICAL_PERIODS:
      return { ...state, loadingCriticalPeriodPresets: false, criticalPeriodPresets: action.criticalPeriodPresets };
    case types.REQUEST_ASSET_TYPES:
      return { ...state, loadingAssetTypes: true };
    case types.RECEIVE_ASSET_TYPES:
      return { ...state, loadingAssetTypes: false, assetTypes: action.assetTypes };
    case types.REQUEST_ASSETS:
      return { ...state, loadingAssets: true };
    case types.RECEIVE_ASSETS:
      return { ...state, loadingAssets: false, assets: action.assets || state.assets };
    default:
      return state;
  }
};
