import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ color, message, button }) => (
  <div style={{ padding: '40px' }}>
    <div style={{ padding: '40px', background: color, textAlign: 'center' }}>
      <div style={{ fontSize: '16px', marginBottom: '20px' }}>{message}</div>
      {button}
    </div>
  </div>
);

Alert.propsTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string,
  button: PropTypes.node
};

Alert.defaultProps = {
  color: '#f0f0f0'
};

export default Alert;
