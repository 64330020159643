import React from 'react';
import { Button, Popconfirm, Divider } from 'antd';
import Modal from '../../../../../components/Modal/Modal';
import Table from '../../../../../components/Table/Table';
import OrganisationUserEdit from './OrganisationUserEdit';

export default ({ organisationUsers, updateOrganisationUser, revokeOrganisationUser }) => {
  const columns = [
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      span: 3,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null),
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstName'
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName'
    },
    { key: 'role', title: 'Role', dataIndex: 'roleName' },
    {
      key: 'addedBy',
      title: 'Added By',
      dataIndex: 'addedByEmail',
      width: 200,
      render: text => (text ? <a href={`mailto:${text}`}>{text}</a> : null)
    },
    {
      key: 'revoked',
      title: 'Revoked',
      filters: [{ text: 'Hide Revoked', value: true }],
      onFilter: (value, record) => !record.isRevoked,
      render: (text, record) => (record.isRevoked ? <span className="subscription-revoked">Yes</span> : <span>No</span>)
    },
    {
      key: 'action',
      width: 160,
      render: (text, record) => (
        <>
          <Popconfirm
            title={record.isRevoked ? 'Sure to unrevoke?' : 'Sure to revoke?'}
            onConfirm={() => revokeOrganisationUser(record)}
          >
            <Button type="link">{record.isRevoked ? 'Unrevoke' : 'Revoke'}</Button>
          </Popconfirm>
          <Divider type="vertical" />
          <Modal toggle={show => <Button type="link" icon="edit" onClick={show} />}>
            {hide => <OrganisationUserEdit user={record} updateUser={updateOrganisationUser} close={hide} />}
          </Modal>
        </>
      )
    }
    // {
    //   key: 'edit',
    //   render: (text, record) =>
    //     !record.addedById || record.addedById === record.userId ? null : (
    //       <Modal toggle={show => <Button type="link" icon="edit" onClick={show} />}>
    //         {hide => <OrganisationUserEdit user={record} updateUser={updateOrganisationUser} close={hide} />}
    //       </Modal>
    //     )
    // }
  ];

  return (
    <>
      <Table
        title="Organisation Users"
        loading={!organisationUsers}
        columns={columns}
        dataSource={organisationUsers}
        rowKey="userId"
        breakpoint={1200}
        searchFields={['email', 'firstName', 'lastName', 'roleName', 'addedByEmail']}
        style={{ paddingBottom: '20px' }}
      />
    </>
  );
};
