import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Steps, Icon, Button } from 'antd';
import Layout from '../../components/Layout/Layout';
import Card from '../../components/Card/Card';
import ShoppingCart from './components/ShoppingCart/ShoppingCart';
import BillingAddress from './components/BillingAddress/BillingAddress';
import Payment from './components/Payment/Payment';
import Confirmation from './components/Confirmation/Confirmation';
import { validateDiscountCode, calculateTotal, updatePurchaseCount } from './PurchaseAction';
import './Purchase.scss';

const { Step } = Steps;

class Purchase extends Component {
  purchaseExternalUrl = 'https://lmigroup.io/product-category/services-themes/';

  state = { step: 0, products: null, discount: null, billingAddress: {}, summary: {} };

  componentDidMount() {
    // this.loadData();
  }

  loadData = async () => {
    const { calculateTotal } = this.props;
    let products = null;
    try {
      const res = await calculateTotal({ quantity: 1 });
      const price = res.data.total;
      products = [{ id: 1, title: 'Licences', price, quantity: 0 }];
    } catch (err) {
      products = [];
    }
    this.setState({ products });
  };

  goPurchase = () => {
    window.open(this.purchaseExternalUrl, '_blank');
  };

  goback = () => {
    const { history } = this.props;
    history.goBack();
  };

  prev = () => {
    this.setState(({ step }) => ({ step: step > 0 ? step - 1 : 0 }));
  };

  next = () => {
    this.setState(({ step }) => ({ step: step + 1 }));
  };

  updateProduct = (index, key, value) => {
    const { products } = this.state;
    products[index][key] = value;
    this.setState({ products });
  };

  updateDiscount = discount => {
    this.setState({ discount });
  };

  updateBillingAddress = newAddress => {
    this.setState(({ billingAddress }) => ({ billingAddress: { ...billingAddress, ...newAddress } }));
  };

  afterPay = summary => {
    const { updatePurchaseCount } = this.props;
    const { products } = this.state;
    const { quantity } = products[0];
    this.setState({ summary });
    updatePurchaseCount(quantity);
  };

  // currently not in use, render this Component when we have the payment back.
  renderPurchase = () => {
    const { purchaser, validateDiscountCode, calculateTotal } = this.props;
    const { step, products, discount, billingAddress, summary } = this.state;
    return (
      <Layout>
        <div className="purchase">
          <Card style={{ padding: '40px 80px' }}>
            <Steps current={step} className="purchase-step">
              <Step title="Orders" icon={<Icon type="shopping-cart" />} />
              <Step title="Billing Info" icon={<Icon type="environment" />} />
              <Step title="Payment" icon={<Icon type="dollar" />} />
              <Step title="Confirmation" icon={<Icon type="check-circle" />} />
            </Steps>
            {step === 0 && (
              <ShoppingCart
                products={products}
                discount={discount}
                updateProduct={this.updateProduct}
                updateDiscount={this.updateDiscount}
                validateDiscountCode={validateDiscountCode}
                next={this.next}
              />
            )}
            {step === 1 && (
              <BillingAddress
                billingAddress={billingAddress}
                prev={this.prev}
                next={this.next}
                updateBillingAddress={this.updateBillingAddress}
              />
            )}
            {step === 2 && (
              <Payment
                product={products[0]}
                discountCode={discount ? discount.code : null}
                billingAddress={billingAddress}
                calculateTotal={calculateTotal}
                prev={this.prev}
                next={this.next}
                afterPay={this.afterPay}
              />
            )}
            {step === 3 && <Confirmation purchaser={purchaser} summary={summary} />}
          </Card>
        </div>
      </Layout>
    );
  };

  render() {
    return (
      <Layout>
        <div className="purchase">
          <Card style={{ padding: '40px 80px' }}>
            <h2>ContinuityCoach Subscription can be purchased via the LMI Group Website.</h2>
            <p>Clicking the purchase button will navigate you to www.lmigroup.io</p>
            <Button className="purchase-action-btn" type="primary" onClick={this.goPurchase}>
              Purchase
            </Button>

            <Button className="purchase-action-btn" onClick={this.goback}>
              Go Back
            </Button>
          </Card>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { purchaseReducer, userReducer } = state;
  return { ...purchaseReducer, purchaser: userReducer.userInfo.email };
};

const mapDispatchToProps = dispatch => ({
  validateDiscountCode: data => dispatch(validateDiscountCode(data)),
  calculateTotal: data => dispatch(calculateTotal(data)),
  updatePurchaseCount: count => dispatch(updatePurchaseCount(count))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Purchase);
