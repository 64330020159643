import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select, Checkbox, Radio, DatePicker, InputNumber, Button, message } from 'antd';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import * as HttpStatus from 'http-status-codes';
import { withOnFocus } from '../../../../../components/HOC';
import { createRisks, updateRisks } from '../RiskAction';
import { planIdSelector, currencySelector } from '../../Plan/PlanSelectors';
import { contactListSelector } from '../../../../Organisation/scenes/Contact/ContactSelectors';
import {
  riskPresetsSelector,
  riskLikelihoodPresetsSelector,
  mitigationStrategiesSelector,
  mitigationStatusSelector
} from '../RiskSelectors';
import explanations from '../data/explanations.json';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../constants';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
const initialState = {
  likelihoodValue: null,
  cost: null,
  mitigationStrategyName: null
};

class RiskEdit extends Component {
  constructor(props) {
    super(props);
    const { riskInfo, riskLikelihoodPresets, mitigationStrategies = [], mitigationStatus = [], contactList } = props;

    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.SelectWithOnFocus = withOnFocus(Select, props.onExplanationChange);
    this.InputNumberWithOnFocus = withOnFocus(InputNumber, props.onExplanationChange);
    this.TextAreaWithOnFocus = withOnFocus(TextArea, props.onExplanationChange);
    this.DatePickerWithOnFocus = withOnFocus(DatePicker, props.onExplanationChange);

    this.riskLikelihoodOptions = riskLikelihoodPresets
      .sort((a, b) => a.value - b.value)
      .map(option => (
        <Option key={option.likelihoodPresetId} value={option.likelihoodPresetId}>
          {option.text}
        </Option>
      ));
    this.mitigationStrategyOptions = mitigationStrategies.map(obj => ({
      label: obj.name,
      value: obj.mitigationStrategyId
    }));
    this.mitigationStatusItems = mitigationStatus.map(obj => (
      <Radio key={obj.mitigationStatusId} value={obj.mitigationStatusId}>
        {obj.name}
      </Radio>
    ));
    this.contactOptions = (contactList || []).map(contact => (
      <Option key={contact.email} value={contact.contactId} label={`${contact.firstName} ${contact.lastName}`}>
        {contact.jobTitle
          ? `${contact.firstName} ${contact.lastName} - ${contact.jobTitle}`
          : `${contact.firstName} ${contact.lastName}`}
      </Option>
    ));

    if (riskInfo) {
      const { likelihoodValue, cost, mitigationStrategies } = riskInfo;
      const mitigationStrategyName = (mitigationStrategies || []).map(obj => obj.name);
      this.state = { ...initialState, likelihoodValue, cost, mitigationStrategyName };
    } else {
      this.state = { ...initialState };
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, riskInfo, riskPresets, categoryId } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        const data = {
          ...values,
          likelihoodPresetId: values.likelihoodId,
          followUpDate: values.followUpDate ? values.followUpDate.format('YYYY-MM-DD') : null
        };
        if (riskInfo) {
          const { riskId, name, description, riskPresetId, riskCategoryId } = riskInfo;
          data.riskCategoryId = riskCategoryId;
          if (riskId) {
            data.riskId = riskId;
            await this.updateRisk(data);
          } else {
            data.name = name;
            data.riskPresetId = riskPresetId;
            data.description = description;
            await this.createRisk(data);
          }
        } else {
          // check if the name of the risk already exist
          const riskName = data.name;
          const riskPresetsName = riskPresets[categoryId].map(risk => risk.name);
          if (riskPresetsName.includes(riskName)) {
            return;
          }
          data.riskCategoryId = categoryId;
          await this.createRisk(data);
        }
      }
    });
  };

  onLikelihoodChange = likelihoodId => {
    const { riskLikelihoodPresets = [] } = this.props;
    const target = riskLikelihoodPresets.find(item => item.likelihoodPresetId === likelihoodId);
    if (target) {
      this.setState({ likelihoodValue: target.value });
    }
  };

  onCostChange = cost => {
    this.setState({ cost });
  };

  onMitigationStrategyChange = values => {
    const { mitigationStrategies = [] } = this.props;
    const mitigationStrategyName = [];
    values.forEach(item => {
      const mitigationStrategy = mitigationStrategies.find(obj => obj.mitigationStrategyId === item);
      if (mitigationStrategy) {
        mitigationStrategyName.push(mitigationStrategy.name);
      }
    });
    this.setState({ mitigationStrategyName });
  };

  createRisk = async data => {
    const { planId, createRisks, close } = this.props;
    try {
      await createRisks(planId, [data]);
      message.success(MSG_SAVE_SUCCESS);
      close();
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  updateRisk = async data => {
    const { planId, updateRisks, close } = this.props;
    try {
      await updateRisks(planId, [data]);
      message.success(MSG_SAVE_SUCCESS);
      close();
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  render() {
    const {
      InputWithOnFocus,
      SelectWithOnFocus,
      InputNumberWithOnFocus,
      TextAreaWithOnFocus,
      DatePickerWithOnFocus,
      riskLikelihoodOptions,
      mitigationStrategyOptions,
      mitigationStatusItems,
      contactOptions
    } = this;
    const { form, riskInfo = {}, riskRequest, currency } = this.props;
    const { getFieldDecorator } = form;
    const { likelihoodValue, cost, mitigationStrategyName } = this.state;

    return (
      <Form>
        {!riskInfo.riskPresetId && (
          <>
            <FormItem label="Name">
              {getFieldDecorator('name', {
                initialValue: riskInfo.name,
                rules: [{ required: true, message: 'Please input the name.' }]
              })(<InputWithOnFocus placeholder="Name" disabled={riskRequest} />)}
            </FormItem>
            <FormItem label="Description">
              {getFieldDecorator('description', {
                initialValue: riskInfo.description
              })(<TextAreaWithOnFocus rows={1} />)}
            </FormItem>
          </>
        )}
        <FormItem label="Likelihood">
          {getFieldDecorator('likelihoodId', {
            initialValue: riskInfo.likelihoodId,
            rules: [{ required: true, message: 'Please select Likelihood' }]
          })(
            <SelectWithOnFocus
              placeholder="Select Risk Likelihood"
              disabled={riskRequest}
              explanation={explanations.likelihood}
              onSelect={this.onLikelihoodChange}
              style={{ width: '200px' }}
            >
              {riskLikelihoodOptions}
            </SelectWithOnFocus>
          )}
        </FormItem>
        <FormItem label="Loss Value">
          {getFieldDecorator('cost', {
            initialValue: riskInfo.cost,
            rules: [{ required: true, message: 'Please input Loss Value' }]
          })(
            <InputNumberWithOnFocus
              disabled={riskRequest}
              formatter={value => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\D\s?|(,*)/g, '')}
              step={100}
              precision={0}
              explanation={explanations.cost}
              onChange={this.onCostChange}
              style={{ width: '200px' }}
            />
          )}
        </FormItem>
        <FormItem style={{ margin: '-10px 0 10px 0' }}>
          {likelihoodValue && cost && (
            <span>
              <CurrencyFormat
                value={((likelihoodValue * cost).toFixed(2) * 100) / 100}
                displayType="text"
                thousandSeparator
                prefix={`Annualised Value: ${currency} `}
              />
            </span>
          )}
        </FormItem>
        <FormItem label="Mitigation Strategy">
          {getFieldDecorator('mitigationStrategyIds', {
            initialValue: riskInfo.mitigationStrategies
              ? riskInfo.mitigationStrategies.map(item => item.mitigationStrategyId)
              : null,
            rules: [{ required: true, message: 'Please select Mitigation Strategy' }]
          })(<CheckboxGroup options={mitigationStrategyOptions} onChange={this.onMitigationStrategyChange} />)}
        </FormItem>
        {mitigationStrategyName && mitigationStrategyName.includes('Transfer') && (
          <FormItem label="Does your Transfer Strategy include Insurance?" colon={false}>
            {getFieldDecorator('includeInsurance', {
              initialValue: riskInfo.includeInsurance,
              rules: [{ required: true, message: 'Please make a select' }]
            })(
              <RadioGroup disabled={riskRequest}>
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </RadioGroup>
            )}
          </FormItem>
        )}
        <FormItem label="How will you achieve your Mitigation Strategy?" colon={false}>
          {getFieldDecorator('mitigationProcess', {
            initialValue: riskInfo.mitigationProcess,
            rules: [{ required: true, message: 'This field cannot be empty' }]
          })(<TextAreaWithOnFocus rows={1} disabled={riskRequest} explanation={explanations.mitigationStrategy} />)}
        </FormItem>
        <FormItem label="Mitigation Status">
          {getFieldDecorator('mitigationStatusId', {
            initialValue: riskInfo.mitigationStatusId
          })(<RadioGroup disabled={riskRequest}>{mitigationStatusItems}</RadioGroup>)}
        </FormItem>
        <FormItem label="Responsible Person">
          {getFieldDecorator('accountableContactId', {
            initialValue: riskInfo.accountableContact ? riskInfo.accountableContact.contactId : null
          })(
            <SelectWithOnFocus
              placeholder="Select from contacts"
              disabled={riskRequest}
              showSearch
              notFoundContent="Not Found"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              optionLabelProp="label"
              explanation={explanations.responsiblePerson}
            >
              {contactOptions}
            </SelectWithOnFocus>
          )}
        </FormItem>
        <FormItem label="Follow Up Date">
          {getFieldDecorator('followUpDate', {
            initialValue: riskInfo.followUpDate ? moment(riskInfo.followUpDate) : null
          })(
            <DatePickerWithOnFocus
              placeholder="DD/MM/YYYY"
              disabled={riskRequest}
              format="DD/MM/YYYY"
              explanation={explanations.followUpDate}
            />
          )}
        </FormItem>
        <FormItem label="Comments">
          {getFieldDecorator('comments', {
            initialValue: riskInfo.comments
          })(<TextAreaWithOnFocus rows={1} disabled={riskRequest} explanation={explanations.comments} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" block loading={riskRequest} onClick={this.onSubmit}>
            {riskRequest ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  planId: planIdSelector(state),
  contactList: contactListSelector(state),
  riskPresets: riskPresetsSelector(state),
  riskLikelihoodPresets: riskLikelihoodPresetsSelector(state),
  mitigationStrategies: mitigationStrategiesSelector(state),
  mitigationStatus: mitigationStatusSelector(state),
  currency: currencySelector(state),
  riskRequest: state.riskReducer.riskRequest
});

const mapDispatchToProps = { createRisks, updateRisks };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(RiskEdit));
