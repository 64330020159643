import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Button, Row, Col, Divider } from 'antd';
import Modal from '../Modal/Modal';
import TermsConditions from './components/TermsConditions';
import PrivacySecurity from './components/PrivacySecurity';
import './Footer.scss';

const { Footer } = Layout;

export default () => (
  <Footer className="footer">
    <Row type="flex" justify="space-between" align="middle">
      <Col xs={24} sm={18}>
        <div className="footer-links">
          <Modal
            title="Terms &amp; Conditions"
            width="auto"
            wrapClassName="terms-conditions-modal"
            toggle={show => (
              <Button type="link" onClick={show}>
                Terms &amp; Conditions
              </Button>
            )}
          >
            {() => <TermsConditions />}
          </Modal>
          <Modal
            title="Privacy &amp; Security Statement"
            width="auto"
            wrapClassName="terms-conditions-modal"
            toggle={show => (
              <Button type="link" onClick={show}>
                Privacy Policy
              </Button>
            )}
          >
            {() => <PrivacySecurity />}
          </Modal>
          <Link to="/contact-us">
            <Button type="link">Contact Us</Button>
          </Link>
        </div>
      </Col>
      <Col xs={24} sm={6}>
        <ul className="footer-social-links">
          <li>
            <Button shape="circle" type="primary" size="small">
              <a href="https://www.linkedin.com/company/lmi-group/" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-linkedin" />
              </a>
            </Button>
          </li>
          <li>
            <Button shape="circle" type="primary" size="small">
              <a href="https://www.facebook.com/LMIGroupPtyLtd/" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-facebook" />
              </a>
            </Button>
          </li>
          <li>
            <Button shape="circle" type="primary" size="small">
              <a href="https://www.youtube.com/user/LMIGROUP" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-youtube" />
              </a>
            </Button>
          </li>
        </ul>
      </Col>
    </Row>
    <div className="footer-copyright">
      <Divider className="divider" />
      &copy; 2005 - {new Date().getFullYear()} <strong>LMI Group</strong>. All Rights Reserved.{' '}
    </div>
  </Footer>
);
