import React from 'react';

export default () => (
  <article className="terms-conditions-modal-inner">
    <h1>Terms &amp; Conditions</h1>
    <p>
      Your use of this web site and those of member companies and their products are expressly conditioned upon your
      accepting and agreeing to these terms of use. By clicking yes to the box marked &quot;I agree to abide by the
      above terms and conditions&quot; you confirm your agreement to be bound by all the terms and conditions listed
      here.
    </p>
    <p>
      IF THESE TERMS OF USE ARE NOT COMPLETELY ACCEPTABLE TO YOU, YOU MUST IMMEDIATELY TERMINATE YOUR USE OF THIS WEB
      SITE.
    </p>
    <p>
      You may print a copy of these Terms of Use for your records at any time by using the &quot;print&quot; function on
      your internet browser.
    </p>
    <p>
      These Terms of Use (&quot;Legal Terms&quot;) describe the terms and conditions applicable to your access and use
      of this web site, which is owned, operated and controlled by LMI Group.
    </p>
    <p>
      LMIGroup, at any time, for any reason and without notice, make changes to (i) this Web Site, including its look,
      feel, format, and content, as well as (ii) the products and/or services as described in this Web Site. Any
      modifications will take effect when posted to the Web Site. Therefore, each time you access the Web Site, you need
      to review the Legal Terms upon which access and use of this Web Site is conditioned. By your continuing use of the
      Web Site after changes are posted, you will be deemed to have accepted such changes.
    </p>
    <p>
      The website is directed to those individuals and entities located in Australia and New Zealand. It is not directed
      to any person or entity in any jurisdiction where (by reason of nationality, residence, citizenship or otherwise)
      the publication or availability of the Web Site and its content, including its products and services, are
      unavailable or otherwise contrary to local laws or regulations. If this applies to you, you are not authorized to
      access or use any of the information on this Web Site. LMIGroup makes no representation that the information,
      opinions, advice or other content on the Web Site (collectively, &quot;Content&quot;) is appropriate or that the
      products referred to and services are available outside of Australia. Those who choose to access this Web Site
      from other locations do so at their own risk and are responsible for compliance with applicable local laws.
    </p>
    <p>
      Not all of the products and services described on this Web Site may be available in all geographic areas of
      Australia or New Zealand. You may not be eligible for all of the insurance products and services described in this
      Web Site. The Web Site may provide a general description of certain types of insurance products, coverage and
      services available through many insurance policies. These descriptions are not a part of an insurance contract or
      policy and do not amend, revise or alter the terms of any policy issued through or services provided by the
      relevant insurer or underwriting agency.
    </p>
    <p>
      <strong>Scope of Use and User e-mail</strong>
    </p>
    <p>
      You are only authorized to view, use, copy for your records and download small portions of the Content (including
      without limitation text, graphics, software, audio and video files and photos) of this Web Site for your
      informational, non-commercial use, provided that you leave all the copyright notices, including copyright
      management information, or other proprietary notices intact. You may not store, modify, reproduce, transmit,
      reverse engineer or distribute a significant portion of the Content on this Web Site, or the design or layout of
      the Web Site or individual sections of it, in any form or media. The systematic retrieval of data from the Web
      Site is also prohibited.
    </p>
    <p>
      E-mail submissions over the Internet may not be secure and are subject to the risk of interception by third
      parties. Please consider this fact before e-mailing any information. Also, please consult our privacy statement.
      You agree not to submit or transmit any e-mails or materials through the Web Site that (i) are defamatory,
      threatening, obscene or harassing, (ii) contain a virus, worm, Trojan horse or any other harmful component, (iii)
      incorporate copyrighted or other proprietary material of any third party without that party&apos;s permission or
      (iv) otherwise violate any applicable laws. Continuity Coach Pty Ltd shall not be subject to any obligations of
      confidentiality regarding any information or materials that you submit online except as specified in these Legal
      Terms, or as set forth in any additional terms and conditions relating to specific products or services, or as
      otherwise specifically agreed or required by law.
    </p>
    <p>
      The commercial use, reproduction, transmission or distribution of any information, software or other material
      available through the Web Site without the prior written consent of Continuity Coach Pty Ltd is strictly
      prohibited.
    </p>
    <p>
      <strong>Copyrights and Trademarks</strong>
    </p>
    <p>
      The Web Site is protected by copyright as a collective work and/or compilation, pursuant to Australian copyright
      laws, international conventions and other laws. Unless otherwise indicated, the Content, including, but not
      limited to, graphic images, buttons, layout, trademarks, service marks, logos, (other than those of insurers,
      underwriting agencies) text, and other materials contained in this Web Site are the exclusive property of
      Continuity Coach Pty Ltd or its third party licensors; except as otherwise set forth in the following paragraphs
      of this Section 3. Except as expressly provided under the &quot;Scope of Use&quot; Section above, you may not use,
      reproduce, modify, transmit, distribute, or publicly display or operate this Web Site without the prior written
      permission of Continuity Coach Pty Ltd. You may not use a part of this Web Site on any other web site, without
      Continuity Coach&apos;s prior written consent.
    </p>
    <p>
      A number of our online services include &apos;bulletin board-type&apos; facilities enabling some subscribers to
      post messages or other materials that will be accessible only to other registered subscribers of their choice. As
      we may provide these services, Continuity Coach Pty Ltd takes special precautions to respect the intellectual
      property of others, and we ask that our subscribers do the same. If you believe that the Website displays
      copyrighted material that infringes on your legal rights, and you want us to remove or disable the infringing
      materials, please provide Continuity Coach&apos;s copyright agent with the information listed below.
    </p>
    <p>
      Our designated agent for notice of claims of copyright infringement on the Website is accessible via email to:{' '}
      <a href="mailto:vic@LMIGroup.com">vic@LMIGroup.com</a>. The following information must be submitted so that we may
      properly address any notice of complaint:
    </p>
    <ul>
      <li>Identification of the copyrighted work claimed to be infringed.</li>
      <li>
        Identification of the material claimed to be infringing together with &quot;information reasonably sufficient to
        permit the service provider to locate the material.&quot; Specifically, a complete URL or URLs in the case of
        multiple works, must be provided.
      </li>
      <li>Your address, telephone number, and email address.</li>
      <li>
        A statement that the complaining party believes, in good faith, that the copyrighted material identified is
        being used in a manner that is not authorized by &quot;the copyright owner, its agent, or the law.&quot;
      </li>
      <li>
        A statement that the information in the notification is accurate, and under penalty of perjury, that the
        complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
        plus a physical or electronic signature of the complaining party.
      </li>
      <li>
        Any notification that fails to comply with the provisions above may not, in our sole discretion, be considered
        sufficient notice and may not be deemed to confer upon Continuity Coach Pty Ltd actual knowledge of facts or
        circumstances from which infringing material or acts are evident.
      </li>
    </ul>
    <p>
      <strong>Links</strong>
    </p>
    <p>
      ContinuityCoach.com offers links to other web sites operated by other entities as a convenience to you in the
      belief that making hyperlinks available to publicly accessible web pages and newsgroups is legally permissible and
      consistent with the common, customary expectations of those who make use of the Internet. Continuity Coach Pty Ltd
      makes no representation regarding the content or accuracy of any web site that you may access through this Web
      Site. Continuity Coach Pty Ltd does not monitor and is not responsible for the content found on other web sites
      that are linked from this Web Site. Inclusion of these links on Continuity Coach&apos;s Web Site does not imply
      endorsement, recommendation or sponsorship for any linked web site or the services, products or advice described
      on the site. Accordingly, Policy Coach International Pty Ltd, LMI Group Pty Ltd, Continuity Coach Pty Ltd, Loss
      Management International Pty Ltd, Risk Management International Pty Ltd, Policy Comparison International Pty Ltd
      or Claims Management International Pty Ltd assumes no responsibility or liability for the content of any linked
      sites. Before entering any linked site, you should review and understand that site&apos;s privacy policy, which is
      available in the Terms &amp; Conditions for Privacy Policy link(s).
    </p>
    <p>
      You may not link to this Web Site without Continuity Coach&apos;s written permission. If you are interested in
      linking to this Web Site, please contact <a href="mailto:vic@LMIGroup.com">vic@LMIGroup.com</a>
    </p>
    <p>
      <strong>No Warranties</strong>
    </p>
    <p>
      The web site, and any content, are provided to you on an &quot;as is,&quot; &quot;as available&quot; basis without
      warranty of any kind whether express, statutory or implied, including but not limited to any implied warranties of
      merchantability, fitness for a particular purpose, quiet enjoyment, systems integration, accuracy, and
      non-infringement, all of which Continuity Coach Pty Ltd expressly disclaims. Continuity Coach Pty Ltd does not
      endorse and makes no warranty as to the accuracy, completeness, currency, or reliability of the content, and
      Policy Coach International Pty Ltd, LMI Group Pty Ltd, Continuity Coach Pty Ltd, Loss Management International Pty
      Ltd, Risk Management International Pty Ltd, Policy Comparison International Pty Ltd and or Claims Management
      International Pty Ltd will not be liable or otherwise responsible for any failure or delay in updating the web
      site or any content. We have no duty to update the content of the web site. Policy Coach International Pty Ltd,
      LMI Group Pty Ltd, Continuity Coach Pty Ltd, Loss Management International Pty Ltd, nor Claims Management
      International Pty Ltd makes any representations or warranties that use of the content will be uninterrupted or
      error-free. You are responsible for any results or other consequences of accessing the web site and using the
      content, and for taking all necessary precautions to ensure that any content you may access, download or otherwise
      obtain is free of viruses or any other harmful components. This warranty disclaimer may be different in connection
      with specific products and services offered by Continuity Coach Pty Ltd.
    </p>
    <p>
      If any part of this warranty is not permitted by law in your jurisdiction, only that portion shall be ignored but
      all other terms and conditions of these terms and conditions shall apply.
    </p>
    <p>
      <strong>Governing Law, Location and Miscellaneous</strong>
    </p>
    <p>
      These Legal Terms shall be governed in all respects by the laws of the State Victoria, Australia, without
      reference to its choice of law rules. If an applicable law is in conflict with any part of the Legal Terms, the
      Legal Terms will be deemed modified to conform to the law. The other provisions will not be affected by any such
      modification.
    </p>
    <p>
      The Web Site is operated by Continuity Coach Pty Ltd on behalf of the member companies of the LMI Group
      (identified below in Section 8), from its offices in Camberwell, Victoria, Australia.
    </p>
    <p>
      <strong>Member companies of the LMI Group</strong>
    </p>
    <p>LMI Group does business in its own name as well as through its subsidiary companies, as listed below.</p>
    <p>
      LMI Group Pty Ltd
      <br />
      LMI Group House
      <br />
      428 Burke Road
      <br />
      Camberwell 3124 Australia
    </p>
    <p>
      Loss Management International Pty Ltd
      <br />
      LMI Group House
      <br />
      428 Burke Road
      <br />
      Camberwell 3124 Australia
    </p>
    <p>
      Claims Management International Pty Ltd
      <br />
      LMI Group House
      <br />
      428 Burke Road
      <br />
      Camberwell 3124 Australia
    </p>
    <p>
      Risk Management International Pty Ltd
      <br />
      LMI Group House
      <br />
      428 Burke Road
      <br />
      Camberwell 3124 Australia
    </p>
    <p>
      Policy Comparison International Pty Ltd
      <br />
      LMI Group House
      <br />
      428 Burke Road
      <br />
      Camberwell 3124 Australia
    </p>
    <p>
      Risk Coach International Pty Ltd
      <br />
      LMI Group House
      <br />
      428 Burke Road
      <br />
      Camberwell 3124 Australia
      <br />
      Licensed in: All states including DC, excluding ME, NH
    </p>
    <p>
      Continuity Coach Pty Ltd
      <br />
      LMI Group House
      <br />
      428 Burke Road
      <br />
      Camberwell 3124 Australia
    </p>
    <p>
      LMI Group Limited
      <br />
      Unit Q, 86 Bush Road, Albany, Auckland, New Zealand
      <br />
      PO Box 310596, Albany, Auckland 0752, New Zealand
    </p>
    <p>
      LMI Group
      <br />
      Ariobimo Sentral 4th Floor
      <br />
      Ji H.R. Rasuna Said Blok x-2 No.5
      <br />
      Jakarta, Indonesia
    </p>
    <p>
      <strong>Termination</strong>
    </p>
    <p>
      Continuity Coach Pty Ltd may terminate the right of any user to access the Web Site at any time, with or without
      cause, in Policy Coach&apos;s absolute discretion and without notice. Any statement in the Legal Terms or
      elsewhere on the Web Site of specific grounds for termination of a user&apos;s right to access the Web Site shall
      in no manner limit Continuity Coach&apos;s absolute right to terminate any user&apos;s access to the Web Site.
    </p>
    <p>
      <strong>Separate Agreements</strong>
    </p>
    <p>
      You may have other agreements with Continuity Coach Pty Ltd. Those agreements are separate and in addition to
      these Legal Terms. These Legal Terms do not modify, revise or amend the terms of any other agreements you may have
      with Continuity Coach Pty Ltd, or with any other company within the LMI Group.
    </p>
    <p>
      <strong>Australian Resident</strong>
    </p>
    <p>You represent that you are an Australian resident.</p>
    <p>
      <strong>No Professional Advice</strong>
    </p>
    <p>
      The information available on the Web Site is intended to be a general information resource regarding the matters
      covered, and is not tailored to your specific circumstance. It is not offered as legal, accounting or other
      professional advice. This Web Site is not intended for use by minors. YOU SHOULD EVALUATE ALL INFORMATION,
      OPINIONS AND ADVICE AVAILABLE ON THIS WEB SITE IN CONSULTATION WITH YOUR INSURANCE SPECIALIST, OR WITH YOUR LEGAL,
      TAX, FINANCIAL OR OTHER ADVISOR, AS APPROPRIATE.
    </p>
    <p>
      <strong>Arbitration</strong>
    </p>
    <p>
      Except as regarding any action seeking equitable relief, including without limitation for the purpose of
      protecting any Continuity Coach Pty Ltd confidential information and/or intellectual property rights, any
      controversy or claim arising out of or relating to these Legal Terms or this Web Site shall be settled by binding
      arbitration in accordance with the commercial arbitration rules, in effect at the time the proceedings begin, of
      the Institute of Australian Arbitrators and Mediators. Any such controversy or claim shall be arbitrated on an
      individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other
      party. The arbitration shall be held in Melbourne Australia.
    </p>
    <p>
      All information relating to or disclosed by any party in connection with the arbitration of any disputes hereunder
      shall be treated by the parties, their representatives, and the arbitrator as proprietary business information.
      Such information shall not be disclosed by any party or their respective representatives without the prior written
      authorization of the party furnishing such information. Such information shall not be disclosed by the arbitrator
      without the prior written authorisation of all parties. Each party shall bear the burden of its own counsel fees
      incurred in connection with any arbitration proceedings.
    </p>
    <p>
      Judgment upon the award returned by the arbitrator may be entered in any court having jurisdiction over the
      parties or their assets or application of enforcement, as the case may be. Any award by the arbitrator shall be
      the sole and exclusive remedy of the parties. The parties hereby waive all rights to judicial review of the
      arbitrator&apos;s decision and any award contained therein.
    </p>
    <p>
      <strong>Limitation of Liability</strong>
    </p>
    <p>
      Your use of the content is at your own risk. Continuity Coach Pty Ltd specifically disclaims any liability,
      whether based in contract, tort, negligence, strict liability or otherwise, for any direct, indirect, incidental,
      punitive, consequential, or special damages arising out of or in any way connected with access to, use of or
      reliance on the content (even if policy coach has been advised of the possibility of such damages) or that arise
      in connection with mistakes or omissions in, or delays in transmission of, information to or from the user, any
      failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission or
      delivery, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration
      of, or use of records, programs or files, interruptions in telecommunications connections to the web site or
      viruses, whether caused in whole or in part by negligence, acts of god, telecommunications failure, theft or
      destruction of, or unauthorized access to the web site or the content. This limitation of liability may be
      different in connection with specific products and services offered by Continuity Coach Pty Ltd. Some
      jurisdictions do not allow the limitation of liability, so this limitation may not apply to you.
    </p>
  </article>
);
