import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../components/Card/Card';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import FolderList from './components/FolderList';
import DocumentList from './components/DocumentList';
import { MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../constants';
import { fetchDocuments, fetchFolders, uploadDocument, updateDocument, deleteDocument } from './DocumentAction';
import { planIdSelector } from '../Plan/PlanSelectors';
import { foldersSelector, documentsSelector, loadingDocumentsSelector } from './DocumentSelectors';
import './Document.scss';

const INFO_MSG = (
  <>
    <p>
      Safely store your organization documents. Upload relevant business continuity documents for easy access - like
      Insurance Policy, Evacuation Plan, etc.
    </p>
    <p>Click the &apos;+ Folder&apos; to create and name a new Folder.</p>
    <p>
      In the case of an incident occurring, consider creating a new folder for the incident so all relevant documents
      can be kept together.
    </p>
  </>
);

const Document = ({
  planId,
  folders,
  documents,
  loadingDocuments,
  fetchFolders,
  fetchDocuments,
  uploadDocument,
  updateDocument,
  deleteDocument
}) => {
  const [selectedFolder, setSelectedFolder] = useState();

  useEffect(() => {
    fetchFolders(planId);
    fetchDocuments(planId);
  }, [planId, fetchFolders, fetchDocuments]);

  const onUploadDocument = async (folder, file) => {
    const { id, name } = folder;
    const formData = new FormData();
    formData.append('document', file);
    formData.append('documentFolderId', id);
    formData.append('documentFolderText', name);
    try {
      await uploadDocument(planId, formData);
      message.success('The document has been successfully uploaded');
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        case HttpStatus.CONFLICT:
          message.error(
            'The file is already exist, please either delete the old one first, or rename the file you want to upload'
          );
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  const onAddUrl = async (folder, url, friendlyName) => {
    const { id, name } = folder;
    const formData = new FormData();
    formData.append('displayName', friendlyName);
    formData.append('documentFolderText', name);
    formData.append('documentFolderId', id);
    formData.append('url', url);
    try {
      await uploadDocument(planId, formData);
      message.success('The url link has been successfully added');
    } catch (err) {
      message.error(MSG_ERROR);
    }
  };

  const onUpdateDocument = async (documentId, data) => {
    try {
      await updateDocument(planId, documentId, data);
      message.success('The document information has been successfully updated');
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        case HttpStatus.CONFLICT:
          message.error(
            'The file is already exist, please either delete the old one first, or rename the file you want to upload'
          );
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  const onDeleteDocument = async document => {
    try {
      await deleteDocument(planId, document.documentId);
      message.success('The document has been successfully deleted');
    } catch (err) {
      switch (err.status) {
        case HttpStatus.PAYMENT_REQUIRED:
          message.error(MSG_PAYMENT_REQUIRED);
          break;
        default:
          message.error(MSG_ERROR);
      }
    }
  };

  return (
    <>
      <ProcessBar title="Plan Documents" />
      <InfoBox color="#f1c400" message={INFO_MSG} />
      {folders && documents ? (
        <div className="doc-container">
          <Card bodyStyle={{ paddingBottom: '100px' }} color="#dbac00" title="All Documents">
            <div className="doc-container-inner">
              <FolderList
                folders={folders}
                documents={documents}
                selectedFolder={selectedFolder}
                onFolderSelect={setSelectedFolder}
                onUploadDocument={onUploadDocument}
                onAddUrl={onAddUrl}
              />
              {selectedFolder && (
                <Spin spinning={loadingDocuments}>
                  <DocumentList
                    documents={documents[selectedFolder.id]}
                    selectedFolder={selectedFolder}
                    onAddUrl={onAddUrl}
                    onUploadDocument={onUploadDocument}
                    onUpdateDocument={onUpdateDocument}
                    onDeleteDocument={onDeleteDocument}
                    loadingDocuments={loadingDocuments}
                  />
                </Spin>
              )}
            </div>
          </Card>
        </div>
      ) : (
        <Spin tip="Loading Documents..." className="loading" />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  planId: planIdSelector(state),
  folders: foldersSelector(state),
  documents: documentsSelector(state),
  loadingDocuments: loadingDocumentsSelector(state)
});

const mapDispatchToProps = {
  fetchDocuments,
  fetchFolders,
  uploadDocument,
  updateDocument,
  deleteDocument
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
