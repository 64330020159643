import React, { Component } from 'react';
import { Row, Col, Spin, Icon } from 'antd';
import { connect } from 'react-redux';
import ProcessBar from '../../../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../../../components/Card/Card';
import InfoBox from '../../../../../../components/InfoBox/InfoBox';
import InformationPanel from '../../../../../../components/InformationPanel/InformationPanel';
import RiskEvaluation from '../../components/RiskEvaluation';
import {
  fetchRiskCategories,
  fetchRiskPresets,
  fetchRiskLikelihoodPresets,
  fetchMitigationStrategies,
  fetchMitigationStatus,
  fetchRisks
} from '../../RiskAction';
import { fetchContact } from '../../../../../Organisation/scenes/Contact/ContactAction';
import { orgIdSelector } from '../../../../../Organisation/OrganisationSelectors';
import { planIdSelector } from '../../../Plan/PlanSelectors';
import { contactListSelector } from '../../../../../Organisation/scenes/Contact/ContactSelectors';
import {
  risksSelector,
  allRisksSelector,
  riskPresetsSelector,
  riskCategoriesSelector,
  selectedRisksSelector
} from '../../RiskSelectors';

const THEME_COLOR = '#a8c08f';
const DEFAULT_INFO_TITLE = 'Risk and Threat Assessment';
const DEFAULT_INFO_DESCRIPTION = (
  <>
    <p>
      This screen will allow you to evaluate the likelihood and severity of risks ranging from your reputation through
      to security and human. If you believe that any of the suggested risks exist to your organization simply toggle the
      Yes/No indicator and complete the questions asked.
    </p>
    <p>You are able to set your mitigation strategy, the status of the strategy, follow up dates and add commentary.</p>
    <p>
      <strong>Note:</strong> You should speak with your Insurance Broker about any risks you believe that you can
      &apos;Transfer&apos; to an Insurer
    </p>
  </>
);

class Risk extends Component {
  state = {
    loaded: false,
    infoTitle: DEFAULT_INFO_TITLE,
    infoDescription: DEFAULT_INFO_DESCRIPTION
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {
      orgId,
      planId,
      fetchRisks,
      fetchRiskCategories,
      fetchRiskPresets,
      fetchRiskLikelihoodPresets,
      fetchMitigationStrategies,
      fetchMitigationStatus,
      fetchContact
    } = this.props;
    await fetchRiskCategories();
    await fetchRiskPresets();
    await fetchRiskLikelihoodPresets();
    await fetchMitigationStrategies();
    await fetchMitigationStatus();
    await fetchContact(orgId);
    await fetchRisks(planId);
    this.setState({ loaded: true });
  };

  onInfoChange = (infoTitle, infoDescription) => {
    this.setState({ infoTitle, infoDescription });
  };

  render() {
    const { allRisks = {}, selectedRisks, riskCategories = [], riskRequest } = this.props;
    const { loaded, infoTitle, infoDescription } = this.state;

    return (
      <>
        <ProcessBar
          color={THEME_COLOR}
          title="Risk and Threat Assessment"
          prev="/dashboard/risk/exposures"
          next="/dashboard/risk/register"
          icon={
            <Icon
              type="info-circle-o"
              className="process-bar-info-icon"
              onClick={() => this.onInfoChange(DEFAULT_INFO_TITLE, DEFAULT_INFO_DESCRIPTION)}
            />
          }
        />
        <InfoBox color={THEME_COLOR} message={DEFAULT_INFO_DESCRIPTION} />
        {loaded ? (
          <>
            <Row gutter={20} className="risk-container">
              <Col xs={24} sm={24} md={24} lg={24} xl={19} xxl={20}>
                <Spin spinning={riskRequest}>
                  <Card color={THEME_COLOR}>
                    {riskCategories.map(category => (
                      <RiskEvaluation
                        key={category.riskCategoryId}
                        risks={allRisks[category.riskCategoryId]}
                        riskCategory={category}
                        selectedRisks={selectedRisks[category.riskCategoryId]}
                        onInfoChange={this.onInfoChange}
                      />
                    ))}
                  </Card>
                </Spin>
              </Col>
              <Col xs={0} sm={0} md={0} lg={0} xl={5} xxl={4}>
                <InformationPanel title={infoTitle} description={infoDescription} />
              </Col>
            </Row>
          </>
        ) : (
          <Spin tip="Loading Risks..." className="loading" />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  planId: planIdSelector(state),
  contactList: contactListSelector(state),
  risks: risksSelector(state),
  allRisks: allRisksSelector(state),
  selectedRisks: selectedRisksSelector(state),
  riskPresets: riskPresetsSelector(state),
  riskCategories: riskCategoriesSelector(state),
  riskRequest: state.riskReducer.riskRequest
});

const mapDispatchToProps = {
  fetchRiskCategories,
  fetchRiskPresets,
  fetchRiskLikelihoodPresets,
  fetchMitigationStrategies,
  fetchMitigationStatus,
  fetchRisks,
  fetchContact
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Risk);
