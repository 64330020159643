import React from 'react';
import { Row, Col, Icon } from 'antd';
import classNames from 'classnames';
import ProcessBar from '../../../../components/ProcessBar/ProcessBar';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import './InvocationLevel.scss';

export default ({ embedded = false, style }) => {
  const span = embedded ? { span: 8 } : { xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 8 };
  return (
    <>
      {!embedded && <ProcessBar title="Activation Levels" />}
      {!embedded && (
        <InfoBox message="Incidents are defined to be one of three levels. Typically a full activation of the Business Continuity Plan only occurs at level 2 or 3 incidents. Following an incident, an assessment should be made on the level of severity using the diagram below as a guide." />
      )}
      <div className="invocation-level-container" style={style}>
        <Row gutter={20}>
          <Col {...span} className="invocation-level-col">
            <div className={classNames('invocation-level-card', { embedded })}>
              <h2 className="invocation-level-title">Level 1</h2>
              <div className="invocation-level-content">
                <Icon type="fire" />
                <p>
                  <strong>Physical Environment and/or Operational</strong>
                </p>
                <p>
                  Minor effects on physical environment and/or operations and limited or no impact on business process
                  Recovery Time Objectives
                </p>
                <Icon type="dislike" />
                <p>
                  <strong>Reputation</strong>
                </p>
                <p>Minor, adverse complaints and local public or media attention.</p>
                <p>Minor hardship from regulator.</p>
              </div>
              <div className="invocation-level-note">
                <p>
                  <strong>Note:</strong>
                </p>
                <p>
                  <i>Business as usual and can be managed on site.</i>
                </p>
              </div>
            </div>
          </Col>
          <Col {...span} className="invocation-level-col">
            <div className={classNames('invocation-level-card', { embedded })}>
              <h2 className="invocation-level-title">Level 2</h2>
              <div className="invocation-level-content">
                <Icon type="fire" />
                <p>
                  <strong>Physical Environment and/or Operational</strong>
                </p>
                <p>
                  One or more business processes effects on physical environment and/or operations and moderate to major
                  impacts on business process Recovery Time Objectives
                </p>
                <Icon type="dislike" />
                <p>
                  <strong>Reputation</strong>
                </p>
                <p>
                  Attention from local media and/or heightened concern by local community. Possible industry credentials
                  affected.
                </p>
                <p>Moderate to major hardship from regulator.</p>
              </div>
              <div className="invocation-level-note">
                <p>
                  <strong>Note:</strong>
                </p>
                <p>
                  <i>The business can be managed on site but may require third party support.</i>
                </p>
              </div>
            </div>
          </Col>
          <Col {...span} className="invocation-level-col">
            <div className={classNames('invocation-level-card', { embedded })}>
              <h2 className="invocation-level-title">Level 3</h2>
              <div className="invocation-level-content">
                <Icon type="fire" />
                <p>
                  <strong>Physical Environment and/or Operational</strong>
                </p>
                <p>
                  Multiple Business Units affected with widespread and severe effects on physical environment and/or
                  operations and severe impacts on business process Recovery Time Objectives
                </p>
                <Icon type="dislike" />
                <p>
                  <strong>Reputation</strong>
                </p>
                <p>Widespread adverse media/public attention. Management credentials are possibly tarnished.</p>
                <p>
                  Major breach of regulation with potential major fine and/or investigation and prosecution by
                  authorities.
                </p>
              </div>
              <div className="invocation-level-note">
                <p>
                  <strong>Note:</strong>
                </p>
                <p>
                  <i>
                    The business cannot be managed on site and may require external support and assistance. The plan
                    requires immediate activation.
                  </i>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
