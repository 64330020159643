import React from 'react';
import { connect } from 'react-redux';
import Logo from '../../../../../../../../components/Logo/Logo';
import { businessNameSelector, businessLogoSelector } from '../../../../../../../Organisation/OrganisationSelectors';
import { planNameSelector } from '../../../../../Plan/PlanSelectors';
import { riskReportDateSelector } from '../../../../RiskSelectors';

const Cover = ({ businessName, businessLogo, planName, reportDate }) => (
  <>
    <section>
      <div className="risk-report-preview-cover">
        <Logo className="lmi-logo" />
        <div className="organisation">
          <div className="organisation-logo" style={{ backgroundImage: `url(${businessLogo}` }} />
          <div className="organisation-name">{businessName || 'PREPARED FOR\nORGANISATION\nNAME'}</div>
          <div className="organisation-date">{`DATE ISSUED ${reportDate}`}</div>
        </div>
        <div className="title">
          <h3>{planName}</h3>
          <h1>RISK</h1>
          <h2>
            <strong>ANALYSIS</strong>
            <br />
            REPORT
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div className="risk-report-preview-disclaimer">
        <div className="disclaimer">
          <div className="disclaimer-content">
            <h2>Disclaimer</h2>
            <p>
              The materials contained within this report constitute general information only and should only be used as
              a guide. It is highly recommended that you speak to an insurance professional when setting your insurance
              program as each policy should be tailored to your specific needs.
            </p>
            <p>
              This document is not to be reproduced or modified without the express permission of the LMI Group. The
              information contained within the document is the property of The LMI Group and is intended for the
              addressee only. For a copy of our full terms of use, please visit our website: www.lmigroup.com AFS
              License No: 283777
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
);

const mapStateToProps = state => ({
  businessName: businessNameSelector(state),
  businessLogo: businessLogoSelector(state),
  planName: planNameSelector(state),
  reportDate: riskReportDateSelector(state)
});

export default connect(mapStateToProps)(Cover);
