import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table, Button, Spin, message } from 'antd';
import * as HttpStatus from 'http-status-codes';
import ProcessBar from '../../../../../../components/ProcessBar/ProcessBar';
import Card from '../../../../../../components/Card/Card';
import InfoBox from '../../../../../../components/InfoBox/InfoBox';
import InformationPanel from '../../../../../../components/InformationPanel/InformationPanel';
import Slider from '../../../../../../components/Slider/Slider';
import { fetchTacticalBia, createTacticalBia, updateTacticalBia } from '../../BiaAction';
import { fetchBranch } from '../../../../../Organisation/scenes/Branch/BranchAction';
import { orgIdSelector } from '../../../../../Organisation/OrganisationSelectors';
import { planIdSelector, mtpdDaysSelector } from '../../../Plan/PlanSelectors';
import { departmentListSelector } from '../../../Department/DepartmentSelectors';
import { tacticalBiasSelector, loadingTacticalBiasSelector } from '../../BiaSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../../constants';

const BIA_STEP_EXPLANATION = {
  title: 'Recovery Time Objective and Recovery Point Objective (RTO-RPO)',
  description:
    'For each Business Unit you need to identify what the Recovery Time Objective and Recovery Point Objective (RTO-RPO) will be.'
};

class TacticalBia extends Component {
  state = { data: null };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { orgId, planId, fetchBranch, fetchTacticalBia } = this.props;
    await fetchBranch(orgId);
    await fetchTacticalBia(planId);
    const { departmentList, tacticalBias, mtpdDays } = this.props;
    const data = departmentList.map(departmentObj => {
      const { departmentId, name } = departmentObj;
      const tacticalBia = tacticalBias.find(tacticalBiaObj => tacticalBiaObj.departmentId === departmentId);
      const tacticalBiaId = tacticalBia ? tacticalBia.tacticalBiaId : null;
      const rto = tacticalBia ? Math.min(tacticalBia.rto, mtpdDays) : 0;
      const rpo = tacticalBia ? Math.min(tacticalBia.rpo, mtpdDays) : 0;
      return { departmentId, name, tacticalBiaId, rto, rpo };
    });
    this.setState({ data });
  };

  onRtoChange = (value, departmentId) => {
    const { data } = this.state;
    const index = data.findIndex(item => item.departmentId === departmentId);
    data[index].rto = value;
    this.setState({ data });
  };

  onRpoChange = (value, departmentId) => {
    const { data } = this.state;
    const index = data.findIndex(item => item.departmentId === departmentId);
    data[index].rpo = value;
    this.setState({ data });
  };

  onSave = async () => {
    const { planId, fetchTacticalBia, createTacticalBia, updateTacticalBia } = this.props;
    const { data } = this.state;
    const tacticalBiasToCreate = [];
    const tacticalBiasToUpdate = [];
    data.forEach(obj => {
      if (obj.tacticalBiaId) {
        tacticalBiasToUpdate.push(obj);
      } else {
        tacticalBiasToCreate.push(obj);
      }
    });
    const promises = [];
    if (tacticalBiasToCreate.length > 0) {
      promises.push(createTacticalBia(planId, tacticalBiasToCreate));
    }
    if (tacticalBiasToUpdate.length > 0) {
      promises.push(updateTacticalBia(planId, tacticalBiasToUpdate));
    }
    if (promises.length > 0) {
      try {
        await Promise.all(promises);
        await fetchTacticalBia(planId);
        message.success(MSG_SAVE_SUCCESS);
      } catch (err) {
        switch (err.status) {
          case HttpStatus.PAYMENT_REQUIRED:
            message.error(MSG_PAYMENT_REQUIRED);
            break;
          default:
            message.error(MSG_ERROR);
        }
      }
    }
  };

  render() {
    const { mtpdDays, loading } = this.props;
    const { data } = this.state;
    const columns = [
      {
        key: 'department',
        title: 'Business Units',
        width: '20%',
        render: (text, record) => <span>{record.name}</span>
      },
      {
        key: 'rpo',
        title: <div className="table-header-cell-rpo">RPO</div>,
        width: '15%',
        render: (text, record) => <div>{record.rpo > 1 ? `${record.rpo} days` : `${record.rpo} day`}</div>
      },
      {
        key: 'rto_slider',
        title: (
          <div>
            Range from: MIN (0 hours) - MAX
            <span className="table-header-cell-mtpd"> (MTPD: {mtpdDays} days)</span>
          </div>
        ),
        colSpan: 3,
        render: (text, record) => (
          <Slider
            min={0}
            max={mtpdDays}
            value={record.rpo}
            color="#aabd8c"
            reversed
            onChange={value => this.onRpoChange(value, record.departmentId)}
            style={{ marginRight: '-16px' }}
          />
        )
      },
      {
        key: 'divider',
        colSpan: 0,
        width: 1,
        className: 'table-cell-divider'
      },
      {
        key: 'rpo_slider',
        colSpan: 0,
        render: (text, record) => (
          <Slider
            min={0}
            max={mtpdDays}
            value={record.rto}
            color="#dd6031"
            onChange={value => this.onRtoChange(value, record.departmentId)}
            style={{ marginLeft: '-16px' }}
          />
        )
      },
      // {
      //   key: 'rto_slider',
      //   title: (
      //     <div>
      //       Range from: MIN (0 hours) - MAX<span className="table-header-cell-mtpd"> (MTPD: {mtpdDays} days)</span>
      //     </div>
      //   ),
      //   render: (text, record) => (
      //     <div style={{ display: 'flex' }}>
      //       <AntSlider
      //         min={0}
      //         max={mtpdDays}
      //         value={record.rto}
      //         disabled={loading}
      //         tipFormatter={value => `RTO: ${value} days`}
      //         onChange={value => this.onRtoChange(value, record.departmentId)}
      //         className="rto-slider"
      //       />
      //       <AntSlider
      //         min={0}
      //         max={mtpdDays}
      //         value={record.rpo}
      //         disabled={loading}
      //         tipFormatter={value => `RPO: ${value} days`}
      //         onChange={value => this.onRpoChange(value, record.departmentId)}
      //         className="rpo-slider"
      //       />
      //     </div>
      //   )
      // },
      {
        key: 'rto',
        title: <div className="table-header-cell-rto">RTO</div>,
        width: '15%',
        render: (text, record) => <div>{record.rto > 1 ? `${record.rto} days` : `${record.rto} day`}</div>
      }
    ];

    return (
      <>
        <ProcessBar
          color="#343579"
          title="Tactical Business Impact Analysis"
          prev="/dashboard/business-impact-analysis/strategic"
          next="/dashboard/business-impact-analysis/operational"
        />
        <InfoBox
          color="#343579"
          message="For each Business Unit you need to identify what the Recovery Time Objective and Recovery Point Objective (RTO-RPO) will be."
          more
          video={
            <iframe
              title="rto-rpo-info-video"
              src="https://www.youtube.com/embed/guYNMFGYqbk?rel=0&amp;showinfo=0"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              className="responsive-video"
            />
          }
        />
        {data ? (
          <div className="bia-container">
            <Row gutter={20}>
              <Col span={20}>
                <Card color="#343579" title="Recovery Time Objective and Recovery Point Objective (RTO-RPO)">
                  <div className="tactical-bia-inner">
                    <Table
                      columns={columns}
                      dataSource={data}
                      rowKey={record => record.departmentId}
                      pagination={false}
                      locale={{ emptyText: 'No Business Units' }}
                      scroll={{ x: 550 }}
                    />
                    <div className="btn-container center">
                      <Button
                        type="primary"
                        className="bia-btn"
                        loading={loading}
                        onClick={this.onSave}
                        disabled={mtpdDays === 0 || data.length === 0}
                      >
                        {loading ? 'Saving' : 'Save'}
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={4}>
                <InformationPanel {...BIA_STEP_EXPLANATION} />
              </Col>
            </Row>
          </div>
        ) : (
          <Spin tip="Loading..." className="loading" />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  planId: planIdSelector(state),
  mtpdDays: mtpdDaysSelector(state),
  departmentList: departmentListSelector(state),
  tacticalBias: tacticalBiasSelector(state),
  loading: loadingTacticalBiasSelector(state)
});

const mapDispatchToProps = {
  fetchBranch,
  fetchTacticalBia,
  createTacticalBia,
  updateTacticalBia
};

export default connect(mapStateToProps, mapDispatchToProps)(TacticalBia);
