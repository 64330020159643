import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout/Layout';
import ResetPasswordForm from '../components/Login/components/ResetPasswordForm';
import { sendVerificationEmail, updatePassword, resetPasswordRequest } from '../components/Login/LoginAction';

class ResetPassword extends Component {
  render() {
    return (
      <Layout>
        <ResetPasswordForm {...this.props} />
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ ...state.loginReducer });

const mapDispatchToProps = {
  sendVerificationEmail,
  updatePassword,
  setResetPasswordRequest: resetPasswordRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
