import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Menu, Avatar, Icon } from 'antd';
import { userAvatarSelector, personNameSelector } from '../../scenes/User/UserSelectors';

const { SubMenu } = Menu;

const Sider = ({ menuItems, selectedKey, defaultOpenKeys, userAvatar, personName, onLogout }) => (
  <>
    <div className="sider-top">
      <Avatar size="large" icon="user" src={userAvatar} className="sider-top-logo" />
      <div className={classNames('sider-top-title')}>{personName}</div>
    </div>
    <Menu
      mode="inline"
      inlineIndent={8}
      selectedKeys={[selectedKey]}
      defaultOpenKeys={defaultOpenKeys}
      className="sider-menu"
    >
      {menuItems.map(item => {
        if (item.type === 'sub-menu') {
          return (
            <SubMenu
              key={item.name}
              title={
                <span>
                  <Icon type={item.icon} />
                  <span>{item.name}</span>
                </span>
              }
            >
              {item.submenus.map(subitem => (
                <Menu.Item key={subitem.route} title={subitem.name}>
                  <Link to={subitem.route}>{subitem.name}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={item.route} title={item.name}>
            <Link to={item.route}>
              <Icon type={item.icon} />
              <span>{item.name}</span>
            </Link>
          </Menu.Item>
        );
      })}
      <Menu.Divider />
      <Menu.Item key="/contact-us">
        <Link to="/contact-us">
          <Icon type="form" />
          <span>Contact Us</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="logout">
        {/* eslint-disable-next-line */}
        <a onClick={onLogout}>
          <Icon type="logout" />
          <span>Log out</span>
        </a>
      </Menu.Item>
    </Menu>
  </>
);

const mapStateToProps = state => ({
  userAvatar: userAvatarSelector(state),
  personName: personNameSelector(state)
});

export default connect(mapStateToProps)(Sider);
