import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Select, Button } from 'antd';
import _ from 'lodash';
import { withOnFocus } from '../../../../../components/HOC';
import { branchesSelector } from '../../../../Organisation/scenes/Branch/BranchSelectors';
import { contactListSelector } from '../../../../Organisation/scenes/Contact/ContactSelectors';
import explanations from '../data/explanations.json';
import { loadingPlansSelector, currenciesSelector } from '../PlanSelectors';

const FormItem = Form.Item;
const { Option } = Select;

const PLAN_ACTIONS = Object.freeze({
  CREATE: 'CREATE',
  CLONE: 'CLONE',
  EDIT: 'EDIT',
  RENEW: 'ERNEW'
});

class PlanEdit extends Component {
  constructor(props) {
    super(props);
    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.SelectWithOnFocus = withOnFocus(Select, props.onExplanationChange);
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, createPlan, clonePlan, updatePlan, renewPlan, close } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const { plan, action } = this.props;
        switch (action) {
          case PLAN_ACTIONS.CREATE: {
            createPlan(values);
            break;
          }
          case PLAN_ACTIONS.CLONE: {
            clonePlan({ ...values, planId: plan.planId, subscriptionKey: values.key });
            break;
          }
          case PLAN_ACTIONS.EDIT: {
            updatePlan(plan.planId, values);
            break;
          }
          case PLAN_ACTIONS.RENEW: {
            renewPlan(plan.planId, values);
            break;
          }
          default:
            break;
        }
        close();
      }
    });
  };

  render() {
    const { InputWithOnFocus, SelectWithOnFocus } = this;
    const { form, plan = {}, action, branches = [], contactList = [], currencies = [], loadingPlans } = this.props;
    const { getFieldDecorator } = form;
    const currency = _.find(currencies, { symbol: plan.currency });
    const initialCurrencyCode = currency ? currency.code : 'AUD';

    return (
      <Form>
        {action !== PLAN_ACTIONS.RENEW && (
          <FormItem label="Plan Name">
            {getFieldDecorator('name', {
              initialValue: plan.name,
              rules: [{ required: true, message: 'Please input the name.' }]
            })(<InputWithOnFocus placeholder="Name" disabled={loadingPlans} explanation={explanations.name} />)}
          </FormItem>
        )}
        {action !== PLAN_ACTIONS.EDIT && action !== PLAN_ACTIONS.RENEW && (
          <FormItem label="Business Location">
            {getFieldDecorator('branchId', {
              rules: [{ required: true, message: 'Please select the business location.' }]
            })(
              <SelectWithOnFocus
                placeholder="Business Location"
                disabled={loadingPlans}
                explanation={explanations.branch}
              >
                {branches.map(obj => (
                  <Option key={obj.name} value={obj.branchId}>
                    {obj.name}
                  </Option>
                ))}
              </SelectWithOnFocus>
            )}
          </FormItem>
        )}
        {action !== PLAN_ACTIONS.EDIT && (
          <FormItem label="Subscription Key or Promo Code">
            {getFieldDecorator('key', {
              rules: [{ required: true, message: 'Please input a valid Subscription Key or Promo Code.' }]
            })(
              <InputWithOnFocus
                placeholder="Subscription Key or Promo Code"
                disabled={loadingPlans}
                explanation={explanations.subscriptionKey}
              />
            )}
          </FormItem>
        )}
        { action !== PLAN_ACTIONS.RENEW && action !== PLAN_ACTIONS.CLONE && (
          <FormItem label="Currency">
            {getFieldDecorator('currency', {
              rules: [{ required: true, message: 'Please select currency.' }],
              initialValue: initialCurrencyCode
            })(
              <SelectWithOnFocus placeholder="Currency" disabled={loadingPlans} explanation={explanations.currency}>
                {currencies.map(obj => (
                  <Option key={obj.code} value={obj.code}>
                    {obj.name}
                  </Option>
                ))}
              </SelectWithOnFocus>
            )}
          </FormItem>
        )}
        {action !== PLAN_ACTIONS.EDIT && action !== PLAN_ACTIONS.RENEW && (
          <FormItem label="Accountable Contacts">
            {getFieldDecorator('contacts')(
              <SelectWithOnFocus
                mode="multiple"
                placeholder="Add accountable members"
                disabled={loadingPlans}
                showSearch
                notFoundContent="Not Found"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
                optionLabelProp="label"
                explanation={explanations.contact}
              >
                {contactList.map(contact => (
                  <Option
                    key={contact.email}
                    value={contact.contactId}
                    label={`${contact.firstName} ${contact.lastName}`}
                  >
                    {contact.jobTitle
                      ? `${contact.firstName} ${contact.lastName} - ${contact.jobTitle}`
                      : `${contact.firstName} ${contact.lastName}`}
                  </Option>
                ))}
              </SelectWithOnFocus>
            )}
          </FormItem>
        )}
        <FormItem>
          <Button type="primary" block loading={loadingPlans} onClick={this.onSubmit}>
            {loadingPlans ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  branches: branchesSelector(state),
  contactList: contactListSelector(state),
  loadingPlans: loadingPlansSelector(state),
  currencies: currenciesSelector(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(PlanEdit));

export { PLAN_ACTIONS };
