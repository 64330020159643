import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import Card from '../../../../../components/Card/Card';
import Modal from '../../../../../components/Modal/Modal';
import InfoBox from '../../../../../components/InfoBox/InfoBox';
import PolicyMemberEdit from './PolicyMemberEdit';
import { fetchContact } from '../../Contact/ContactAction';
import { businessInfoSelector, orgIdSelector } from '../../../OrganisationSelectors';

const PolicyMember = ({ color = '#f1c400', title, members = [], businessInfo, orgId, fetchContact, addMember }) => {
  const [memberContacts, setMemberContacts] = useState(members);

  useEffect(() => {
    fetchContact(orgId);
  }, [orgId, fetchContact]);

  const onAdd = addedMemberContacts => {
    setMemberContacts(addedMemberContacts);
    addMember(addedMemberContacts);
  };

  return (
    <>
      <Card
        color={color}
        title={title}
        description={`Click the "Add new" button to assign your senior executives who will ultimately be responsible for the implementation or your Business Continuity Plan.`}
      >
        <InfoBox
          color={color}
          type="fill"
          message="Senior executives will be accountable for critical business functions, and are required to oversee the development, maintenance and testing of Business Continuity Plans. They are to ensure that risks are analyzed and that essential services are maintained at an acceptable level during a major disruptive event, and restored to full functionality within an acceptable timeframe. It is the social and economic accountability that the business has, to all its stakeholders, and cannot be pushed to lower levels within the organization."
        />
        <Row gutter={20} className="policy-members">
          {memberContacts.map(obj => (
            <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={6} key={obj.contactId}>
              <div className="policy-members-item">
                <i className="policy-icon fa fa-user" style={{ color }} />
                <div className="policy-tile-subtitle" title={businessInfo.name}>
                  {businessInfo.name}
                </div>
                <div className="policy-tile-title" title={obj.jobTitle}>
                  {obj.jobTitle ? obj.jobTitle : 'No Job Title'}
                </div>
                <div className="policy-tile-text" style={{ color }}>{`${obj.firstName} ${obj.lastName}`}</div>
              </div>
            </Col>
          ))}
        </Row>
        <div className="btn-container">
          <Modal
            title="Add new member"
            toggle={show => (
              <Button
                type="primary"
                icon={memberContacts.length === 0 ? 'plus' : 'edit'}
                onClick={show}
                style={{ backgroundColor: color, borderColor: color }}
              >
                {memberContacts.length === 0 ? 'Add new' : 'Edit'}
              </Button>
            )}
          >
            {hide => <PolicyMemberEdit memberContacts={memberContacts} addMember={onAdd} close={hide} />}
          </Modal>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  businessInfo: businessInfoSelector(state),
  orgId: orgIdSelector(state)
});

const mapDispatchToProps = { fetchContact };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyMember);
