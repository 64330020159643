import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout/Layout';

const style = {
  container: {
    padding: '80px 0',
    textAlign: 'center'
  },
  h1: {
    fontSize: '4vh',
    fontWeight: 400,
    textTransform: 'uppercase'
  },
  h2: {
    display: 'inline-block',
    padding: '40px 0',
    color: '#f1c400',
    fontSize: '7vh',
    fontWeight: 400,
    border: 'solid #f1c400',
    borderWidth: '1px 0'
  },
  p: {
    margin: '15px 0',
    fontSize: '16px'
  }
};

export default () => (
  <Layout>
    <div style={style.container}>
      <h1 style={style.h1}>Something Just Went Wrong !</h1>
      <h2 style={style.h2}>404 Error</h2>
      <p style={style.p}>For some reason the page you requested could not be found on our server.</p>
      <p style={style.p}>
        Go <Link to="/">Home</Link> or <Link to="/dashboard">Dashboard</Link>
      </p>
    </div>
  </Layout>
);
