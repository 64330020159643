import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Alert } from 'antd';
import CurrencyFormat from 'react-currency-format';

export default ({ purchaser, summary }) => (
  <div className="shopping-cart">
    <Row type="flex" justify="end">
      <Col style={{ marginBottom: '20px' }}>
        <Alert
          type="success"
          showIcon
          message="Payment Complete"
          description={
            <>
              <p>
                Thank you, your payment has been successful and your purchase is now confirmed. The tax invoice will be
                sent to <strong>{purchaser}</strong>.
              </p>
              <p>
                You can view your purchased licences and transaction history in{' '}
                <Link to="/admin/transactions">Admin Page</Link>.
              </p>
            </>
          }
        />
      </Col>
      <Col xs={24} sm={10}>
        <div className="fee">
          <span>Total Subscription</span>
          <strong><CurrencyFormat value={summary.totalSubscription} displayType='text' thousandSeparator prefix='AU$ ' /></strong>
        </div>
        {summary.discount && (
          <div className="fee discount">
            <span>Discount</span>
            <strong><CurrencyFormat value={summary.discount} displayType='text' thousandSeparator prefix='-AU$ ' /></strong>
          </div>
        )}
        <div className="fee">
          <span>Sub Total</span>
          <strong><CurrencyFormat value={summary.subTotal} displayType='text' thousandSeparator prefix='AU$ ' /></strong>
        </div>
        <div className="fee">
          <span>Plus GST</span>
          <strong><CurrencyFormat value={summary.tax} displayType='text' thousandSeparator prefix='AU$ ' /></strong>
        </div>
        <div className="fee">
          <span>Total Price</span>
          <strong><CurrencyFormat value={summary.total} displayType='text' thousandSeparator prefix='AU$ ' /></strong>
        </div>
        <Button type="primary" className="btn-pay">
          <Link to="/dashboard/plans">Go to Create New Plans</Link>
        </Button>
        <Button type="primary" className="btn-pay">
          <Link to="/admin/subscriptions">View Subscription Keys</Link>
        </Button>
      </Col>
    </Row>
  </div>
);
