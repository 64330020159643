import React from 'react';
import { Menu, Icon } from 'antd';

const { SubMenu } = Menu;
const MENU_KEY_ORG = '/organization';
const MENU_KEY_DASHBOARD = '/dashboard';

export default ({ collapsed, selectedKey, onChange }) =>
  collapsed ? (
    <Menu mode="inline" selectedKeys={[selectedKey]} onClick={onChange} className="menu-switch">
      <SubMenu key="switch" title={<Icon type={selectedKey === MENU_KEY_ORG ? 'home' : 'dashboard'} />}>
        <Menu.Item key={MENU_KEY_ORG}>
          <Icon type="home" />
          <span>Organization</span>
        </Menu.Item>
        <Menu.Item key={MENU_KEY_DASHBOARD}>
          <Icon type="dashboard" />
          <span>Plan</span>
        </Menu.Item>
      </SubMenu>
    </Menu>
  ) : (
    <Menu mode="horizontal" selectedKeys={[selectedKey]} onClick={onChange} className="menu-switch">
      <Menu.Item key={MENU_KEY_ORG}>Organization</Menu.Item>
      <Menu.Item key={MENU_KEY_DASHBOARD}>Plan</Menu.Item>
    </Menu>
  );
