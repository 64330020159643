/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import Transition from 'react-transition-group/Transition';
import { Row, Col, Carousel, Button } from 'antd';
import ga from 'react-ga';
import RSSParser from 'rss-parser';
import Layout from '../../components/Layout/Layout';
import NewsContainer from '../../components/newsContainer/NewsContainer';
import './Landing.scss';
import laptopScreen from './images/laptop_screen.jpg';
import bannerBottom from './images/banner_bottom.jpg';
import bannerBg from './images/banner_bg.jpg';
import ring from './images/ring.png';
import steps from './images/steps.png';

// RSS
const RSS_URL = 'https://lmigroup.io/feed/';

const fadeInDropFx = {
  default: {
    transition: 'transform 800ms ease-out, opacity 800ms ease-out',
    transform: 'translateY(-100%)',
    opacity: 0
  },
  transition: {
    entering: { transform: 'translateY(-100%)', opacity: 0 },
    entered: { transform: 'translateY(0)', opacity: 1 }
  }
};

const fadeInFx = {
  default: {
    transition: 'opacity 1200ms ease-out',
    opacity: 0
  },
  transition: {
    entering: { opacity: 0 },
    entered: { opacity: 1 }
  }
};

const rotateFx = {
  default: {
    transition: 'transform 3600ms ease-out, opacity 2000ms ease-in',
    transform: 'rotateZ(-360deg)',
    opacity: 0
  },
  transition: {
    entering: { transform: 'rotateZ(-360deg)', opacity: 0 },
    entered: { transform: 'rotateZ(0deg)', opacity: 1 }
  }
};

export default class Landing extends Component {
  constructor() {
    super();
    this.slider = React.createRef();
    this.sliderLatestNews = React.createRef();
    this.state = {
      componentMounted: false,
      innerWidth: window.innerWidth,
      newsList: []
    };
  }

  componentDidMount() {
    this.setState({ componentMounted: true });
    this.loadLatestNews();
    ga.pageview(this.props.location.pathname);
  }

  loadLatestNews = async () => {
    const parser = new RSSParser();
    parser.parseURL(RSS_URL, (err, feed) => {
      if (!err) {
        this.setState({
          newsList: feed.items
        });
      }
    });
  };

  onResize = () =>
    this.setState({
      innerWidth: window.innerWidth
    });

  render() {
    window.onresize = this.onResize;
    const { innerWidth, componentMounted, newsList } = this.state;
    return (
      <Layout>
        <div className="landing-container">
          <div className="landing-banner" style={{ backgroundImage: `url(${bannerBg})` }}>
            <div className="landing-banner-text">
              <Transition in={componentMounted} timeout={100}>
                {state => (
                  <h1 style={{ ...fadeInDropFx.default, ...fadeInDropFx.transition[state] }}>
                    What threats
                    <br />
                    to your business
                  </h1>
                )}
              </Transition>
              <Transition in={componentMounted} timeout={500}>
                {state => (
                  <h2 style={{ ...fadeInFx.default, ...fadeInFx.transition[state] }}>KEEP YOU AWAKE AT NIGHT?</h2>
                )}
              </Transition>
              <Transition in={componentMounted} timeout={1200}>
                {state => (
                  <p className="slogan" style={{ ...fadeInFx.default, ...fadeInFx.transition[state] }}>
                    Let the LMI ContinuityCoach assist you in identifying your risk and planning your recovery.
                  </p>
                )}
              </Transition>
            </div>
            <Transition in={componentMounted} timeout={800}>
              {state => (
                <img
                  className="steps"
                  src={steps}
                  alt="steps"
                  style={{ ...fadeInFx.default, ...fadeInFx.transition[state] }}
                />
              )}
            </Transition>
            <Transition in={componentMounted} timeout={650}>
              {state => (
                <img
                  className="ring"
                  src={ring}
                  alt="ring"
                  style={{ ...rotateFx.default, ...rotateFx.transition[state] }}
                />
              )}
            </Transition>
          </div>
          <Row type="flex" gutter={32} justify="center" align="middle" className="landing-section-two">
            <Col xs={22} sm={20} md={20} lg={8} xl={8} xxl={6} className="landing-section-two-text">
              <h2>What is Business</h2>
              <h1>Continuity Management</h1>
              <p>
                Business Continuity enables you to identify potential threats and their impacts to your business
                operations.
              </p>
              <p>
                With the ability that comes from a defined strategy, the pro-active management of these threats allows
                your organization to protect and continue the value-creating operations in the event of disruption.
              </p>
            </Col>
            <Col xs={22} sm={20} md={20} lg={12} xl={10} xxl={10}>
              <div className="responsive-video-container">
                <iframe
                  title="intro"
                  src="https://www.youtube.com/embed/U-EoStHt4-g?rel=0&amp;showinfo=0"
                  // src="https://www.youtube.com/embed/-9BYUssSBaE?rel=0&amp;showinfo=0"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  className="responsive-video"
                />
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center" className="landing-section-three">
            <Col xs={20} sm={20} md={22} lg={20} xl={18} xxl={16}>
              <h2>Why choose</h2>
              <h1>ContinuityCoach?</h1>
              <p>
                With ContinuityCoach you have world class experts in business continuity management planning and crisis
                recovery experts coaching you through every step of the process.
              </p>
              <p>
                ContinuityCoach.com is structured and is updated where appropriate to reflect current best Business
                Continuity Management practice as stated in Business Continuity Institute Good Practice Guidelines &
                International Organization for Standardization ISO 22301 Societal Security
                <br />
                --- Business Continuity Management Systems --- Requirements.
              </p>
              <ul>
                <li>
                  <div className="icon" />
                  <h3>VISUALISE</h3>
                  <p>Identifying and visualising the many aspects of risk that may affect your business</p>
                </li>
                <li>
                  <div className="icon" />
                  <h3>UNDERSTAND</h3>
                  <p>Delivering a broad understanding of your organizational threats and its business imperatives</p>
                </li>
                <li>
                  <div className="icon" />
                  <h3>IMPROVE</h3>
                  <p>Identifying opportunities to improve operational processes</p>
                </li>
                <li>
                  <div className="icon" />
                  <h3>PLAN</h3>
                  <p>Providing plans to recover your crisis AREAS</p>
                </li>
                <li>
                  <div className="icon" />
                  <h3>TEST</h3>
                  <p>Scenarios and testing levels for self-evaluation</p>
                </li>
              </ul>
            </Col>
          </Row>
          <section className="landing-section-four">
            <Row type="flex" justify="center">
              <Col xs={20} sm={20} md={22} lg={20} xl={18} xxl={16}>
                <h2>The most advanced way to</h2>
                <h1>help your business recover from a crisis</h1>
                <p>
                  LMI ContinuityCoach is modelled on International Organization Standards 22301 standards and the
                  Business Continuity Institute (&apos;BCI&apos;) Good Practice Guidelines (GPG: 2013/2018). It is
                  designed to be an easy-to-use, cost effective and highly available Web-based Continuity tool.
                </p>
                <p>
                  Whilst recovery rates vary, what is undeniable is that a Business Continuity Management Plan
                  (&apos;BCP&apos;) can:
                </p>
              </Col>
            </Row>
            <Row type="flex" className="landing-section-four-image">
              <Col xs={0} sm={0} md={12} lg={10} xl={12} xxl={12}>
                <div className="landing-banner" style={{ backgroundImage: `url(${laptopScreen})`, height: '100%' }} />
              </Col>
              <Col
                className="landing-section-four-list"
                xs={{ span: 22, offset: 2, push: 1 }}
                sm={{ span: 21, offset: 3, push: 1 }}
                md={{ span: 10, offset: 1 }}
                lg={10}
                xl={8}
                xxl={7}
                justify="center"
                align="middle"
              >
                <ul>
                  <li>help improve the chance of survival</li>
                  <li>make your business more attractive to Insurers</li>
                  <li>reduce downtime</li>
                  <li>opportunistically improve efficiency and costs</li>
                  <li>provide a competitive advantage</li>
                </ul>
              </Col>
            </Row>
          </section>
          <section className="landing-section-five" style={{ backgroundImage: `url(${bannerBottom})` }}>
            <div className="landing-section-five-overlay">
              <h2>Gain a better understanding of the risks facing your business</h2>
              <h1>FAST. SECURE. EASY</h1>
            </div>
          </section>
          <Row type="flex" justify="center" className="landing-section-six">
            <Col xs={20} sm={20} md={22} lg={20} xl={18} xxl={16}>
              <h2>Top 10 global</h2>
              <h1>disruptions</h1>
              <div className="landing-disruption">
                <Button type="link" className="landing-disruption-btn" onClick={() => this.slider.current.prev()}>
                  <i className="fa fa-angle-left" />
                </Button>
                <div className="landing-disruption-carousel">
                  <Carousel
                    dots={false}
                    ref={this.slider}
                    slidesToShow={Math.min(Math.floor(innerWidth / 250), 5)}
                    slidesToScroll={Math.min(Math.floor(innerWidth / 250), 5)}
                  >
                    <li className="landing-disruption-item blue">
                      <i className="fa fa-desktop landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        1<sup>st</sup>
                      </div>
                      <div className="landing-disruption-item-title">Unplanned IT and telecom outages</div>
                    </li>
                    <li className="landing-disruption-item dark-blue">
                      <i className="fa fa-medkit landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        2<sup>nd</sup>
                      </div>
                      <div className="landing-disruption-item-title">Health and safety incident</div>
                    </li>
                    <li className="landing-disruption-item yellow">
                      <i className="fa fa-users landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        3<sup>rd</sup>
                      </div>
                      <div className="landing-disruption-item-title">Lack of talent/Key skills</div>
                    </li>
                    <li className="landing-disruption-item red">
                      <i className="fa fa-shield landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        4<sup>th</sup>
                      </div>
                      <div className="landing-disruption-item-title">Cyber attack</div>
                    </li>
                    <li className="landing-disruption-item green">
                      <i className="fa fa-arrow-circle-left landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        5<sup>th</sup>
                      </div>
                      <div className="landing-disruption-item-title">Product recall</div>
                    </li>
                    <li className="landing-disruption-item blue">
                      <i className="fa fa-snowflake-o landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        6<sup>th</sup>
                      </div>
                      <div className="landing-disruption-item-title">Adverse weather/natural disaster</div>
                    </li>
                    <li className="landing-disruption-item dark-blue">
                      <i className="fa fa-money landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        7<sup>th</sup>
                      </div>
                      <div className="landing-disruption-item-title">Exchange rate volatility</div>
                    </li>
                    <li className="landing-disruption-item yellow">
                      <i className="fa fa-globe landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        8<sup>th</sup>
                      </div>
                      <div className="landing-disruption-item-title">Natural resources shortage</div>
                    </li>
                    <li className="landing-disruption-item red">
                      <i className="fa fa-file-text landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        9<sup>th</sup>
                      </div>
                      <div className="landing-disruption-item-title">Regulatory changes</div>
                    </li>
                    <li className="landing-disruption-item green">
                      <i className="fa fa-line-chart landing-disruption-item-icon" />
                      <div className="landing-disruption-item-rank">
                        10
                        <sup>th</sup>
                      </div>
                      <div className="landing-disruption-item-title">Higher cost of borrowing</div>
                    </li>
                  </Carousel>
                </div>
                <Button type="link" className="landing-disruption-btn" onClick={() => this.slider.current.next()}>
                  <i className="fa fa-angle-right" />
                </Button>
              </div>
              <p>Top ten disruptions - past twelve months. Courtesy of the BCI Horizon Scan Report 2019.</p>
            </Col>
          </Row>
          <Row type="flex" justify="center" className="landing-section-seven">
            <Col xs={20} sm={20} md={22} lg={20} xl={18} xxl={16}>
              <div className="news-section">
                <div className="page-title">
                  <h1>Latest News</h1>
                </div>

                <div className="landing-news-btn">
                  <Button
                    type="link"
                    icon="left"
                    onClick={() => this.sliderLatestNews.current.prev()}
                    className="landing-news-btn-left"
                  />
                  <Button
                    type="link"
                    icon="right"
                    onClick={() => this.sliderLatestNews.current.next()}
                    className="landing-news-btn-right"
                  />
                </div>
                <div className="news-container">
                  <div className="carousel-content-container">
                    <Carousel
                      dots={false}
                      ref={this.sliderLatestNews}
                      slidesToShow={innerWidth <= 900 ? 1 : 4}
                      slidesToScroll={innerWidth <= 900 ? 1 : 2}
                    >
                      {newsList.map(news => (
                        <NewsContainer
                          key={news.guid}
                          title={news.title}
                          content={news.content}
                          contentSnippet={news.contentSnippet}
                          creator={news.creator}
                          publishedDate={news.pubDate}
                          url={news.link}
                        />
                      ))}
                    </Carousel>
                  </div>
                </div>
                <div className="btn-container">
                  <Button
                    type="primary"
                    href="https://www.lmigroup.io/news/"
                    size="large"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More News
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }
}
