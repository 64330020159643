import React, { Component } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import PropTypes from 'prop-types';

export default class RtoRpoChart extends Component {
  static propTypes = {
    height: PropTypes.number
  };

  static defaultProps = {
    height: 500
  };

  render() {
    const { height, tacticalBias, mtpd } = this.props;
    const xAxisData = tacticalBias.map(item => item.departmentName);
    const rto = tacticalBias.map(item => item.rto);
    const rpo = tacticalBias.map(item => item.rpo);
    const options = {
      tooltip: {},
      legend: {
        right: '10%'
      },
      grid: {
        bottom: 100
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: 45
        },
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        max: mtpd.value,
        min: 0
      },
      series: [
        {
          name: 'Recovery Point Objective',
          type: 'bar',
          data: rpo,
          itemStyle: {
            color: '#aabd8c'
          }
        },
        {
          name: 'Recovery Time Objective',
          type: 'bar',
          data: rto,
          itemStyle: {
            color: '#dd6031'
          }
        },
        {
          name: 'Maximum Tolerable Period of Disruption',
          type: 'line',
          itemStyle: {
            color: '#f1c400'
          },
          markLine: {
            label: {
              show: true,
              position: 'middle',
              formatter: `(Maximum Tolerable Period of Disruption: ${mtpd.value} days)`
            },
            lineStyle: {
              color: '#f1c400',
              type: 'dashed'
            },
            data: [
              {
                yAxis: mtpd.value
              }
            ],
            symbol: 'circle',
            symbolSize: 0,
            animation: false
          }
        }
      ]
    };

    return (
      <ReactEchartsCore
        echarts={echarts}
        option={options}
        notMerge
        lazyUpdate
        className="widget"
        style={{ height: `${height}px` }}
      />
    );
  }
}
