import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Card from '../../../../components/Card/Card';
import BusinessInfo from '../../components/BusinessInfo';
import OrganisationLogo from '../../components/OrganisationLogo';
import Branch from '../Branch/Branch';
import AlternativeSite from '../AlternativeSite/AlternativeSite';
import { uploadOrganisationLogo } from '../../OrganisationAction';
import { orgIdSelector, businessNameSelector, businessLogoSelector } from '../../OrganisationSelectors';

const OrganisationSetting = ({ orgId, businessName, businessLogo, uploadOrganisationLogo }) => (
  <div className="component-wrapper">
    <Row gutter={20}>
      <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={18}>
        <BusinessInfo />
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6}>
        <Card>
          <div className="organisation-logo-inner">
            <OrganisationLogo orgId={orgId} logoUrl={businessLogo} upload={uploadOrganisationLogo} />
            <div className="organisation-logo-title">{businessName}</div>
          </div>
        </Card>
      </Col>
      <Col span={24}>
        <Branch />
        <AlternativeSite />
      </Col>
    </Row>
  </div>
);

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  businessName: businessNameSelector(state),
  businessLogo: businessLogoSelector(state)
});

const mapDispatchToProps = { uploadOrganisationLogo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationSetting);
