import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

export const requestBrokerOrgsSelector = state => state.brokerReducer.requestBrokerOrgs;

export const loadingBrokerSelector = state => state.brokerReducer.loadingBroker;

export const brokerInfoSelector = state => state.brokerReducer.brokerInfo;

export const loadingBrokersSelector = state => state.brokerReducer.loadingBrokers;

export const brokersSelector = state => state.brokerReducer.brokers;

export const loadingBrokerageSelector = state => state.brokerReducer.loadingBrokerage;

export const brokerageInfoSelector = state => state.brokerReducer.brokerageInfo;

export const brokerIdSelector = createSelector(brokerInfoSelector, brokerInfo =>
  brokerInfo ? brokerInfo.brokerId : null
);

export const subscriptionsSelector = state => state.brokerReducer.brokerageSubscriptions;

export const loadingBrokerageSubscriptionsSelector = state => state.brokerReducer.loadingBrokerageSubscriptions;

export const brokerageSummarySelector = createSelector(subscriptionsSelector, subscriptions =>
  subscriptions ? subscriptions.summary : null
);

export const brokerageSubscriptionsSelector = createSelector(
  subscriptionsSelector,
  brokersSelector,
  (subscriptions, brokers) =>
    subscriptions && subscriptions.subscriptions
      ? _.chain(subscriptions.subscriptions)
          .map(item => {
            const broker = _.find(brokers, { brokerId: item.assignedById });
            const brokerName = broker ? `${broker.firstName} ${broker.lastName}` : null;
            const activeDate = item.activeDate ? moment(item.activeDate).format('DD/MM/YYYY') : null;
            const exactExpiryDate = item.expiryDate ? item.expiryDate : null;
            // if a Subscription has no expiry and is not assigned to a Plan, then it has no expiry.
            // if a Subscription has no expiry but is assigned to a Plan, then that Subscription expires 1 year after the activation date
            let isExpired = false;
            let expiryDate = null;
            if (!item.promoCodeId && !item.isRevoked) {
              if (!item.expiryDate) {
                if (item.planId && item.activeDate) {
                  expiryDate = moment(item.activeDate)
                    .add(1, 'year')
                    .format('DD/MM/YYYY');
                  const currentDate = moment();
                  isExpired = moment(item.activeDate)
                    .add(1, 'year')
                    .isBefore(currentDate);
                }
              } else {
                expiryDate = moment(item.expiryDate).format('DD/MM/YYYY');
                const currentDate = moment();
                isExpired = moment(item.expiryDate).isBefore(currentDate);
              }
            }
            return { ...item, brokerName, activeDate, expiryDate, exactExpiryDate, isExpired };
          })
          .value()
      : undefined
);

export const brokerageSubscriptionsExpiredCountSelector = createSelector(
  brokerageSubscriptionsSelector,
  subscriptions => {
    let expiredCount = 0;
    if (subscriptions) {
      subscriptions.forEach(item => {
        if (item.isExpired) {
          expiredCount += 1;
        }
      });
      return expiredCount;
    }
    return expiredCount;
  }
);

export const brokerageIdSelector = createSelector(brokerInfoSelector, brokerInfo =>
  brokerInfo ? brokerInfo.brokerageId : null
);

export const brokerEmailSelector = createSelector(brokerInfoSelector, brokerInfo =>
  brokerInfo ? brokerInfo.email : null
);

export const promoCodesSelector = createSelector(subscriptionsSelector, brokersSelector, (subscriptions, brokers) => {
  return subscriptions && subscriptions.promoCodes
    ? _.chain(subscriptions.promoCodes)
        .map(item => {
          const broker = _.find(brokers, { brokerId: item.createdById });
          const brokerName = broker ? `${broker.firstName} ${broker.lastName}` : null;
          const expiryDate = item.expiryDate ? moment(item.expiryDate).format('DD/MM/YYYY') : null;
          const exactExpiryDate = item.expiryDate ? item.expiryDate : null;
          let isExpired = false;
          if (!item.isRevoked && item.expiryDate) {
            const currentDate = new Date();
            isExpired = new Date(item.expiryDate).getTime() < currentDate.getTime();
          }
          return { ...item, brokerName, expiryDate, exactExpiryDate, isExpired };
        })
        .value()
    : undefined;
});

export const promoCodesExpiredSelector = createSelector(promoCodesSelector, promoCodes => {
  let expiredCount = 0;
  if (promoCodes) {
    promoCodes.forEach(item => {
      if (item.isExpired) {
        expiredCount += 1;
      }
    });
    return expiredCount;
  }
  return expiredCount;
});
