import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';

const { SubMenu } = Menu;

export default ({ selectedKey }) => (
  <Menu mode="inline" inlineIndent={8} selectedKeys={[selectedKey]} className="sider-menu">
    <Menu.Item key="/dashboard">
      <Link to="/dashboard">
        <Icon type="dashboard" />
        <span>Dashboard</span>
      </Link>
    </Menu.Item>
    <SubMenu
      key="enterprise_risk_management"
      title={
        <span>
          <Icon type="exception" />
          <span title="Enterprise Risk Management">Enterprise Risk Management</span>
        </span>
      }
    >
      <Menu.Item key="/dashboard/risk/exposures">
        <Link to="/dashboard/risk/exposures">Risk Exposures</Link>
      </Menu.Item>
      <Menu.Item key="/dashboard/risk/assessment">
        <Link to="/dashboard/risk/assessment">Risk and Threat Assessment</Link>
      </Menu.Item>
      <Menu.Item key="/dashboard/risk/register">
        <Link to="/dashboard/risk/register">Risk Register</Link>
      </Menu.Item>
      <Menu.Item key="/dashboard/risk/report">
        <Link to="/dashboard/risk/report">
          <Icon type="file-text" />
          Risk &amp; Insurance Report
        </Link>
      </Menu.Item>
    </SubMenu>
    <SubMenu
      key="business_continuity_management"
      title={
        <span>
          <Icon type="area-chart" />
          <span title="Business Continuity Management">Business Continuity Management</span>
        </span>
      }
    >
      <SubMenu key="policy_program" title="Program" className="sider-menu-program">
        <Menu.Item key="/dashboard/business-units">
          <Link to="/dashboard/business-units">Business Units</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/teams">
          <Link to="/dashboard/teams">Teams</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/policy" title="Business Continuity Policy Accountable Members">
          <Link to="/dashboard/policy">Accountable Members</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="analysis" title="Analysis" className="sider-menu-analysis">
        <Menu.Item key="/dashboard/business-impact-analysis/strategic" title="Strategic Business Impact Analysis">
          <Link to="/dashboard/business-impact-analysis/strategic">Strategic Business Impact Analysis</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/business-impact-analysis/tactical" title="Tactical Business Impact Analysis">
          <Link to="/dashboard/business-impact-analysis/tactical">Tactical Business Impact Analysis</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="design" title="Design" className="sider-menu-design">
        <Menu.Item key="/dashboard/business-impact-analysis/operational" title="Operational Business Impact Analysis">
          <Link to="/dashboard/business-impact-analysis/operational">Operational Business Impact Analysis</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/recovery-strategy">
          <Link to="/dashboard/recovery-strategy">Recovery Strategy</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="implementation" title="Implementation" className="sider-menu-implementation">
        <Menu.Item key="/dashboard/plan-document" title="Business Continuity Plan">
          <Link to="/dashboard/plan-document">
            <Icon type="file-text" />
            Business Continuity Plan
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="validation" title="Validation" className="sider-menu-validation">
        <Menu.Item>Coming Soon...</Menu.Item>
      </SubMenu>
    </SubMenu>
    <Menu.Item key="/dashboard/activation-levels">
      <Link to="/dashboard/activation-levels">
        <Icon type="setting" />
        <span>Activation Levels</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/dashboard/documents">
      <Link to="/dashboard/documents">
        <Icon type="folder-open" />
        <span>Documents</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/dashboard/glossary">
      <Link to="/dashboard/glossary">
        <Icon type="book" />
        <span>Glossary</span>
      </Link>
    </Menu.Item>
  </Menu>
);
