import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, message } from 'antd';
import { withOnFocus } from '../../../../../components/HOC';
import AddressSearchBox from '../../../../../components/AddressSearchBox/AddressSearchBox';
import { createAlternativeSite, updateAlternativeSite } from '../AlternativeSiteAction';
import { orgIdSelector } from '../../../OrganisationSelectors';
import { loadingAlternativeSitesSelector } from '../AlternativeSiteSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../constants';
import explanations from '../data/explanations.json';

const FormItem = Form.Item;

class AlternativeSiteEdit extends Component {
  constructor(props) {
    super(props);
    this.InputWithOnFocus = withOnFocus(Input, props.onExplanationChange);
    this.SearchBoxWithOnFocus = withOnFocus(AddressSearchBox, props.onExplanationChange);
  }

  onSubmit = e => {
    e.preventDefault();
    const { form, orgId, alternativeSiteInfo, updateAlternativeSite, createAlternativeSite, close } = this.props;
    form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        const values = Object.keys(fieldsValue).reduce((acc, key) => {
          acc[key] =
            fieldsValue[key] && typeof fieldsValue[key] === 'string' ? fieldsValue[key].trim() : fieldsValue[key];
          return acc;
        }, {});
        try {
          if (alternativeSiteInfo) {
            const { alternativeSiteId } = alternativeSiteInfo;
            await updateAlternativeSite(orgId, alternativeSiteId, values);
          } else {
            await createAlternativeSite(orgId, values);
          }
          message.success(MSG_SAVE_SUCCESS);
          close();
        } catch (err) {
          message.error(MSG_ERROR);
        }
      }
    });
  };

  render() {
    const { InputWithOnFocus, SearchBoxWithOnFocus } = this;
    const { form, alternativeSiteInfo = {}, loading } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form>
        <FormItem label="Name">
          {getFieldDecorator('name', {
            initialValue: alternativeSiteInfo.name,
            rules: [{ required: true, message: 'Please input the name.' }]
          })(
            <InputWithOnFocus
              placeholder="Name"
              disabled={loading}
              spellCheck={false}
              explanation={explanations.name}
            />
          )}
        </FormItem>
        <Row gutter={20}>
          <Col span={16}>
            <FormItem label="Address">
              {getFieldDecorator('address', {
                initialValue: alternativeSiteInfo.address,
                rules: [{ required: true, message: 'Please input the address.' }]
              })(
                <SearchBoxWithOnFocus
                  placeholder="Address"
                  disabled={loading}
                  spellCheck={false}
                  explanation={explanations.address}
                />
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Unit/Suite/Floor">
              {getFieldDecorator('additionalAddressInformation', {
                initialValue: alternativeSiteInfo.additionalAddressInformation
              })(
                <InputWithOnFocus
                  placeholder="Unit/Suite/Floor"
                  disabled={loading}
                  explanation={explanations.additionalAddressInformation}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem label="Email">
          {getFieldDecorator('email', {
            initialValue: alternativeSiteInfo.email,
            validateTrigger: 'onBlur',
            rules: [{ type: 'email', message: 'Please input an valid email.' }]
          })(
            <InputWithOnFocus
              placeholder="Email"
              disabled={loading}
              spellCheck={false}
              explanation={explanations.email}
            />
          )}
        </FormItem>
        <FormItem label="Phone">
          {getFieldDecorator('phone', {
            initialValue: alternativeSiteInfo.phone
          })(<InputWithOnFocus placeholder="Phone" disabled={loading} explanation={explanations.phone} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" block loading={loading} onClick={this.onSubmit}>
            {loading ? 'Saving' : 'Save'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  loading: loadingAlternativeSitesSelector(state)
});

const mapDispatchToProps = { createAlternativeSite, updateAlternativeSite };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AlternativeSiteEdit));
