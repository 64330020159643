import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Radio, Button, Input, Select, message, Icon } from 'antd';
import Card from '../../../../../../../components/Card/Card';
import { criticalPeriodPresetsSelector, loadingStrategicBiasSelector } from '../../../BiaSelectors';

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const { TextArea } = Input;
const { Option } = Select;
const CRITICALITIES = [
  { value: 1, text: '1 Desirable' },
  { value: 2, text: '2 Necessary' },
  { value: 3, text: '3 Essential' },
  { value: 4, text: '4 Critical' }
];

const Question = ({ strategicBia, loading, criticalPeriodPresets = [], onSave, explanations, onExplanationChange }) => {
  const [isCritical, setIsCritical] = useState(!!strategicBia.strategicBiaId);
  const [criticality, setCriticality] = useState(strategicBia.criticality);
  const [criticalPeriods, setCriticalPeriods] = useState((strategicBia.criticalPeriods || []).map(item => item.name));
  const [penalty, setPenalty] = useState(strategicBia.penalty);
  const [showPenalties, setShowPenalties] = useState(!!penalty);
  const [penaltyAttachment, setPenaltyAttachment] = useState(strategicBia.penaltyAttachment);
  const [notes, setNotes] = useState(strategicBia.notes);

  const penalties =
    strategicBia.strategicCriticalArea.strategicCriticalAreaCategory.strategicCriticalAreaCategoryPenalties;

  const hasPenalties = penalties && penalties.length > 0;

  const criticalPeriodOptions = criticalPeriodPresets.map(item => (
    <Option key={item.name} value={item.name}>
      {item.name}
    </Option>
  ));

  const onCriticalChange = e => {
    setIsCritical(e.target.value);
    setShowPenalties(false);
  };

  const save = () => {
    if (isCritical && (!criticality || criticality === 0)) {
      message.error('Please select criticality');
      return;
    }
    const data = {};
    data.criticality = criticality;
    data.criticalPeriods = criticalPeriods;
    data.penalty = showPenalties ? penalty : '';
    data.penaltyAttachment = penalty === 'Other' ? penaltyAttachment : null;
    data.notes = showPenalties ? notes : '';
    if (!strategicBia.strategicBiaId && isCritical) {
      data.strategicCriticalAreaId = strategicBia.strategicCriticalArea.strategicCriticalAreaId;
    }
    onSave(isCritical, data);
  };

  return (
    strategicBia && (
      <Card
        color="#343579"
        title={`"${strategicBia.strategicCriticalArea.name}"`}
        description="You must answer the questions below for each Critical Strategic Area you identified."
        icon={
          <Icon
            type="info-circle-o"
            className="bia-info-icon"
            onClick={() => onExplanationChange(explanations.default)}
          />
        }
      >
        <div className="bia-sca-question">
          {isCritical !== null && (
            <Row className="bia-sca-question-item">
              <Col span={10}>
                Is this Critical?
                <Icon
                  type="info-circle-o"
                  className="bia-info-icon"
                  onClick={() => onExplanationChange(explanations.isThisCritical)}
                />
              </Col>
              <Col span={13} offset={1}>
                <RadioGroup onChange={onCriticalChange} value={isCritical} disabled={loading}>
                  <Radio value>yes</Radio>
                  <Radio value={false}>no</Radio>
                </RadioGroup>
              </Col>
            </Row>
          )}
          {isCritical && (
            <Row className="bia-sca-question-item">
              <Col span={10}>
                What is the Criticality of {strategicBia.strategicCriticalArea.name}?
                <Icon
                  type="info-circle-o"
                  className="bia-info-icon"
                  onClick={() => onExplanationChange(explanations.criticality)}
                />
              </Col>
              <Col span={13} offset={1}>
                <RadioGroup onChange={e => setCriticality(e.target.value)} value={criticality} disabled={loading}>
                  {CRITICALITIES.map(item => (
                    <RadioButton key={item.value} value={item.value}>
                      {item.text}
                    </RadioButton>
                  ))}
                </RadioGroup>
              </Col>
            </Row>
          )}
          {isCritical && (
            <Row className="bia-sca-question-item">
              <Col span={10}>
                Critical Period
                <Icon
                  type="info-circle-o"
                  className="bia-info-icon"
                  onClick={() => onExplanationChange(explanations.criticalPeriod)}
                />
              </Col>
              <Col span={13} offset={1}>
                <Select
                  mode="tags"
                  value={criticalPeriods}
                  placeholder="Type critical periods or select from the list"
                  disabled={loading}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={setCriticalPeriods}
                  style={{ width: '100%' }}
                >
                  {criticalPeriodOptions}
                </Select>
              </Col>
            </Row>
          )}
          {isCritical && hasPenalties && (
            <Row className="bia-sca-question-item">
              <Col span={10}>
                Does this area have Non delivery Penalties?
                <Icon
                  type="info-circle-o"
                  className="bia-info-icon"
                  onClick={() => onExplanationChange(explanations.nonDeliveryPenalty)}
                />
              </Col>
              <Col span={13} offset={1}>
                <RadioGroup onChange={e => setShowPenalties(e.target.value)} value={showPenalties} disabled={loading}>
                  <Radio value>yes</Radio>
                  <Radio value={false}>no</Radio>
                </RadioGroup>
              </Col>
            </Row>
          )}
          {hasPenalties && showPenalties && (
            <Row className="bia-sca-question-item">
              <Col span={10}>
                Delivery Penalties
                <Icon
                  type="info-circle-o"
                  className="bia-info-icon"
                  onClick={() => onExplanationChange(explanations.deliveryPenalties)}
                />
              </Col>
              <Col span={13} offset={1}>
                <RadioGroup onChange={e => setPenalty(e.target.value)} value={penalty} disabled={loading}>
                  {penalties.map(item => (
                    <Radio key={item.penalty.penaltyId} value={item.penalty.name}>
                      {item.penalty.name}
                    </Radio>
                  ))}
                </RadioGroup>
                {penalty === 'Other' && (
                  <Input
                    defaultValue={penaltyAttachment}
                    onBlur={e => setPenaltyAttachment(e.target.value)}
                    disabled={loading}
                    className="bia-sca-input"
                  />
                )}
              </Col>
            </Row>
          )}
          {isCritical && penalty && showPenalties && (
            <Row className="bia-sca-question-item">
              <Col span={10}>
                Comments
                <Icon
                  type="info-circle-o"
                  className="bia-info-icon"
                  onClick={() => onExplanationChange(explanations.deliveryPenaltyComments)}
                />
              </Col>
              <Col span={13} offset={1}>
                <TextArea value={notes} onChange={e => setNotes(e.target.value)} disabled={loading} />
              </Col>
            </Row>
          )}
          <div className="btn-container center">
            <Button type="primary" className="bia-btn" onClick={save} loading={loading}>
              Next
            </Button>
          </div>
        </div>
      </Card>
    )
  );
};

const mapStateToProps = state => ({
  criticalPeriodPresets: criticalPeriodPresetsSelector(state),
  loading: loadingStrategicBiasSelector(state)
});

export default connect(mapStateToProps)(Question);
