import React from 'react';
import { Button, Select, Input, message, Spin } from 'antd';
import * as HttpStatus from 'http-status-codes';
import Card from '../../../../../components/Card/Card';
import Table from '../../../../../components/Table/Table';
import AddressSearchBox from '../../../../../components/AddressSearchBox/AddressSearchBox';
import { MSG_SAVE_SUCCESS, MSG_ERROR, MSG_PAYMENT_REQUIRED } from '../../../../../constants';

const { Option } = Select;
const { TextArea } = Input;

export default ({
  title,
  data,
  planId,
  locations,
  loading,
  onLocationChange,
  onCommentChange,
  createRecoveryStrategy,
  updateRecoveryStrategy,
  fetchRecoveryStrategy
}) => {
  const locationOptions = locations.map(item => (
    <Option key={item} value={item}>
      {item}
    </Option>
  ));

  const onSave = async () => {
    const processedData = data.map(item => {
      const address = item.recoveryStrategyAddress === 'Other' ? item.otherAddress : item.recoveryStrategyAddress;
      return { ...item, address, comments: item.recoveryStrategyComments };
    });
    const promises = [];
    const createdRecords = processedData.filter(obj => !obj.recoveryStrategyId);
    const updatedRecords = processedData.filter(obj => obj.recoveryStrategyId);
    if (createdRecords.length > 0) {
      promises.push(createRecoveryStrategy(planId, createdRecords));
    }
    if (updatedRecords.length > 0) {
      promises.push(updateRecoveryStrategy(planId, updatedRecords));
    }
    if (promises.length > 0) {
      try {
        await Promise.all(promises);
        await fetchRecoveryStrategy(planId);
        message.success(MSG_SAVE_SUCCESS);
      } catch (err) {
        switch (err.status) {
          case HttpStatus.PAYMENT_REQUIRED:
            message.error(MSG_PAYMENT_REQUIRED);
            break;
          default:
            message.error(MSG_ERROR);
        }
      }
    }
  };

  const columns = [
    { key: 'department', title: 'Department', dataIndex: 'name', span: 2 },
    {
      key: 'location',
      title: 'Recovery Strategy Location',
      span: 2,
      render: (text, record) =>
        record.recoveryStrategyAddress === 'Other' ? (
          <AddressSearchBox
            value={record.otherAddress}
            placeholder="Please enter address"
            disabled={loading}
            onChange={value => onLocationChange(value, record, true)}
          />
        ) : (
          <Select
            value={record.recoveryStrategyAddress}
            disabled={loading}
            onSelect={value => onLocationChange(value, record)}
            style={{ width: '100%' }}
          >
            {locationOptions}
          </Select>
        )
    },
    {
      key: 'comments',
      title: 'Comments',
      span: 2,
      render: (text, record) => (
        <TextArea
          rows={1}
          value={record.recoveryStrategyComments}
          onChange={e => onCommentChange(e.target.value, record)}
          disabled={loading}
        />
      )
    }
  ];

  return (
    <Card color="#F1C400" title={title}>
      <Spin spinning={loading}>
        <Table title="title" columns={columns} dataSource={data} rowKey="name" />
        <div className="btn-container">
          <Button type="primary" loading={loading} onClick={onSave}>
            {loading ? 'Saving' : 'Save'}
          </Button>
        </div>
      </Spin>
    </Card>
  );
};
