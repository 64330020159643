import React, { useState, useEffect } from 'react';
import { Layout, Button, BackTop } from 'antd';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Header from '../Header/Header';
import Sider from '../Sider/Sider';
import Footer from '../Footer/Footer';
import './Layout.scss';
import TwoFactorBanner from '../Banner/TwoFactorBanner';
import { showNotificationSelector, showSmsSettingBannerSelector } from '../../scenes/Admin/scenes/User/UserSelectors';

const { Content } = Layout;
const WIDTH_SIDER = 280;

export default ({ children, sider, hasSider, bg }) => {
  const [siderVisible, setSiderVisible] = useState(false);
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const showNotification = useSelector(showNotificationSelector);
  const showSmsSettingBanner = useSelector(showSmsSettingBannerSelector);

  useEffect(() => {
    const onResize = () => {
      const mq = window.matchMedia('(max-width: 1024px)');
      setSiderCollapsed(mq.matches);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  const toggleSiderVisible = () => {
    setSiderVisible(!siderVisible);
  };

  return (
    <Layout className="layout">
      <Header hasSider={sider || hasSider} siderWidth={WIDTH_SIDER} toggleSiderVisible={toggleSiderVisible} />
      {showNotification && showSmsSettingBanner && <TwoFactorBanner />}
      <Layout>
        {(sider || hasSider) && (
          <Layout.Sider
            width={WIDTH_SIDER}
            collapsible
            collapsed={siderCollapsed}
            trigger={null}
            className={classNames('sider', { show: siderVisible, collapsed: siderCollapsed })}
          >
            <Button
              type="link"
              icon={siderCollapsed ? 'menu-unfold' : 'menu-fold'}
              className="sider-toggle-btn"
              onClick={toggleSider}
            />
            {sider}
            {hasSider && <Sider collapsed={siderCollapsed} toggle={toggleSider} />}
          </Layout.Sider>
        )}
        <Content className="content" style={{ backgroundImage: bg ? `url(${bg})` : null }}>
          {children}
        </Content>
      </Layout>
      <Footer />
      <BackTop />
    </Layout>
  );
};
