import React from 'react';
import { Icon } from 'antd';
import Logo from '../../../../../../../../components/Logo/Logo';
import './BackCover.scss';

export default () => (
  <section className="plan-preview-back-cover">
    <div className="company-intro">
      <div className="introduction">
        ContinuityCoach.com is structured and is updated where appropriate to reflect current best Business Continuity
        Management practice as stated in Business Continuity Institute Good Practice Guidelines &amp; International
        Organization for Standardization ISO 22301 Societal Security --- Business Continuity Management Systems ---
        Requirements.
      </div>
      <Logo width={200} />
    </div>
    <div className="company-info">
      <ul>
        <li>
          <span>Support@ContinuityCoach.com</span>
          <Icon type="mail" />
        </li>
        <li>
          <span>www.ContinuityCoach.com</span>
          <Icon type="home" />
        </li>
      </ul>
    </div>
  </section>
);
