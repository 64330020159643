import React from 'react';
import './Disclaimer.scss';

export default () => (
  <section className="plan-preview-disclaimer">
    <div className="disclaimer">
      <div className="disclaimer-content">
        <h2>Disclaimer / Terms and Conditions</h2>
        <p>
          The information provided on the Continuity Coach website is intended as a non-exhaustive guide and for
          information referencing and educational purposes only. Continuity Coach Pty Ltd shall in no event whatsoever
          be responsible for the results or outcomes or any actions taken on the basis of reliance on the material
          contained in the site nor accept any liability for loss or damage suffered by any person or body due to
          information provided on the site nor for any error in or omission from the material contained therein or those
          sites linked to the Continuity Coach website. Continuity Coach Pty Ltd is not providing advice of a business,
          financial, legal or any other kind by or through the making available of information through the site. The
          information on the website is provided solely on the basis that persons accessing the website undertake
          responsibility for assessing the accuracy and relevance of its content. The content of websites linked to this
          site and the views expressed by contributors and advertisers are not necessarily or implicitly those of
          Continuity Coach Pty Ltd and are inserted and/or placed for convenience and do not constitute endorsement of
          any material that may be at those sites nor does it constitute an endorsement of any associated body, product
          or service.
        </p>
      </div>
    </div>
  </section>
);
