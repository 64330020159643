import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Input, Upload, Icon, Button } from 'antd';
import { foldersSelector, loadingDocumentsSelector } from '../DocumentSelectors';

const { Dragger } = Upload;

const DocumentAdd = ({ folders, loading, onUploadDocument, close, onAddUrl }) => {
  const [folderName, setFolderName] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [URL, setURL] = useState(null);
  const [friendlyName, setFriendlyName] = useState(null);

  const onFolderNameChange = ({ target }) => {
    setFolderName(target.value);
  };

  const onURLChange = ({ target }) => {
    setURL(target.value);
  };
  const onFriendlyNameChange = ({ target }) => {
    setFriendlyName(target.value);
  };

  const onUpload = () => {
    let folder = {};
    const folderIndex = folders.findIndex(item => item.name && item.name.toLowerCase() === folderName.toLowerCase());
    if (folderIndex === -1) {
      folder = { id: 8, name: folderName };
    } else {
      const { id, name } = folders[folderIndex];
      folder = { id, name };
    }
    if (fileList[0] && URL === null) {
      onUploadDocument(folder, fileList[0]);
    } else {
      onAddUrl(folder, URL, friendlyName);
    }
    close();
  };

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      const newFileList = [...fileList, file];
      setFileList(newFileList);
      return false;
    },
    fileList
  };

  return (
    <>
      <Input placeholder="Folder Name" onChange={onFolderNameChange} style={{ marginBottom: '20px' }} />
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">Click or drag file to this area</p>
        <p className="ant-upload-hint">
          You can add document by clicking or dragging file to this area, and then click the Upload button
        </p>
      </Dragger>
      <h3 style={{ margin: '1em 0' }}>or</h3>
      <div>
        <Input placeholder="URL" onChange={onURLChange} style={{ marginBottom: '20px' }} />
        <Input placeholder="URL's Friendly Name" onChange={onFriendlyNameChange} style={{ marginBottom: '20px' }} />
      </div>
      <div className="btn-container no-padding">
        <Button
          type="primary"
          onClick={onUpload}
          loading={loading}
          disabled={!folderName || (fileList.length === 0 && !(URL && friendlyName))}
        >
          {loading ? 'Uploading' : 'Upload'}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  folders: foldersSelector(state),
  loading: loadingDocumentsSelector(state)
});

export default connect(mapStateToProps)(DocumentAdd);
