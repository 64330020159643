import React from 'react';

export default () => (
  <article className="terms-conditions-modal-inner">
    <h1>Privacy &amp; Security Statement</h1>
    <p>
      This Privacy Policy covers the collection and use of personal information that may be collected by us anytime you
      interact with ContinuityCoach Pty Ltd, such as when you visit our website or one of our offices, when you purchase
      ContinuityCoach Pty Ltd (online/manual) products and services, or when you call our technical advisers. Please
      take a moment to read the following to learn more about our information practices, including what type of
      information is gathered, how the information is used and for what purposes, to whom we disclose the information,
      and how we safeguard your personal information. Your privacy is a priority at ContinuityCoach.
    </p>
    <p>
      <strong>General</strong>
    </p>
    <p>
      ContinuityCoach Pty Ltd is committed to ensuring your privacy and understands your concerns regarding the
      confidentiality and security of personal information you provide to us. ContinuityCoach Pty Ltd&apos;s privacy
      policy complies with the Privacy Act 1988 (Cth) and the Australian Privacy Principles.
    </p>
    <p>
      <strong>Collection</strong>
    </p>
    <p>
      There are a number of situations in which your personal information may help us give you better service. For
      example, we may ask for your personal information when you&apos;re discussing a loss management or risk and threat
      assessment issue on the phone or face-to-face with a technical adviser, downloading an online subscription service
      or software update, registering for a training module, participating in an survey or purchasing a business
      publication. At such times, the information we may collect includes:
    </p>
    <ul>
      <li>
        your name, business address, ABN, home address, phone numbers, fax numbers, email address and in some cases your
        credit card number and contact preferences;
      </li>
      <li>
        your occupation/job role function, insurance/risk management information and information about the levels of
        covers you have, such as their policy numbers, insurers, excesses, security provisions, levels of gross profit
        and assets, extent of loss or damage and/or nature of injury sustained;
      </li>
      <li>
        information such as renewal dates, current premiums and details of previous claims history and financing
        arrangements; and
      </li>
      <li>details of financials may be needed to calculate the cost of your loss and assess claims.</li>
    </ul>
    <p>
      By providing ContinuityCoach Pty Ltd with your personal information you consent to its use for the following
      purposes:
    </p>
    <ul>
      <li>analysing client needs;</li>
      <li>fufilling customer orders;</li>
      <li>delivering products and services to you;</li>
      <li>managing and enhancing ContinuityCoach Pty Ltd customer data;</li>
      <li>providing customer service;</li>
      <li>
        assessing your interest in our products and services, or conducting customer research or satisfaction surveys in
        order improve our products and services; and
      </li>
      <li>developing and improving our products and services.</li>
    </ul>
    <p>
      In addition, your information allows us to send you direct marketing communications including announcements,
      software updates, special offers, and training events that you might like to hear about. We always give you the
      option of electing not to receive such notifications so please contact us if you do not wish to receive them.
    </p>
    <p>
      <strong>Failure to provide information</strong>
    </p>
    <p>
      We collect and use your personal information in order to provide you with information on financial products and
      services. In the absence of correct information or if you do not give us the information that we request
      ContinuityCoach Pty Ltd may be unable to determine which product or service is appropriate for your needs.
    </p>
    <p>
      <strong>Use &amp; Disclosure of Personal Information</strong>
    </p>
    <p>
      We cannot guarantee the privacy of personal information you transmit over the web or that it may be collected in
      transit by others, including contractors who provide services to us.
    </p>
    <p>
      However, be assured that ContinuityCoach Pty Ltd does not sell or rent your contact information to other marketers
      and we will never give your information to an outside organisation, unless we need to in order to assist you, the
      disclosure is outlined in this privacy policy or it is required by law or if we determine that for national
      security, law enforcement, or other issues of public importance, disclosure is necessary.
    </p>
    <p>
      ContinuityCoach Pty Ltd are a global organisation and for the purposes set out above we may transfer your personal
      information to parties located in other countries (including countries that have a different data protection
      regime than is found in Australia). Any data we collect will be shared, if appropriate, with any of the offices of
      ContinuityCoach Pty Ltd throughout the world, including the United States, Europe and Asia and any third parties
      who provide services on our behalf. If this is likely to happen, we will inform you prior if it is possible to do
      so
    </p>
    <p>
      The information we collect may be provided to organisations if we are required to assist you with a claim and
      these organisations could include:
    </p>
    <ul>
      <li>insurers/reinsurers;</li>
      <li>insurance advisers</li>
      <li>lawyers;</li>
      <li>assessors;</li>
      <li>repairers;</li>
      <li>advisers (including engineers, architects and other professional advisers);</li>
      <li>loss adjusters; and</li>
      <li>accountants.</li>
    </ul>
    <p>
      If information is provided to overseas organisations (including ContinuityCoach Pty Ltd offices located outside
      Australia), we will take reasonable steps to inform you prior and to ensure that they protect your information as
      required under the Privacy Act and the APPs.
    </p>
    <p>
      <strong>Security</strong>
    </p>
    <p>
      ContinuityCoach Pty Ltd takes precautions (including administrative, technical, and physical measures) to
      safeguard your personal information against loss, theft, and misuse, as well as unauthorised access, disclosure,
      alteration, and destruction. In some cases, your personal information may be included in our office files and
      these may be archived and sent to an external data storage provider for a period of time. We only use storage
      providers located in Australia who are also regulated by the Privacy Act.
    </p>
    <p>
      <strong>Collection of information by third-party sites and sponsors</strong>
    </p>
    <p>
      Where another website is linked on our website, ContinuityCoach Pty Ltd does not warrant that the site complies
      with its legal obligations regarding privacy and accessing the site is at the users risk in respect of such
      matters as bugs, inaccuracies, viruses, and the content on the site. Visitors should consult the other sites&apos;
      privacy notices as we have no control over information that is submitted to, or collected by, these third parties.
    </p>
    <p>
      <strong>Access &amp; Correction</strong>
    </p>
    <p>
      ContinuityCoach Pty Ltd will take reasonable steps to make sure that the personal information we collect, use or
      disclose is accurate, complete and up to date for the purposes for which it is used. Clients can access their
      personal information that ContinuityCoach Pty Ltd holds and correct the details you have provided at no charge. If
      you wish to access or correct your personal information please contact our Privacy Officer by email to{' '}
      <a href="mailto:Support@ContinuityCoach.com">Support@ContinuityCoach.com</a> or by telephone to{' '}
      <a href="tel:+61398359900">+61 3 9835 9900.</a>
    </p>
    <p>
      In some limited cases, we may need to refuse access to your information or refuse a request for correction. We
      will advise you as soon as possible after your request if this is the case and the reasons for our refusal.
    </p>
    <p>
      <strong>ContinuityCoach Pty Ltd&apos;s Website, Use of Cookies &amp; Other Technologies</strong>
    </p>
    <p>
      The ContinuityCoach Pty Ltd website is protected by encryption technology and it uses cookies. Cookies are very
      small text files that a website can transfer to your computer&apos;s hard drive for record keeping. Cookies can
      make using our website easier by storing information about your preferences and enabling you to take full
      advantage of our services.
    </p>
    <p>
      Most Internet web browsers are preset to accept cookies to enable full use of websites that employ them. However,
      if you do not wish to receive any cookies you may configure your browser to reject them or warn you when cookies
      are being used. In some instances, this may mean that you will not be able to use some or all of the services
      provided on our website. However you may still be able to access information only pages.
    </p>
    <p>
      <strong>Changes to this Policy</strong>
    </p>
    <p>
      From time to time, it may be necessary for us to review our Privacy Policy. We reserve the right to amend our
      Privacy Policy at any time and advise you by posting an updated version on ContinuityCoach Pty Ltd&apos;s website.
    </p>
    <p>
      <strong>Privacy Concerns or Complaints</strong>
    </p>
    <p>
      If you have concerns that we have not complied with the Privacy Act or this Privacy Policy, or if you would like
      to obtain further information about ContinuityCoach Pty Ltd&apos;s privacy policy, please refer to ContinuityCoach
      Pty Ltd&apos;s complaint procedure or contact our Privacy Officer via email:{' '}
      <a href="mailto:Support@ContinuityCoach.com">Support@ContinuityCoach.com</a> or telephone on{' '}
      <a href="tel:+61398359900">+61 3 9835 9900</a>. A ContinuityCoach representative will respond to your inquiry
      within 10 working days.
    </p>
  </article>
);
