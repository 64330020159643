import React, { Component } from 'react';

export default (WrappedComponent, handler) =>
  class extends Component {
    render() {
      const { explanation, ...otherProps } = this.props;
      return handler ? (
        <WrappedComponent onFocus={() => handler(explanation || {})} {...otherProps} />
      ) : (
        <WrappedComponent {...otherProps} />
      );
    }
  };
