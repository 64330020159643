import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';

export default ({ selectedKey, userRole }) => (
  <Menu mode="inline" inlineIndent={8} selectedKeys={[selectedKey]} className="sider-menu">
    <Menu.Item key="/organization">
      <Link to="/organization">
        <Icon type="home" />
        <span>Organization Settings</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/organization/contacts">
      <Link to="/organization/contacts">
        <Icon type="contacts" />
        <span>Internal Contacts</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/organization/customers">
      <Link to="/organization/customers">
        <Icon type="shopping-cart" />
        <span>Strategic Customers</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/organization/suppliers">
      <Link to="/organization/suppliers">
        <Icon type="car" />
        <span>Strategic Suppliers</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/organization/strategic-third-parties">
      <Link to="/organization/strategic-third-parties">
        <Icon type="team" />
        <span>Strategic Third Parties</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/organization/strategic-crisis-experts">
      <Link to="/organization/strategic-crisis-experts">
        <Icon type="solution" />
        <span>Strategic Crisis Experts</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/organization/strategic-areas">
      <Link to="/organization/strategic-areas">
        <Icon type="warning" />
        <span>Strategic Areas</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/organization/policy">
      <Link to="/organization/policy">
        <Icon type="file-text" />
        <span>Business Continuity Policy</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="/organization/plans">
      <Link to="/organization/plans">
        <Icon type="schedule" />
        <span>Plans</span>
      </Link>
    </Menu.Item>
    {userRole && (userRole === 'owner' || userRole === 'broker') && (
      <Menu.Item key="/organization/users">
        <Link to="/organization/users">
          <Icon type="team" />
          <span>Users</span>
        </Link>
      </Menu.Item>
    )}
  </Menu>
);
