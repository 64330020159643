import React from 'react';

export default ({ children, header }) => (
  <div className="risk-report-preview-table">
    <table>
      <thead>
        <tr className="table-header">
          {(header || []).map(item => (
            <th
              key={item.name}
              width={item.width ? `${item.width}%` : '100%'}
              colSpan={item.span}
              className="table-top-row"
            >
              {item.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  </div>
);
