import axios from 'axios';

export const PURCHASE_COUNT_UPDATE = 'purchase/PURCHASE_COUNT_UPDATE';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_PURCHASE = 'purchase';

export const updatePurchaseCount = purchaseCount => ({ type: PURCHASE_COUNT_UPDATE, purchaseCount });

export const validateDiscountCode = data => () => {
  const url = `${API_BASE}${API_PURCHASE}/discount-code/validate`;
  return axios.post(url, data);
};

export const calculateTotal = data => () => {
  const url = `${API_BASE}${API_PURCHASE}/calculate`;
  return axios.post(url, data);
};
