import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, Button, message } from 'antd';
import { createDepartment, deleteDepartment } from '../DepartmentAction';
import { orgIdSelector } from '../../../../Organisation/OrganisationSelectors';
import { branchIdSelector } from '../../Plan/PlanSelectors';
import { departmentListSelector, loadingDepartmentsSelector } from '../DepartmentSelectors';
import { MSG_SAVE_SUCCESS, MSG_ERROR } from '../../../../../constants';

const FormItem = Form.Item;
const { Option } = Select;

const departmentPresets = [
  'Finance',
  'Information Technology',
  'Engineering',
  'Call Centre',
  'Human Resource',
  'Sales'
];

const DepartmentAdd = ({
  form,
  orgId,
  branchId,
  loading,
  departmentList = [],
  createDepartment,
  deleteDepartment,
  close
}) => {
  const { getFieldDecorator } = form;

  const onSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const addDepartmentNames = values.names.filter(item => !departmentList.map(item => item.name).includes(item));
        const deleteDepartmentIds = departmentList
          .filter(obj => !values.names.includes(obj.name))
          .map(obj => obj.departmentId);
        try {
          if (addDepartmentNames.length > 0) {
            await createDepartment(orgId, branchId, { names: addDepartmentNames });
          }
          if (deleteDepartmentIds.length > 0) {
            await deleteDepartment(orgId, branchId, deleteDepartmentIds);
          }
          message.success(MSG_SAVE_SUCCESS);
          close();
        } catch (err) {
          message.error(MSG_ERROR);
        }
      }
    });
  };

  const departmentOptions = departmentPresets.map(departmentName => (
    <Option key={departmentName} value={departmentName}>
      {departmentName}
    </Option>
  ));

  return (
    <Form>
      <FormItem>
        {getFieldDecorator('names', {
          initialValue: departmentList.map(item => item.name)
        })(
          <Select
            mode="tags"
            placeholder="Add business units"
            disabled={loading}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            tokenSeparators={[',']}
          >
            {departmentOptions}
          </Select>
        )}
      </FormItem>
      <FormItem>
        <Button type="primary" block loading={loading} onClick={onSubmit}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </FormItem>
    </Form>
  );
};

const mapStateToProps = state => ({
  orgId: orgIdSelector(state),
  branchId: branchIdSelector(state),
  departmentList: departmentListSelector(state),
  loading: loadingDepartmentsSelector(state)
});

const mapDispatchToProps = { createDepartment, deleteDepartment };

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(DepartmentAdd));
