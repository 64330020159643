import React from 'react';
import ReactEcharts from 'echarts-for-react';

const COLOR_REMAINING = '#5BC2E7';
const COLOR_TOTAL = '#78849E';
const COLOR_CIRCLE = '#D8D8D8';

export default ({ summary }) => {
  const { total = 0, remaining = 0 } = summary;
  const data = [
    {
      value: remaining >= 0 ? remaining : 0,
      color: COLOR_REMAINING
    },
    {
      value: total - remaining >= 0 ? total - remaining : 0,
      color: COLOR_CIRCLE
    }
  ];

  const option = {
    title: {
      text: [`{remaining|${remaining}}`, `{remain|remaining}`, `{of|out of} {total|${total}}`].join(`\n`),
      x: 'center',
      y: 'center',
      textStyle: {
        rich: {
          remaining: {
            color: COLOR_REMAINING,
            lineHeight: 20,
            fontWeight: 600,
            fontSize: 32,
            padding: [30, 0, 10, 0],
            opcity: 1
          },
          remain: {
            color: COLOR_TOTAL,
            lineHeight: 0,
            fontSize: 11
          },
          of: {
            color: COLOR_TOTAL,
            fontSize: 11,
            lineHeight: 40
          },
          total: {
            color: COLOR_TOTAL,
            fontSize: 14
          }
        }
      }
    },
    series: [
      {
        type: 'pie',
        radius: ['35%', '45%'],
        data: data.sort((a, b) => b.value - a.value),
        labelLine: { show: false },
        itemStyle: { color: ({ data }) => data.color },
        top: -30
      }
    ]
  };

  return (
    <div className="summary-chart">
      <ReactEcharts option={option} notMerge lazyUpdate />
    </div>
  );
};
